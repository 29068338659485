import { FormControl } from '@angular/forms';
import { of } from 'rxjs';

export default class CECValidators {
    static EmptyStringValidator(control: FormControl) {
        const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
        const isValid = !isWhitespace;
        const returnedVal = isValid ? null : { 'whitespace': true };
        return of(returnedVal);
    }
}