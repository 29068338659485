import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/constants/constants';
import { BirthdayStar } from 'src/app/models/birthday-star';
import { AuthorizationApiService } from 'src/app/services/authorization-api.service';
import { ContentApiService } from 'src/app/services/content-api.service';
import { FreedompayService } from 'src/app/services/freedompay/freedompay.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ReservationApiService } from 'src/app/services/reservation-api.service';
import { Utilities } from 'src/app/services/utilities';
import { environment } from '../../../environments/environment';
import { WebString } from '../../constants/string';
import { Reservation } from '../../models/reservation';
import { SaveReservationDto } from '../../models/save-reservation-dto';
import { BookingApiService } from '../../services/booking-api.service';
import { GTMService } from '../../services/gtm.service';
import { ReservationService } from '../../services/reservation.service';
import { DynamicScriptService } from '../../services/script.service';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';
import { FreedompayComponent } from '../freedompay/freedompay.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import CECValidators from 'src/app/validators/CECValidators';
import { PromoService } from 'src/app/services/promo.service';

const Config = {
	fields: {
		card: {
			selector: '[data-cc-card]'
		},
		exp: {
			selector: '[data-cc-exp]'
		},
		name: {
			selector: '[data-cc-name]'
		}
	},

	// css classes to be injected into the iframes.
	// the properties allowed are restricted via whitelist.
	// further, unrestricted styling can be applied to the div's in which the iframes are injected.
	styles: {
		input: {
			'font-size': '16px',
			color: '#363f54',
			'font-family': 'monospace',
			background: 'black'
		},
		'.card': {
			'font-family': 'sans-serif'
		},
		':focus': {
			color: '#363f54'
		},
		'.valid': {
			color: '#363f54'
		},
		'.invalid': {
			color: '#363f54'
		},
		'@media screen and (max-width: 700px)': {
			input: {
				'font-size': '16px',
				color: '#363f54',
				'font-weight': '700'
			}
		},
		'input:-webkit-autofill': {
			'-webkit-box-shadow': '0 0 0 50px white inset'
		},
		'input:focus:-webkit-autofill': {
			'-webkit-text-fill-color': '#00a9e0'
		},
		'input.valid:-webkit-autofill': {
			'-webkit-text-fill-color': '#43B02A'
		},
		'input.invalid:-webkit-autofill': {
			'-webkit-text-fill-color': '#C01324'
		},
		'input::placeholder': {
			color: '#aaa'
		}
	},

	// these values correspond to css class names defined above
	classes: {
		empty: 'empty',
		focus: 'focus',
		invalid: 'invalid',
		valid: 'valid'
	}
};

const DomUtils = {
	getEl: selector => window.document.querySelector(selector),

	hasClass: (el, cssClass) => {
		if (el.classList) {
			return el.classList.contains(cssClass);
		}
		return !!el.className.match(new RegExp(`(\\s|^)${cssClass}(\\s|$)`));
	},

	removeClass: (el, cssClass) => {
		if (el.classList) {
			el.classList.remove(cssClass);
		} else if (DomUtils.hasClass(el, cssClass)) {
			const reg = new RegExp(`(\\s|^)${cssClass}(\\s|$)`);
			el.className = el.className.replace(reg, ' ');
		}
	}
};

class ccTokenResponseObject {
	isOk: boolean;
	token: string;
	message: string;
}
let ccTokenResponseObj: ccTokenResponseObject;

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(SideMenuComponent) sideMenu: SideMenuComponent;
	@ViewChild(FreedompayComponent) freedomPay: FreedompayComponent;

	$: any;
	adultName: any;
	alertColor: any;
	altDepositText = '';
	applicationMessage = '';
	arrPaymentFieldsName = ['billAddress', 'billCity', 'billState', 'billZip', 'billCVV', 'inPaymentDisclaimer'];
	assetRoot = environment.assetRoot;
	birthdayStars: BirthdayStar[];
	ccInfo: any = {};
	checkTokenInterval: any;
	currentStoreAcceptsOnlinePayment: any;
	currentStoreAcceptsonlyOnlinePayment: any;
	depositAmountDue: number;
	depositDueDate = '';
	email: any;
	gatewayPaymentTypes = Constants.GatewayPaymentTypes;
	hooks: any = {};
	isDepositRequired = false;
	isFormSubmitted = false;
	isLoading = false;
	isOldPPPConfirmationNumber = false;
	isPaymentFormEmpty = true;
	isPaymentFormValid = false;
	isReservationAlreadyPaid: any;
	isSMSEnabled = false;
	minimumAdults: number;
	minimumChildren: number;
	model: any = {};
	monerisIFrameSrc: any;
	organizationGuest: any = {};
	orgGroup: string[];
	partyDate: any;
	paymentCard = true;
	paymentForm: FormGroup;
	paymentScript: Promise<any>;
	phone: any;
	possibleDupes: any;
	promoApplied: boolean;
	promoCode = '';
	promoDescription = WebString.PROMODESCRIPTION;
	promoMessage: string;
	reservation: Reservation;
	reservationPrice: any;
	reservationTypeBirthday = Constants.ReservationTypes.BIRTHDAYPARTY;
	reservationTypeGroupEvent = Constants.ReservationTypes.GROUPEVENT;
	reservationTypePartyRoom = Constants.ReservationTypes.PARTYROOM;
	selectedStore = '';
	sfContent: any;
	showAlert = false;
	showDuplicateModal = false;
	showPromo = true;
	showPromoMessage: boolean;
	smsOptIn = false;
	smsPhone = '';
	specialInstructions: any;
	storePaymentHandler: any;
	summaryOpen = false;
	isFreedomPayFormValid = false;
	freedomPayPaymentInfo: any = {};
	subscribeValidatePayment$: Subscription;
	subscribeFormValidityHandler$: Subscription;
	selectedPaymentType = null;
	subscribeErrorHandler$: Subscription;
	holder: any;
	showIframeWrapper = false;
	zipCode = null;
	subscribepromoUpdatedInfo$: Subscription;
	subscribeClearPromo$: Subscription;


	readonly partyTypePricing = Constants.PriceAsPer.PartyType;

	get birthdayStarControls() {
		return (<FormArray>this.paymentForm.get('birthdayStars')).controls;
	}

	constructor(dynamicScript: DynamicScriptService, public reservationService: ReservationService, private router: Router, private storageService: StorageService,
		private gtmService: GTMService, private utilsService: UtilsService, private promoservice: PromoService,
		private fb: FormBuilder, private bookingApiService: BookingApiService, private contentApiService: ContentApiService,
		private logger: LoggingService, private authApiService: AuthorizationApiService, private reservationApiService: ReservationApiService, private freedomPayService: FreedompayService) {

		this.paymentScript = dynamicScript.load(['paymentjs']);
		this.hooks = { preFlowHook: this.authorizeSession.bind(this), paymentComponent: this };

		this.sfContent = {
			bookingReservationSubHeader: '',
			depositQuestion: '',
			upsellCalloutAfterConfirmation: '',
			upsellBlurbs: '',
			starPartyTypeDesc: '',
			superStarPartyTypeDesc: '',
			msStarPartyTypeDesc: '',
			additionalStarCopy: '',
			inStoreDepositDisclaimer: '',
			refundPolicy: ''
		};

		const localData = this.storageService.fetch();

		this.paymentForm = this.fb.group({
			phone: ['', Validators.required],
			email: ['', Validators.required, CECValidators.EmptyStringValidator],
			adultName: ['', Validators.required, CECValidators.EmptyStringValidator],
			...(parseInt(localData?.booking?.type) !== Constants.ReservationTypes.GROUPEVENT ? { birthdayStars: this.fb.array([this.newBirthdayStar()]) } : {}),

			// organization Fields
			organizationName: [''],
			organizationPhone: [''],
			organizationType: [''],

			// CC fields
			billAddress: [''],
			billCity: [''],
			billState: [''],
			billZip: [''],
			billCVV: [''],

			// random fields
			specialInstructions: [''],
			promoCode: [''],
			inPaymentDisclaimer: [''],
			inStoreDisclaimer: [''],
			paymentControl: [''],
			smsOptIn: [],
			smsPhone: [],
			nameOnCard: [''],
			cardAddress: [''],
			city: [''],
			state: [''] 
		});
		this.paymentForm.get('smsOptIn').valueChanges.subscribe(val => {
			if (val && localData?.reservationContext?.Store?.parameters?.SMSEnabled) {
				this.paymentForm.controls['smsPhone'].setValidators([Validators.required]);
			} else {
				this.paymentForm.controls['smsPhone'].clearValidators();
			}
			this.paymentForm.controls['smsPhone'].updateValueAndValidity({ emitEvent: false });
		});
		this.subscribeValidatePayment$  = this.freedomPayService.validatePayment$.subscribe((data) => {
			this.validPaymentHandler(data);
		});
		this.subscribeErrorHandler$ = this.freedomPayService.errorHandler$.subscribe((data) => {
			this.freedomPayErrorHandler(data);
		});
		const paymentFields = [
			{key:'CardNumber', isValid: false},
			{key:'ExpirationDate', isValid: false},
			{key:'SecurityCode', isValid: false},
			{key:'PostalCode', isValid: false},
		];
		const that = this;
		this.subscribeFormValidityHandler$ = this.freedomPayService.formValidityHandler$.subscribe((data) => {
			paymentFields.forEach((field, ind) => {
				if(field.key === data.emittedBy){
					paymentFields[ind].isValid = data.isValid;
				}
			});
			const isFormValid = paymentFields.every((field)=> field.isValid);
			if(isFormValid) {
				that?.paymentForm?.patchValue({
					paymentControl: true
				});
			} else {
				that?.paymentForm?.patchValue({
					paymentControl: ''
				});
			}
		});
		this.subscribeClearPromo$ = this.promoservice.clearPromo$.subscribe(() => {
			this.paymentForm.controls.promoCode.setValue('');
			this.promoMessage = WebString.PROMOERROR;
			this.promoDescription = WebString.PROMODESCRIPTION;
		});
	}

	ngOnInit() {

		this.subscribepromoUpdatedInfo$ = this.promoservice.promoUpdatedInfo$.subscribe((data: any) => {
			this.paymentForm.patchValue({
				promoCode: data
			});
			this.applyPromoReward();	
		});

		this.isReservationAlreadyPaid = false;
		this.reservationService.sharedData = this.storageService.fetch();
		const resType = this.reservationService?.sharedData?.booking?.type || '1';
		if (resType === '1' || resType === '4') {
			this.paymentForm.patchValue({
				smsOptIn: this.reservationService.sharedData.reservationContext.Reservation.IsSMSOptIn,
				smsPhone: this.reservationService.sharedData.reservationContext.Reservation.SMSPhone
			});
			this.isSMSEnabled = this.reservationService.sharedData.reservationContext.Store.parameters.SMSEnabled;
			this.displaySMSPhone(this.isSMSEnabled, true);
		}
		this.setFieldsValidation(['organizationName', 'organizationPhone'], parseInt(resType) === this.reservationTypeGroupEvent);
		const localData = this.storageService.fetch();
		if (localData?.booking?.isDepositRequired && this.currentStoreAcceptsOnlinePayment && this.selectedPaymentType !== 'FREEDOMPAY') {
			this.setFieldsValidation(this.arrPaymentFieldsName, this.paymentCard);
		}
		if(this.selectedPaymentType === 'FREEDOMPAY'){
				this.paymentForm.controls['paymentControl'].setValidators([Validators.required]);
		} else {
			this.paymentForm.controls['paymentControl'].clearValidators();
		}
		this.altDepositText = 'Deposit due ' + moment.min([
			moment(this.reservationService.sharedData.reservationContext.Reservation.CreatedOn).add(2, 'days'),
			moment(this.reservationService.sharedData.reservationContext.Reservation.PartyDate).subtract(1, 'days')
		]).format('MM/DD');
		this.reservation = this.reservationService.sharedData;

		// Redirect if we don't have a reservation
		if (!this.reservationService.sharedData.reservationContext || !this.reservationService.sharedData.reservationContext.Reservation) {
			this.router.navigate(['/reservations']);
			return;
		}

		// Format party date //
		this.partyDate = moment(this.reservationService.sharedData.date).format('dddd MMMM Do');

		// Deposit should be due 48 hours from creation, unless the party is before then
		const depositDueMoment = moment.min([
			moment(this.reservationService.sharedData.reservationContext.Reservation.CreatedOn).add(2, 'days'),
			moment(this.reservationService.sharedData.reservationContext.Reservation.PartyDate).subtract(1, 'days')
		]);

		this.depositDueDate = depositDueMoment.format('MM/DD');

		// Conditional logic for 'new' reservations - user alternate text if party is within 2 days from current/booking date
		if (!this.reservationService.sharedData.reservationContext.Reservation.ID && (moment().add(2, 'days').startOf('day') > moment(this.reservationService.sharedData.reservationContext.Reservation.PartyDate).startOf('day'))) {
			this.altDepositText = 'Deposit due ' + moment.min([
				moment(this.reservationService.sharedData.reservationContext.Reservation.CreatedOn).add(2, 'days'),
				moment(this.reservationService.sharedData.reservationContext.Reservation.PartyDate).subtract(1, 'days')
			]).format('MM/DD');
		}

		this.reservationPrice = this.reservationService.sharedData.reservationContext.Reservation.TotalPrice;
		let existingReservationObject = null;

		if (this.reservationService.sharedData.reservationContext.Reservation.TempBookingSeatID > 0 && !this.reservationService.sharedData.reservationContext.Reservation.ID) {
			this.reservationService.countdown();
		}

		this.enforceStoreDetails();

		this.updateDepositConditionals();

		// If there exists a reservation ID, look it up and pre populate form
		const reservationId = this.reservationService.sharedData.existingReservationId || 0;

		if (reservationId) {
			this.isLoading = true;
			this.utilsService.changingPopUpMessagesforVisit(this.reservationService.sharedData.booking.type);

			this.bookingApiService.getReservationData(reservationId).subscribe(
				data => {

					if (!data) {
						this.isLoading = false;
						return;
					}

					existingReservationObject = data;
					const birthdayGuest = existingReservationObject.Reservation.BirthdayGuestDetails;
					if (birthdayGuest && birthdayGuest.length > 0) {
						birthdayGuest.forEach((guest, index: number) => {
							const currentControl = (<FormGroup>this.birthdayStarControls[index]);
							if (currentControl) {
								currentControl.patchValue({
									guestName: guest.GuestName,
									age: guest.Age,
									gender: guest.Gender
								});
							}
							if (birthdayGuest.length > this.birthdayStarControls.length) {
								this.addBirthdayStar();
							}
						});
					}

					// Org fields
					if (existingReservationObject.Reservation && existingReservationObject.Reservation.OrganizationGuestDetails) {
						this.organizationGuest = existingReservationObject.Reservation.OrganizationGuestDetails;
						this.paymentForm.patchValue({
							// Organication Fields value set.
							organizationName: this.organizationGuest.OrganizationName,
							organizationPhone: this.organizationGuest.OrganizationPhone,
							organizationType: this.organizationGuest.OrganizationType,

							// Random Fields value set.
							adultName: existingReservationObject.Reservation.CustomerName,
							phone: existingReservationObject.Reservation.Phone1,
							email: existingReservationObject.Reservation.Email,
							smsOptIn: existingReservationObject.Reservation.IsSMSOptIn,
							smsPhone: existingReservationObject.Reservation.SMSPhone,
							specialInstructions: existingReservationObject.Reservation.Comments || '',

						});
					}
					this.reservationService.sharedData.reservationContext.Reservation.DepositType = existingReservationObject.Reservation.DepositType;
					this.reservationService.sharedData.reservationContext.Reservation.IsOnlinePaid = existingReservationObject.Reservation.IsOnlinePaid;
					this.reservationService.sharedData.reservationContext.Reservation.DepositDetails = existingReservationObject.Reservation.DepositDetails;
					this.isOldPPPConfirmationNumber = existingReservationObject.Reservation.ConfirmationID.includes('-');

					if (this.reservationService.sharedData.reservationContext.Store &&
						this.reservationService.sharedData.reservationContext.Store.PaymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT &&
						this.reservationService.sharedData.reservationContext.Store.parameters.maximumDeposit
					) {
						this.isDepositRequired = true;
					}

					this.reservationService.sharedData.reservationContext.Payments =
						existingReservationObject.Payments;

					this.updateDepositConditionals();

					this.isLoading = false;
				},
				e => {
					this.isLoading = false;
				}
			);
		}

		// Select payment handler //
		const paymentOptionID = this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID;
		this.storePaymentHandler = null;
		if (paymentOptionID == Constants.GatewayPaymentTypes.MONERISPAYMENTTYPE) {
			this.storePaymentHandler = Constants.GatewayPaymentTypes.MONERISPAYMENTTYPE;
		} else if (paymentOptionID == Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE) {
			this.storePaymentHandler = Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE;
		} else if (paymentOptionID == Constants.GatewayPaymentTypes.FREEDOMPAYTYPE){
			this.storePaymentHandler = Constants.GatewayPaymentTypes.FREEDOMPAYTYPE;
		}

		// Get SiteFinity content
		const body = [
			'BookingReservationSubHeader',
			'AdditionalStarCopy',
			'InStoreDepositDisclaimer',
			'RefundPolicy'
		];

		this.contentApiService.get(body).subscribe((data: any) => {
			if (!data) {
				return;
			}
			const sfContentsArr = data.Contents;
			this.sfContent.bookingReservationSubHeader = sfContentsArr['BookingReservationSubHeader'];
			this.sfContent.additionalStarCopy = sfContentsArr['AdditionalStarCopy'];
			this.sfContent.inStoreDepositDisclaimer = sfContentsArr['InStoreDepositDisclaimer'];
			this.sfContent.refundPolicy = sfContentsArr['RefundPolicy'];
		},
			error => this.logger.logError(error)
		);

		// Raise labels on focused input //
		$('body').on('focus', '.cec__form input', function () {
			$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
		});

		// Lower labels on blur if field is empty. Remove any errors //
		$('body').on('blur', '.cec__form input', function () {
			if ($(this).val() == null || $(this).val() == '') {
				$('label[for="' + $(this).attr('id') + '"]').removeClass('focus');
			}
			$('.email-error').css('opacity', '0');
		});
		// Raise labels on focused textarea //
		$('.cec__form textarea').on('focus', function () {
			$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
		});
		// Lower labels on blur if field is empty //
		$('.cec__form textarea').on('blur', function () {
			if ($(this).val() == null || $(this).val() == '') {
				$('label[for="' + $(this).attr('id') + '"]').removeClass('focus');
			}
		});

		$('.cec__form input').each(function () {
			if ($(this).val() != null && $(this).val() != '') {
				$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
			}
		});

		$('.cec__form textarea').each(function () {
			if ($(this).val() != null && $(this).val() != '') {
				$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
			}
		});

		this.gtmService.pushEvent({
			event: 'contactInformation',
			estimatedTotal: this.reservationService.sharedData.reservationContext
				.Reservation.TotalPrice
		});

		this.gtmService.pushCoreViewDataToGTM('');

		this.showPromoUIAndUpdateMessage();

	}

	authorizeSession(callback) {
		const nonce = `${new Date().getTime() + Math.random()}`;
		const storeId = this.reservationService.sharedData.reservationContext.Store.ID;
		this.authApiService.authorizeClientToken(nonce, storeId).subscribe((response: any) => {
			const res = response;
			if (res) {
				this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation.Nonce = nonce;
				const auth = JSON.parse(response);
				callback(auth);
			} else {
				this.displayAlertWithMessage(WebString.PAYMENTAUTHORIZEERROR, '#cc0000', 6000);
				this.isLoading = false;
			}
		},
			() => {
				this.displayAlertWithMessage(WebString.CONNECTION_ERROR, '#cc0000', 6000);
				this.isLoading = false;
			}
		);
	}

	paymentFormValidation(paymentFields, errorDiv) {
		const paymentConfigFields = Object.keys(Config.fields);
		// Check for empty
		paymentConfigFields.forEach(field => {
			const fieldValidity = paymentFields[field].validity;
			if (fieldValidity.empty) {
				this.isPaymentFormEmpty = false;
			}
		});

		if (!this.isPaymentFormEmpty) {
			return;
		}
		// Check for valid
		paymentConfigFields.forEach(field => {
			const fieldValidity = paymentFields[field].validity;
			if (!fieldValidity.valid) {
				this.isPaymentFormValid = true;
			}
		});
	}

	onCreate = paymentForm => {
		const paymentComponent = this.hooks['paymentComponent'];
		const errorDiv = DomUtils.getEl('#paymentError');

		const onSuccess = clientToken => {
			ccTokenResponseObj = { token: '', isOk: true, message: '' };
		};

		const onError = error => {
			ccTokenResponseObj = { token: '', isOk: false, message: '' };
			paymentComponent.displayAlertWithMessage('Tokenize Error: ' + error.message, '#cc0000', 6000);
			paymentForm.reset(() => { });
		};

		const form = DomUtils.getEl('#paymentDetails');
		const paymentButton = DomUtils.getEl('#paymentButton');
		paymentButton.addEventListener('click', e => {
			e.preventDefault();
			this.isPaymentFormEmpty = true;
			this.isPaymentFormValid = false;
			if (!paymentForm.isValid()) {
				clearInterval(paymentComponent.checkTokenInterval);
				paymentComponent.isLoading = false;
				paymentComponent.paymentFormValidation(paymentForm.state.data.fields, errorDiv);
				paymentComponent.displayAlertWithMessage(WebString.FORMVALIDATION, '#cc0000', 8000);
				return;
			}
			paymentForm.onSubmit(onSuccess, onError);
		});

		const ccFields = window.document.getElementsByClassName('payment-fields');
		for (let i = 0; i < ccFields.length; i++) {
			DomUtils.removeClass(ccFields[i], 'disabled');
		}
	}

	ngAfterViewInit() {
		this.paymentForm.controls['paymentControl'].clearValidators();
		const paymentGatewayInformation = this.reservationService.sharedData.reservationContext.Payments;
		const paymentOptionID = this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID;
		// Check if Moneris is used //
		if (paymentOptionID === Constants.GatewayPaymentTypes.MONERISPAYMENTTYPE) {
			this.displayMonerisIFrame(paymentGatewayInformation);
			if (window.addEventListener) {
				window.addEventListener(
					'message',
					e => this.monerisResponseHandler(e, paymentGatewayInformation),
					false
				);
			} else {
				if (window['attachEvent']) {
					window['attachEvent']('onmessage', e =>
						this.monerisResponseHandler(e, paymentGatewayInformation)
					);
				}
			}
		} else if (paymentOptionID === Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE) {
			this.paymentScript.then(data => {
				// card is used to ensure payment field exist before initializing
				const card = DomUtils.getEl(Config.fields.card.selector);
				if (data[0].loaded === true && card) {
					this.initializePaymentForm();
				}
			}).catch(error => this.logger.logError(error));
		} else if (paymentOptionID === Constants.GatewayPaymentTypes.FREEDOMPAYTYPE) {
			this.selectedPaymentType = 'FREEDOMPAY';
			this.paymentForm.controls['paymentControl'].setValidators([Validators.required]);
			this.paymentForm.controls['nameOnCard'].setValidators([Validators.required]);
			this.paymentForm.controls['cardAddress'].setValidators([Validators.required]);
			this.paymentForm.controls['city'].setValidators([Validators.required]);
			this.paymentForm.controls['state'].setValidators([Validators.required]);
		}
	}

	initializePaymentForm() {
		this.emptyPaymentContainers();
		window.firstdata.createPaymentForm(Config, this.hooks, this.onCreate);
	}

	// clean div htmls before injecting iframes
	emptyPaymentContainers() {
		const paymentConfigFields = Object.keys(Config.fields);
		paymentConfigFields.forEach(field => {
			const paymentField = DomUtils.getEl(Config.fields[field].selector);
			$(paymentField).empty();
		});
	}

	onPaymentTypeSelect(isCreditCardSelected: boolean) {
		this.paymentCard = isCreditCardSelected;
		this.selectedPaymentType = null;
		if (this.paymentCard) {
			this.paymentForm.controls['inStoreDisclaimer'].clearValidators();
		} else {
			if (this.reservationService.sharedData?.booking?.isDepositRequired) {
				this.paymentForm.controls['inStoreDisclaimer'].setValidators([Validators.required]);
			}
		}
		const paymentOptionID = this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID;
		this.setFieldsValidation(this.arrPaymentFieldsName, this.paymentCard && (paymentOptionID !== 3 && this.selectedPaymentType !== 'FREEDOMPAY'));
		this.paymentForm.controls['inStoreDisclaimer'].updateValueAndValidity({ emitEvent: false });
		if(isCreditCardSelected === true) {
			switch(paymentOptionID) {
				case 3: 
					this.selectedPaymentType = 'FREEDOMPAY';
					break;
				case 2: 
					setTimeout(() => this.initializePaymentForm(), 0);
					break;
				case 1:
					const paymentGatewayInformation = this.reservationService.sharedData.reservationContext.Payments;
					this.displayMonerisIFrame(paymentGatewayInformation);
					break;
			}	
		}
		if(this.selectedPaymentType === 'FREEDOMPAY'){
				this.paymentForm.controls['paymentControl'].setValidators([Validators.required]);
		} else {
			this.paymentForm.controls['paymentControl'].clearValidators();
		}
		const fields = ['nameOnCard', 'cardAddress', 'city', 'state'];
		if(isCreditCardSelected === false) {
			this?.paymentForm?.patchValue({
				paymentControl: true
			});
		} else {
			this?.paymentForm?.patchValue({
				paymentControl: ''
			});
		}
		fields.forEach((field: string) => {
			if (isCreditCardSelected === false) {
				this?.paymentForm?.controls[field].reset();
				this?.paymentForm?.controls[field].clearValidators();
			} else {
				this?.paymentForm?.controls[field].setValidators(
					Validators.required
				);
			}
			this?.paymentForm?.controls[field].updateValueAndValidity();
		});
		this.gtmService.pushPaymentInfo(isCreditCardSelected, this.reservationService.sharedData.reservationContext.Reservation.ID === 0);
	}


	private showPromoUIAndUpdateMessage() {
		const localData = this.storageService.fetch();
		const storeRewards = localData?.storeRewards || localData?.reservationContext?.Store?.parameters?.RewardList || [];

		if (storeRewards) {
			const promoRewards = storeRewards.filter(({ RewardTypeId }) => RewardTypeId === 3);
			this.showPromo = promoRewards.length > 0;
		}
		const reservationContext = localData.reservationContext.Reservation;
		const appliedReward = reservationContext.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
		if (appliedReward) {
			const selectedReward = storeRewards.find(x => x.RewardID === appliedReward.RewardID);
			this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', selectedReward.RewardName);
			this.showPromoMessage = true;
		}
	}

	updateInfo(event) {
		if (event) {
			this.reservationService.sharedData = this.storageService.fetch();
			const localData = this.storageService.fetch();
			this.depositAmountDue = localData.reservationContext.Reservation.DepositAmount;
		}
	}

	applyChanges(event) {
		this.selectTime();
	}

	newBirthdayStar() {
		return this.fb.group({
			age: ['', Validators.required],
			guestName: ['', Validators.required, CECValidators.EmptyStringValidator],
			gender: ['', [Validators.required]]
		});
	}

	addBirthdayStar() {
		(this.paymentForm.get('birthdayStars') as FormArray).push(this.newBirthdayStar());
	}

	removeBirthdayStar(index) {
		(this.paymentForm.get('birthdayStars') as FormArray).removeAt(index);
	}

	ccTokenIsReady() {
		if (typeof ccTokenResponseObj !== 'undefined' && ccTokenResponseObj && ccTokenResponseObj.isOk !== undefined) {
			clearInterval(this.checkTokenInterval);
			if (ccTokenResponseObj.isOk) {
				const paymentOptionGatewayId = this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID;
				if (paymentOptionGatewayId == Constants.GatewayPaymentTypes.MONERISPAYMENTTYPE) {
					this.ccInfo.cardNumber = ccTokenResponseObj.token;
					this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation = this.ccInfo;
				} else if (paymentOptionGatewayId == Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE) {
					const nonce = this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation.Nonce;
					this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation = this.ccInfo;
					this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation.Nonce = nonce;
				}
				this.reservationService.sharedData.showPaymentSuccess = true;
				this.saveReservation();
			} else {
				this.isLoading = false;
				this.displayAlertWithMessage('Could not process the credit card. Check your information and try again.', '#cc0000', 6000);
			}
		}
		ccTokenResponseObj = null;
	}

	refreshdueDate() {
		const partyDate = new Date(`${this.reservationService.sharedData.date.month}/${this.reservationService.sharedData.date.day}/${this.reservationService.sharedData.date.year}`);
		return this.utilsService.getDepositDueDate(partyDate, this.reservationService.sharedData.reservationContext.Store.parameters.dueDateInDays);
	}

	saveReservation() {
		const reservationContext = this.reservationService.sharedData.reservationContext;

		// This is to handle cases where we don't have the correct Party Type ID when updating an existing reservation
		if (reservationContext.PartyType && reservationContext.PartyType.ID > 0) {
			reservationContext.Reservation.PartyTypeID = reservationContext.PartyType.ID;
		}

		// Update promo rewards to the reservation context
		const data = this.storageService.fetch();
		const storeId = parseInt(data.booking.store, 10);
		if (data.reservationContext.PartyType.PriceAsPer == Constants.PriceAsPer.PartyType) {
			const partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, data.kids, data.adults, data.reservationContext.PartyType);
			if (partyTypePricing) {
				if (typeof partyTypePricing === 'string') {
					return this.displayAlertWithMessage(partyTypePricing, '#cc0000', 8000);
				}
				if (partyTypePricing['flag']) {
					this.reservationAPI();
				} else {
					partyTypePricing['dialogRef'].afterClosed().subscribe(dialogResult => {
						if (dialogResult) {
							this.reservationAPI();
						} else {
							this.isLoading = false;
						}
					});
				}
			}
		} else {
			const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, data.kids, data.adults, data.reservationContext.PartyType);
			if (packageValidation) {
				this.isLoading = false;
				this.displayAlertWithMessage(packageValidation, '#cc0000', 8000);
				return;
			} else {
				this.reservationAPI();
			}
		}
	}

	checkForDuplicatesThenSave() {
		this.isLoading = true;
		this.isFormSubmitted = true;
		const localStorage = this.storageService.fetch();
		if (this.paymentForm.valid) {
			this.model = this.paymentForm.value;
			this.reservationService.sharedData.paymentInformation = this.model;
			this.reservationService.sharedData.birthdayGuests = this.paymentForm.value.birthdayStars;
			this.organizationGuest.OrganizationName = this.paymentForm.value.organizationName;
			this.organizationGuest.OrganizationPhone = this.paymentForm.value.organizationPhone;
			this.organizationGuest.OrganizationType = this.paymentForm.value.organizationType;
			this.reservationService.sharedData.organizationGuest = this.organizationGuest;
			this.reservationService.sharedData.smsOptIn = this.paymentForm.value.smsOptIn;
			this.reservationService.sharedData.SMSPhone = this.paymentForm.value.smsPhone;
			this.reservationService.sharedData.reservationContext.Reservation.ID = localStorage.existingReservationId;
			this.reservationService.sharedData.reservationContext.Reservation.CustomerName = this.reservationService.sharedData.paymentInformation.adultName;
			this.reservationService.sharedData.reservationContext.Reservation.Phone1 = this.reservationService.sharedData.paymentInformation.phone;
			this.reservationService.sharedData.reservationContext.Reservation.Email = this.reservationService.sharedData.paymentInformation.email;
			this.reservationService.sharedData.reservationContext.Reservation.Comments = this.reservationService.sharedData.paymentInformation.specialInstructions;
			this.reservationService.sharedData.reservationContext.Reservation.IsSMSOptIn = !!this.paymentForm.value.smsOptIn;
			this.reservationService.sharedData.reservationContext.Reservation.SMSPhone = this.paymentForm.value.smsPhone;
			this.reservationService.sharedData.reservationContext.Reservation.BirthdayGuestDetails = this.paymentForm.value.birthdayStars;
			this.reservationService.sharedData.reservationContext.Reservation.OrganizationGuestDetails = this.paymentForm.value.organizationGuest;


			// populate cc info
			if (!this.isReservationAlreadyPaid) {
				if (this.paymentCard) {
					this.reservationService.sharedData.reservationContext.Reservation.DepositType =
						Constants.ReservationPaymentTypeConst.CREDIT;
					this.paymentForm.controls['inStoreDisclaimer'].clearValidators();
					this.populateCCInfo();
				} else {
					this.ccInfo = null;
					this.reservationService.sharedData.reservationContext.Reservation.DepositType =
						Constants.ReservationPaymentTypeConst.INSTORE;
					if (this.reservationService.sharedData?.booking?.isDepositRequired) {
						this.paymentForm.controls['inStoreDisclaimer'].setValidators([Validators.required]);
					}
				}
				this.setFieldsValidation(this.arrPaymentFieldsName, this.paymentCard && this.selectedPaymentType !== 'FREEDOMPAY');
				this.paymentForm.controls['inStoreDisclaimer'].updateValueAndValidity({ emitEvent: false });
			} else {
				this.ccInfo = null;
			}

			if (this.reservationService.sharedData.reservationContext.Reservation.ID == 0 || this.reservationService.sharedData.reservationContext.Reservation.ID == undefined) {

				this.reservationApiService.duplicateCheck(this.reservationService.sharedData.reservationContext).subscribe((data: any) => {
					if (data) {
						this.possibleDupes = data;
						if (this.possibleDupes.length > 0) {
							this.isLoading = false;
							this.showDuplicateModal = true;
						} else {
							// call save function
							this.handlePaymentAndSave();
						}
					}
				},
					() => {
						this.handlePaymentAndSave();
					}
				);
			} else {
				this.handlePaymentAndSave();
			}
		} else {
			this.isLoading = false;
			this.displayAlertWithMessage(WebString.FORMVALIDATION, '#cc0000', 8000);
		}
	}

	selectTime() {
		let TempId = 0;
		let partyType = 0;
		if (this.reservationService.sharedData.reservationId) {
			TempId = this.reservationService.sharedData.reservationId;
		} else if (this.reservationService.sharedData.reservationContext.Reservation.ID) {
			TempId = this.reservationService.sharedData.reservationContext.Reservation.ID;
		} else if (this.reservationService.sharedData.existingReservationId) {
			TempId = this.reservationService.sharedData.existingReservationId;
		}

		if (this.reservationService.sharedData.partyTypeId) {
			partyType = this.reservationService.sharedData.partyTypeId;
		} else if (this.reservationService.sharedData.requestedPartyType) {
			partyType = this.reservationService.sharedData.requestedPartyType;
		}

		const body = {
			BookingParameters: {
				AdultCount: this.reservationService.sharedData.numberOfAdults,
				ChildCount: this.reservationService.sharedData.numberOfKids,
				PartyDate: this.reservationService.sharedData.date,
				TimeSlot: this.reservationService.sharedData.timeStart,
				StoreId: this.reservationService.sharedData.storeId,
				ReservationTypeId: this.reservationService.sharedData.restype,
				TempBookingID: TempId
			},
			PartyTypeID: partyType
		};

		this.reservationApiService.saveReservation(body).subscribe((data: any) => {
			if (data) {
				if (data.Reservation.TempBookingSeatID > 0) {
					this.reservationService.sharedData.reservationContext = data;
					this.storageService.store(this.reservationService.sharedData);
					this.reservationPrice = this.reservationService.sharedData.reservationContext.Reservation.TotalPrice;
					this.displayAlertWithMessage(
						'Your reservation has been updated',
						'#4BB543',
						8000
					);
					this.reservationService.cdreset();
					this.reservationService.countdown();
				} else {
					this.displayAlertWithMessage(
						'This time slot does not support your party size',
						'#cc0000',
						8000
					);
					const localStorage = this.storageService.fetch();
					this.reservationService.sharedData.numberOfAdults = localStorage.numberOfAdults;
					this.reservationService.sharedData.numberOfKids = localStorage.numberOfKids;
				}
			} else {
				this.displayAlertWithMessage(
					'An error occurred and the reservation was not updated.',
					'#cc0000',
					8000
				);
			}
		},
			error => {
				this.displayAlertWithMessage(
					'An error occurred and the reservation was not updated.',
					'#cc0000',
					8000
				);
			}
		);
	}

	// payment functions
	displayMonerisIFrame(paymentGatewayInformation) {
		const monerisiFrameUrl = paymentGatewayInformation.monerisInfo.IframeURL;
		const HPPKey = paymentGatewayInformation.monerisInfo.HPPKey;
		this.monerisIFrameSrc =
			monerisiFrameUrl +
			'?id=' +
			HPPKey +
			'&css_body=background:WhiteSmoke;font-family:sans-serif;padding:30px;margin:15px&css_textbox=border-width:1px;&css_textbox_pan=width:140px;&enable_exp=1&css_textbox_exp=width:40px;&enable_cvd=1&display_labels=1&css_textbox_cvd=width:40px&css_textbox_cvd=margin:5px&css_label_cvd=padding:5px;font-weight:bold;font-size:14px;&css_textbox_exp=margin:5px&css_label_exp=padding:5px;font-weight:bold;font-size:14px;&css_textbox_pan=margin:5px&css_label_pan=padding:5px;font-weight:bold;font-size:14px;';
		const monerisIframeDOMObj =
			'<iframe id=\'monerisIFrame\' src=\'' +
			this.monerisIFrameSrc +
			'\' style=\'min-height: 200px;\'></iframe>';

		$('#monerisIframeContainer').append(monerisIframeDOMObj);
	}

	createMonerisToken(paymentGatewayInformation) {
		const monerisiFrameUrl = paymentGatewayInformation.monerisInfo.IframeURL;
		const monFrameRef = document.getElementById('monerisIFrame')['contentWindow'];
		monFrameRef.postMessage('', monerisiFrameUrl);
		return false;
	}

	monerisResponseHandler = function (e, paymentGatewayInformation) {
		if (e.origin === 'https://www3.moneris.com') {
			const respData = eval('(' + e.data + ')');
			ccTokenResponseObj = new ccTokenResponseObject();
			if (respData.responseCode == '001') {
				ccTokenResponseObj.token = respData.dataKey;
				ccTokenResponseObj.isOk = true;
			} else {
				if (respData.responseCode && respData.responseCode.length > 0) {
					// "940" : Invalid profile
					// "941" : TokenError
					// "942" : Invalid profile
					// "943" : InvalidCardData
					// "944" : InvalidExpDate
					// "945" : InvalidCVDData
					ccTokenResponseObj.token = respData.responseCode[0];
				} else {
					ccTokenResponseObj.token = '-1';
				}
				ccTokenResponseObj.isOk = false;
			}
		}
	};

	createToken(paymentGatewayInformation) {
		const paymentButton = DomUtils.getEl('#paymentButton');
		paymentButton.click();
	}

	// attempt to make an online credit card deposit payment
	handlePaymentAndSave() {
		if (this.isReservationAlreadyPaid || !this.isDepositRequired) {
			this.saveReservation();
			return;
		}

		// check if the user wants to pay with card or in store deposit
		if (this.paymentCard) {
			this.checkTokenInterval = setInterval(() => {
				this.ccTokenIsReady();
			}, 500);

			const paymentOptionGatewayId = this.reservationService.sharedData
				.reservationContext.Store.StorePaymentGatewayTypeID;
			const paymentGatewayInformation = this.reservationService.sharedData
				.reservationContext.Payments;
			// if gateway type == 1 then Moneris, if 2 then payeezy, else error out
			if (
				paymentOptionGatewayId ==
				Constants.GatewayPaymentTypes.MONERISPAYMENTTYPE
			) {
				this.createMonerisToken(paymentGatewayInformation);
			} else if (
				paymentOptionGatewayId ==
				Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE
			) {
				this.createToken(paymentGatewayInformation);
			} else if(paymentOptionGatewayId == Constants.GatewayPaymentTypes.FREEDOMPAYTYPE && !this.isReservationAlreadyPaid){
				this.doFreedomPayment(paymentGatewayInformation);
			} else {
				this.isLoading = false;
				this.displayAlertWithMessage(
					'Cannot process online payments at this time, refresh the page or select in store payment',
					'#cc0000',
					8000
				);
			}
		} else {
			this.saveReservation();
		}
	}

	enforceStoreDetails(isRetry?: boolean) {
		const restype = this.reservation && this.reservation.restype;
		// Check that we have enough store information (if not, go get it)
		if (!this.reservationService.sharedData.reservationContext || !this.reservationService.sharedData.reservationContext.Store) {
			this.reservationService.getStoreDetails(this.reservationService.sharedData.storeId, restype).subscribe(data => {
				// Retry now that we should have minimum attendees, but only once
				if (!isRetry) {
					this.enforceStoreDetails(true);
				}
			});
		} else {

			this.orgGroup = (this.reservationService.sharedData.reservationContext.Store.parameters.OrganizationTypes);

			if (this.reservationService.sharedData.reservationContext.Store.PaymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT && this.reservationService.sharedData.reservationContext.Store.parameters.maximumDeposit) {
				this.isDepositRequired = true;
			}

			// Check minimum in shared service store if we have it
			this.minimumChildren = (this.reservationService.sharedData.reservationContext.Store.parameters && this.reservationService.sharedData.reservationContext.Store.parameters.Children) || 1;
			this.minimumAdults = (this.reservationService.sharedData.reservationContext.Store.parameters && this.reservationService.sharedData.reservationContext.Store.parameters.Adults) || 1;

			if (this.reservationService.sharedData.numberOfKids < this.minimumChildren) {
				this.reservationService.sharedData.numberOfKids = this.minimumChildren;
			}

			if (this.reservationService.sharedData.numberOfAdults < this.minimumAdults) {
				this.reservationService.sharedData.numberOfAdults = this.minimumAdults;
			}
		}
	}

	goToReservationDetails() {
		this.storageService.store(this.reservationService.sharedData);
		const storeId = this.reservationService.sharedData.reservationContext.Store.ID ?? 0;

		if (this.reservationService.sharedData.reservationContext.PartyType.PriceAsPer == Constants.PriceAsPer.PartyType) {
			const partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, parseInt(this.reservationService.sharedData.kids), parseInt(this.reservationService.sharedData.adults), this.reservationService.sharedData.reservationContext.PartyType);
			if (partyTypePricing) {
				if (typeof partyTypePricing === 'string') {
					return this.displayAlertWithMessage(partyTypePricing, '#cc0000', 8000);
				}
				if (partyTypePricing['flag']) {
					this.navigateToReservationDashboard();
				} else {
					partyTypePricing['dialogRef'].afterClosed().subscribe(dialogResult => {
						if (dialogResult) {
							const data = {
								kids: partyTypePricing['tempKids'],
								adults: partyTypePricing['tempAdults']
							};
							this.storageService.store(data);
							this.navigateToReservationDashboard();
						}
					});
				}
			}
		} else {
			const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, parseInt(this.reservationService.sharedData.kids), parseInt(this.reservationService.sharedData.adults), this.reservationService.sharedData.reservationContext.PartyType);
			if (packageValidation) {
				this.isLoading = false;
				this.displayAlertWithMessage(packageValidation, '#cc0000', 8000);
				return;
			} else {
				this.navigateToReservationDashboard();
			}
		}
	}

	// -------------- Attendee functions -----------------
	incrementChildAttendees() {
		this.reservation.numberOfKids++;
		this.reservationService.sharedData.numberOfKids = this.reservation.numberOfKids;
	}

	decrementChildAttendees() {
		if (this.reservation.numberOfKids > this.minimumChildren) {
			this.reservation.numberOfKids = this.reservation.numberOfKids - 1;
		} else {
			alert(
				'Must have at least ' +
				this.minimumChildren +
				' child attendee' +
				(this.minimumChildren > 1 ? 's' : '')
			);
		}
		this.reservationService.sharedData.numberOfKids = this.reservation.numberOfKids;
	}

	incrementAdultAttendees() {
		this.reservation.numberOfAdults++;
		this.reservationService.sharedData.numberOfAdults = this.reservation.numberOfAdults;
	}

	decrementAdultAttendees() {
		if (this.reservation.numberOfAdults > this.minimumAdults) {
			this.reservation.numberOfAdults = this.reservation.numberOfAdults - 1;
		} else {
			alert(
				'Must have at least ' +
				this.minimumAdults +
				' adult attendee' +
				(this.minimumAdults > 1 ? 's' : '')
			);
		}

		this.reservationService.sharedData.numberOfAdults = this.reservation.numberOfAdults;
	}

	// ----------- Auxiliary functions ------------------------------

	displayAlertWithMessage(message: string, color: string, timeout) {
		this.showAlert = true;
		this.applicationMessage = message;
		this.alertColor = color;
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '';
		}, timeout);
	}

	getCardType(number) {
		if (number) {
			// visa
			let re = new RegExp('^4');
			if (number.match(re) != null) {
				return 'VISA';
			}
			if (
				/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
					number
				)
			) {
				return 'MASTERCARD';
			}
			re = new RegExp('^3[47]');
			if (number.match(re) != null) {
				return 'AMERICAN EXPRESS';
			}
			re = new RegExp(
				'^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
			);
			if (number.match(re) != null) {
				return 'DISCOVER';
			}
			re = new RegExp('^36');
			if (number.match(re) != null) {
				return 'DINERS CLUB';
			}
			re = new RegExp('^30[0-5]');
			if (number.match(re) != null) {
				return 'Diners - Carte Blanche';
			}
			re = new RegExp('^35(2[89]|[3-8][0-9])');
			if (number.match(re) != null) {
				return 'JCB';
			}
			re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
			if (number.match(re) != null) {
				return 'Visa Electron';
			}
		}
		return '';
	}

	duplicateOverride() {
		this.showDuplicateModal = false;
		if (!this.isLoading) {
			this.isLoading = true;
			this.handlePaymentAndSave();
		}
	}

	extractHostname(url) {
		let hostname;
		// find & remove protocol (http, ftp, etc.) and get hostname

		if (url.indexOf('//') > -1) {
			hostname = url.split('/')[2];
		} else {
			hostname = url.split('/')[0];
		}

		hostname = hostname.split(':')[0];
		hostname = hostname.split('?')[0];

		return hostname;
	}

	setFieldsValidation(fieldsArr: string[], hasValidation: boolean) {
		for (const key of fieldsArr) {
			if (hasValidation) {
				this.paymentForm.controls[key].setValidators([Validators.required]);
			} else {
				this.paymentForm.controls[key].clearValidators();
			}
			this.paymentForm.controls[key].updateValueAndValidity({ emitEvent: false });
		}
	}

	populateCCInfo() {
		this.ccInfo = {};
		if (this.storePaymentHandler == Constants.GatewayPaymentTypes.PAYEEZYPAYMENTTYPE) {
			this.ccInfo.cardCVV = this.reservationService.sharedData.paymentInformation.billCVV;
			this.ccInfo.cardStreet = this.reservationService.sharedData.paymentInformation.billAddress;
			this.ccInfo.cardCity = this.reservationService.sharedData.paymentInformation.billCity;
			this.ccInfo.cardState = this.reservationService.sharedData.paymentInformation.billState;
			this.ccInfo.cardZip = this.reservationService.sharedData.paymentInformation.billZip;
			this.ccInfo.currency = 'USD';
		}
	}

	updateDepositConditionals() {
		this.currentStoreAcceptsOnlinePayment =
			((this.reservationService.sharedData.reservationContext.Store
				.PaymentOptionID === Constants.StoreDepositTypes.ONLINEANDINSTOREPAYMENT) ||
				(this.reservationService.sharedData.reservationContext.Store
					.PaymentOptionID === Constants.StoreDepositTypes.CreditDepositRequired));

		this.currentStoreAcceptsonlyOnlinePayment =
			(this.reservationService.sharedData.reservationContext.Store
				.PaymentOptionID === Constants.StoreDepositTypes.CreditDepositRequired);

		if (!this.currentStoreAcceptsOnlinePayment) {
			this.paymentCard = false;
		}

		if (this.reservationService.sharedData.reservationContext.Store.PaymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT &&
			this.reservationService.sharedData.reservationContext.Store.parameters.ReservationTypeId !== Constants.ReservationTypes.GROUPEVENT &&
			this.reservationService.sharedData.reservationContext.Store.parameters.maximumDeposit) {
			this.isDepositRequired = true;
		}

		if (typeof this.reservationService.sharedData.reservationContext !== 'undefined') {
			if (typeof this.reservationService.sharedData.reservationContext.Reservation.DepositType !== 'undefined') {
				this.isReservationAlreadyPaid = this.reservationService.sharedData.reservationContext.Reservation.DepositType === Constants.ReservationPaymentTypeConst.CREDIT && this.reservationService.sharedData.reservationContext.Reservation.IsOnlinePaid;
			}
			if (this.reservationService.sharedData.reservationContext.Reservation.DepositDetails.length > 0) {
				this.isReservationAlreadyPaid = this.isReservationAlreadyPaid ||
					(this.reservationService.sharedData.reservationContext.Reservation.DepositDetails[0].PaidOn.length > 0 && this.reservationService.sharedData.reservationContext.Reservation.DepositDetails[0].PaidOn !== '0001-01-01T00:00:00');
			}
			this.depositAmountDue = this.reservationService.sharedData.reservationContext.Reservation.DepositAmount;
		}
	}

	displaySMSPhone(isChecked, isOnLoad) {

		if (!isOnLoad) {
			this.smsOptIn = isChecked;
		}

		if (isChecked) {
			if (this.smsOptIn) {
				$('#divSmsPhone').children('input').show();
				$('#divSmsPhone').children('label').show();
				$('.check-smsPhone').attr('required', true);
			} else {
				$('#divSmsPhone').children('input').hide();
				$('#divSmsPhone').children('label').hide();
			}
		} else {
			$('#divSmsPhone').children('input').hide();
			$('#divSmsPhone').children('label').hide();
		}
	}

	private getPromotionCategoryId(localData: any) {
		return localData.reservationContext.PartyType.PromotionCategoryID;
	}

	applyPromoReward() {
		const promoCode = this.paymentForm.get('promoCode').value;
		if (promoCode) {
			this.showPromoMessage = true;
			this.promoCode = '';
			$('.lable_promo').removeClass('focus');
			const response = this.sideMenu.applyPromoRewardToReservation(promoCode, true);
			const localData = this.storageService.fetch();
			const storeRewards = this.sideMenu.getStorerewards(localData);
			const prom = storeRewards.find((promo: any) => promoCode.toLowerCase() === promo.RewardName.toLowerCase());
			if (response.isValid) {
				const promoRewardName = this.sideMenu.getPromoRewardName();
				this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', promoRewardName);
			}
			if (response.isNotEligible) {
				if(response.isPromoDiscriptionDisplay === true){
					if(prom) {
						this.promoDescription = prom.RewardDescription;
					}
					this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
				} else {
					this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
					
				}
			}
			if (response.isNotFound) {
				this.promoMessage = WebString.PROMONOTFOUND.replace('{0}', promoCode);
			}
			if(response.isNotApplied){
				const selectedPartyTypeId = this.getPromotionCategoryId(localData);
				if(!prom?.QualifyingPartyTypes.includes(selectedPartyTypeId)){
					this.promoDescription = prom.RewardDescription;
				} else if(prom) {
					this.promoDescription = prom.RewardDescription;
				}
				if(promoCode.toLowerCase() === 'vipfree'){
					this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
				} else {
					this.promoMessage = WebString.PROMONOTAPPLIED.replace('{0}', promoCode);
				}
			}
		} else {
			this.showPromoMessage = true;
			this.promoMessage = WebString.PROMOERROR;
		}
	}
	private reservationAPI() {

		const isNewReservation =
			(this.reservationService.sharedData.reservationContext.Reservation.ID == undefined || this.reservationService.sharedData.reservationContext.Reservation.ID ===
				0);
		const data = this.storageService.fetch();
		this.reservationService.sharedData.reservationContext.Reservation.ReservationRewards = data.reservationContext.Reservation.ReservationRewards;
		this.reservationService.sharedData.mytimeslot = data['mytimeslot'];
		this.reservationService.sharedData.myrewards = data['myrewards'];
		this.reservationService.sharedData.isVipParty = data['isVipParty'];
		this.reservationService.sharedData.tempBookingInfo = undefined;
		this.isLoading = true;
		if (
			this.isFreedomPayFormValid &&
			this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID === 3 &&
			this.selectedPaymentType === 'FREEDOMPAY'
		) {
			this.reservationService.sharedData.reservationContext.Reservation.DepositStatus = 1;
		}
		const inputObj = {
			DepositOrRefund: 'D',
			InvoiceNumber: this.reservationService.sharedData.reservationContext.Reservation.InvoiceNumber,
			IsOnlinePayment: true,
			Receipt: 'CECFunnelOnlinePayment',
			PaymentGatewayID: this.reservationService.sharedData?.reservationContext.Store?.StorePaymentGatewayID,
			PaymentGatewayTypeID: this.reservationService.sharedData?.reservationContext.Store?.StorePaymentGatewayTypeID,
			ReservationId: this.reservationService.sharedData.reservationContext.Reservation?.ID,
			TakenBy: '1667',
			CreatedBy: '1667',
			// CreatedOn: Date(),
			authorizationNumber:
				this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.authorizationCode,
			bankMessage:
				this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.processorResponseMessage,
			bankResponseCode:
				this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.processorResponseCode,
			depositAmount: this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.amount,
			paidOn: this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.authorizedDateTime,
			transactionApproved: this.freedomPayPaymentInfo?.data?.FreewayResponse.decision == 'ACCEPT',
			transactionCardType: this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.cardType,
			transactionID: this.freedomPayPaymentInfo?.data?.FreewayResponse.requestID,
			transactionNumber:
				this.freedomPayPaymentInfo?.data?.FreewayResponse.ccAuthReply.processorTransactionID,
			transactionTag: this.freedomPayPaymentInfo?.data?.FreewayResponse.merchantReferenceCode,
			reservationID: this.reservationService.sharedData.reservationContext.Reservation.ID,
			confirmationID: this.reservationService.sharedData.reservationContext.Reservation.InvoiceNumber,
		};
		const payload = {
			FreedomPayPurchaseKey: this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID === 3 &&
			this.selectedPaymentType === 'FREEDOMPAY' ? this.freedomPayPaymentInfo : null,
			...this.reservationService.sharedData.reservationContext,
			Reservation: {
				...this.reservationService.sharedData.reservationContext.Reservation,
				...(this.reservationService.sharedData.reservationContext.Store.StorePaymentGatewayTypeID === 3 &&
					this.selectedPaymentType === 'FREEDOMPAY' ? {
						DepositDetails: [inputObj],
						PaymentGatewayId:this.reservationService?.sharedData?.reservationContext?.Store?.StorePaymentGatewayTypeID,
						UserId: '1807',
						PaymentInformation: {
							...this.reservationService.sharedData.reservationContext.Reservation.PaymentInformation,
							cardHoldersName: this.paymentForm.get('nameOnCard')?.value,
							cardStreet: this.paymentForm.get('cardAddress')?.value,
							cardCity: this.paymentForm.get('city')?.value,
							cardState: this.paymentForm.get('state')?.value,
							cardZip: this.zipCode
						}
					} : {})
			}
		};
		this.bookingApiService.updateReservation(payload).subscribe((saveReservationDto: SaveReservationDto) => {
			if (saveReservationDto) {
				if (saveReservationDto.Reservation.ID > 0) {
					this.reservationService.sharedData.reservationContext = saveReservationDto;
					this.storageService.store(this.reservationService.sharedData);
					this.reservationService.cdpause();
						this.isLoading = false;
						this.router.navigate(['/reservation-dashboard'], {
							queryParams: {
								qId: Utilities.encodeParameter(this.reservationService.sharedData.reservationContext.Reservation.ConfirmationID),
								qDate: Utilities.encodeParameter(moment(
									this.reservationService.sharedData.reservationContext
										.Reservation.PartyDate
								).format('MM-DD-YYYY')),
								new: isNewReservation,
								isFromCancelUpdate: false
							}
						});
						if (isNewReservation) {
							this.gtmService.pushEvent({
								event: 'newReservation',
								location: this.reservationService.sharedData.reservationContext.Reservation.StoreID,
								OrderID: this.reservationService.sharedData.reservationContext.Reservation.ID,
								price: this.reservationService.sharedData.reservationContext.Reservation.TotalPrice,
								order_code: this.reservationService.sharedData.reservationContext.Reservation.ConfirmationID,
								sku: this.reservationService.sharedData.reservationContext.Reservation.PartyTypeID,
								package: this.reservationService.sharedData.reservationContext.Reservation.ReservationTypeID,
								cecSession: this.reservationService.sharedData.reservationContext.Reservation.InvoiceNumber,
								children: this.reservationService.sharedData.reservationContext.Reservation.Children,
								adults: this.reservationService.sharedData.reservationContext.Reservation.Adults
							});
						}
						this.gtmService.pushBirthdayStarData(isNewReservation);
						this.gtmService.pushMoreThanOneBirthdayData(isNewReservation);
				} else {
					if(['$0Auth failed:REJECT', '$0Auth failed:FAILURE'].includes(saveReservationDto.Message.Error)){
						this.displayAlertWithMessage(
							'Please validate your credit card information and retry.',
							'#cc0000',
							8000
						);
						this.isLoading = false;
					} else if (saveReservationDto.Message.IsError) {
						this.displayAlertWithMessage(
							saveReservationDto.Message.Error,
							'#cc0000',
							8000
						);
						this.isLoading = false;
					} else {
						this.displayAlertWithMessage(
							'An error occurred while saving the reservation.',
							'#cc0000',
							8000
						);
						this.isLoading = false;
					}
				}
			} else {
				this.displayAlertWithMessage(
					'An error occurred while saving the reservation.',
					'#cc0000',
					8000
				);
				this.isLoading = false;
			}
		},
			error => {
				this.displayAlertWithMessage(
					'An error occurred while saving the reservation.',
					'#cc0000',
					8000
				);
				this.isLoading = false;
			}
		);
	}

	private navigateToReservationDashboard() {
		this.router.navigate(['/reservation-dashboard'], {
			queryParams: {
				id: this.reservationService.sharedData.reservationContext.Reservation
					.ConfirmationID,
				date: moment(
					this.reservationService.sharedData.reservationContext.Reservation
						.PartyDate
				).format('MM-DD-YYYY'),
				new: false
			}
		});
	}

	onKeyPress(event) {
		return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57 && event.target.value.length <= 2; // onkeypress getting previous value
	}
	// Freedom pay implementation

	freedomPayErrorHandler({ errors = [], type = 'ERROR' }) {
		this.displayAlertWithMessage(
			errors.join('\n'),
			'#cc0000',
			'6000'
		);
	}

	freepdomPayIframeCall() {
		const iframe =
			this.freedomPay.freedompayContainer.nativeElement.querySelector(
				'iframe'
			);
		const fpFrameSrc = new URL(iframe.src);
		const fpDomain = `${fpFrameSrc.protocol}//${fpFrameSrc.hostname}`;
		this.freedomPayService.paymentError$.next(true);
		iframe.contentWindow.postMessage('getPaymentKeys', fpDomain);
		let counter = 0;
		this.holder = async () => {
			const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
			await sleep(1000);
			if (!this.isFreedomPayFormValid && counter < 10) {
				this.holder();
				counter++;
			}
		};
		this.holder();
	}

	doFreedomPayment(paymentGatewayInformation) {
		if(this.reservationService.sharedData.reservationContext.Reservation.ID){
			this.freepdomPayIframeCall();
			return ;
		}
		this.freepdomPayIframeCall();
		
	}

	iframeLoadingHandler(hasLoaded: boolean) {
		if (hasLoaded) {
			this.isLoading = true;
		} else {
			this.isLoading = false;
		}
	}

	validPaymentHandler(data: any) {
		this.isFreedomPayFormValid = true;
		this.freedomPayPaymentInfo = data.paymentInfo;
		this.zipCode = data.additionalInfo.find((data:any) => data.Key === 'PostalCode')?.Value;
		this.saveReservation();
	}

	ngOnDestroy(): void {
		this.subscribeValidatePayment$.unsubscribe();
		this.subscribeFormValidityHandler$.unsubscribe();
		this.subscribeErrorHandler$.unsubscribe();
		this.subscribepromoUpdatedInfo$.unsubscribe();
	}

	toggleIframeLoaderHandler(hasLoaded: boolean) {
			this.showIframeWrapper = !hasLoaded;
	}

	evalutePromoCode() {
		const promoCode = this.paymentForm.get('promoCode').value;
		this.promoservice.promoUpdatedInfo$.next(promoCode);
	}

}
