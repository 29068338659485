import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import * as moment from 'moment';
import { IMyDpOptions } from 'mydatepicker';
import { LoggingService } from 'src/app/services/logging.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utilities } from 'src/app/services/utilities';
import { environment } from '../../../environments/environment';
import { WebString } from '../../constants/string';
import { BookingApiService } from '../../services/booking-api.service';
import { GTMService } from '../../services/gtm.service';
import { EXCLUDEDSTORES } from 'src/app/tokens';


@Component({
	selector: 'app-reservation-details',
	templateUrl: './reservation-details.component.html',
	styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit {

	alertColor: any;
	apiDomain: any;
	applicationMessage: string;
	automationHost: string;
	bypassCaptcha: boolean;
	bypassValue: string;
	captchaMessage: string;
	isCaptcha: boolean;
	isConfirmNumOrEmail: boolean;
	isFundraiserEvent: boolean;
	isLoading: any;
	isReservationDateRequired: boolean;
	isReservationDtCurrentDt: boolean;
	model: any = {};
	multipleReservation: any;
	myDatePickerOptions: IMyDpOptions;
	PPPflag: boolean;
	recapthcaSiteKey = environment.recapthcaSiteKey;
	samedayChangeMessage: string;
	showAlert: boolean;
	showDetails: any;
	showModal: any;
	assetRoot = environment.assetRoot;

	constructor(private route: ActivatedRoute, private router: Router, private bookingApiService: BookingApiService, private gtmService: GTMService,
		private storageService: StorageService, private logger: LoggingService, @Inject(EXCLUDEDSTORES) private excludedStores: string) {

		this.PPPflag = environment.context === 'PPP';
		this.samedayChangeMessage = environment.context === 'PPP' ? WebString.PPPSAMEDAYCHANGE : WebString.CECSAMEDAYCHANGE;
		this.isLoading = false;
		this.isConfirmNumOrEmail = false;
		this.showModal = false;
		this.isCaptcha = false;
		this.isReservationDateRequired = false;
		this.isReservationDtCurrentDt = false;
		this.bypassCaptcha = false;
		this.automationHost = environment['automationHost'] ? environment['automationHost'] : '';
		this.apiDomain = environment.apiDomain;

		this.automationCheck();

	}

	ngOnInit() {

		this.storageService.clear('reservation');
		// Add field masking for dates if IE11 or Safari //
		const isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11./);
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;

		if (isIE11 || (isSafari && !isIOS)) {
			$('#resDate').mask('00/00/0000');
			$('#resDate').attr('placeholder', 'mm/dd/yyyy');
			if (!isIE11 && $(window).width() < 1100) {
				$('#resDate').unmask();
			}
		}

		// Raise labels on focused input //
		$('body').on('focus', '.cec__form input', function () {
			$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
		});

		// Lower labels on blur if field is empty. Remove any errors //
		$('body').on('blur', '.cec__form input', function () {
			if ($(this).val() == null || $(this).val() === '') {
				$('label[for="' + $(this).attr('id') + '"]').removeClass('focus');
			}
			$('.email-error').css('opacity', '0');
		});
		this.gtmService.pushCoreViewDataToGTM('cancelUpdate');
		const date = new Date();
		this.myDatePickerOptions = {
			openSelectorOnInputClick: true,
			editableDateField: false,
			showTodayBtn: false,
			dateFormat: 'mm/dd/yyyy',
			firstDayOfWeek: 'su',
			sunHighlight: true,
			satHighlight: true,
			disableUntil: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
		};
	}

	// Look up reservation based on inputs //
	resLookup(f: NgForm) {
		this.model = f.value;
		this.isLoading = true;
		let isValidEmail = true;
		let isCaptchRequired = true;
		const formattedDate = this.model?.resDate?.formatted;
		// PBI-5998,5999
		if ((this.model.contEmail === undefined || this.model.contEmail === '')
			&& (this.model.confNumber === undefined || this.model.confNumber === '')) {
			this.isConfirmNumOrEmail = true;
			this.isLoading = false;
		} else {
			this.isConfirmNumOrEmail = false;
		}

		if (this.isCaptcha) {
			this.captchaMessage = '';
		} else {
			this.isLoading = false;
			this.captchaMessage = 'Please verify that you are not robot before submitting this form.';
			isCaptchRequired = false;
		}
		if (this.model?.resDate === undefined || this.model?.resDate?.length === 0) {
			this.isReservationDateRequired = true;
			this.isLoading = false;
		} else {
			this.isReservationDateRequired = false;
			const validDate = moment(formattedDate, ['MM/DD/YYYY'], true);
			if (!(validDate.isValid())) {
				this.isLoading = false;
				this.displayAlertWithMessage('Please provide a valid Date', '#cc0000', 8000);

			} else if (this.model?.resDate) {
				const date = moment(formattedDate, ['MM/DD/YYYY']).format('YYYYMMDD').toString();
				const now = moment().format('YYYYMMDD').toString();

				if (date <= now) {
					this.isReservationDtCurrentDt = true;
					this.isLoading = false;
				}

			} else {
				this.isReservationDateRequired = false;
			}
		}
		if (!this.isConfirmNumOrEmail && (this.model.contEmail !== undefined && this.model.contEmail !== '')) {
			if (this.validateEmail(this.model.contEmail)) {
				isValidEmail = true;
			} else {
				this.displayAlertWithMessage('Please provide a valid E-Mail', '#cc0000', 8000);
				this.isLoading = false;
				isValidEmail = false;
			}
		}
		if (!this.isReservationDateRequired && !this.isReservationDtCurrentDt && isCaptchRequired && !this.isConfirmNumOrEmail && isValidEmail) {
			const reservation = {
				ConfirmationNumber: this.model.confNumber,
				PartyDateFrom: moment(formattedDate, ['MM/DD/YYYY']).format('YYYY-MM-DD').toString(),
				Email: this.model.contEmail,
				StoreType: environment.context === 'PPP' ? 2 : 1
			};
			this.bookingApiService.searchReservation(reservation).subscribe(data => {
				const reservationSearch = data;
				if (reservationSearch.Reservations.length) {
					if (reservationSearch.Reservations.length === 1) {
						const redirectUrl = this.excludedStores.includes(reservationSearch.Reservations[0].StoreID) ? 'reservationsV2/dashboard' : 'reservation-dashboard';
						
						this.router.navigate([redirectUrl], {
							queryParams: {
								qDate: Utilities.encodeParameter(moment(formattedDate, ['MM/DD/YYYY']).format('YYYY-MM-DD').toString()),
								qEmail: Utilities.encodeParameter(this.model.contEmail),
								qId: Utilities.encodeParameter(this.model.confNumber)
							}
						});
					} else {
						this.multipleReservation = reservationSearch.Reservations;
						this.isLoading = false;
						this.showModal = true;
					}
				} else {
					this.displayUnableToFindReservationAlert();
					this.isLoading = false;
				}
			},
				error => {
					this.logger.logError(error);
					this.isLoading = false;
				}
			);
		}
	}
	// Validate Email
	validateEmail(email) {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	displayUnableToFindReservationAlert() {
		this.showAlert = true;
		this.applicationMessage = 'Unable to find reservation';
		this.alertColor = '#cc0000';
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '#4BB543';
			this.applicationMessage = '';
		}, 8000);
	}

	automationCheck() {

		this.bypassCaptcha = this.automationHost === location.hostname;

		this.route.queryParams.subscribe(params => {
			this.bypassValue = params['bypass'];
		});

		if (this.bypassValue === 'e3c50468-dc9b-470b-a1ac-18dfdb0fbd1e' && this.bypassCaptcha) {
			this.isCaptcha = true;
		} else {
			this.bypassCaptcha = false;
		}
	}

	displayAlertWithMessage(message: string, color: string, timeout) {
		this.showAlert = true;
		this.applicationMessage = message;
		this.alertColor = color;
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '';
		}, timeout);
	}

	toggle() {
		this.isReservationDtCurrentDt = false;
		this.isReservationDateRequired = false;
	}

	resolved(captchaResponse: string) {
		if (captchaResponse) {
			this.captchaMessage = '';
			this.isCaptcha = true;
		} else {
			this.isCaptcha = false;
		}
	}

}
