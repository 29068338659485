export class Alert {
    message: string;
    show: boolean;
    className: string;
    constructor() {
        this.message = 'Alert Message!';
        this.className = 'Alert ClassName';
        this.show = false;
    }
}
