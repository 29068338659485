<app-message [alert]="alertMessage"></app-message>

<app-edit-info [page]="page" *ngIf="showEditInfo" (updatedInfo)="updateInfo(event)" (closeModalHandler)="closeModalHandler(event)"></app-edit-info>

<app-change-package (updatedLocal)="updatedLocalEvent($event)" *ngIf="showChangePackage"></app-change-package>

<ng-container *ngIf="localStorageExists">

  <!-- Printing the file -->
  <div class="printMe PPP-wrapper">
    <h4 class="c-info-card__title d-print-none" *ngIf="page == 3">Reservation Summary</h4>
    <div class="c-info-card__divider d-print-none"></div>
    <div *ngIf="page == 3">
      <a href="javascript:void(0);" oncontextmenu="return false" class="c-info-card__link d-print-none"
        (click)="printPage()">Print
        Reservation</a>
      <div class="c-info-card__divider"></div>
    </div>
    <span class="c-info-card__lbl" *ngIf="localStorage?.store"
      data-test-id="sidebarStoreAddress">{{localStorage.store.address}} <br />
      {{localStorage.store.city}},
      {{localStorage.store.state}} {{localStorage.store.zip}} <br />{{localStorage.store.phone}}</span>
    <a (click)="changeLocation()" class="c-info-card__link d-print-none"
      data-test-id="sidebarChangeStoreLocationLink">Change Location</a>
    <div *ngIf="page == 3">
      <div class="c-info-card__divider"></div>

      <ng-container *ngTemplateOutlet="displayBirthdayStars"></ng-container>

      <a href="/payment" class="c-info-card__link d-print-none">{{updateLink}}</a>
    </div>
    <div class="c-info-card__divider"></div>

    <ng-container *ngTemplateOutlet="ReadOnlyResData; context:{reservation: localStorage}"></ng-container>

    <div class="party_room_label" *ngIf="reservationType == reservationPartyRoomType">In a private party room</div>

    <ng-container *ngIf="allowEdit ; else noEdit">
      <a class="c-info-card__link d-print-none" data-test-id="sidebarEditPartyBtn" (click)="editParty()">Edit
        {{localStorage?.booking?.type == 4 ? 'Visit'
        :'Party'}}
        Information</a>
    </ng-container>
    <div class="c-info-card__divider"></div>
    <div class="c-info-card__button {{colorClass}}" *ngIf="page == 2 || page == 3" (click)="change()">
      <div class="c-info-card__package" data-test-id="sidebarPackageNameLabel">
        {{partyName}}
      </div>
      <div class="c-info-card__change d-print-none" *ngIf="allowEdit">
        Change
      </div>
    </div>
    <div *ngIf="page == 2 || page == 3">
      <div class="c-info-card__divider"></div>
      <div *ngIf="isDepositRequired && isDeposit">
        <span class="c-info-card__lbl m-b5">Deposit due: <span class="-bold" style="float: right">{{(!isAlreadyPaid ?
            localStorage.reservationContext?.Reservation?.DepositAmount : 0) |
            currency:'USD':'symbol':'.2-2'}}</span></span>
      </div>
      <div *ngIf="!isAlreadyPaid && isDeposit">
        <span class="c-info-card__lbl m-b5">Deposit by: <span class="-bold">{{refreshdueDate()}}</span></span>
      </div>
      <div *ngIf="page == 3 && !isAlreadyPaid && !hidePayOnlineLink && isDeposit">
        <a href="/payment" class="c-info-card__link d-print-none" data-test-id="sidebarPayOnlineBtn">Pay for your
          deposit online</a>
      </div>
      <div class="c-info-card__divider"></div>
      <div *ngIf="page == 3">
        <span class="c-info-card__lbl m-b5">({{localStorage?.kids}}) Children: <span class="-bold"
            *ngIf="localStorage?.reservationContext?.PartyType"
            style="float:right">{{localStorage.reservationContext.PartyType.PriceAsPer == partyTypePricing ? '$0.00' :
            localStorage.reservationContext.PartyType.Price * localStorage.kids |
            currency:'USD':'symbol':'.2-2'}}</span></span>
        <span class="c-info-card__lbl m-b5">({{localStorage.adults}}) Adults <span class="-bold" style="float:right">
            {{ localStorage?.reservationContext?.Reservation?.AdultCharge||0 *
            localStorage?.reservationContext?.Reservation?.Adults||0 | currency:'USD':'symbol':'.2-2'}}
          </span>
        </span>

        <ng-container *ngTemplateOutlet="displayAdditionalItems"></ng-container>

      </div>
      <div>
        <span class="c-info-card__lbl m-b5"><strong>Estimated Total: </strong> <span class="-bold"
            data-test-id="sidebarEstimatedTotalValue"
            *ngIf="localStorage.reservationContext && localStorage.reservationContext.Reservation"
            style="float: right">{{localStorage.reservationContext.Reservation.TotalPrice |
            currency:'USD':'symbol':'.2-2'}}</span></span>
        <span class="c-info-card__lbl m-b5">This price is an estimate based on the items selected
          online.</span>
        <ul>
          <li>
            <span class="c-info-card__lbl m-b5">Estimate does not include tax or food for adults at the party.</span>
          </li>
          <li *ngIf="partyDuration">
            <span class="c-info-card__lbl m-b5">I understand my party is reserved for the duration of {{ partyDuration }} {{ partyDuration==="1" ? 'hour' : 'hours'}} from my
              selected start time.</span>
          </li>
          <li>
            <span class="c-info-card__lbl m-b5">No outside food or beverages other than a party cake will be allowed
              inside the facility.</span>
          </li>
        </ul>
      </div>
      <div class="c-info-card__divider"></div>
    </div>
    <div>
      <div *ngIf="reward?.length">
        <span class="c-info-card__lbl m-b5" data-test-id="sidebarIncludedRewardsLabel"><Strong>Included
            Rewards</Strong></span>
        <ul>
          <li *ngFor="let r of reward">
            <span class="c-info-card__lbl m-b5 text-break" data-test-id="sidebarRewardTitle">{{r.RewardTitle}}
              <span *ngIf="r.RewardTypeID === 3">- {{promoRewardDescription}}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="page == 3">
      <div class="c-info-card__divider"></div>
      <a href="javascript:void(0);" data-test-id="sidebarCancelReservationLink" class="c-info-card__link d-print-none"
        (click)="cancel()">Cancel Reservation</a>
    </div>
  </div>


  <section (scroll)="onWindowScroll($event)" class="noPrint">
    <div class="m-select-section__right PPP-wrapper" *ngIf="localStorage?.store">
      <app-loader *ngIf="loader"></app-loader>
      <div class="c-info-card">
        <h4 class="c-info-card__title" *ngIf="page != 3">Location & Attendee Info</h4>
        <h4 class="c-info-card__title" *ngIf="page == 3">Reservation Summary</h4>
        <div class="c-info-card__divider"></div>
        <div *ngIf="page == 3">
          <a href="javascript:void(0);" oncontextmenu="return false" class="c-info-card__link d-print-none"
            (click)="printPage()">Print
            Reservation</a>
          <div class="c-info-card__divider"></div>
        </div>
        <span class="c-info-card__lbl" *ngIf="localStorage?.store"
          data-test-id="sidebarStoreAddress">{{localStorage.store.address}} <br />
          {{localStorage.store.city}},
          {{localStorage.store.state}} {{localStorage.store.zip}} <br />{{localStorage.store.phone}}</span>
        <a (click)="changeLocation()" class="c-info-card__link d-print-none"
          data-test-id="sidebarChangeStoreLocationLink">Change Location</a>
        <div *ngIf="page == 3">
          <div class="c-info-card__divider"></div>

          <ng-container *ngTemplateOutlet="displayBirthdayStars"></ng-container>

          <a href="/payment" class="c-info-card__link d-print-none">{{updateLink}}</a>
        </div>
        <div class="c-info-card__divider"></div>

        <ng-container *ngTemplateOutlet="ReadOnlyResData; context:{reservation: localStorage}"></ng-container>

        <div class="party_room_label" *ngIf="localStorage?.booking?.type == reservationPartyRoomType">In a private party
          room</div>

        <ng-container *ngIf="allowEdit; else noEdit">
          <a class="c-info-card__link d-print-none" data-test-id="sidebarEditPartyBtn" (click)="editParty()">Edit
            {{localStorage?.booking?.type == 4?
            'Visit':'Party'}}
            Information</a>
        </ng-container>

        <div class="c-info-card__divider"></div>
        <div class="c-info-card__button {{colorClass}}" *ngIf="page == 2 || page == 3" (click)="change()">
          <div class="c-info-card__package" data-test-id="sidebarPackageNameLabel">
            {{partyName}}
          </div>
          <div class="c-info-card__change d-print-none" *ngIf="allowEdit">
            Change
          </div>
        </div>
        <div *ngIf="page == 2 || page == 3">
          <div class="c-info-card__divider"></div>
          <div *ngIf="isDepositRequired && isDeposit">
            <span class="c-info-card__lbl m-b5">Deposit due: <span class="-bold" style="float: right">{{(!isAlreadyPaid
                ? localStorage.reservationContext.Reservation.DepositAmount : 0) |
                currency:'USD':'symbol':'.2-2'}}</span></span>
          </div>
          <div *ngIf="!isAlreadyPaid && isDeposit">
            <span class="c-info-card__lbl m-b5">Deposit by: <span class="-bold">{{refreshdueDate()}}</span></span>
          </div>
          <div *ngIf="page == 3 && !isAlreadyPaid && !hidePayOnlineLink && isDeposit">
            <a href="/payment" class="c-info-card__link d-print-none" data-test-id="sidebarPayOnlineBtn">Pay for your
              deposit online</a>
          </div>
          <div class="c-info-card__divider"></div>
          <div *ngIf="page == 3 && localStorage?.reservationContext">
            <span class="c-info-card__lbl m-b5">({{localStorage.kids}}) Children: <span class="-bold"
                *ngIf="localStorage.reservationContext.PartyType"
                style="float:right">{{localStorage.reservationContext.PartyType.PriceAsPer == partyTypePricing ? '$0.00'
                : localStorage.reservationContext.PartyType.Price * localStorage.kids |
                currency:'USD':'symbol':'.2-2'}}</span></span>
            <span class="c-info-card__lbl m-b5">({{localStorage.adults}}) Adults <span class="-bold"
                style="float:right">
                {{ (localStorage.reservationContext.Reservation?.AdultCharge || 0) *
                (localStorage.reservationContext.Reservation?.Adults|| 0) | currency:'USD':'symbol':'.2-2'}}</span>
            </span>

            <ng-container *ngTemplateOutlet="displayAdditionalItems"></ng-container>

          </div>
          <div>
            <span class="c-info-card__lbl m-b5 estimated-total"><strong>Estimated Total: </strong> <span class="-bold"
                data-test-id="sidebarEstimatedTotalValue" *ngIf="localStorage.reservationContext.Reservation"
                style="float: right">{{localStorage.reservationContext.Reservation.TotalPrice |
                currency:'USD':'symbol':'.2-2'}}</span></span>
            <span class="c-info-card__lbl m-b5">This price is an estimate based on the items selected
              online.</span>
            <ul>
              <li>
                <span class="c-info-card__lbl m-b5">Estimate does not include tax or food for adults at the
                  party.</span>
              </li>
              <li *ngIf="partyDuration">
                <span class="c-info-card__lbl m-b5">I understand my party is reserved for the duration of {{ partyDuration }} {{ partyDuration==="1" ? 'hour' : 'hours'}} from
                  my selected start time.</span>
              </li>
              <li>
                <span class="c-info-card__lbl m-b5">No outside food or beverages other than a party cake will be allowed
                  inside the facility.</span>
              </li>
            </ul>
          </div>
          <div class="c-info-card__divider"></div>
        </div>
        <!------------------ Adding the promocode-section ---------------->
        <div *ngIf="page !== 1 && showPromo">
          <div class="col-md-12 section_promocode">
            <label class="lable_promocode" for="promocode">Enter the Promo Code</label>
            <input class="input_promocode" id="promocode" name="promocode" type="text" [(ngModel)]="promoCode">
          </div>
          <a class="btn btn-ghost" data-test-id="sidebarPromoCodeApplyBtn"
            (click)="evalutePromoCode()">Apply</a>
          <ng-container *ngIf="showPromoMessage">
            <span class="c-info-card__lbl m-b5"
              data-test-id="sidebarPromoMessageLabel"><strong>{{promoMessage}}</strong></span>
          </ng-container>
          <span class="c-info-card__lbl m-b5">{{promoDescription}}</span>
          <div class="c-info-card__divider"></div>
        </div>
        <!----------------- End of promocode-section ------------------->
        <div>
          <div *ngIf="reward?.length">
            <span class="c-info-card__lbl m-b5" data-test-id="sidebarIncludedRewardsLabel"><Strong>Included
                Rewards</Strong></span>
            <ul>
              <li *ngFor="let r of reward">
                <span class="c-info-card__lbl m-b5 text-break" data-test-id="sidebarRewardTitle">{{r.RewardTitle}}
                  <span *ngIf="r.RewardTypeID === 3">- {{promoRewardDescription}}</span>
                </span>

              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="page == 3">

          <div class="c-info-card__divider"></div>
          <a href="javascript:void(0);" data-test-id="sidebarCancelReservationLink"
            class="c-info-card__link d-print-none" (click)="cancel()">Cancel Reservation</a>
        </div>
      </div>

      <!-- Mobile View -->
      <div class="c-mobile-collapse" id="mobile-collapse" *ngIf="localStorage.store">

        <div class="c-mobile-collapse__head" style="background-color:#088482">
          <div class="c-mobile-collapse__inline" (click)="doInfoCollapseAction()">
            <h4 class="c-mobile-collapse__title top-header" *ngIf="page != 3">View/Edit Your Reservation</h4>
            <h4 class="c-mobile-collapse__title top-header" *ngIf="page == 3">Reservation Summary
            </h4>
            <span class="c-mobile-collapse__icon" *ngIf="collapseContent == true">
              <img src="{{assets}}/assets/substract-white.png" />
            </span>
            <span class="c-mobile-collapse__icon" *ngIf="collapseContent == false">
              <img src="{{assets}}/assets/add-white.png" />
            </span>
          </div>
        </div>

        <div class="c-mobile-collapse__body" *ngIf="collapseContent">
          <div *ngIf="page == 3" class="align">
            <a href="javascript:void(0);" oncontextmenu="return false" class="c-info-card__link d-print-none"
              (click)="printPage()">Print Reservation</a>
            <div class="c-info-card__divider"></div>
          </div>
          <div class="c-mobile-collapse__inline border-b1 PPP-sticky">
            <span class="c-mobile-collapse__lbl -bold" data-test-id="sidebarStoreAddress">
              {{localStorage?.store?.address}} <br /> {{localStorage?.store?.city}},
              {{localStorage?.store?.state}} {{localStorage?.store?.zip}} <br /> {{localStorage?.store?.phone}}
            </span>
            <div class="c-mobile-collapse__action">
              <a (click)="changeLocation()" class="c-mobile-collapse__link d-print-none"
                data-test-id="sidebarChangeStoreLocationLink">Change Location</a>
            </div>
          </div>

          <ng-container *ngIf="allowEdit">
            <div class="c-mobile-collapse__inline">
              <span class="c-info-card__lbl m-b5">
                Child Attendees
              </span>
              <div class="c-mobile-collapse__action">
                <div class="c-mobile-collapse__inline -no-padding">
                  <span class="c-mobile-collapse__action--icon" (click)="updateCount(0,0)">
                    <img src="{{assets}}/assets/PPP-substract.png" alt="-" />
                  </span>
                  <span class="-count">{{mKid}}</span>
                  <span class="c-mobile-collapse__action--icon" (click)="updateCount(0,1)">
                    <img src="{{assets}}/assets/PPP-add.png" alt="+" />
                  </span>
                </div>
              </div>
            </div>
            <div class="c-mobile-collapse__inline">
              <span class="c-info-card__lbl m-b5">
                Adult Attendees
              </span>
              <div class="c-mobile-collapse__action">
                <div class="c-mobile-collapse__inline -no-padding">
                  <span class="c-mobile-collapse__action--icon" (click)="updateCount(1,0)">
                    <img src="{{assets}}/assets/PPP-substract.png" alt="-" />
                  </span>
                  <span class="-count">{{mAdult}}</span>
                  <span class="c-mobile-collapse__action--icon" (click)="updateCount(1,1)">
                    <img src="{{assets}}/assets/PPP-add.png" alt="+" />
                  </span>
                </div>
              </div>
            </div>
            <div class="c-mobile-collapse__inline ">
              <span *ngIf='dateofParty' class="c-info-card__lbl m-b5">
                {{dateofParty}}:
              </span>
              <div class="c-mobile-collapse__action">
                <form #myForm="ngForm" novalidate class="c-custom-form dateFix ">
                  <my-date-picker name="mydate" [options]="myDatePickerOptions" [(ngModel)]="mydate"
                    placeholder="mm-dd-yyyy" (dateChanged)="onDateChanged($event)" required>
                  </my-date-picker>
                </form>
              </div>
            </div>
            <div class="party-room" *ngIf="local?.store?.hasPartyRooms && [1,5].includes(reservationType)">
              <input name="partyRoom" id="partyRoom" type="checkbox" [value]="false" [(ngModel)]="partyRoom"
                (ngModelChange)="changePartyRoomHandler($event)">
              <label class="checkbox-label" for="partyRoom">&nbsp;Select a private party room Requires an additional
                ${{localStorage.booking.AdditionalDepositPartyRoom}} non-refundable deposit</label>
            </div>
            <div class="c-mobile-collapse__inline">
              <span class="c-info-card__lbl m-b5">Time:
              </span>
              <div class="c-mobile-collapse__action-time c-mobile-collapse__action">
                <select (change)="partyTime($event.target.value)" class="c-custom-form__textbox  time-slots-list"
                  style="max-width: 100%; font: size 14px !important;" [(ngModel)]="mytimeslot">
                  <option *ngIf='timeofParty' value={{timeofParty}} disabled selected>{{timeofParty}}</option>
                  <option *ngFor="let slot of availableSlot; let i = index " value={{i}} [disabled]="!slot.available"
                    [ngClass]="{'grey':!slot.available, 'white': slot.available}">
                    {{ slot.start | displayTimeSlotTime }} - {{ slot.end | displayTimeSlotTime }}
                    &nbsp; <span *ngIf="slot.IsVipParty">VIP &nbsp;</span><span *ngIf="!slot.available">Full</span>
                  </option>
                </select>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!allowEdit">
            <ng-container *ngTemplateOutlet="ReadOnlyResData; context:{reservation:localStorage}"></ng-container>
          </ng-container>

          <div class="party_room_label" *ngIf="reservationType == reservationPartyRoomType">In a private party room
          </div>

          <ng-container *ngIf="allowEdit; else noEdit">
            <div class="c-mobile-collapse__inline" (click)="update()">
              <a class="-bold {{active}} apply-text">Apply Changes</a>
            </div>
          </ng-container>

          <div *ngIf="page == 3" class="align">
            <div class="c-info-card__divider"></div>

            <ng-container *ngTemplateOutlet="displayBirthdayStars"></ng-container>

            <a href="/payment" data-test-id="sidebarUpdatePaymentLink"
              class="c-info-card__link d-print-none">{{updateLink}}</a>
            <div class="c-info-card__divider"></div>
          </div>
          <div class="c-info-card__button {{colorClass}}" *ngIf="page == 2 || page == 3" (click)="change()">
            <div class="c-info-card__package" data-test-id="sidebarPackageNameLabel">
              {{partyName}}
            </div>
            <div class="c-info-card__change" *ngIf="allowEdit">
              Change
            </div>
          </div>
          <div *ngIf="page == 2 || page == 3" class="align">
            <div class="c-info-card__divider"></div>
            <div *ngIf="isDepositRequired && isDeposit">
              <span class="c-info-card__lbl m-b5">Deposit due: <span class="-bold"
                  style="float: right">{{(!isAlreadyPaid ? localStorage.reservationContext.Reservation.DepositAmount :
                  0) | currency:'USD':'symbol':'.2-2'}}</span></span>
            </div>
            <div *ngIf="!isAlreadyPaid && isDeposit">
              <span class="c-info-card__lbl m-b5">Deposit by: <span class="-bold">{{dueDate}}</span></span>
            </div>
            <div *ngIf="page == 3 && !isAlreadyPaid && !hidePayOnlineLink && isDeposit">
              <a href="/payment" class="c-info-card__link d-print-none" data-test-id="sidebarPayOnlineBtn">Pay for your
                deposit online</a>
            </div>
            <div class="c-info-card__divider"></div>
            <div *ngIf="page == 3">
              <span class="c-info-card__lbl m-b5">({{localStorage.kids}}) Children: <span class="-bold"
                  style="float:right">{{localStorage.reservationContext.PartyType.PriceAsPer == partyTypePricing ?
                  '$0.00' : localStorage.reservationContext.PartyType.Price * localStorage.kids |
                  currency:'USD':'symbol':'.2-2'}}</span></span>
              <span class="c-info-card__lbl m-b5">({{localStorage.adults}}) Adults <span class="-bold"
                  style="float:right">
                  {{localStorage.reservationContext.Reservation.AdultCharge *
                  localStorage.reservationContext.Reservation.Adults |
                  currency:'USD':'symbol':'.2-2'}}</span>
              </span>

              <ng-container *ngTemplateOutlet="displayAdditionalItems"></ng-container>

            </div>
            <div>
              <span class="c-info-card__lbl m-b5"><strong>Estimated Total: </strong> <span class="-bold"
                  data-test-id="sidebarEstimatedTotalValue"
                  style="float: right">{{localStorage.reservationContext.Reservation.TotalPrice |
                  currency:'USD':'symbol':'.2-2'}}</span></span>
              <span class="c-info-card__lbl m-b5">This price is an estimate based on the items selected
                online.</span>
              <ul>
                <li>
                  <span class="c-info-card__lbl m-b5">Estimate does not include tax or food for adults at the
                    party.</span>
                </li>
                <li *ngIf="partyDuration">
                  <span class="c-info-card__lbl m-b5">I understand my party is reserved for the duration of {{ partyDuration }} {{ partyDuration==="1" ? 'hour' : 'hours'}} from
                    my selected start time.</span>
                </li>
                <li>
                  <span class="c-info-card__lbl m-b5">No outside food or beverages other than a party cake will be
                    allowed inside the facility.</span>
                </li>
              </ul>
            </div>
            <div class="c-info-card__divider"></div>
          </div>
          <!------------------ Adding the promocode-section ---------------->


          <div *ngIf="page !== 1 && showPromo">

            <div class="col-md-12 section_promocode">
              <label class="lable_promocode" for="promocode">Enter the Promo Code</label>
              <input style="margin-bottom: 5px;" class="input_promocode" id="promocode" name="promocode" type="text"
                [(ngModel)]="promoCode">
            </div>
            <div style="margin-top: 25px;">
              <a class="btn btn-ghost" data-test-id="sidebarPromoCodeApplyBtn"
                (click)="evalutePromoCode()">Apply</a>
            </div>
            <ng-container *ngIf="showPromoMessage">
              <span class="c-info-card__lbl m-b5 promo-success-msg"
                data-test-id="sidebarPromoMessageLabel"><strong>{{promoMessage}}</strong></span><br>
            </ng-container>
            <span class="c-info-card__lbl m-b5">{{promoDescription}}</span>
            <div class="c-info-card__divider"></div>
          </div>


          <!----------------- End of promocode-section ------------------->
          <div class="align">
            <div *ngIf="reward?.length">
              <span class="c-info-card__lbl m-b5" data-test-id="sidebarIncludedRewardsLabel"><Strong>Included
                  Rewards</Strong></span>
              <ul>
                <li *ngFor="let r of reward">
                  <span class="c-info-card__lbl m-b5 text-break" data-test-id="sidebarRewardTitle">{{r.RewardTitle}}
                    <span *ngIf="r.RewardTypeID === 3">- {{promoRewardDescription}}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="page == 3" class="align">
            <div class="c-info-card__divider"></div>
            <a href="javascript:void(0);" class="c-info-card__link d-print-none" (click)="cancel()">Cancel
              Reservation</a><br>
          </div>
        </div>
      </div>
      <!--End  view/ edit your party info html -->
      <div class="m-select-section">
      </div>
    </div>
  </section>
</ng-container>


<ng-template #displayBirthdayStars>

  <div class="c-info-card__lbl m-b5">Birthday
    {{localStorage?.reservationContext?.Reservation?.BirthdayGuestDetail?.length===1 ? 'Child' : 'Children' }}:
  </div>

  <div class="c-info-card__lbl"
    *ngFor="let child of localStorage?.reservationContext?.Reservation?.BirthdayGuestDetails">
    <span class="-bold">
      {{child.GuestName}} ({{child.Age}} {{child.Age>1 ? 'yrs' : 'yr'}})
    </span>
  </div>
</ng-template>

<ng-template #displayAdditionalItems>

  <div class="d-flex justify-content-between c-info-card__lbl" *ngIf="upsellArr?.length===0">
    <div>Additional Items:</div>
    <div class="-bold">{{ additionItemPrice|currency }}</div>
  </div>

  <div class="c-info-card__lbl m-b5" *ngIf="upsellArr.length>0">
    <div class="d-flex flex-row justify-content-start">
      <div class="mr-auto">
        <a (click)="isExpandAddItems = !isExpandAddItems" class="c-info-card__link"> Additional Items: </a>
        <img *ngIf="upsellArr?.length > 0" src="{{assets}}/assets/down-arrow.png" alt="" width="12px" height="12px"
          [ngClass]="!isExpandAddItems ? 'rotate-90' : ''" />
      </div>
      <div class="">
        <span class="-bold">{{ additionItemPrice | currency }}</span>
      </div>
    </div>

    <div [ngClass]="{'expand': isExpandAddItems, '': !isExpandAddItems}" class="additional-items-container">
      <div class="additional-items-wrap">
        <ul class="additional-items-list">
          <li *ngFor="let item of upsellArr">
            <span class="itemQuantity">({{ item.Quantity }})</span>
            <span class="itemTitle">{{ item.ItemTitle }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #noEdit>
  <p class="text-danger no-res-message">
    Please contact the store to change your reservation.
  </p>
</ng-template>

<ng-template let-reservation="reservation" #ReadOnlyResData>
  <ng-container *ngIf="reservation">
    <div class="reservation-data">
      <div class="d-flex ">
        <div>
          Child Attendees:
        </div>
        <div class="text-right font-weight-bold">
          &nbsp; {{reservation?.kids}}
        </div>
      </div>

      <div class="d-flex ">
        <div>
          Adult Attendees:
        </div>
        <div class="text-right font-weight-bold">
          &nbsp;{{reservation?.adults}}
        </div>
      </div>

      <div class="d-flex" *ngIf="reservation.date">
        <div>
          Date of Party:
        </div>
        <div class="text-right font-weight-bold">
          &nbsp;{{reservation?.date | displaySimpleDate}}
        </div>
      </div>

      <div class="d-flex " *ngIf="reservation?.time">
        <div>
          Time:
        </div>
        <div class="text-right font-weight-bold">
          &nbsp;
          {{ reservation.time.start | displayTimeSlotTime}} -
          {{ reservation.time.end | displayTimeSlotTime}}
        </div>
      </div>
    </div>
  </ng-container>

</ng-template>
