<div class="cec__module PPP-wrapper" *ngIf="popup?.showPromotionPopup">
  <div id="packageUpsellModal" class="upsell-modal closeUpsellPackageType">
    <div class="upsell__inner cec__form" *ngIf="data">
      <div>
        <span (click)="closePopup()" class="close_button"><img src="{{assetRoot}}/assets/PPP-close.png" /></span>
      </div>
      <div>
        <div>
          <img class="upsell-thumbnail" src={{data.Image}} alt="upsell" title="upsell image" />
        </div>
        <div>
          <h4>{{data.Title}}</h4>
        </div>
        <div>
          <p class="promo_description" [innerHtml]="data.Description | keepHtml"></p>
        </div>
      </div>
      <br />
      <button class="btn--primary btn--purple yesUpsellPackageType -circle_image" (click)="upgrade(1)" >
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.47443 182.64998">
          <defs>
            <style>
              .cls-1 {
                fill: none;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-width: 7px;
              }
            </style>
          </defs>
          <path class="cls-1 path"
            d="M16.35228,67.598S185.18984-41.06585,451.85651,69.60082c0,0,111.97656,41.02035-26.34505,74.17684s-364.26172,50.42619-398.625-19.542S233.52317,27.93415,314.18984,37.26749" />
        </svg>
        Upgrade!
      </button>
      <button class="noUpsellPackageType" (click)="upgrade(0)">No Thanks</button>
    </div>
  </div>
</div>
