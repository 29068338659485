import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FreedompayService } from 'src/app/services/freedompay/freedompay.service';
import { StorageService } from 'src/app/services/storage.service';
import { FREEDOMPAY_URL } from 'src/app/tokens';
let isPurchaseTriggered = false;
let attrRes: any = null;

@Component({
	selector: 'app-freedompay',
	templateUrl: './freedompay.component.html',
	styleUrls: ['./freedompay.component.scss'],
})
export class FreedompayComponent implements OnInit {
	@ViewChild('freedompay') freedompayContainer: any;
	@Output() validPaymentHandler = new EventEmitter<any>();
	@Output() iframeLoading = new EventEmitter<boolean>();
	@Output() toggleIframeLoader = new EventEmitter<boolean>();
	@Output() errorHandler = new EventEmitter<any>();
	@Input() invoiceAmount = 0;
	@Input() invoiceNumber = '';
	subscribePaymentError$: Subscription;
	localStorage: any;

	defaultErrorMsg =
		'An error occured with your payment detail, please contact site admin or try with another credit card.';
	fpControlsReloadAttempt = 0;
	validationResults: any;
	fpPaymentKeysErrors = [
		'An unknown error occurred.Please try again.',
		'Unable to generate payment key: Reached maximum attempts.',
		'401 - Unauthorized: Authorization has been denied for this request.',
		'404 - Not Found: The resource you are looking for has been removed, had its name changed, or is temporarily unavailable.',
	];
	fpInitErrors = [
		'Controls did not load properly. Please refresh and try again.',
	];

	constructor(
		private freedomPayService: FreedompayService,
		@Inject(FREEDOMPAY_URL) public freedomPayUrl: string,
		private storageService: StorageService
	) {
		this.subscribePaymentError$ = this.freedomPayService.paymentError$.subscribe(() => {
			isPurchaseTriggered = false;
		});
	}

	ngOnInit(): void {
		this.localStorage = this.storageService.fetch();
		try {
			this.toggleIframeLoader.emit(true);
			this.iframeLoading.emit(true);
			this.addScript();
			const settings = {
				appId: this.localStorage?.reservationContext.Store?.StorePaymentGatewayTypeID,
				storeId: this.localStorage?.reservationContext?.Store?.ID,
			};

			this.freedomPayService
				.getAttributes({
					appId: settings.appId,
					storeId: settings.storeId,
				})
				.subscribe({
					next: (res: any) => {
						this.removeMessageListener();
						this.addMessageListener();
						attrRes = res;
						this.freedomPayService.getIframe(res).subscribe({
							next: ({ data, errors, isSuccess }: any) => {
								if (isSuccess) {
									this.freedompayContainer.nativeElement.innerHTML =
										data.iframe;
									attrRes.sessionKey = data.sessionKey;
								} else {
									this.errorHandler.emit({
										errors: [this.defaultErrorMsg],
									});
								}
								this.iframeLoading.emit(false);
							},
							error: () => {
								this.errorHandler.emit({
									errors: [this.defaultErrorMsg],
								});
								this.iframeLoading.emit(false);
							},
						});
					},
					error: () => {
						this.errorHandler.emit({
							errors: [this.defaultErrorMsg],
						});
						this.iframeLoading.emit(false);
					},
				});
		} catch (e) {
			this.iframeLoading.emit(false);
			this.errorHandler.emit({ errors: [this.defaultErrorMsg] });
		}
	}

	addScript() {
		const version = 'v1.5';
		const scriptsUrl = [
			`${this.freedomPayUrl}/api/${version}/cdn/hpc_min.js`,
		];
		scriptsUrl.forEach((script: string) => {
			let node = document.createElement('script');
			node.src = script;
			node.type = 'text/javascript';
			node.async = true;
			node.charset = 'utf-8';
			document.getElementsByTagName('head')[0].appendChild(node);
		});
	}

	handleMessage(e: any) {
		if (
			e.origin !== location.origin &&
			e.origin !== this.freedomPayUrl
		)
			return;

		const message = e.data;
		const data = message.data;

		switch (message.type) {
			case 1:
				this.errorHandler.emit({
					errors: data?.errors?.map((e: any) => e.message),
				});
				this.iframeLoading.emit(false);
				setTimeout(() => {
					window.location.reload();
				  }, 3000);
				break;
			case 2:
				this.fpSetFrameHeight(data);
				break;
				case 3:
					if(!isPurchaseTriggered){
						isPurchaseTriggered = true;
						this.localStorage = this.storageService.fetch();
						this.freedomPayService.validatePayment$.next({
							paymentInfo: {
								paymentType: data?.paymentType,
								paymentKeys: data?.paymentKeys,
								invoiceAmount: this.localStorage?.reservationContext?.Reservation?.DepositAmount,
								invoiceNumber: this.localStorage?.reservationContext?.Reservation?.InvoiceNumber,
								...attrRes,
							},
							additionalInfo: data?.attributes
						});
					}
					break;
			case 4:
				// this.handleFPValidityChange(data);
				this.freedomPayService.formValidityHandler$.next(data);
				// Feedback messages showing below to each control
				break;
		}
	}

	addMessageListener() {
		window.addEventListener('message', this.handleMessage.bind(this));
	}

	removeMessageListener() {
		window.removeEventListener('message', this.handleMessage.bind(this));
	}

	//reset the Iframe height on the browser
	fpSetFrameHeight(data: any) {
		const iframe = this.freedompayContainer.nativeElement.querySelector(
			'iframe'
		);
		iframe.style.height = data.height + 'px';
		iframe.style.width = '100%';
		this.toggleIframeLoader.emit(false);
	}

	//handle error message emitted by any events
	// fpHandleErrors(data) {
	// 	data.errors.forEach(function (error) {
	// 		if (
	// 			(error.emittedBy === 'RequestPaymentKey' &&
	// 				this.fpPaymentKeysErrors.indexOf(error.message) > -1) ||
	// 			(error.emittedBy === 'Init' &&
	// 				this.fpInitErrors.indexOf(error.message) > -1)
	// 		)
	// 			this.fpReloadControls();
	// 		else {
	// 			console.log(error.message);
	// 			// this.fpReloadControls();
	// 		}
	// 	});
	// }

	handleFPValidityChange(data: any) {
		this.validationResults = this.validationResults.filter(function (
			x: any
		) {
			return x.emittedBy !== data.emittedBy;
		});

		if (data.isValid === false) this.validationResults.push(data);
	}
}
