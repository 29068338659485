import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { PaymentComponent } from './components/payment/payment.component';
import { CompareComponent } from './components/compare/compare.component';
import { EntryComponent } from './components/entry/entry.component';
import { ReservationDetailsComponent } from './components/reservation-details/reservation-details.component';
import { ReservationDashboardComponent } from './components/reservation-dashboard/reservation-dashboard.component';
import { PackageInfoComponent } from './components/package-info/package-info.component';
import {CommonModule} from '@angular/common';

const routes: Routes = [
  { path: 'payment', component: PaymentComponent },
  { path: 'reservations', component: EntryComponent },
  { path: 'packageInfo', component: PackageInfoComponent },
  { path: 'reservation-dashboard', component: ReservationDashboardComponent },
  { path: 'reservation-details', component: ReservationDetailsComponent },
  { path: 'compare', component: CompareComponent }
];

@NgModule({
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })]
})

export class AppRoutingModule { }
