import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  promoUpdatedInfo$: BehaviorSubject<any>;
  clearPromo$: Subject<any>;

  constructor() {
    this.promoUpdatedInfo$ = new BehaviorSubject('');
    this.clearPromo$ = new Subject();
   }
}
