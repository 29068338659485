import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../environments/environment';
import { LoggingService } from './logging.service';
import { StorageService } from './storage.service';


@Injectable({ providedIn: 'root' })
export class GTMService {
	static newReservation = 'new';
	static changeReservation = 'Change Reservation';
	siteVerision: string;
	localStorage: any;

	constructor(private storageServie: StorageService, private googleTagManagerService: GoogleTagManagerService, private logger: LoggingService) { }

	pushCoreViewDataToGTM(fromPage: string) {
		this.localStorage = this.storageServie.fetch();
		let nonCoreStoreStatus;

		const storeId = (fromPage === '' && this.localStorage && this.localStorage.booking)
			? this.localStorage.booking.store : undefined;
		let country;
		if (this.localStorage && this.localStorage.country) {
			country = this.localStorage.country;
		} else if (this.localStorage && this.localStorage.store && this.localStorage.store.country) {
			country = this.localStorage.store.country;
		}
		if (country === undefined) {
			if (this.localStorage && this.localStorage.reservationContext
				&& this.localStorage.reservationContext.store
				&& this.localStorage.reservationContext.store.CountryID) {
				country = this.localStorage.reservationContext.store.CountryID;
			}
		}
		nonCoreStoreStatus = undefined;
		if (fromPage === '' && country !== undefined) {
			nonCoreStoreStatus = country === 2 ? 'yes' : 'no';
		}
		const siteVerision = environment.siteVersion;
		this.pushEvent({
			'event': 'dataLayer-initialized',
			'noncoreStore': nonCoreStoreStatus,
			'storeID': storeId,
			'userID': 'Birthday Funnel',
			'loggedIn': 'no',
			'siteVersion': siteVerision,
			'cheeseClubID': undefined,
			'birthdayPartyScheduled': undefined
		});
	}

	pushUpgradePackageData(upgradeFlag: boolean) {
		const upgradeText = upgradeFlag ? 'upgrade' : 'No Thanks';

		this.localStorage = this.storageServie.fetch();
		const newVsChangeReservation = (this.localStorage.reservationContext !== undefined
			&& this.localStorage.reservationContext.Reservation.ID > 0)
			? GTMService.changeReservation : GTMService.newReservation;

		const upsellItemId = (this.localStorage && this.localStorage.upsell !== undefined)
			? this.localStorage.upsell : undefined;

		this.pushEvent({
			'event': 'checkout-option',
			'newVsChangeReservation': newVsChangeReservation,
			'upsellItemId': upsellItemId,
			'ecommerce': {
				'checkout_option': {
					'actionField': {
						'step': '3',
						'option': upgradeText
					}
				}
			}
		});
	}

	pushPaymentInfo(isCreditCardSelected: boolean, isNewReservation: boolean) {
		this.localStorage = this.storageServie.fetch();
		const upsellItemId = (this.localStorage && this.localStorage.upsell !== undefined)
			? this.localStorage.upsell : undefined;
		const newVsChangeReservation = isNewReservation === true ? GTMService.newReservation : GTMService.changeReservation;
		this.pushEvent({
			'event': 'checkout-option',
			'newVsChangeReservation': newVsChangeReservation,
			'upsellItemId': upsellItemId,
			'ecommerce': {
				'checkout_option': {
					'actionField': {
						'step': '8',
						'option': isCreditCardSelected === true ? 'Online' : 'In-Store'
					}
				}
			}
		});
	}
	// onclick of celebrating more than one birthday
	pushMoreThanOneBirthdayData(isNewReservation: boolean) {
		this.localStorage = this.storageServie.fetch();
		let reservation;
		if (this.localStorage && this.localStorage.reservationContext && this.localStorage.reservationContext.Reservation) {
			reservation = this.localStorage.reservationContext.Reservation;
		}
		const newVsChangeReservation = isNewReservation ? GTMService.newReservation : GTMService.changeReservation;
		const upsellItemId = (this.localStorage && this.localStorage.upsell !== undefined)
			? this.localStorage.upsell : undefined;

		const birthdayCount = reservation.BirthdayGuestDetails.length > 1 ? reservation.BirthdayGuestDetails.length : '1';

		this.pushEvent({
			'event': 'checkout-option',
			'newVsChangeReservation': newVsChangeReservation,
			'upsellItemId': upsellItemId,
			'ecommerce': {
				'checkout_option': {
					'actionField': {
						'step': '4',
						'option': birthdayCount
					}
				}
			}
		});
	}

	pushBirthdayOnPayment(newReservation: string, itemCount: any) {
		this.localStorage = this.storageServie.fetch();
		let reservation;
		let reservationContext;
		let partyId;
		let name;
		let dimension41;
		const itemQuantity = itemCount.length > 0 ? itemCount.length : undefined;
		const newVsChangeReservation = newReservation === 'true' ? GTMService.newReservation : GTMService.changeReservation;
		if (this.localStorage && this.localStorage.reservationContext) {
			reservationContext = this.localStorage.reservationContext;
		}
		if (this.localStorage && this.localStorage.reservationContext && this.localStorage.reservationContext.Reservation) {
			reservation = this.localStorage.reservationContext.Reservation;
		}
		const reservationId = (reservation && reservation.ID > 0) ? reservation.ID : undefined;

		partyId = (reservationContext && reservationContext.PartyType) ? reservationContext.PartyType.ID : undefined;
		name = (reservationContext && reservationContext.PartyType) ? reservationContext.PartyType.PartyTypeTitle : undefined;
		dimension41 = this.getDimensionFromHistoryData(reservation);
		this.pushEvent({
			'event': 'transaction',
			'paymentType': undefined, // optional
			'ecommerce': {
				'currencyCode': 'USD',
				'purchase': {
					'actionField': {
						'id': reservationId // required
					},
					'products': [
						{
							'name': name,
							'id': partyId,
							'dimension37': itemQuantity, // What should be the value of this
							'dimension39': 'birthday package',
							'dimension41': dimension41,
							'dimension42': newVsChangeReservation
						}
					]
				}
			}
		});
	}

	pushBirthdayPartySteps(stepNumber: number, requestedType: string) {
		const localStore = this.storageServie.fetch();
		let dimension42;
		let dimension41;
		const currencyCode = undefined;
		const category = undefined;
		let stepName;
		let reservationContext;
		let reservation;
		let partyTypes;
		if (localStore && localStore.reservationContext) {
			reservationContext = localStore.reservationContext;
		}
		if (localStore && localStore.reservationContext && localStore.reservationContext.Reservation) {
			reservation = localStore.reservationContext.Reservation;
		}
		let name = (reservationContext && reservationContext.PartyType && reservationContext.PartyType.PartyTypeTitle) ? reservationContext.PartyType.PartyTypeTitle : undefined;
		let id = (reservationContext && reservationContext.PartyType) ? reservationContext.PartyType.ID : undefined;
		dimension41 = this.getDimensionFromHistoryData(reservation);
		if (reservation === undefined) {
			dimension42 = GTMService.newReservation;
			if (stepNumber === 1) {
				if (requestedType !== undefined && dimension42 === GTMService.newReservation) {
					if (localStore && localStore.bookingInfo && localStore.bookingInfo.PartyTypes) {
						// For VIP package
						if (localStore && localStore.isVipParty) {
							partyTypes = localStore.bookingInfo.PartyTypes[0];
						} else {
							const partyTypeList = localStore.bookingInfo.PartyTypes;
							for (let i = 0; i < partyTypeList.length; i++) {
								const PromotionCategoryID = partyTypeList[i].PromotionCategoryID;
								const reqType = parseInt(requestedType.toString());
								if (PromotionCategoryID === reqType) {
									partyTypes = partyTypeList[i];
									break;
								}
							}
						}
						if (partyTypes) {
							name = partyTypes.PartyTypeTitle;
							id = partyTypes.ID;
							dimension41 = partyTypes.PartyTypeTitle;
						}

					}
				}
			}
		} else {
			if ((reservation && (reservation.ID === undefined || reservation.ID === 0))) {
				dimension42 = GTMService.newReservation;
			} else {
				dimension42 = GTMService.changeReservation;
			}
		}
		if (stepNumber !== 1) {
			stepName = stepNumber === 2 ? 'select a package' : 'contact information';
			// Undefined for dimension 41 should be set only for entry page for new reservation.
			if (dimension41 === undefined && stepNumber !== 1) {
				dimension41 = name;
			}
		} else if (stepNumber === 1) {
			stepName = 'book a birthday';
		}
		this.pushEvent({
			'event': 'checkout-step',
			'ecommerce': {
				'currencyCode': currencyCode,
				'checkout': {
					'actionField': {
						'step': stepNumber,
						'stepName': stepName
					},
					'products': [
						{
							'name': name,
							'id': id,
							'category': category,
							'dimension42': dimension42,
							'dimension41': dimension41
						}
					]
				}
			}
		});
	}

	private getDimensionFromHistoryData(reservation: any) {
		const packageList = [];
		let dimension;
		if (reservation && reservation.History && reservation.History.length > 0) {
			const historyList = reservation.History;
			for (const history of historyList) {
				if (history.HistoryTypeID === 23) {
					packageList.push(history);
				}
			}
			packageList.sort((a, b) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime());

			const previouslySelectedPartyTitle = packageList.length > 0 ? packageList[0].Note : undefined;
			if (previouslySelectedPartyTitle !== undefined && previouslySelectedPartyTitle !== name) {
				dimension = previouslySelectedPartyTitle.split(':')[1].split('$')[0].trim().replace('From', '');
			}
		} else {
			dimension = undefined;
		}
		return dimension;
	}

	// birthday star
	pushBirthdayStarData(isNewReservation: boolean) {
		this.localStorage = this.storageServie.fetch();
		let reservation;
		if (this.localStorage && this.localStorage.reservationContext && this.localStorage.reservationContext.Reservation) {
			reservation = this.localStorage.reservationContext.Reservation;
		}
		const newVsChangeReservation = isNewReservation ? GTMService.newReservation : GTMService.changeReservation;
		const upsellItemId = (this.localStorage && this.localStorage.upsell !== undefined)
			? this.localStorage.upsell : undefined;

		let birthdayStarGender = '';
		for(let guest of reservation.BirthdayGuestDetails){
			birthdayStarGender += guest.Gender + ', ';
		}
		const birthdayStarGenders = birthdayStarGender.replace(/,\s*$/, '');

		this.pushEvent({
			'event': 'checkout-option',
			'newVsChangeReservation': newVsChangeReservation,
			'upsellItemId': upsellItemId,
			'ecommerce': {
				'checkout_option': {
					'actionField': {
						'step': '4',
						'option': birthdayStarGenders
					}
				}
			}
		});
	}

	pushEvent(event: any): void {
		if (!environment.gtmConfig?.id) {
			return;
		}
		this.googleTagManagerService.pushTag(event);
	}


}
