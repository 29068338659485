import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'displayTimeSlotTime'
})
export class DisplayTimeSlotTimePipe implements PipeTransform {

	transform(value: string): unknown {

		if(!value){
			return '';
		}

		return  value.charAt(0) === '0' ? value.substring(1) : value;
	}

}
