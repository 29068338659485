
<app-loader *ngIf="isLoading"></app-loader>

<div class="container cec__form PPP-wrapper" id="paymentStep">
  <div class="row">
    <div class="col-sm-12 text-center">
      <h3 class="payment-title">Reservation Details</h3>
      <p class="res-info"
        *ngIf="reservation && reservation.reservationContext && reservation.reservationContext.Reservation && (reservation.reservationContext.Reservation.ConfirmationID && reservation.reservationContext.Reservation.PartyDate)">
        Confirmation #<a href="javascript:void(0);" (click)="goToReservationDetails()">{{isOldPPPConfirmationNumber ?
          'PPP'+ reservation.reservationContext.Reservation.ConfirmationID :
          reservation.reservationContext.Reservation.ConfirmationID}}</a>
      </p>
      <p *ngIf="isDepositRequired && depositAmountDue && !reservation?.reservationContext?.Reservation?.IsOnlinePaid"
        class="page-top-subheading">All reservations require a ${{depositAmountDue}} deposit. 
        Cancel your party 48 hours prior to your reservation date and time to receive a deposit refund.</p>
    </div>
  </div>
  <div class="row payment-row">
    <div class="col-12 col-lg-9 d-flex justify-content-end">
      <form [formGroup]="paymentForm" name="paymentDetails" id="paymentDetails"
        (ngSubmit)="checkForDuplicatesThenSave()" validate>
        <div
          *ngIf="reservation && (reservation.booking.type == reservationTypeBirthday || reservation.booking.type == reservationTypePartyRoom)">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="text-center text-sm-left birthday-star-info">Step 3: Tell us about the Birthday VIP </h3>
            </div>
          </div>
          <ng-container formArrayName="birthdayStars" *ngFor="let birthdayStar of birthdayStarControls; let i = index;">
            <div [formGroupName]="i" class="row star__row">
              <div class="col-sm-6">
                <button type="button" title="Remove Child" aria-labe="Remove Child"
                  *ngIf="birthdayStarControls?.length > 1" (click)="removeBirthdayStar(i)" class="remove-child">
                  <svg _ngcontent-c1="" xmlns:xlink="http://www.w3.org/1999/xlink" height="12px" version="1.1"
                    viewBox="0 0 12 12" width="12px" xmlns="http://www.w3.org/2000/svg">
                    <defs _ngcontent-c1=""></defs>
                    <g _ngcontent-c1="" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g _ngcontent-c1="" stroke="#702F8A" transform="translate(-1260.000000, -426.000000)">
                        <g _ngcontent-c1="" transform="translate(1211.000000, 410.000000)">
                          <g _ngcontent-c1="" transform="translate(50.000000, 17.000000)">
                            <path _ngcontent-c1=""
                              d="M8.39222519,8.10190258 C8.12879564,8.44468566 7.92750496,8.4301136 7.74614703,8.26883945 C7.03423037,7.6358988 6.31748152,7.00771482 5.62232649,6.35687999 C4.66781906,5.46307688 3.75015704,4.53016328 2.77994502,3.65342382 C2.20982276,3.13819216 1.87979967,2.44953038 1.46257075,1.82240345 C1.43455918,1.78027284 1.42542333,1.72130509 1.41877908,1.66686751 C1.41515494,1.63681736 1.43493669,1.59982095 1.46838447,1.47773279 C1.6446837,1.51744731 1.89769385,1.49434343 1.95839817,1.60155752 C2.2499903,2.11663817 2.82834237,2.31324768 3.19445584,2.73666784 C3.70047615,3.32181517 4.16391283,3.94796056 4.70270139,4.50041514 C5.53406362,5.35276717 6.41601278,6.1553628 7.27198894,6.98370491 C7.65214595,7.35163045 8.02127953,7.73095692 8.39222519,8.10190258 Z"
                              transform="translate(4.905289, 4.928269) rotate(-315.000000) translate(-4.905289, -4.928269) ">
                            </path>
                            <path _ngcontent-c1=""
                              d="M8.39222519,8.10190258 C8.12879564,8.44468566 7.92750496,8.4301136 7.74614703,8.26883945 C7.03423037,7.6358988 6.31748152,7.00771482 5.62232649,6.35687999 C4.66781906,5.46307688 3.75015704,4.53016328 2.77994502,3.65342382 C2.20982276,3.13819216 1.87979967,2.44953038 1.46257075,1.82240345 C1.43455918,1.78027284 1.42542333,1.72130509 1.41877908,1.66686751 C1.41515494,1.63681736 1.43493669,1.59982095 1.46838447,1.47773279 C1.6446837,1.51744731 1.89769385,1.49434343 1.95839817,1.60155752 C2.2499903,2.11663817 2.82834237,2.31324768 3.19445584,2.73666784 C3.70047615,3.32181517 4.16391283,3.94796056 4.70270139,4.50041514 C5.53406362,5.35276717 6.41601278,6.1553628 7.27198894,6.98370491 C7.65214595,7.35163045 8.02127953,7.73095692 8.39222519,8.10190258 Z"
                              transform="translate(4.905289, 4.928269) rotate(-225.000000) translate(-4.905289, -4.928269) ">
                            </path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <label for="guestName-{{i}}">Name<sup>*</sup></label>
                <input id="guestName-{{i}}" name="guestName-{{i}}" type="text" formControlName="guestName" required
                  maxlength="30">
              </div>
              <div class="col-sm-3">
                <label for="age-{{i}}">Age<sup>*</sup></label>
                <input id="age-{{i}}" name="age-{{i}}" type="number" formControlName="age" min="1" required
                  (keypress)="onKeyPress($event)">
              </div>
              <div class="col-sm-3">
                <label for="gender-{{i}}">Gender<sup>*</sup></label>
                <select aria-label="Gender" required id="gender-{{i}}" name="gender-{{i}}" formControlName="gender">
                  <option value="" selected disabled>Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                      <g id="Group-4" transform="translate(19.000000, 180.000000)">
                        <path
                          d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                          id="Fill-1"
                          transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) ">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                *ngIf="isFormSubmitted && (birthdayStarControls[i].controls.age.invalid || birthdayStarControls[i].controls.guestName.invalid || birthdayStarControls[i].controls.gender.invalid)"
                class="alert-danger">
                Please fill out all featured Guest Information.
              </div>
            </div>
          </ng-container>

          <div>
              <div *ngIf="!birthdayStarControls || birthdayStarControls?.length === 1">
                  <a class="addStar arrow d-block" href="javascript:void(0);" (click)="addBirthdayStar()" id="addStar">
                    <img class="plus_sign"
                  alt="plus_sign" src="{{assetRoot}}/assets/PPP-add-red.png" />Celebrating more than one Birthday VIP? Click here</a>
              </div>
              <div *ngIf="birthdayStarControls?.length > 1">
                <a class="addStar arrow d-block" href="javascript:void(0);" (click)="addBirthdayStar()" id="addStar">
                  <img class="plus_sign" alt="plus_sign"
                  src="{{assetRoot}}/assets/add-pink.png" />
                  Celebrating more than {{birthdayStarControls?.length}} Birthday VIP? Click here
                </a> 
              </div>
          </div>
          
        </div>
        <div *ngIf="reservation && reservation?.booking?.type == reservationTypeGroupEvent">
          <div class="row">
            <div class="col-sm-12">
              <h3 style="margin-bottom: 0; margin-top: 0;">Group Party Information</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label [ngClass]="{focus: paymentForm.get('organizationName').value != null}"
                for="organizationName">Organization
                Name<sup>*</sup></label>
              <input id="organizationName" name="organizationName" type="text" formControlName="organizationName"
                required>
            </div>
            <div class="col-md-3">
              <label [ngClass]="{focus: paymentForm.get('organizationPhone').value != null}"
                for="organizationPhone">Org.
                Phone<sup>*</sup></label>
              <input id="organizationPhone" name="organizationPhone" type="text" formControlName="organizationPhone"
                required mask='(000) 000-0000' minlength="10">
              <div *ngIf="isFormSubmitted && (
                 !paymentForm.get('organizationName').invalid
                 && paymentForm.get('organizationPhone').invalid
                 && !paymentForm.get('organizationType').invalid)" class="alert-danger">
                Please enter a valid phone number.
              </div>
            </div>
            <div class="col-md-3">
              <select aria-label="OrgGroup" required id="OrgGroup" name="OrgGroup" class="dropdown2"
                formControlName="organizationType">
                <option [ngValue]="undefined" selected disabled>Org. Type</option>
                <option *ngFor="let group of orgGroup" [ngValue]="group">{{group}}</option>
              </select>
              <label for="OrgGroup" style="top: 20px;">
                <svg width="16px" height="9px" style="right:28px" viewBox="0 0 16 9" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                      <g id="Group-4" transform="translate(19.000000, 180.000000)">
                        <path
                          d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                          id="Fill-1"
                          transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) ">
                        </path>
                      </g>
                    </g>
                  </g>
                </svg>
              </label>
            </div>
            <div *ngIf="isFormSubmitted && (
                  paymentForm.get('organizationName').invalid
                 || paymentForm.get('organizationType').invalid)" class="alert-danger">
              Please fill out all organization information.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="contact-res text-center text-sm-left">Contact Information</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label [ngClass]="{focus: adultName != null}" for="adultName">Adult's Name<sup>*</sup></label>
            <input id="adultName" name="adultName" type="text" formControlName="adultName" required maxlength="65">
            <div *ngIf="isFormSubmitted && paymentForm.get('adultName').invalid" class="alert-danger error_msg"
              data-test-id="adultNameErrMessage">
              Please enter the adult's name.
            </div>
          </div>
          <div class="col-sm-3">
            <label [ngClass]="{focus: phone != null}" for="phone">Phone<sup>*</sup></label>
            <input id="phone" name="phone" type="text" formControlName="phone" required mask='(000) 000-0000'
              minlength="10">
            <div *ngIf="isFormSubmitted && paymentForm.get('phone').invalid" class="alert-danger error_msg"
              data-test-id="phoneNumberErrMessage">
              Please enter a valid phone number.
            </div>
          </div>
          <div class="col-sm-3">
            <label [ngClass]="{focus: email != null}" for="email">Email<sup>*</sup></label>
            <input id="email" name="email" type="text" formControlName="email" required email
              pattern="[a-zA-Z0-9.-_+]{1,}@[a-zA-Z0-9.\-]{1,}[.]{1}[a-zA-Z]{2,}" maxlength="100">
            <div *ngIf="isFormSubmitted && paymentForm.get('email').invalid" class="alert-danger error_msg"
              data-test-id="emailErrMessage">
              Please enter a valid email address.
            </div>
          </div>
          <div *ngIf="isSMSEnabled == true" class="col-sm-12 chkbox_recv_msg" style="margin: 0px 0px -25px 10px;">
            <input name="smsOptIn" id="smsOptIn" type="checkbox" [value]="false" formControlName="smsOptIn"
              style="margin: 30px 0px -15px 10px;" (change)="displaySMSPhone($event.target.checked, false)">
            &nbsp;&nbsp;&nbsp;&nbsp;<label class="checkbox-label" for="smsOptIn">&nbsp;Click here to receive text
              message about your party</label>
          </div>
          <div class="col-md-6 recv-msg-phone" id="divSmsPhone"
            *ngIf="isSMSEnabled == true && paymentForm.get('smsOptIn').value == true">
            <label class="label-smsPhone" [ngClass]="{focus: smsPhone != null}" for="smsPhone">Enter Cell Phone
              #<sup>*</sup></label>
            <input id="smsPhone" name="smsPhone" type="text" formControlName="smsPhone" required mask='(000) 000-0000'
              minlength="10">
            <div *ngIf="isFormSubmitted &&  paymentForm.get('smsPhone').invalid" class="alert-danger error_msg"
              data-test-id="smsPhoneErrMessage">
              Please enter a valid phone number.
            </div>
          </div>
          <!------------------ Adding the promocode-section ---------------->
          <div *ngIf="showPromo" class="promocode-section">
            <div class="col-sm-12">
              <h3 class="contact-res text-center text-sm-left">Enter Promo Code</h3>
            </div>

            <div class="col-md-9 section_promocode_header">
              <label class="lable_promo" for="promocode_payment">Enter the Promo Code</label>
              <input class="input_promocode" id="promocode_payment" name="promocode_payment" type="text"
                formControlName="promoCode">

            </div>
            <a class="btn btn-ghost" data-test-id="promoCodeApplyBtn" (click)="evalutePromoCode()">Apply</a>
            <div class="col" style="margin-bottom: 0; margin-top: 0;">
              <div class="note_promo">
                <ng-container *ngIf="showPromoMessage">
                  <span class="note_promocode"><strong>{{promoMessage}}</strong></span><br>
                </ng-container>
                <span class="note_promocode">{{promoDescription}}</span>
              </div>
            </div>
          </div>
          <!----------------- End of promocode-section ------------------->
          <div class="col-sm-12">
            <label style="position: absolute; transform: translateY(-50px);"
              [ngClass]="{focus: specialInstructions != null}" class="textarea-label" for="SpecialInstructions">Let us know if you have any special instructions.
            </label>
            <textarea class="special-instructions" name="specialInstructions" formControlName="specialInstructions"
              id="SpecialInstructions"
              placeholder="(Please note if there is anything else you would like us to know about this reservation)"
              maxlength="1000" style="border: 4px solid black; background-image: none; border-radius: 20px;"></textarea>
          </div>
        </div>
        <div *ngIf="isReservationAlreadyPaid !== true && isDepositRequired === true && depositAmountDue">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="payment-res text-center text-sm-left">Payment Information</h3>
            </div>
          </div>
          <div class="row clearfix" style="margin-top: 0;">
            <div class="col-sm-12">
              <div class="col-12" style="float: left; padding-left: 0;">
                <p class="payment-text text-center text-sm-left font-weight-bold">
                  How would you like to provide payment for your ${{depositAmountDue}} deposit? 
                </p>
              </div>
              <div class="col-12 radio-select" style="float: left; white-space: nowrap;">
                <div [hidden]="!currentStoreAcceptsOnlinePayment">
                  <input name="r1" id="r1" type="radio" [checked]="paymentCard === true"
                    (click)="onPaymentTypeSelect(true)"><label class="radio-label" for="r1">With my debit/credit
                    card</label>
                </div>
                <div [hidden]="currentStoreAcceptsonlyOnlinePayment">
                  <input name="r2" id="r2" type="radio" [checked]="paymentCard === false"
                    (click)="onPaymentTypeSelect(false)"><label
                    [ngClass]="{'left_align':!currentStoreAcceptsOnlinePayment, 'right' : currentStoreAcceptsOnlinePayment }"
                    class="radio-label" for="r2">In restaurant within 48 hours</label>
                </div>
              </div>
            </div>
          </div>
          <div [hidden]="storePaymentHandler != gatewayPaymentTypes.MONERISPAYMENTTYPE || paymentCard !== true">
            <div id="monerisIframeContainer">
            </div>
          </div>

          <div id="paymentInformation" [ngClass]="{'active': paymentCard === true}" *ngIf="paymentCard === true && selectedPaymentType !== 'FREEDOMPAY'">
            <div name="payeezyContainer" *ngIf="storePaymentHandler === gatewayPaymentTypes.PAYEEZYPAYMENTTYPE">
              <div id="paymentError" *ngIf="isPaymentFormValid" class="alert-danger">
                Please check your information and try again.
              </div>
              <div *ngIf="isFormSubmitted && (
                paymentForm.get('billAddress').invalid
                || paymentForm.get('billCity').invalid
                || paymentForm.get('billState').invalid
                || paymentForm.get('billZip').invalid
                || paymentForm.get('billCVV').invalid || !isPaymentFormEmpty
                )" class="alert-danger">
                Please fill out all payment information.
              </div>
              <div class="row">
                <div class="col-sm-6 payment-outer billName-outer">
                  <label for="billName">Name<sup>*</sup></label>
                  <div class="cec__module cec__form input form-controls payment-fields disabled" id="billName"
                    data-cc-name></div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <label for="billAddress">Address<sup>*</sup></label>
                  <input id="billAddress" name="billAddress" type="text" formControlName="billAddress" required>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label for="billCity">City<sup>*</sup></label>
                  <input id="billCity" name="billCity" type="text" formControlName="billCity" required>
                </div>
                <div class="col-sm-4">
                  <label for="billState">State<sup>*</sup></label>
                  <input id="billState" name="billState" type="text" formControlName="billState" required>
                </div>
                <div class="col-sm-4">
                  <label for="billZip">Zip Code<sup>*</sup></label>
                  <input id="billZip" name="billZip" type="text" formControlName="billZip" required>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <label for="billCard">Card Number<sup>*</sup></label>
                  <div class="form-controls payment-fields disabled empty" id="billCard" data-cc-card></div>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="billExp">Exp.<sup>*</sup></label>
                  <div class="form-controls payment-fields disabled empty" id="billExp" data-cc-exp></div>
                </div>
                <div class="col-12 col-sm-4">
                  <label for="billCVV">CVV<sup>*</sup></label>
                  <div class="cvv-block" [ngClass]="{hasCardIcon: showCVV}">
                    <input id="billCVV" name="billCVV" type="text" formControlName="billCVV" autocomplete="off" required>
                    
                    <svg *ngIf="showCVV" class="showCVVImg" viewBox="0 0 90 87.5" version="1.1" x="0px" y="0px">
                      <defs>
                        <rect id="a" x="14" y="22" width="63" height="9" />
                      </defs>
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="90" height="70" />
                        <rect stroke="#363f54" x="14.5" y="14.5" width="62" height="41" rx="4" />
                        <g>
                          <use fill="#363f54" fill-rule="evenodd" xlink:href="#a" />
                          <rect stroke="#363f54" stroke-width="1" x="14.5" y="22.5" width="62" height="8" />
                        </g>
                        <rect stroke="#363f54" x="55.5" y="33.5" width="9" height="5" rx="1" />
                      </g>
                    </svg>
                  </div>
                  <a class="showCVV" href="javascript:void(0);" (click)="showCVV = !showCVV">What's this?</a>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedPaymentType === 'FREEDOMPAY'">
            <div class="payment-wrapper row">
              <div class="col py-2" [ngClass]="{'d-none': !showIframeWrapper}">
                <div class="mb-3">
                    <label for="CardHolderName" id="bill-label">Card Holder Name</label>
                    <input
                      type="text"
                      id="bill-input"
                      class="form-control"
                      formControlName="nameOnCard"
                      name="nameOnCard"
                      placeholder="Enter Card Holder Name"
                      autocapitalize="off"
                      autocorrect="off"
                      spellcheck="false"
                      pattern="^[^\s].{0,}[^\s]$"
                      required
                    />
                    <div class="validation-message feedback" id="cardHolderName-incomplete-error-msg" 
								      *ngIf="paymentForm?.controls?.nameOnCard?.invalid && (paymentForm?.controls?.nameOnCard?.dirty || paymentForm?.controls?.nameOnCard?.touched) && paymentForm?.controls?.nameOnCard?.errors?.required">
								    	Card Holder Name is incomplete
								    </div>
                    <div class="validation-message feedback" id="cardHolderName-invalid-error-msg" 
                    *ngIf="paymentForm?.controls?.nameOnCard?.invalid && (paymentForm?.controls?.nameOnCard?.dirty || paymentForm?.controls?.nameOnCard?.touched) && paymentForm?.controls?.nameOnCard?.errors?.pattern">
                    Card Holder Name is invalid
                    </div>
                </div>
                <div class="mb-3">
                  <label for="billAddress" id="bill-label">Address</label>
                  <input
                  type="text"
                  id="bill-input"
                  class="form-control"
                  formControlName="cardAddress"
                  name="addressOnCard"
                  placeholder="Enter Address"
                  autocapitalize="off"
                  autocorrect="off"
                  spellcheck="false"
                  pattern="^[^\s].{0,}[^\s]$"
                  required
                  />
                  <div class="validation-message feedback" id="address-incomplete-error-msg" 
                    *ngIf="paymentForm?.controls?.cardAddress?.invalid && (paymentForm?.controls?.cardAddress?.dirty || paymentForm?.controls?.cardAddress?.touched) && paymentForm?.controls?.cardAddress?.errors?.required">
                    Address is incomplete
                  </div>
                  <div class="validation-message feedback" id="address-invalid-error-msg" 
                    *ngIf="paymentForm?.controls?.cardAddress?.invalid && (paymentForm?.controls?.cardAddress?.dirty || paymentForm?.controls?.cardAddress?.touched) && paymentForm?.controls?.cardAddress?.errors?.pattern">
                    Address is invalid
                  </div>
                </div>
                <div class="mb-3">
                  <label for="billCity" id="bill-label">City</label>
                  <input
                    type="text"
                    id="bill-input"
                    class="form-control"
                    formControlName="city"
                    name="city"
                    placeholder="Enter City"
                    autocapitalize="off"
                    autocorrect="off"
                    spellcheck="false"
                    pattern="^[^\s].{0,}[^\s]$"
                    required
                  />
                  <div class="validation-message feedback" id="city-incomplete-error-msg" 
                    *ngIf="paymentForm?.controls?.city?.invalid && (paymentForm?.controls?.city?.dirty || paymentForm?.controls?.city?.touched) && paymentForm?.controls?.city?.errors?.required">
                    City is incomplete
                  </div>
                  <div class="validation-message feedback" id="city-invalid-error-msg" 
                    *ngIf="paymentForm?.controls?.city?.invalid && (paymentForm?.controls?.city?.dirty || paymentForm?.controls?.city?.touched) && paymentForm?.controls?.city?.errors?.pattern">
                    City is invalid
                  </div>
                </div>
                <div class="mb-3">
                  <label for="billState" id="bill-label">State</label>
                  <input
                    type="text"
                    id="bill-input"
                    class="form-control"
                    formControlName="state"
                    name="state"
                    placeholder="Enter State"
                    autocapitalize="off"
                    autocorrect="off"
                    spellcheck="false"
                    pattern="^[^\s].{0,}[^\s]$"
                    required
                  />
                  <div class="validation-message feedback" id="state-incomplete-error-msg" 
                    *ngIf="paymentForm?.controls?.state?.invalid && (paymentForm?.controls?.state?.dirty || paymentForm?.controls?.state?.touched) && paymentForm?.controls?.state?.errors?.required">
                    State is incomplete
                  </div>
                  <div class="validation-message feedback" id="state-invalid-error-msg" 
                    *ngIf="paymentForm?.controls?.state?.invalid && (paymentForm?.controls?.state?.dirty || paymentForm?.controls?.state?.touched) && paymentForm?.controls?.state?.errors?.pattern">
                    State is invalid
                  </div>
                </div>
              </div>
              <app-freedompay 
                [invoiceAmount]="depositAmountDue" 
                [invoiceNumber]="reservation?.reservationContext?.Reservation?.InvoiceNumber"
                (validPaymentHandler)="validPaymentHandler($event)"
                (iframeLoading)="iframeLoadingHandler($event)"
                (toggleIframeLoader)="toggleIframeLoaderHandler($event)" 
                (errorHandler)="freedomPayErrorHandler($event)"
                class="col" 
                #freedomPay>
              </app-freedompay>
            </div>
            <input type="hidden" formControlName="paymentControl"/>
          </ng-container>
          <div class="clearfix"></div>
          <div id="onlinePayment" *ngIf="paymentCard === true">
            <div class="row">
              <div class="col-sm-12 chkbox tc_description">
                <input name="cb1" id="cb1" type="checkbox" [value]="false" formControlName="inPaymentDisclaimer"
                  required>
                <label style="transform: none;" for="cb1"></label>
                <p class="tc_des_store"> I acknowledge that I have read and accept the <a target="_blank"
                    href="https://www.peterpiperpizza.com/privacy-and-terms">Privacy & Terms</a>,
                  and understand that Peter Piper Pizza requires a refundable ${{depositAmountDue}} deposit to secure my
                  reservation. The deposit will
                  be applied towards your party total on the date of the party. The billing descriptor you will see on
                  your credit/bank statement will
                  be portrayed as "PETER PIPER P. DEPOSIT IRVING TX".
                </p>
              </div>
              <div *ngIf="isFormSubmitted && paymentForm.get('inPaymentDisclaimer').invalid" class="alert-danger">
                You must check the box to continue.
              </div>
            </div>
          </div>
          <div id="inStoreText" *ngIf="paymentCard !== true">
            <div class="row">
              <div class="col-sm-12 chkFix tc_description">
                <input name="cb" id="cb" type="checkbox" [value]="false" formControlName="inStoreDisclaimer" required>
                <label style="transform: none;" for="cb" data-test-id="storeDisclaimerCheckLabel"></label>
                <!-- <p>{{sfContent.inStoreDepositDisclaimer}}</p> -->
                <p class="tc_des_store" data-test-id="storeAcknowlegeText">
                  You have elected to pay your ${{depositAmountDue}} deposit off-line at your chosen party location. We
                  require that all reservation deposits be received before or on {{refreshdueDate()}}. All reservations
                  failing to pay the deposit within this timeframe are subject to cancellation. Please contact your
                  chosen party location with any questions regarding deposit payment and party information.
                </p>
              </div>
              <div *ngIf="isFormSubmitted && paymentForm.get('inStoreDisclaimer').invalid" class="alert-danger">
                You must check the box to continue.
              </div>
            </div>
            <div class="row align-yourstore">
              <div class="col-sm-12">
                <p>
                  <span><strong>Your Restaurant:</strong></span><br>
                  {{reservation.store.address}} <br /> {{reservation.store.city}},
                  {{reservation.store.state}} {{reservation.store.zip}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="after"></div>
        <div class="row" style="margin-top: 0;">
          <div class="col-sm-12 book-now" style="margin-top: 10px;">
            <button type="button" id="paymentButton" style="display: none;" for="paymentDetails" hidden></button>
            <button class="btn--primary btn--purple book-party bookParty" data-test-id="submitBtn" for="paymentDetails"
              type="submit" [disabled]="isLoading">
              <svg style="top: 50%;" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 511.47443 182.64998">
                <defs>
                  <style>
                    .cls-1 {
                      fill: none;
                      stroke-linecap: round;
                      stroke-miterlimit: 10;
                      stroke-width: 7px;
                    }
                  </style>
                </defs>
                <path class="cls-1 path"
                  d="M16.35228,67.598S185.18984-41.06585,451.85651,69.60082c0,0,111.97656,41.02035-26.34505,74.17684s-364.26172,50.42619-398.625-19.542S233.52317,27.93415,314.18984,37.26749" />
              </svg>
              <div class="complete-reservation">Complete Reservation</div>
            </button>
          </div>
          <span
            *ngIf="isDepositRequired === true && isReservationAlreadyPaid !== true && currentStoreAcceptsOnlinePayment === true && depositAmountDue"
            style="margin-top: 20px; padding-left: 15px;">
            Your credit card will be charged immediately if paying online.
          </span>
        </div>
      </form>
    </div>
    <div class="col-sm-3">
      <app-side-menu [page]="2" (updatedInfo)="updateInfo($event)" (packageUpdated)="updateInfo($event)"></app-side-menu>
    </div>
  </div>
</div>
<div class="cec__module PPP-wrapper">
  <div [ngClass]="{'show': showAlert || reservationService.showAlert, '': !showAlert && !reservationService.showAlert}"
    id="applicationMessage" style="width:70%;background-color: #f5c6cb;margin-left:15%" class="application-message">
    <h4 *ngIf="!reservationService.showAlert" class="m-0"
      style="color:#721c24;">{{this.applicationMessage}}</h4>
    <h4 *ngIf="reservationService.showAlert"><a style="text-decoration: underline; color:#721c24;"
        href="javascript:void(0);" (click)="selectTime()">{{reservationService.applicationMessage}}</a></h4>
    <a href="javascript:void(0)" class="close-btn" (click)="showAlert = false">x</a>
  </div>
</div>
<div class="cec__module PPP-wrapper">
  <div [ngClass]="{'show': showDuplicateModal, '': !showDuplicateModal}" id="duplicateModal" data-test-id="dupModal"
    class="duplicate-modal">
    <div class="duplicate__inner cec__form">
      <a class="duplicate-close" aria-label="Close Modal" href="javascript:void(0);"
        (click)="showDuplicateModal = false"></a>
      <h3>Potential Duplicates</h3>
      <p>We have found potential duplicate bookings. Please review the parties below before proceeding</p>
      <ul *ngFor="let duplicate of possibleDupes">
        <li>Reservation: <a [routerLink]="['/reservation-dashboard']"
            [queryParams]="{id: duplicate.ConfirmationID, date: duplicate.PartyDate | date: 'MM-dd-yyyy'}">
            <strong>#{{duplicate.ConfirmationID}}</strong></a> - Party Date:
          <strong>{{duplicate.PartyDate | date: 'MM-dd-yyyy'}}</strong>
        </li>
      </ul>
      <button class="btn--primary btn--purple duplicateSubmit" type="submit" (click)="duplicateOverride()">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.47443 182.64998">
          <defs>
            <style>
              .cls-1 {
                fill: none;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-width: 7px;
              }
            </style>
          </defs>
          <path class="cls-1 path"
            d="M16.35228,67.598S185.18984-41.06585,451.85651,69.60082c0,0,111.97656,41.02035-26.34505,74.17684s-364.26172,50.42619-398.625-19.542S233.52317,27.93415,314.18984,37.26749" />
        </svg>
        New Reservation
      </button>
    </div>
  </div>
</div>
