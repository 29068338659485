import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PackageInfoService {

	sfEveryPartyIncludesBlock: any;
	public editPartyFlagSubject: BehaviorSubject<string>;

	constructor() {
		this.sfEveryPartyIncludesBlock = '';
		this.editPartyFlagSubject = new BehaviorSubject('');
	}

	// Adding the Behavioral Object
	public getEditPartySubject() {
		return this.editPartyFlagSubject;
	}

	public setEditPartySubject(newVal) {
		this.editPartyFlagSubject.next(newVal);
	}

}
