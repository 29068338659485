import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { Constants } from 'src/app/constants/constants';
import { AvailableTimeSlotI, ReservationTimeSlot, RewardI, SimpleDateI, StoreDetailsI } from 'src/app/models';
import { PackageInfoService } from 'src/app/services/package.service';
import { ReservationApiService } from 'src/app/services/reservation-api.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utilities } from 'src/app/services/utilities';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { WebString } from '../../constants/string';
import { Alert } from '../../models/alerts';
import { SaveReservationDto } from '../../models/save-reservation-dto';
import { BookingApiService } from '../../services/booking-api.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MessageComponent } from '../message/message.component';
import { Subscription } from 'rxjs';
import { PromoService } from 'src/app/services/promo.service';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

	@Input() page: number;

	@Output() updatedInfo = new EventEmitter<Object>();
	@Output() cancelReservation = new EventEmitter<boolean>();
	@Output() packageUpdated = new EventEmitter<boolean>();

	@ViewChild(MessageComponent) message: MessageComponent;

	active = 'not-active';
	additionalBirthdayStar = 0;
	additionItemPrice = 0;
	alertMessage = new Alert();
	allowEdit = true;
	assets = environment.assetRoot;
	availableSlot: AvailableTimeSlotI[] = [];
	changePackageFlag = 0;
	collapseContent = false;
	colorClass: string;
	dateofParty: string;
	dueDate: string;
	eventDate = '';
	flag = 0;
	formattedDate: any = {};
	hidePayOnlineLink: boolean;
	isAlreadyPaid = false;
	isDate = false;
	isDateSelected = false;
	isDeposit = 1;
	isDepositRequired = false;
	isExpandAddItems: boolean;
	isType = true;
	loader = false;
	local: any;
	localStorage: any = {};
	localStorageExists = false;
	mAdult: number;
	mKid: number;
	mydate: any;
	myDatePickerOptions: IMyDpOptions;
	mytimeslot: any;
	partyDisplayTitles: string;
	partyName: string;
	partyRoom: boolean;
	partytime: any = {};
	partyDuration: string = null;
	promoCode = '';
	promoDescription = WebString.PROMODESCRIPTION;
	promoMessage: string;
	promoRewardDescription = '';
	reservationType: number;
	reservationUpdate = 0;
	reward: any = [];
	sendInviteLink: string;
	showChangePackage = false;
	showEditInfo = false;
	showPromo = true;
	showPromoMessage: boolean;
	skipPromoValidation = false;
	tempAdult: number;
	tempAvailableSlots: any[];
	tempDate: any;
	tempKid: number;
	tempReservationTimeSlots: any[];
	timeofParty: string;
	timeSlot: any = {};
	timeToggle = false;
	totalPrice = 0;
	typeName: string;
	typeValue: string;
	updateLink: string;
	upsellArr: any[];
	hasDateChanged = false;
	subscribepromoUpdatedInfo$: Subscription;
	subscribeClearPromo$: Subscription;
	locationUri = environment.locationUri;

	readonly partyTypePricing = Constants.PriceAsPer.PartyType;
	readonly reservationPartyRoomType = Constants.ReservationTypes.PARTYROOM;

	constructor(private storageService: StorageService, private reservationApiService: ReservationApiService, @Inject(DOCUMENT) document,
		private bookingApiService: BookingApiService,
		private promoservice: PromoService,
		private matDialog: MatDialog, private packageInfo: PackageInfoService, private utilsService: UtilsService, private renderer: Renderer2, private route: ActivatedRoute, private router: Router) {

		this.subscribeClearPromo$ = this.promoservice.clearPromo$.subscribe(() => {
			this.promoMessage = WebString.PROMOERROR;
			this.promoDescription = WebString.PROMODESCRIPTION;
		});
	}

	ngOnInit() {
		this.subscribepromoUpdatedInfo$ = this.promoservice.promoUpdatedInfo$.subscribe((data: any) => {
			this.promoCode = data;
			this.applyPromoReward(this.promoCode, true);	
		});

		this.local = this.storageService.fetch();
		this.partyDuration = this.getPartyDuration(this.local?.bookingInfo?.PartyDuration);

		if (this.local && Object.keys(this.local).length > 0) {
			this.reservationType = parseInt(this.local?.booking?.type || 1, 10);
			this.mKid = parseInt(this.local.kids, 10);
			this.tempKid = parseInt(this.local.kids, 10);
			this.mAdult = parseInt(this.local.adults, 10);
			this.tempAdult = parseInt(this.local.adults, 10);
			if (this.local.date) {
				this.tempDate = {
					day: (parseInt(this.local.date.day, 10)),
					month: (parseInt(this.local.date.month, 10)),
					year: (parseInt(this.local.date.year, 10))
				};
				this.mydate = {
					date: this.tempDate,
				};
			}
			this.availableSlot = this.local.mytimeslot;

			this.mytimeslot = this.availableSlot?.findIndex(t => t.start === this.local.time?.start);

			if (this.local.booking) {
				const earliest = moment().add(this.local.booking.minDate - 1, 'days');
				const endDate = moment().add(this.local.booking.maxDate, 'days');
				this.myDatePickerOptions = {
					openSelectorOnInputClick: true,
					editableDateField: false,
					showTodayBtn: false,
					dateFormat: 'mm-dd-yyyy',
					firstDayOfWeek: 'su',
					sunHighlight: true,
					satHighlight: true,
					disableUntil: { year: earliest.year(), month: earliest.month() + 1, day: earliest.date() },
					disableSince: { year: endDate.year(), month: endDate.month() + 1, day: endDate.date() + 1 }
				};
			}
		}

		this.allowEdit = this.canEditReservation(this.local?.date, this.local?.reservationContext?.Store);
		this.handleControlFocusInOut();
		this.localStorageExists = this.checkDataInLocalStorage();
		if (this.local && Object.keys(this.local).length > 0) {
			// this code bracket referancing can't make to Object referancing because of type conflicts.
			this.partyRoom = this.local.booking && this.local.booking.type == Constants.ReservationTypes.PARTYROOM;
			if (this.page === 2) {
				this.loadPaymentInfo(this.local);
			} else if (this.page === 3) {
				this.loadSummaryInfo(this.local);
			} else {
				this.getPartyInfo(this.local);
				this.updateRewardsInfo(this.local);
			}
			this.showPromoUIAndUpdateMessage();
		}
	}

	checkDataInLocalStorage() {
		if (this.page === 3) {
			const res = this.storageService.fetch();
			if (res && res !== '{}' && res.length > 0) {
				return true;
			}

			return false;
		}
		return true;
	}

	private showPromoUIAndUpdateMessage() {
		if (this.page === 1) {
			this.applyPromoRewardToReservation(undefined, false);
		}
		if (this.page === 2 || this.page === 3) {
			this.local = this.storageService.fetch();
			this.isDeposit = this.local?.booking?.deposit || 0;
			const storeRewards = this.getStorerewards(this.local);
			if (storeRewards) {
				const promoRewards = storeRewards.filter(({ RewardTypeId }) => RewardTypeId === 3);
				this.showPromo = promoRewards.length > 0;
			}
			const reservationContext = this.local?.reservationContext?.Reservation;
			const appliedReward = reservationContext?.ReservationRewards.find(x => x.RewardTypeID === 3);
			if (appliedReward) {
				this.showPromoMessage = true;
				this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', this.getPromoRewardName());
			}
		}
	}

	refreshdueDate() {
		if (this.localStorage && Object.keys(this.localStorage).length > 0) {
			const partyDate = new Date(`${this.localStorage?.date?.month}/${this.localStorage?.date?.day}/${this.localStorage?.date?.year}`);
			return this.utilsService.getDepositDueDate(partyDate, this.localStorage?.reservationContext?.Store?.parameters?.dueDateInDays);
		}
	}

	loadSummaryInfo(data: any) {
		this.getPartyInfo(data);
		if (data.booking.type === Constants.ReservationTypes.GROUPEVENT) {
			this.isType = false;
			this.typeName = 'Organization Name';
			this.updateLink = 'Update Contact or Group party Information';
			this.typeValue = data.reservationContext.Reservation.OrganizationGuestDetails.OrganizationName;
		} else if (data.booking.type === Constants.ReservationTypes.BIRTHDAYPARTY || data.booking.type === Constants.ReservationTypes.PARTYROOM) {
			this.isType = true;
			this.updateLink = 'Update Contact or Birthday Child Information';
			this.typeValue = '';
			this.typeName = (data.reservationContext.Reservation.BirthdayGuestDetails?.length > 1) ? 'Birthday Children' : 'Birthday Child';
		} else if (data.booking.type === Constants.ReservationTypes.VISITEVENT) {
			this.isType = false;
			this.typeName = '';
			this.typeValue = '';
			this.updateLink = 'Update Contact Name Information';
		}
		this.loadPaymentInfo(data);
	}

	loadPaymentInfo(data: any) {
		this.getPartyInfo(data);
		const paymentOptionID = data.reservationContext.Store.PaymentOptionID;
		this.hidePayOnlineLink = paymentOptionID === Constants.StoreDepositTypes.INSTOREPAYMENT
			|| paymentOptionID === Constants.StoreDepositTypes.NODEPOSITPAYMENT;
		if (paymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT) {
			this.isDepositRequired = true;
		} else {
			this.isAlreadyPaid = true;
		}
		if (data.reservationContext.Reservation.DepositType === Constants.ReservationPaymentTypeConst.CREDIT
			&& data.reservationContext.Reservation.IsOnlinePaid) {
			this.isAlreadyPaid = true;
		} else if (data.reservationContext.Reservation.DepositType === Constants.ReservationPaymentTypeConst.INSTORE
			&& data.reservationContext.Reservation.DepositDetails.length > 0) {
			this.isAlreadyPaid = true;
		} else {
			this.dueDate = moment(data.reservationContext.Reservation.DueDate).format('MM/DD');
		}
		this.updateRewardsInfo(data);
	}

	updateRewardsInfo(data: any) {
		if (data.reservationContext) {
			const reservationRewards = data.reservationContext.Reservation.ReservationRewards;
			if (reservationRewards) {
				if (this.page === 1 && data.bookingInfo && data.bookingInfo.Rewards) {
					this.reward = data.bookingInfo.Rewards;
					const reward = data.reservationContext.Reservation.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
					if (reward) {
						this.reward.push(reward);
					}
				} else {
					this.reward = reservationRewards.filter(r => [0, 2, 3].includes(r.RewardTypeID));
				}

				/* --------------- Adding reward description ----------------- */
				let promoReward: RewardI = null;
				for (const promorewardDes of this.reward) {
					if (promorewardDes.RewardTypeID === 3) {
						promoReward = promorewardDes;
					}
				}
				const localData = this.storageService.fetch();
				const storeRewards = this.getStorerewards(localData);
				if (promoReward) {
					for (const storerwd of storeRewards) {
						if (promoReward.RewardID === storerwd.RewardID) {
							this.promoRewardDescription = storerwd.RewardDescription;
						}
					}
				}
			}
		} else {
			this.reward = !data.booking ? null : data.bookingInfo.Rewards;
		}
	}
	private reInitializeObjects() {
		this.formattedDate = this.localStorage.date;
		this.eventDate = Utilities.formatSimpleDate(this.localStorage.date);
		this.active = 'not-active';
		// In change package scenario we need this
		if (this.timeSlot && this.timeSlot.AvailabilityDates && this.timeSlot.AvailabilityDates.length > 0) {
			this.timeSlot.AvailabilityDates[0].ReservationTimeSlots = this.localStorage.reservationTimeSlots;
		}
		this.partyRoom = parseInt(this.localStorage.booking.type, 10) === this.reservationPartyRoomType;
	}

	doInfoCollapseAction() {
		this.collapseContent = !this.collapseContent;
		if (this.collapseContent) {
			this.localStorage = this.storageService.fetch();
			this.tempAvailableSlots = this.localStorage.mytimeslot;
			this.reservationType = parseInt(this.localStorage.booking.type, 10);
			this.tempReservationTimeSlots = this.localStorage?.reservationTimeSlots;
			this.mKid = parseInt(this.localStorage.kids, 10);
			this.mAdult = parseInt(this.localStorage.adults, 10);
			this.tempDate = {
				day: (parseInt(this.localStorage.date.day, 10)),
				month: (parseInt(this.localStorage.date.month, 10)),
				year: (parseInt(this.localStorage.date.year, 10))
			};
			this.mydate = {
				date: this.tempDate,
			};
			this.reInitializeObjects();
			this.reservationApiService.getTimeSlot(this.eventDate, this.localStorage.kids, this.localStorage.adults, this.localStorage?.booking?.store,
				this.localStorage?.booking?.type, 0, 0).subscribe(res => {
					this.tempReservationTimeSlots = res.AvailabilityDates[0].ReservationTimeSlots;
				});
			this.partytime.eventTime = this.localStorage.time;
			this.availableSlot = this.localStorage.mytimeslot;
			if (this.availableSlot) {
				this.availableSlot.forEach((element, index) => {
					if (element.start === this.localStorage.time.start) {
						this.mytimeslot = index;
					}
				});
			}
		} else if (this.changePackageFlag === 1) {
			this.changePackageFlag = 0;
		} else {
			// available slots and reservation time slots needs to be reset in local storage
			this.availableSlot = this.tempAvailableSlots;
			const data = {
				mytimeslot: this.availableSlot,
				// reservationTimeSlots: this.tempReservationTimeSlots
			};
			this.storageService.store(data);
		}
	}

	getPartyInfo(data: any) {
		this.localStorage = data;
		if (this.localStorage) {

			this.isDeposit = this.localStorage?.booking?.deposit || 0;

			if (this.localStorage.date) {
				this.eventDate = this.localStorage.date.year + '-' + this.localStorage.date.month + '-' + this.localStorage.date.day;
			}
			this.partytime = {
				eventTime: this.localStorage.time
			};
			this.formattedDate = this.localStorage.date;

			this.mAdult = parseInt(this.localStorage.adults, 10);
			this.mKid = parseInt(this.localStorage.kids, 10);
			if (this.localStorage.hasOwnProperty('booking')) {
				this.reservationType = this.localStorage.booking.type;
				this.dateofParty = this.localStorage.booking.type === Constants.ReservationTypes.VISITEVENT ? 'Date of Visit' : 'Date of Party';
				this.timeofParty = this.localStorage.booking.type === Constants.ReservationTypes.VISITEVENT ? 'Time of Visit*' : 'Time of Party*';
			}
			if (this.localStorage.hasOwnProperty('bookingInfo')) {
				this.localStorage.bookingInfo.PartyTypes.forEach((element, index) => {
					this.partyDisplayTitles = element.DisplayTypeTitle;
					if (parseInt(element.ID, 10) === this.localStorage.packageId) {
						this.partyName = element.DisplayTypeTitle;
						this.colorClass = this.utilsService.updateColor(this.partyDisplayTitles);
					}
				});
			} else {
				this.getPackgeInfo(data);
			}
		} else {
			this.localStorage = false;
		}

		if (this.localStorage.reservationContext) {
			this.updatePrice();
		}

		if (!this.localStorage.booking) {
			console.error('no booking object provided');
			return;
		}

		const earliest = moment().add(this.localStorage.booking.minDate - 1, 'days');
		const endDate = moment().add(this.localStorage.booking.maxDate, 'days');
		this.myDatePickerOptions = {
			openSelectorOnInputClick: true,
			editableDateField: false,
			showTodayBtn: false,
			dateFormat: 'mm-dd-yyyy',
			firstDayOfWeek: 'su',
			sunHighlight: true,
			satHighlight: true,
			disableUntil: { year: earliest.year(), month: earliest.month() + 1, day: earliest.date() },
			disableSince: { year: endDate.year(), month: endDate.month() + 1, day: endDate.date() }
		};
	}

	getPackgeInfo(data) {
		const existingResId = this.localStorage.existingReservationId || 0;
		const reservationId = this.localStorage.reservationId || 0;

		if (!data.date || data.kids === 0 || data.adults === 0 || data.store === 0) {
			console.error('no date provided');
			return;
		}

		const date = Utilities.formatSimpleDate(data.date);
		this.reservationApiService.getTimeSlot(date, data.kids.toString(), data.adults.toString(), data.booking.store.toString(), data.booking.type.toString(), reservationId, existingResId).subscribe(
			(response: any) => {
				const res = response;
				this.timeSlot = response;
				res.AvailabilityDates[0].ReservationTimeSlots.forEach((element, index) => {
					if (element.TimeSlot === data.time.start && element.EndTimeSlot === data.time.end) {
						const json = {
							bookingInfo: res.AvailabilityDates[0].ReservationTimeSlots[index]
						};
						this.getAllTimeSlot(this.eventDate, this.mKid, this.mAdult);

						if (this.localStorage.birthdayGuests === null || this.localStorage.birthdayGuests === undefined) {
							// eslint-disable-next-line @typescript-eslint/no-shadow
							const localStorage = {
								birthdayGuests: (this.localStorage.reservationContext ?
									this.localStorage.reservationContext.Reservation.BirthdayGuestDetails : '')
							};
							this.storageService.store(localStorage);
						}

						this.storageService.store(json);
					}
				});
				const localStore = this.storageService.fetch();
				this.getPartyInfo(localStore);
			},
			error => {
				this.loader = false;
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = error.message;
			});
	}

	updatePrice() {
		this.upsellArr = [];
		let data = this.storageService.fetch();
		if (!data) {
			data = this.local;
		}

		this.upsellArr = (data.reservationContext.Reservation.OptionalPartyItems);
		let sum = 0;
		this.upsellArr.forEach(element => {
			sum += element.Price * element.Quantity;
		});
		this.additionItemPrice = sum;

		const total = this.additionItemPrice + data.booking.deposit;
		this.totalPrice = total.toFixed(2);
	}

	change() {
		this.showChangePackage = true;
		this.togglerBodyClass(true);
	}

	editParty() {
		this.showEditInfo = true;
		this.togglerBodyClass(true);
	}

	getUpdatedData() {
		this.localStorage = this.storageService.fetch();
		this.localStorage.bookingInfo.PartyTypes.forEach((element, index) => {
			// eslint-disable-next-line radix
			this.partyDisplayTitles = element.DisplayTypeTitle;
			if (parseInt(element.ID, 10) === this.localStorage.packageId) {
				this.partyName = element.DisplayTypeTitle;
				this.colorClass = this.utilsService.updateColor(this.partyDisplayTitles);
			}
		});
		this.updateInfo(true);
	}

	updateInfo(changed: boolean = true) {
		this.showEditInfo = false;
		this.togglerBodyClass(false);
		if (!changed) {
			return;
		}
		this.packageUpdated.emit(true);
		this.local = this.storageService.fetch();
		this.allowEdit = this.canEditReservation(this.local?.date, this.local?.reservationContext?.Store);
		const tempBookingInfo = this.local.tempBookingInfo;
		if (tempBookingInfo && tempBookingInfo.hasOwnProperty('showChangePackage') && tempBookingInfo.showChangePackage) {
			this.change();
		} else {
			// we need to hide promcode UI from change/cancel flow
			if (this.skipPromoValidation) {
				this.showPromoUIAndUpdateMessage();
			}
			// while loading reservation summary from change/cancel show all rewards from reservation
			if (!this.skipPromoValidation) {
				const promoValidateResponse = this.applyPromoRewardToReservation(undefined, false);
				if (promoValidateResponse === false || promoValidateResponse === undefined) {
					this.showPromoMessage = false;
					this.promoCode = '';
				}
				if (promoValidateResponse === true) {
					this.showPromoMessage = true;
					const promoRewardName = this.getPromoRewardName();
					this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', promoRewardName);
					this.promoservice.clearPromo$.next(true);
				}
			}
			this.local = this.storageService.fetch();
			this.getPartyInfo(this.local);
			this.updateRewardsInfo(this.local);
			if (this.local.reservationContext) {
				this.updatePrice();
			}
		}
	}

	updateCount(a: number, b: number) {
		if (a === 0) {
			if (b === 0) {
				if (this.mKid > this.localStorage.booking.minKids) {
					this.mKid -= 1;
					this.kids();
					this.active = 'active';
				} else {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = `Minimum ${this.localStorage.booking.minKids} kids are required to book ${parseInt(this.localStorage.booking.type) === Constants.ReservationTypes.VISITEVENT ? 'visit' : 'the party'}!`;
					this.message.clear();
				}
			} else if (b === 1) {
				this.mKid += 1;
				this.kids();
				this.active = 'active';
			}
		} else if (a === 1) {
			if (b === 0) {
				if (this.mAdult > this.localStorage.booking.minAdults) {
					this.mAdult -= 1;
					this.adults();
					this.active = 'active';
				} else {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = `Minimum ${this.localStorage.booking.minAdults} adults are required to book ${parseInt(this.localStorage.booking.type) === Constants.ReservationTypes.VISITEVENT ? 'visit' : 'the party'}!`;
					this.message.clear();
				}
			} else if (b === 1) {
				this.mAdult += 1;
				this.adults();
				this.active = 'active';
			}
		}
	}

	onDateChanged(event: IMyDateModel) {
		this.mytimeslot = this.timeofParty;
		this.isDateSelected = true;
		this.active = 'active';
		this.formattedDate = event.date;
		this.hasDateChanged = true;

		if (this.mKid && this.mAdult && event.date.day && event.date.month && event.date.year) {
			this.loader = true;
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.INFO;
			this.alertMessage.message = WebString.TIMESLOT;
			this.availableSlot = [];
			const existingResId = this.localStorage.existingReservationId || 0;
			const reservationId = this.localStorage.reservationId || 0;
			const newDate = event.date.year + '-' + event.date.month + '-' + event.date.day;
			this.reservationApiService.getTimeSlot(newDate, this.mKid, this.mAdult, this.localStorage.booking.store, this.reservationType, reservationId, existingResId)
				.subscribe((response: any) => {
					this.timeSlot = response;
					this.tempReservationTimeSlots = response.AvailabilityDates[0].ReservationTimeSlots;
					this.eventDate = newDate;
					this.getAllTimeSlot(this.eventDate, this.mKid, this.mAdult);
					this.loader = false;
					this.alertMessage.show = false;
					if (this.flag === 0) {
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.WARNING;
						this.alertMessage.message = WebString.NOTIMESLOT;
						this.mydate = null;
						this.message.clear();
					}
				},
					error => {
						this.loader = false;
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.ERROR;
						this.alertMessage.message = error.message;
					});

		} else {
			if (!event.date.day && !event.date.month && !event.date.year) {
				this.availableSlot = [];
			}
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.ERROR;
			this.alertMessage.message = WebString.SELECTDATE;
			this.message.clear();
		}
	}

	getAllTimeSlot(date: string, kids: number, adults: number) {
		$('.time-slots-list').removeClass('time-slots-list-colors');
		this.availableSlot = [];
		this.flag = 0;
		this.isDate = true;

		const totalAttendees = (kids) + (adults);
		this.availableSlot = this.utilsService.getEnreachedTimeslot(this.timeSlot.AvailabilityDates[0].ReservationTimeSlots, totalAttendees);
		const hasAvailableSlot = this.availableSlot.findIndex(x => x.available) >= 0;

		if (hasAvailableSlot) {
			this.timeToggle = true;
			this.flag = 1;
		}
		if (this.timeToggle === false) {
			$('.time-slots-list').addClass('time-slots-list-colors');
		}
		const data = {
			mytimeslot: this.availableSlot,
		};
		this.storageService.store(data);
	}

	kids() {
		this.tempKid = parseInt(this.localStorage.kids, 10);
		this.flag = 0;
		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
		} else {
			this.onKidAdultUpdate(false, 'K');
		}
	}
	showErrorPopup(errorType: string, errorMsg: string) {
		this.alertMessage.show = true;
		this.alertMessage.className = errorType;
		this.alertMessage.message = errorMsg;
		this.message.clear();
	}

	changePartyRoomHandler(isPartyRoom: boolean) {
		const localData = this.storageService.fetch();
		this.reservationType = isPartyRoom ? Constants.ReservationTypes.PARTYROOM : Constants.ReservationTypes.BIRTHDAYPARTY;
		if (localData.kids !== undefined && localData.adults !== undefined) {
			this.onKidAdultUpdate(false, null);
		}
		this.mytimeslot = 'Time of Party*';
	}

	onKidAdultUpdate(isNavigation: boolean, fromEvent: string) {
		const localData = this.storageService.fetch();
		if (this.eventDate === undefined) {
			this.eventDate = this.local.date.year + '-' + this.local.date.month + '-' + this.local.date.day;
		}
		if (this.partytime.eventTime === undefined) {
			this.partytime = {
				eventTime: {
					start: this.local.time.start,
					end: this.local.time.end,
				}
			};
		}
		if ((this.mKid !== undefined && this.mKid !== 0) && (this.mAdult !== undefined && this.mAdult !== 0)) {
			this.loader = true;
			if (fromEvent !== 'K' && fromEvent !== 'A') {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.INFO;
				this.alertMessage.message = WebString.INFOUPDATE;
			}
			this.availableSlot = [];
			const existingResId = localData.existingReservationId || 0;
			const reservationId = localData.reservationId || 0;
			this.reservationApiService.getTimeSlot(this.eventDate, this.mKid, this.mAdult,
				localData.booking.store, this.reservationType, reservationId, existingResId)
				.subscribe((response: any) => {
					this.timeSlot = response;
					this.tempReservationTimeSlots = response.AvailabilityDates[0].ReservationTimeSlots;
					this.getAllTimeSlot(this.eventDate, this.mKid, this.mAdult);
					this.alertMessage.show = false;
					const totalAttendees = +(this.mKid) + +(this.mAdult);

					this.loader = false;
					if (!this.availableSlot.some(x => x.available) || this.flag === 0) {
						this.mydate = null;
						let errorMessage = +this.reservationType === Constants.ReservationTypes.PARTYROOM ? WebString.PARTY_ROOM_NO_AVAILABLE_SEAT : WebString.SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT;
						this.showErrorPopup(WebString.WARNING, errorMessage);
						this.mytimeslot = this.timeofParty;
						this.isDateSelected = false;
					} else {
						if (this.mytimeslot !== this.timeofParty && this.availableSlot !== null && this.availableSlot.length > 0
							&& !this.availableSlot[this.mytimeslot].available) {
							let errorMessage = '';
							if (this.availableSlot[this.mytimeslot].seat < totalAttendees) {
								errorMessage = +this.reservationType === Constants.ReservationTypes.PARTYROOM ? WebString.PARTY_ROOM_NO_AVAILABLE_SEAT : WebString.SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT;
							} else {
								errorMessage = WebString.NO_TIME_SLOT;
							}
							this.showErrorPopup(WebString.WARNING, errorMessage);
							this.mytimeslot = this.timeofParty;
							this.isDateSelected = false;
						}
						if (isNavigation) {
							this.storeLocal();
						}
					}
					this.isDateSelected = false;
					this.flag = 0;
				},
					error => {
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.ERROR;
						this.alertMessage.message = error.message;
					});

		}
	}

	adults() {
		this.tempAdult = parseInt(this.local.adults, 10);
		this.flag = 0;
		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
		} else {
			this.onKidAdultUpdate(false, 'A');
		}
	}

	partyTime(time: number) {
		this.active = 'active';
		this.partytime = {
			eventTime: {
				start: this.availableSlot[time].start,
				end: this.availableSlot[time].end,
			}
		};
		this.active = 'active';
		const data = {
			isVipParty: this.availableSlot[time].IsVipParty,
		};
		this.storageService.store(data);
	}

	storeLocal() {
		this.partytime.bookingInfo = this.getBookingInfo();
		const data: any = {
			kids: this.mKid,
			adults: this.mAdult,
			time: this.partytime.eventTime,
			bookingInfo: this.partytime.bookingInfo,
			date: this.formattedDate
		};
		data.booking = Object.assign(this.localStorage.booking, { type: this.reservationType });
		this.storageService.store(data);
		this.localStorage = this.storageService.fetch();
		this.updatedInfo.emit(true);
	}

	update() {
		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
			return;
		} else if (this.mytimeslot === null || this.mytimeslot === undefined || this.mytimeslot === this.timeofParty) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_TIMESLOT);
			return;
		}
		const data = this.storageService.fetch();
		const totalGuests = this.mKid + this.mAdult;
		let partyTypeFlag = false;
		let partyTypePricing;
		const maxGuest = this.tempReservationTimeSlots[this.mytimeslot].MaxGuest;
		const AvailableSeatForSlot = this.tempReservationTimeSlots[this.mytimeslot].AvailableSeat;
		if (this.tempReservationTimeSlots.every((seat: any) => totalGuests > seat.AvailableSeat || seat.BookingStatus === 'unavailable')) {
			this.mydate = null;
			this.availableSlot = [];
			this.mytimeslot = this.timeofParty;
			this.showErrorPopup(WebString.ERROR, WebString.NOTIMESLOT);
			this.active = 'not-active';
			return;
		} else if (totalGuests > AvailableSeatForSlot) {
			this.mytimeslot = this.timeofParty;
			this.showErrorPopup(WebString.ERROR, WebString.NO_TIME_SLOT);
			this.active = 'not-active';
			return;
		}

		if (this.tempReservationTimeSlots) {
			if (maxGuest == 0 || totalGuests <= maxGuest) {
				if (this.tempReservationTimeSlots[this.mytimeslot].MinConfig > this.mKid) {
					const minKidAlertTime = 'This timeslot requires a minimum of ' + this.tempReservationTimeSlots[this.mytimeslot].MinConfig + ' children';
					this.showErrorPopup(WebString.ERROR, minKidAlertTime);
					return;
				}
			}
		}

		const storeId = parseInt(data.booking.store, 10);
		if (this.page !== 1 && data.bookingInfo.IsVipParty === this.availableSlot[this.mytimeslot].IsVipParty) {
			if (data.reservationContext && data.reservationContext.PartyType) {
				const availableSeats = this.availableSlot ? this.availableSlot[this.mytimeslot].seat : data.bookingInfo.AvailableSeat;
				if (data.reservationContext.PartyType.PriceAsPer == Constants.PriceAsPer.PartyType) {
					partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, this.mKid, this.mAdult, data.reservationContext.PartyType, availableSeats);
				} else {
					const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, this.mKid, this.mAdult, data.reservationContext.PartyType);
					if (packageValidation) {
						return this.showErrorPopup(WebString.ERROR, packageValidation);
					} else {
						partyTypeFlag = true;
					}
				}
			}
		} else {
			this.selectPartyType();
		}

		if (this.tempReservationTimeSlots) {
			if (maxGuest && maxGuest < totalGuests) {
				this.showErrorPopup(WebString.ERROR, WebString.TIMESLOT_MAX_GUEST);
				return;
			}
		}

		if (partyTypeFlag) {
			this.selectPartyType();
		}

		if (partyTypePricing) {

			if (typeof partyTypePricing === 'string') {
				return this.showErrorPopup(WebString.ERROR, partyTypePricing);
			}

			if (partyTypePricing.flag) {
				this.selectPartyType();
			} else {
				partyTypePricing.dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) {
						this.mKid = partyTypePricing.tempKids;
						this.mAdult = partyTypePricing.tempAdults;
						const pricingData = {
							kids: partyTypePricing.tempKids,
							adults: partyTypePricing.tempAdults
						};
						this.storageService.store(pricingData);
						this.selectPartyType();
					}
				});
			}
		}
		this.promoservice.clearPromo$.next(true);
	}

	private selectPartyType() {
		this.tempKid = this.mKid;
		this.tempAdult = this.mAdult;
		if (this.active === 'active') {
			if (this.localStorage.existingReservationId && this.page !== 1) {
				this.reservationUpdate = 1;
				this.select_package();
			} else if (this.localStorage.reservationId && this.page !== 1) {
				this.select_package();
			} else {
				this.onKidAdultUpdate(true, 'U');
				this.storeLocal();
			}
			this.active = 'not-active';
			this.changePackageFlag = 1;
			this.doInfoCollapseAction();
		}
	}

	updateReservation() {
		this.loader = true;
		const data = this.storageService.fetch();
		this.bookingApiService.updateReservation(data.reservationContext).subscribe((response: SaveReservationDto) => {
			this.loader = false;
			const res = response;
			if (res.Message.IsOk) {
				const storeData = {
					reservationContext: res
				};
				this.storageService.store(storeData);
				this.packageInfo.setEditPartySubject(this.mKid);
				if (this.page === 3 && this.hasDateChanged) {
					this.router.navigate(
						[],
						{
							relativeTo: this.route,
							queryParams: { qDate: encodeURIComponent(btoa(Utilities.formatSimpleDate(data.date, 'yyyy-mm-dd'))) },
							queryParamsHandling: 'merge'
						});
				}
			}
		},
			error => {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = error.message;
			});
	}

	getBookingInfo(): ReservationTimeSlot {
		const selectedTimeSlot = this.partytime.eventTime;

		return this.tempReservationTimeSlots.find((reservationTimeSlot: any) => {
			return reservationTimeSlot.EndTimeSlot === selectedTimeSlot.end
				&& reservationTimeSlot.TimeSlot === selectedTimeSlot.start;
		});
	}

	checkIfPackageExist(): boolean {
		const newBookingInfo = this.getBookingInfo();
		const data = this.storageService.fetch();
		const index = newBookingInfo?.PartyTypes?.findIndex(t => t.ID === data.packageId);
		return index >= 0;
	}

	select_package() {
		if (!this.checkIfPackageExist()) {
			const currentData = this.storageService.fetch();
			const tempData = {
				kids: this.mKid.toString(),
				adults: this.mAdult.toString(),
				time: this.partytime.eventTime,
				bookingInfo: this.getBookingInfo(),
				date: this.formattedDate,
				showChangePackage: true,
				oldPartyTypeTitle: currentData.partyTypeTitle,
				oldPackageId: currentData.packageId,
				oldTimeSlot: this.tempAvailableSlots,
			};
			this.storageService.store({ tempBookingInfo: tempData, packageId: undefined });
			this.change();
			this.active = 'not-active';
			this.changePackageFlag = 1;
			this.doInfoCollapseAction();
			return;
		}
		this.alertMessage.show = true;
		this.alertMessage.className = WebString.INFO;
		this.alertMessage.message = WebString.INFOUPDATE;
		this.loader = true;
		const date = this.eventDate;
		this.localStorage = this.storageService.fetch();
		const existingResId = this.localStorage.existingReservationId || 0;
		const reservationId = this.localStorage.reservationId || 0;
		this.bookingApiService.bookPackage(this.mAdult, this.mKid, date, this.partytime.eventTime.start,
			this.localStorage.booking.store, this.reservationType.toString(),
			reservationId, existingResId, this.localStorage.packageId)
			.subscribe((response: any) => {
				this.loader = false;
				this.alertMessage.show = false;
				let res = response;
				if (res.Message.IsOk) {
					this.storeLocal();
					res = this.reValidatePromoReward(res);
					const storeData = {
						reservationContext: res
					};
					this.storageService.store(storeData);
					this.updatedInfo.emit(true);
					if (this.reservationUpdate === 1) {
						this.updateReservation();
					}
				} else {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = res.Message.Error;
				}
			},
				error => {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
	}

	reValidatePromoReward(res: any): any {
		const promoValidateResponse = this.applyPromoRewardToReservation(undefined, false);
		if (promoValidateResponse === false) {
			this.showPromoMessage = false;
			this.promoCode = '';
		}
		if (promoValidateResponse === true) {
			this.showPromoMessage = true;
			const promoRewardName = this.getPromoRewardName();
			this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', promoRewardName);
		}
		this.localStorage = this.storageService.fetch();
		const promoReward = this.localStorage.reservationContext.Reservation.ReservationRewards.
			find(({ RewardTypeID }) => RewardTypeID === 3);
		if (promoReward) {
			res.Reservation.ReservationRewards.push(promoReward);
		}
		return res;
	}

	printPage() {
		this.isExpandAddItems = true;
		setTimeout(() => {
			window.print();
		}, 200);
	}

	cancel() {
		this.cancelReservation.emit(true);
	}

	private getTimeSlotSequence(localData: any) {
		let timeSlotSequence;
		const time = localData.time;
		if (this.tempReservationTimeSlots) {
			this.tempReservationTimeSlots.forEach((reservationTimeSlot: any) => {
				if (reservationTimeSlot.TimeSlot === time.start) {
					timeSlotSequence = reservationTimeSlot.TimeSlotSequence;
					return false;
				}
			});
		} else {
			timeSlotSequence = localData.reservationContext.Reservation.TimeSlotSequence;
		}
		return timeSlotSequence;
	}

	private fallsInDateRange(momentDate, startDate, endDate) {
		return momentDate.isBetween(startDate, endDate, null, '[]');
	}

	private getPromotionCategoryId(localData: any) {
		return localData.reservationContext.PartyType.PromotionCategoryID;
	}

	// Active,RewardsType,Enabled, Res type, Client App, DoW,TS,Eligiblity, min Chil
	private validatePromoReward(promoCode: string): { isNotEligible: boolean, isNotFound: boolean, isValid: boolean, storeRewardDTO: any, isNotApplied: boolean, isPromoDiscriptionDisplay: boolean } {
		const response = { isNotEligible: false, isNotFound: false, isValid: false, storeRewardDTO: undefined, isNotApplied: false , isPromoDiscriptionDisplay: false};
		if (promoCode) {
			const localData = this.storageService.fetch();
			const storeRewards = this.getStorerewards(localData);
			const promoRewards = storeRewards.filter(({ RewardTypeId }) => RewardTypeId === 3);

			// Loop through promo rewards and validate
			if (promoRewards.some(reward => reward.RewardName.toLowerCase().trim() === promoCode.toLowerCase().trim()
				&& [1, 3].includes(reward.EligibleReservationApplication))) {
				response.isNotEligible = false;
				promoRewards.forEach((promoReward) => {
					if (promoReward.RewardName.toLowerCase().trim() === promoCode.toLowerCase().trim()) {
						// Need add Earliest also to current date
						const isActive = this.fallsInDateRange(moment(), promoReward.ActiveStartOn, promoReward.ActiveEndOn);
						if (isActive) {
							const selectedPartyDate = localData.date;
							const partyDate = moment(selectedPartyDate.year + '-' + selectedPartyDate.month + '-' + selectedPartyDate.day, 'YYYY-MM-DD');
							const timeSlotSequence = this.getTimeSlotSequence(localData);
							if (promoReward.EligibleWeekdays.indexOf(partyDate.format('dddd')) > -1) {
								if (promoReward.EligibleTimeSlots.indexOf(timeSlotSequence) > -1) {
									const isEligible = this.fallsInDateRange(partyDate, promoReward.EligibleStartOn, promoReward.EligibleEndOn);
									if (isEligible) {
										if(parseInt(localData.kids) >= promoReward.EligibleMiniumChildren) {
											if (promoReward.QualifyingPromotionalCategory === 2) {
												const selectedPartyTypeId = this.getPromotionCategoryId(localData);
												if (promoReward.QualifyingPartyTypes.indexOf(selectedPartyTypeId) > -1) {
													response.isValid = true;
													response.storeRewardDTO = promoReward;
													response.isNotFound = false;
													response.isNotEligible = false;
													response.isNotApplied = false;
													response.isPromoDiscriptionDisplay = false;
												}
												else {
													response.isValid = false;
													response.storeRewardDTO = promoReward;
													response.isNotFound = false;
													response.isNotEligible = true;
													response.isNotApplied = false;
													response.isPromoDiscriptionDisplay = true;
												}
											} else {
												response.isValid = true;
												response.storeRewardDTO = promoReward;
												response.isNotFound = false;
												response.isNotEligible = false;
												response.isNotApplied = false;
												response.isPromoDiscriptionDisplay = false;
											}
										} else {
											response.isValid = false;
											response.storeRewardDTO = promoReward;
											response.isNotFound = false;
											response.isNotEligible = false;
											response.isNotApplied = true;
											response.isPromoDiscriptionDisplay = false;
										}
										
									} else {
										response.isValid = false;
										response.storeRewardDTO = promoReward;
										response.isNotFound = false;
										response.isNotEligible = true;
										response.isNotApplied = false;
										response.isPromoDiscriptionDisplay = false;
									}
								}
							}
						}
					}
				});
			} else {
				response.isNotFound = true;
			}
		}
		return response;
	}

	/* Logic for applying promo code to reservation context
	  OLD	  NEW	  ACTION
	  true	true	remove/add
	  false	true	add
	  false	false	-
	  true	false	keep OLD
	*/

	public applyPromoRewardToReservation(promoCode: string, isApply: boolean = false): any {
		const promoCodeStatus = { isNotFound: false, isValid: false, isNotEligible: false, isNotApplied: false, isPromoDiscriptionDisplay:false };
		const localData = this.storageService.fetch();
		if (!localData.reservationContext) {
			return;
		}
		const reservationContext = localData.reservationContext.Reservation;
		if (!isApply) {
			const appliedReward = reservationContext.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
			if (appliedReward) {
				const storeRewards = this.getStorerewards(localData);
				const selectedReward = storeRewards.find(({ RewardID }) => RewardID === appliedReward.RewardID);
				if (selectedReward) {
					const validateResponse = this.validatePromoReward(selectedReward.RewardName);
					if (!validateResponse.isValid) {
						this.removePromoReward(reservationContext, localData);
					}
					return validateResponse.isValid;
				}
				this.removePromoReward(reservationContext, localData);
				return false;
			}
			return;
		}

		const response = this.validatePromoReward(promoCode);
		if (response.isValid) {
			// Remove
			const reservationRewards = reservationContext.ReservationRewards.filter(reward => reward.RewardTypeID !== 3);
			// Add
			reservationRewards[reservationRewards.length] = {
				ReservationID: reservationContext.ID,
				ID: 0,
				RewardID: response.storeRewardDTO.RewardID,
				RewardTitle: response.storeRewardDTO.RewardTitle,
				RewardDescription: response.storeRewardDTO.RewardDescription,
				DisplaySequence: response.storeRewardDTO.DisplaySequence,
				IsActive: true,
				IsDeleted: false,
				CreatedBy: reservationContext.CreatedBy,
				CreatedOn: reservationContext.CreatedOn,
				DisplayIconPath: response.storeRewardDTO.DisplayIconPath,
				LastModifiedBy: null,
				LastModifiedOn: null,
				Quantity: 1,
				RewardTypeID: response.storeRewardDTO.RewardTypeId
			};
			reservationContext.ReservationRewards = reservationRewards;
			localData.reservationContext.Reservation = reservationContext;
			this.storageService.store({ reservationContext: localData.reservationContext });
			this.updateRewardsInfo(localData);
			promoCodeStatus.isValid = true;
			return promoCodeStatus;
		} else if (response.isNotEligible) {
			promoCodeStatus.isNotEligible = true;
			if(response.isPromoDiscriptionDisplay !== promoCodeStatus.isPromoDiscriptionDisplay){
				promoCodeStatus.isPromoDiscriptionDisplay = response.isPromoDiscriptionDisplay;
			}
			return promoCodeStatus;
		} else if (response.isNotFound) {
			promoCodeStatus.isNotFound = true;
			return promoCodeStatus;
		} else if (response.isNotApplied) {
			promoCodeStatus.isNotApplied = true;
			return promoCodeStatus;
		} else {
			return promoCodeStatus;
		}
	}

	private removePromoReward(reservation: any, localData: any) {
		const reservationRewards = reservation.ReservationRewards.filter(reward => reward.RewardTypeID !== 3);
		reservation.ReservationRewards = reservationRewards;
		localData.reservationContext.Reservation = reservation;
		this.storageService.store({ reservationContext: localData.reservationContext });
		this.updateRewardsInfo(localData);
	}

	applyPromoReward(promoCode: string, isApply: boolean = false) {
		if (promoCode) {
			this.showPromoMessage = isApply;
			this.promoCode = '';
			$('.lable_promocode').removeClass('focus');
			const response = this.applyPromoRewardToReservation(promoCode, isApply);
			const localData = this.storageService.fetch();
			const storeRewards = this.getStorerewards(localData);
			const prom = storeRewards.find((promo: any) => promoCode.toLowerCase() === promo.RewardName.toLowerCase());
			if (response.isValid) {
				const promoRewardName = this.getPromoRewardName();
				this.promoMessage = WebString.PROMOAPPLIED.replace('{0}', promoRewardName);
				const localData = this.storageService.fetch();
				if (localData.existingReservationId && this.page === 3) {
					this.updateReservation();
				}
			}
			switch(true) {
				case response.isNotEligible === true && response.isPromoDiscriptionDisplay === true:
					if(prom) {
						this.promoDescription = prom.RewardDescription;
					}
					this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
				break;
				case response.isNotEligible === true && response.isPromoDiscriptionDisplay === false:
					this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
				break;
				case response.isNotFound === true: 
					this.promoMessage = WebString.PROMONOTFOUND.replace('{0}', promoCode);
				break;
				case response.isNotApplied === true:
					const selectedPartyTypeId = this.getPromotionCategoryId(localData);
					if(!prom?.QualifyingPartyTypes.includes(selectedPartyTypeId)){
						this.promoDescription = prom.RewardDescription;
					} else if(prom) {
						this.promoDescription = prom.RewardDescription;
					}
					if(promoCode.toLowerCase() === 'vipfree'){
						this.promoMessage = WebString.PROMOCODENOTELIGIBLE.replace('{0}', promoCode);
					} else {
						this.promoMessage = WebString.PROMONOTAPPLIED.replace('{0}', promoCode);
					}
				break;
			}
		} else {
			this.showPromoMessage = true;
			this.promoMessage = WebString.PROMOERROR;
		}
	}

	getStorerewards(data: any): any {
		return data?.storeRewards || data?.reservationContext?.Store?.parameters?.RewardList || [];
	}

	getPromoRewardName(): string {
		const localData = this.storageService.fetch();
		const reservationContext = localData.reservationContext.Reservation;
		const appliedReward = reservationContext.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
		const storeRewards = this.getStorerewards(localData);
		const selectedReward = storeRewards.find(({ RewardID }) => RewardID === appliedReward.RewardID);
		return selectedReward.RewardName;
	}

	chatChildWindow() {
		window['chatWindow'] = window.open('https://iceim01.iceuc.com/cec/Login.html?destinationURI=sip:ice02bsw9@iceuc.com&lang=en-CA',
			'iceIM', 'width=400, height=600, resizable=yes,scrollbars=yes,left=0, top=0');
	}

	openChat() {
		if (window['chatWindow']) {
			if (window['chatWindow'].closed) {
				this.chatChildWindow();
			}
		} else {
			this.chatChildWindow();
		}
		window['chatWindow'].focus();
	}

	private handleControlFocusInOut() {
		// Raise labels on focused input //
		$('body').on('focus', '.cec__form input', function () {
			$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
		});

		// Lower labels on blur if field is empty. Remove any errors //
		$('body').on('blur', '.cec__form input', function () {
			if ($(this).val() == null || $(this).val() === '') {
				$('label[for="' + $(this).attr('id') + '"]').removeClass('focus');
			}
			$('.email-error').css('opacity', '0');
		});
		// Raise labels on focused textarea //
		$('.cec__form textarea').on('focus', function () {
			$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
		});
		// Lower labels on blur if field is empty //
		$('.cec__form textarea').on('blur', function () {
			if ($(this).val() == null || $(this).val() === '') {
				$('label[for="' + $(this).attr('id') + '"]').removeClass('focus');
			}
		});

		$('.cec__form input').each(function () {
			if ($(this).val() != null && $(this).val() !== '') {
				$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
			}
		});

		$('.cec__form textarea').each(function () {
			if ($(this).val() != null && $(this).val() !== '') {
				$('label[for="' + $(this).attr('id') + '"]').addClass('focus');
			}
		});
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e) {
		const element = document.getElementById('mobile-collapse');
		if (element) {
			if (window.pageYOffset > 80) {
				element.classList.add('sticky-mobile-collapse');
			} else {
				element.classList.remove('sticky-mobile-collapse');
			}
		}
	}

	togglerBodyClass(isShown: boolean): void {
		['html', 'body'].forEach(elSelector => {
			const el = document.querySelector(elSelector);
			if (isShown) {
				this.renderer.addClass(el, 'overflow-hidden');
			} else {
				this.renderer.removeClass(el, 'overflow-hidden');
			}
		});
	}
	
    changeLocation() {

		const target = this.locationUri;//'/locations?fromBooking=1';

		const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
			data: {
				message: 'If you continue, you will lose your changes and will need to start over with your reservation.',
				buttonText: {
					ok: 'Change my location',
					cancel: 'Cancel'
				}
			}
		});

		dialogRef.afterClosed().subscribe(changeLocation => {
			if (changeLocation) {
				window.location.href = target;
			}
		});
	}

	canEditReservation(simpleDate: SimpleDateI, store: StoreDetailsI): boolean {
		let canUpdate = true;
		if (!simpleDate || !store?.parameters?.EarliestDate) {
			return true;
		}

		const partyDate = parseInt(Utilities.formatSimpleDate(simpleDate, 'yyyymmdd'), 10);
		const earliestDate = parseInt(moment(store.parameters.EarliestDate.substring(0, 10), 'YYYY-MM-DD').format('YYYYMMDD'), 10);
		if (partyDate < earliestDate) {
			canUpdate = false;
		}

		return canUpdate;
	}

	updatedLocalEvent(updated: boolean): void {
		this.showChangePackage = false;
		this.togglerBodyClass(false);
		if (updated) {
			this.getUpdatedData();
		}
	}

	private getPartyDuration(partyDuration: string): string {
		if (!partyDuration) {
			return null;
		}

		const arr = partyDuration.split(':');
		let output = parseInt(arr[0], 10).toString();
		if (arr[1] !== '00') {
			output += `:${arr[1]}`;
		}
		return output;
	}

	evalutePromoCode() {
		this.promoservice.promoUpdatedInfo$.next(this.promoCode);
	}

	closeModalHandler(e) {
		this.showEditInfo = false;
	}
}
