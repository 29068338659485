export class Promotion {
    Image: string;
    Description: string;
    Title: string;
    constructor() {
        this.Image = 'Alert Message!';
        this.Description = 'Alert ClassName';
        this.Title = 'Title';
    }
}
