<app-message [alert]="alertMessage"></app-message>

<app-loader *ngIf="isShow"></app-loader>

<div class="entry_page m-page PPP-wrapper" id="head" [ngStyle]="{ background: 'url(' + bgImagePath + ') no-repeat top center', 'background-size': 'cover' }">
  <div class="row model">
    <div class="col-md-12 model_box">
      <div class="col-md-12 col-sm-12 model_header">
        <ng-container *ngIf="StoreData">
          <span (click)="goback()"> <img id="close_button_entryPage" class="close_Button" alt="X" src="{{ assets }}/assets/close.png" /> </span>
        </ng-container>
        <p class="book-a-birthday">{{ Title }}</p>
      </div>
      <div class="model_body">
        <div *ngIf="StoreData" class="d-flex justify-content-center">
          <table class="location-details">
            <tbody>
              <tr>
                <td><strong>Location:</strong></td>
              </tr>
              <tr>
                <td>{{ StoreData.StoreName }}</td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Restaurant Information:</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="text-nowrap">
                    {{ StoreData.Address }}
                  </div>
                  <div>{{ StoreData.City }}, {{ StoreData.State }} {{ StoreData.ZipCode }}</div>
                  <div>
                    {{ phoneNumber }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <app-display-comment [comment]="StoreData.AlertMessage" *ngIf="StoreData?.AlertMessage"></app-display-comment>

        <a id="change_location" href="{{ locationUri }}" class="c-entry-page__link"> <img src="{{ assets }}/assets/location-PPP.png" class="c-entry-page__icon" alt="?" />Change Location</a>

      </div>

      <div>
        <p *ngIf="Type" class="text-center mb-4 text-md-left">
          <strong class="party_info">Step 1: How many guests are coming to {{ Type === "4" ? "visit" : "your party" }}?</strong>
        </p>
      </div>

      <div class="col-sm-12 party-info">
        <div class="row">
          <div class="col-sm-6 children" id="children_textbox_entryPage">
            <div class="c-custom-form__group">
              <select #child (change)="handleChangeKidCount($event.target.value)" class="c-custom-form__textbox" id="select_children_entryPage">
                <option value="-1" disabled selected>Children<span class="must">*</span></option>
                <option id="children_{{ kid }}_entryPage" *ngFor="let kid of minKids" value="{{ kid }}">
                  {{ kid }}
                </option>
              </select>
              <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                    <g id="Group-4" transform="translate(19.000000, 180.000000)">
                      <path
                        d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                        id="Fill-1"
                        transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div class="row">
              <div class="col-sm-12 children">
                <p style="font-size: 100%; text-align: left" [innerHTML]="childText"></p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 adults" id="adult_textbox_entryPage">
            <div class="c-custom-form__group">
              <select #adult (change)="handleChangeAdultCount($event.target.value)" class="c-custom-form__textbox" id="select_adult_entryPage">
                <option value="-1" disabled selected>Adults<span class="must">*</span></option>
                <option id="adult_{{ adult }}_entryPage" *ngFor="let adult of minAdults" value="{{ adult }}">{{ adult }}</option>
              </select>
              <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                    <g id="Group-4" transform="translate(19.000000, 180.000000)">
                      <path
                        d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                        id="Fill-1"
                        transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div class="row">
              <div class="col-sm-12 children">
                <p style="font-size: 100%; text-align: left">{{ adultText }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 date date_textbox_entryPage" id="date_textbox_entryPage" [ngClass]="{ hasRoomParty: reservationData?.store?.hasPartyRooms }">
            <form id="select_date_entryPage" #myForm="ngForm" novalidate class="c-custom-form contentDatePicker" style="height: 104px; position: relative; padding: 0">
              <my-date-picker
                style="top: 50%; position: absolute; right: 24px; left: 20px; transform: translateY(-50%); z-index: 995"
                name="mydate"
                [options]="myDatePickerOptions"
                [(ngModel)]="mydate"
                placeholder="{{ dateofParty }}"
                (dateChanged)="onDateChanged($event)"
                class="mydatepick"
                id="date_picker_entryPage"
                required
              >
              </my-date-picker>
            </form>
          </div>
          <div class="col-sm-6 time" id="time_textbox_entryPage">
            <div class="party-room" *ngIf="reservationData?.store?.hasPartyRooms && [1, 5].includes(+Type)">
              <input name="partyRoom" id="partyRoom" type="checkbox" [(ngModel)]="partyRoom" (ngModelChange)="changePartyRoomHandler($event)" />
              <label class="checkbox-label text-black" for="partyRoom"
                >&nbsp;Select a private party room Requires an additional ${{ StoreData?.parameters?.AdditionalDepositPartyRoom }} non-refundable deposit</label
              >
            </div>
            <div class="c-custom-form__group">
              <select
                id="select_time_entryPage"
                [disabled]="!isDate"
                (change)="partyTime($event.target.value)"
                class="c-custom-form__textbox time-slots-list time-slots-list-color"
                [(ngModel)]="mytimeslot"
              >
                <option *ngIf="timeofParty" value="{{ timeofParty }}" disabled selected>{{ timeofParty }}</option>
                <option
                  id="timeslot_{{ i }}_entryPage"
                  *ngFor="let slot of availableSlot; let i = index"
                  value="{{ slot.uuid }}"
                  [disabled]="!slot.available"
                  [ngClass]="{ grey: !slot.available, white: slot.available }"
                >
                  {{ slot.start | displayTimeSlotTime }} - {{ slot.end | displayTimeSlotTime }}&nbsp;&nbsp;&nbsp;
                  <span *ngIf="slot.IsVipParty">VIP &nbsp;</span>
                  <span *ngIf="!slot.available">Full</span>
                </option>
              </select>
              <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                    <g id="Group-4" transform="translate(19.000000, 180.000000)">
                      <path
                        d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                        id="Fill-1"
                        transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 model_footer -custom-modal-footer">
        <button type="button" class="btn--primary btn--purple submit-disable" (click)="selectPackage()" id="select_package">
          <svg style="top: 40%" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.47443 182.64998">
            <defs>
              <style>
                .cls-1 {
                  fill: none;
                  stroke-linecap: round;
                  stroke-miterlimit: 10;
                  stroke-width: 7px;
                }
              </style>
            </defs>
            <path
              class="cls-1 path"
              d="M16.35228,67.598S185.18984-41.06585,451.85651,69.60082c0,0,111.97656,41.02035-26.34505,74.17684s-364.26172,50.42619-398.625-19.542S233.52317,27.93415,314.18984,37.26749"
            />
          </svg>
          <label class="-btn-lbl">Select Package</label>
        </button>
        <div style="text-align: left">
          <div *ngIf="mydate">
            <div *ngFor="let reward of myrewardsList">
              <tr>
                <td>
                  <span>
                    *<span>{{ reward.RewardTitle }}</span></span
                  >
                </td>
              </tr>
            </div>
          </div>
          <p *ngIf="StoreData && isdepositrequired && depositAmount" class="max-deposit text-center">
            <sup>*</sup>All reservations require a ${{ depositAmount }} deposit. A party can be cancelled 48 hours prior to your reservation date and time to receive a deposit refund.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="entry-page-bottom-img">
    <img src="{{ assets }}/assets/background-banner.png" class="img-responsive" alt="Background Banner" />
  </div>
</div>
