import { InjectionToken } from '@angular/core';

export const RESERVATION_API_URL = new InjectionToken('RESERVATION_API_URL');
export const CONTENT_API_URL = new InjectionToken('CONTENT_API_URL');
export const APP_INSIGHTS_INSTRUMENTATION_KEY = new InjectionToken('APP_INSIGHTS_INSTRUMENTATION_KEY');
export const APP_CONFIG = new InjectionToken('APP_CONFIG');
export const FREEDOMPAY_URL = new InjectionToken('FREEDOMPAY_URL');
export const FREEDOMPAY_API_URL = new InjectionToken('FREEDOMPAY_API_URL');
export const MINI_PACKAGES_STORES = new InjectionToken('MINI_PACKAGES_STORES');
export const EXCLUDEDSTORES = new InjectionToken('EXCLUDEDSTORES');
