import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import * as moment from 'moment';
import { Constants, FilterCategoryI, OptionalItemI } from 'src/app/models';
import { ApplicationData } from 'src/app/models/application-data';
import { ReservationDetail } from 'src/app/models/reservation-detail';
import { BookingApiService } from 'src/app/services/booking-api.service';
import { ContentApiService } from 'src/app/services/content-api.service';
import { GTMService } from 'src/app/services/gtm.service';
import { LoggingService } from 'src/app/services/logging.service';
import { PackageInfoService } from 'src/app/services/package.service';
import { ReservationApiService } from 'src/app/services/reservation-api.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utilities } from 'src/app/services/utilities';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { SaveReservationDto } from '../../models/save-reservation-dto';
import { SideMenuComponent } from '../side-menu/side-menu.component';

@Component({
	selector: 'app-reservation-dashboard',
	templateUrl: './reservation-dashboard.component.html',
	styleUrls: ['./reservation-dashboard.component.scss']
})
export class ReservationDashboardComponent implements OnInit {

	@ViewChild(SideMenuComponent) sideMenu: SideMenuComponent;

	// Configuration for optional item carousels //
	slideConfig = {
		slidesToShow: 3,
		slidesToScroll: 3,
		infinite: true,
		dots: true,
		arrows: true,
		prevArrow:
			'<button title="Previous Slide" aria-label="Previous Slide" type="button" class="slick-btn left"><svg width="13px" height="22px" viewBox="0 0 13 22" version="1.1">\
                <defs></defs>\
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                  <g transform="translate(-158.000000, -278.000000)" fill="#5c3e25">\
                    <g transform="translate(158.000000, 259.000000)">\
                      <path d="M16.9045739,24.0768605 C17.6455776,24.2908745 17.415271,24.7915146 17.1545922,25.1746883 C16.6103045,25.9752298 16.0422822,26.7703404 15.3988319,27.4912299 C13.4857794,29.6343879 11.5421533,31.7503918 9.58826909,33.8563387 C8.81649085,34.6882581 8.0382761,35.5318437 7.15285317,36.2304066 C6.85717581,36.4633279 6.03913513,36.4655404 5.76457759,36.2249757 C3.68779617,34.4062583 1.69750541,32.4891829 -0.342668328,30.6276224 C-1.37613108,29.6846731 -2.43292619,28.7630448 -3.52371415,27.8872766 C-4.50970761,27.0961889 -4.62556486,26.6804303 -4.0848977,25.7481415 C-3.67638021,25.0435444 -3.09085859,24.9023434 -2.47959092,25.2533345 C-1.38960753,25.8790843 -0.302641258,26.5418441 0.672691728,27.3291102 C2.46465741,28.7757167 4.2067401,30.289102 5.90054894,31.8497555 C6.45630169,32.3614583 6.76103039,32.0335984 7.06596023,31.7268583 C8.4037494,30.3822303 9.74173971,29.0345852 11.0113421,27.6263967 C11.8581459,26.6874702 12.514268,25.5699305 13.4006967,24.6758585 C13.9568517,24.114675 14.7811278,23.8198022 15.4851215,23.4052505 C15.4973911,23.4949594 15.5098618,23.5848694 15.5221314,23.6745783 C15.9829456,23.8087394 16.4435586,23.9437051 16.9045739,24.0768605 Z" transform="translate(6.500000, 29.905250) rotate(-270.000000) translate(-6.500000, -29.905250) "></path>\
                    </g>\
                  </g>\
                </g>\
              </button>',
		nextArrow:
			'<button title="Next Slide" aria-label="Next Slide" type="button" class="slick-btn right"><svg width="13px" height="22px" viewBox="0 0 13 22" version="1.1">\
                <defs></defs>\
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                  <g transform="translate(-920.000000, -278.000000)" fill="#5c3e25">\
                    <g transform="translate(158.000000, 259.000000)">\
                      <path d="M778.904574,24.0768605 C779.645578,24.2908745 779.415271,24.7915146 779.154592,25.1746883 C778.610305,25.9752298 778.042282,26.7703404 777.398832,27.4912299 C775.485779,29.6343879 773.542153,31.7503918 771.588269,33.8563387 C770.816491,34.6882581 770.038276,35.5318437 769.152853,36.2304066 C768.857176,36.4633279 768.039135,36.4655404 767.764578,36.2249757 C765.687796,34.4062583 763.697505,32.4891829 761.657332,30.6276224 C760.623869,29.6846731 759.567074,28.7630448 758.476286,27.8872766 C757.490292,27.0961889 757.374435,26.6804303 757.915102,25.7481415 C758.32362,25.0435444 758.909141,24.9023434 759.520409,25.2533345 C760.610392,25.8790843 761.697359,26.5418441 762.672692,27.3291102 C764.464657,28.7757167 766.20674,30.289102 767.900549,31.8497555 C768.456302,32.3614583 768.76103,32.0335984 769.06596,31.7268583 C770.403749,30.3822303 771.74174,29.0345852 773.011342,27.6263967 C773.858146,26.6874702 774.514268,25.5699305 775.400697,24.6758585 C775.956852,24.114675 776.781128,23.8198022 777.485121,23.4052505 C777.497391,23.4949594 777.509862,23.5848694 777.522131,23.6745783 C777.982946,23.8087394 778.443559,23.9437051 778.904574,24.0768605 Z" transform="translate(768.500000, 29.905250) rotate(-90.000000) translate(-768.500000, -29.905250) "></path>\
                    </g>\
                  </g>\
                </g>\
              </svg>\
            </button>',
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
					arrows: false
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false
				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false
				}
			},
			{
				breakpoint: 580,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false
				}
			}
		]
	};



	$: any;
	alertColor: any;
	applicationMessage: string;
	assetRoot = environment.assetRoot;
	buttonShow = true;
	changePackage = 0;
	confirmationNumber: any;
	currentPartyType = 0;
	depositAmountDue: number;
	depositDueDate = '';
	depositStatusPaid = true;
	depositTypeCredit = false;
	email: any;
	expandAddItems = false;
	filterCategories: FilterCategoryI[] = [];
	hasOptionalItems: any;
	hideYesNoBtn: boolean;
	isCancelled = false;
	isDepositRequired = false;
	isFundraiserEvent = false;
	isInStorePayment = false;
	isLoading = false;
	isMega = false;
	isPPPConfirmationNumber = false;
	isReservationAlreadyPaid = false;
	isShow = false;
	itemCount = [];
	itemPrice = [];
	itemTypeChildCount = Constants.ItemTypeId.CHILDCOUNT;
	localStorage: ApplicationData;
	menuFlag = false;
	minimumAdults: number;
	minimumChildren: number;
	mobile = false;
	mobile_div = true;
	modalUpsellContent = '';
	model: any = {};
	noBtnSelected: boolean;
	optionalItems: any[] = [];
	optionalPrice = 0;
	optionalQuantity = 0;
	pageUpsellCount = 0;
	partyDate: any;
	partyItem: any[] = [];
	partyMoreThanTwoDay = true;
	popUpClass = '';
	popUpData: any[] = [];
	recommendationText: string[] = [];
	refundComplete = false;
	reservationDetails: ReservationDetail;
	reservationTypeBirthday = Constants.ReservationTypes.BIRTHDAYPARTY;
	reservationTypeGrpEvent = Constants.ReservationTypes.GROUPEVENT;
	selectClass = 'active';
	selectedOptionalItems: any = [];
	sfPartyTypeDescs: { partyPromotionCategoryId: Constants.PartyPromotionCategoryIds, description: string }[] = [];
	sfPunchbowlBaseUrl = '';
	sfStarUpsellContent = '';
	sfSuperStarUpsellContent = '';
	showAlert: boolean;
	showCancelModal = false;
	showDetails = true;
	showId = 0;
	showUpgradePackageModal = false;
	showUpsellModal = false;
	someText = 'some text';
	unsavedItem = false;
	unsavedPerson = false;
	userEditing = false;
	yesBtnSelected: boolean;

	constructor(
		public reservationService: ReservationService,
		private titleService: Title,
		private route: ActivatedRoute,
		private router: Router,
		private storageService: StorageService,
		private packageInfoService: PackageInfoService,
		private gtmService: GTMService,
		private bookingApiService: BookingApiService,
		private contentApiService: ContentApiService,
		private reservationApiService: ReservationApiService,
		private utilsService: UtilsService,
		private logger: LoggingService
	) { }

	// Initialize additional item carousels //
	slickInit(e) {
		setTimeout(function () {
			const $this = $('.carousel');
			const $track = $this.find('.slick-track');
			const $slides = $track.children();
			$slides.css('height', '');
			const minSlideHeight = $track.height();
			$slides.css('height', minSlideHeight);
		}, 500);
	}

	// If unsaved items, alert the user before doing anything //
	@HostListener('window:beforeunload', ['$event'])
	warnUser($event) {
		if (this.unsavedItem || this.unsavedPerson) {
			$event.returnValue = 'You have unsaved changes!';
		}
	}

	ngOnInit() {
		this.localStorage = this.storageService.fetch();
		this.titleService.setTitle('Reservation Dashboard');
		if (window.screen.width < 500) { // 768px portrait
			this.mobile = true;
		}
		const newReservation = this.route.snapshot.queryParamMap.get('new');
		// from cancel/update flow 'isFromCancelUpdate' key will not be appended to the QS
		// and if block gets executed
		let isFromCancelUpdate = false;
		if (this.route.snapshot.queryParamMap.get('isFromCancelUpdate') == null) {
			this.storageService.clear();
			isFromCancelUpdate = true;
		} else {
			// 'isFromCancelUpdate' is to falso from payment -> reserbatio-details flow
			// in the case else block executed and removed the added qs(key)
			// Remove 'new' query parameter
			this.router.navigate([], {
				queryParams: {
					isFromCancelUpdate: null
				},
				queryParamsHandling: 'merge'
			});
		}
		// Removing User's Credit Card Information from the local Storage
		this.reservationService.sharedData = this.storageService.fetch();
		if (this.reservationService.sharedData === null) {
			this.storageService.clear();
		} else {
			if (this.reservationService.sharedData.hasOwnProperty('paymentInformation')) {
				this.logger.logInfo('Clearing Local Storage');
				this.reservationService.sharedData.paymentInformation = {};
			} else {
				this.logger.logInfo('Payment details are cleared');
			}
		}

		// Original parameters (legacy from before encoding change)
		let confirmationID: string = this.route.snapshot.queryParamMap.get('id');
		let resDate: string = this.route.snapshot.queryParamMap.get('date');
		let contEmail: string = this.route.snapshot.queryParamMap.get('email');

		// Encoded parameters
		const encodedMaskedEmail: string = this.route.snapshot.queryParamMap.get('qEmail');
		const encodedMaskedConfirmation: string = this.route.snapshot.queryParamMap.get('qId');
		const encodedDate: string = this.route.snapshot.queryParamMap.get('qDate');

		if (!resDate && encodedDate) {
			resDate = Utilities.decodeParameter(encodedDate);
		}

		if (encodedMaskedEmail || encodedMaskedConfirmation) {
			contEmail = Utilities.decodeParameter(encodedMaskedEmail);
			confirmationID = Utilities.decodeParameter(encodedMaskedConfirmation);
		} else {
			// Pseudo obfuscate if we didn't come in with encoded parameters
			const maskedEmail = contEmail && Utilities.encodeParameter(contEmail);
			const maskedId = confirmationID && Utilities.encodeParameter(confirmationID);
			const maskedDate = resDate && Utilities.encodeParameter(resDate);
			this.router.navigate([], {
				queryParams: {
					qId: maskedId,
					qEmail: maskedEmail,
					qDate: maskedDate,
					id: null,
					date: null,
					email: null
				},
				queryParamsHandling: 'merge'
			});
		}

		$(window).on('resize orientationchange', function () {
			$('.carousel').slick('resize');
		});

		this.getSitefinityContent();

		this.isLoading = true;
		const postheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
		const body = {
			ConfirmationNumber: confirmationID,
			PartyDateFrom: resDate,
			Email: contEmail,
			StoreType: environment.context === 'PPP' ? 2 : 1
		};

		const isValidQueryParamsSearch =
			body.PartyDateFrom != null &&
			body.PartyDateFrom != 'undefined' &&
			(body.Email != null || body.ConfirmationNumber != null);

		if (typeof body.PartyDateFrom != 'undefined' && isValidQueryParamsSearch) {

			this.bookingApiService.getReservationDetails(body).subscribe(reservationDetail => {

				if (reservationDetail) {
					this.saveReservationDetailsObject(reservationDetail);

					setTimeout(() => {
						this.saveReservationToLocalStorage();
					});

					this.setOnlineInvitationLink();

					if (this.reservationDetails != null && typeof this.reservationDetails !== 'undefined') {
						if (this.reservationDetails.Reservation.ReservationTypeID == Constants.ReservationTypes.FUNDRAISEREVENT) {
							this.isFundraiserEvent = true;
							this.isLoading = false;
							this.showDetails = false;
							this.router.navigate([], {
								queryParams: {
									date: null,
									id: null,
									email: null,
									qId: null,
									qEmail: null,
									qDate: null
								},
								queryParamsHandling: 'merge'
							});
						} else {
							this.configureOptionalItems();
							// check if this reservation has already been paid
							this.updateIsReservationPaid();
							this.checkPaymentSuccess();
							// Set the party date
							this.partyDate = moment(
								this.reservationDetails.Reservation.PartyDate
							).format('dddd MMMM Do');

							// Deposit should be due 48 hours from creation, unless the party is before then
							const depositDueMoment = moment.min([
								moment(this.reservationDetails.Reservation.CreatedOn).add(2, 'days'),
								moment(this.reservationDetails.Reservation.PartyDate).subtract(1, 'days')
							]);
							this.depositDueDate = depositDueMoment.format('MM/DD');

							this.enforceStoreDetails();
							this.validateRefundDate();

							// Slight delay before displaying upsell modal
							setTimeout(() => {
								this.checkForUpsell();
							}, 3000);

							this.showDetails = true;
						}
						this.isPPPConfirmationNumber = this.reservationDetails?.Reservation?.ConfirmationID?.includes('-');
						this.gtmService.pushCoreViewDataToGTM('');
						if (!isFromCancelUpdate) {
							this.gtmService.pushBirthdayOnPayment(newReservation, this.itemCount);
						}
					} else {
						this.isLoading = false;
						this.showDetails = false;
						this.router.navigate([], {
							queryParams: {
								date: null,
								id: null,
								email: null,
								qId: null,
								qEmail: null,
								qDate: null
							},
							queryParamsHandling: 'merge'
						});

						this.displayUnableToFindReservationAlert();
					}
				} else {
					this.logger.logInfo('Not Found');
					this.displayUnableToFindReservationAlert();
					this.isLoading = false;
					this.showDetails = false;
				}
			},
				error => {
					this.router.navigate([], {
						queryParams: {
							date: null,
							id: null,
							email: null,
							qId: null,
							qEmail: null,
							qDate: null
						},
						queryParamsHandling: 'merge'
					});
					this.displayUnableToFindReservationAlert();
					this.logger.logError(error);
					this.isLoading = false;
					this.showDetails = false;
				}
			);
		} else {
			this.isLoading = false;
			this.showDetails = false;
			this.router.navigate([], {
				queryParams: {
					date: null,
					id: null,
					email: null,
					qId: null,
					qEmail: null,
					qDate: null
				},
				queryParamsHandling: 'merge'
			});

			if (isValidQueryParamsSearch) {
				this.displayUnableToFindReservationAlert();
			}
		}

		$(window).keydown(this.interceptPrintKeys(this));

		// Subscribing the Behavioral 0bject
		this.packageInfoService.getEditPartySubject().subscribe(newVal => {
			if (!newVal) {
				return;
			}

			this.reservationDetails.Reservation.Children = parseInt(newVal, 10);
			this.configureOptionalItems();
			for (let i = 0; i < this.selectedOptionalItems.length; i++) {
				if (this.selectedOptionalItems[i].ItemType === this.itemTypeChildCount) {
					const item = this.selectedOptionalItems[i];
					item.ItemTypeId = item.ItemType;
					item.Quantity = this.reservationDetails.Reservation.Children;
					this.configureOptionalItems();
					this.updateOptionalItem(item);
				}
			}

		});

	}

	packageUpdated() {
		const mylocal = this.storageService.fetch();
		this.saveReservationDetailsObject(mylocal.reservationContext);
		this.configureOptionalItems();
	}

	openChat() {
		this.sideMenu.openChat();
	}


	getSitefinityContent() {
		// Get SF content//
		const body = [
			'StarUpsellAllYouCanPlay',
			'SuperStarUpsellAllYouCanPlay',
			'PunchbowlBaseUrl',
			'StarPartyTypeDescription',
			'SuperStarPartyTypeDescription',
			'MSStarPartyTypeDescription',
			'VIPMSStarPartyTypeDescription',
			'60MinPartyTypeDescription',
			'90MinPartyTypeDescription',
			'120MinPartyTypeDescription'
		];

		this.contentApiService.get(body).subscribe((data: any) => {
			if (!data) {
				return;
			}

			const contentsArr = data.Contents;
			this.sfStarUpsellContent = contentsArr['StarUpsellAllYouCanPlay'];
			this.sfSuperStarUpsellContent = contentsArr['SuperStarUpsellAllYouCanPlay'];
			this.sfPunchbowlBaseUrl = contentsArr['PunchbowlBaseUrl'];
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.STAR,
				description: contentsArr['StarPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.SUPERSTAR,
				description: contentsArr['SuperStarPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.MEGASUPERSTAR,
				description: contentsArr['MSStarPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.VIPMEGASUPERSTAR,
				description: contentsArr['VIPMSStarPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.PLAY60,
				description: contentsArr['60MinPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.PLAY90,
				description: contentsArr['90MinPartyTypeDescription']
			});
			this.sfPartyTypeDescs.push({
				partyPromotionCategoryId: Constants.PartyPromotionCategoryIds.PLAY120,
				description: contentsArr['120MinPartyTypeDescription']
			});
		},
			error => this.logger.logError(error)
		);
	}

	getSFPartyTypeDesc(partyPromotionCategoryTypeId: number): string {
		const partyTypeDescription = this.sfPartyTypeDescs.find(
			x => x.partyPromotionCategoryId === partyPromotionCategoryTypeId
		);
		return partyTypeDescription && partyTypeDescription.description;
	}

	getTimeSlotDetails() {
		this.popUpData = [];
		this.partyItem = [];
		this.currentPartyType = this.reservationDetails.Reservation.PartyTypeID;
		this.buttonShow = false;
		this.selectClass = 'active';

		const body = {
			ChildCount: this.reservationService.sharedData.numberOfKids,
			AdultCount: this.reservationService.sharedData.numberOfAdults,
			PartyDate: this.getRequestedDate(this.reservationService.sharedData.reservationContext.Reservation.PartyDate),
			Timeslot: this.reservationService.sharedData.reservationContext.Reservation.TimeSlot,
			ReservationId: this.reservationService.sharedData.reservationContext.Reservation.ID,
			StoreId: this.reservationService.sharedData.storeId,
			ReservationTypeId: this.reservationService.sharedData.restype
		};

		this.reservationApiService.getTimeSlot(body.PartyDate, body.ChildCount, body.AdultCount, body.StoreId, body.ReservationTypeId, 0, body.ReservationId).subscribe((data: any) => {

			if (!data || parseInt(data.StoreId) === 0 || parseInt(data.ChildCount) === 0 || parseInt(data.AdultCount) === 0) {
				return;
			}

			const data2 = {
				AvailabilityDates: {
					[0]: {
						ReservationTimeSlots: data
					}
				}
			};

			this.popUpData = data2.AvailabilityDates[0].ReservationTimeSlots[0].PartyTypes;
			if (this.popUpData.length === 1) {
				this.isMega = true;
			}
			const tempArr = ['STAR', 'SUPER', 'MEGA'];
			for (let i = 0; i < this.popUpData.length; i++) {
				this.popUpData[i].className = tempArr[i];
				if (this.popUpData[i].ID === this.currentPartyType) {
					this.showId = this.popUpData[i].PromotionCategoryID;
					if (!this.mobile) {
						this.showPopUpData(this.popUpData[i].PromotionCategoryID);
					}
					this.popUpData[i].isActive = 'active';
				}
			}

		},
			error => this.logger.logError(error)
		);
	}

	showPopUpData(id: number) {
		this.mobile_div = false;
		if (this.showId === id) {
			this.buttonShow = false;
			this.selectClass = 'active';
		} else {
			this.buttonShow = true;
		}
		const tempId = id % 89 - 1;
		this.partyItem = this.popUpData[tempId].PartyItemList;
		this.changePackage = this.popUpData[tempId].ID;
		this.popUpClass = this.popUpData[tempId].className;
		this.logger.logInfo(this.popUpClass);
	}

	back() {
		this.mobile_div = !this.mobile_div;
	}

	getDateString(dateStr: string, offsetDays: number) {
		const inputDate = moment(dateStr);
		const desiredDate = moment(inputDate).add(offsetDays, 'days');
		return desiredDate.format('YYYY-MM-DD');
	}

	getRequestedDate(dateString: string) {
		const requestedMoment = moment(dateString);
		return requestedMoment.format('YYYY-MM-DD');
	}

	showUpgradePkgModal() {
		this.showUpgradePackageModal = true;
		this.getTimeSlotDetails();
	}


	// Set up list of possible optional items to show on the page
	configureOptionalItems() {
		this.optionalItems = [];
		const promotionCategoryGroupsObjs = [];
		this.currentPartyType = this.reservationDetails.Reservation.PartyTypeID;
		const itemsToDisplay: OptionalItemI[] = this.reservationDetails.PartyType.OptionalItemList.filter(
			x => x.ItemShowOnPage === true
		);

		const uniqueCategorySample: OptionalItemI[] = this.filterUniqueArrayByProperty(itemsToDisplay, 'PromotionCategoryGroupTitle').sort(function (a, b) {
			return a.PromotionCategoryGroupOrder - b.PromotionCategoryGroupOrder;
		});

		for (let i = 0; i < uniqueCategorySample.length; i++) {
			if (!uniqueCategorySample[i].PromotionCategoryGroupUseFilter) {
				let tmpArray: Array<OptionalItemI> = itemsToDisplay.filter(
					function (item) {
						return (
							item.PromotionCategoryGroupTitle ==
							uniqueCategorySample[i].PromotionCategoryGroupTitle
						);
					}
				);
				tmpArray = tmpArray.sort(this.compareOptionalItemsByDisplayOrder);
				this.optionalItems.push(tmpArray);
			} else {

				const uniqueItemGroupCategories = this.filterUniqueArray(
					itemsToDisplay.filter(item => item.PromotionCategoryGroupTitle == uniqueCategorySample[i].PromotionCategoryGroupTitle)
						.sort((a, b) => a.ItemGroupOrder - b.ItemGroupOrder)
						.map(item => item.ItemGroupName)
				);

				if (uniqueItemGroupCategories && uniqueItemGroupCategories.length > 0) {
					// Add each of the itemgroup arrays to the optionalItems array (e.g., 8" Vanilla, 1/4 sheet chocolate, etc. for "Cakes")
					for (let filterCategoryIndex = 0; filterCategoryIndex < uniqueItemGroupCategories.length; filterCategoryIndex++) {

						let tmpArray: Array<OptionalItemI> = itemsToDisplay.filter(function (item) {
							return (item.ItemGroupName == uniqueItemGroupCategories[filterCategoryIndex]);
						});

						tmpArray = tmpArray.sort(this.compareOptionalItemsByDisplayOrder);
						tmpArray = tmpArray.map((x: any) => {
							x.IsHidden = filterCategoryIndex !== 0; // hide all but first subcategory
							return x;
						});

						this.optionalItems.push(tmpArray);
					}

					// Build filterCategory
					let categoryOptions = this.filterCategories.find(x => x.categoryTitle == uniqueCategorySample[i].PromotionCategoryGroupTitle);
					if (!categoryOptions) {
						categoryOptions = {
							categoryTitle:
								uniqueCategorySample[i].PromotionCategoryGroupTitle,
							categoryId: uniqueCategorySample[i].PromotionCategoryGroupID,
							categoryFilter: '',
							categoryOptions: [],
							selectedCategory: ''
						};
						categoryOptions.categoryFilter = uniqueItemGroupCategories[0];
						categoryOptions.selectedCategory = uniqueItemGroupCategories[0];
						categoryOptions.categoryOptions = uniqueItemGroupCategories;
						this.filterCategories.push(categoryOptions);
					}
				}
			}
		}

		this.hasOptionalItems = false;
		if (this.optionalItems.find(x => x.length > 0)) {
			this.hasOptionalItems = true;
		}

		this.setSelectedOptionalItems();

		this.optionalQuantity = 0;
		this.optionalPrice = 0;
		for (let i = 0; i < this.reservationDetails.Reservation.OptionalPartyItems.length; i++) {
			this.itemCount.push(this.reservationDetails.Reservation.OptionalPartyItems[i].Quantity);
			this.itemPrice.push(this.reservationDetails.Reservation.OptionalPartyItems[i].Price);
			this.optionalQuantity += this.itemCount[i];
			this.optionalPrice += this.itemPrice[i] * this.itemCount[i];
		}

		this.configureCategoryOptions();
		if (this.filterCategories.length > 0) {
			for (let i = 0; i < this.filterCategories.length; i++) {
				if (this.filterCategories[i].categoryOptions && this.filterCategories[i].categoryOptions.length > 0) {
					this.changeCategoryFilter(
						this.filterCategories[i].categoryOptions[0],
						this.filterCategories[i].categoryOptions[0]
					);
				}
			}
		}
		this.makeOptionalItemRecommendations();
	}

	newPackage() {
		this.mobile_div = true;
		this.showUpgradePackageModal = false;
		this.reservationService.sharedData.reservationContext.PartyType.ID = this.changePackage;
		this.reservationService.sharedData.reservationContext.Reservation.PartyTypeID = this.changePackage;
		const body = this.reservationDetails;
		this.isLoading = true;

		// todo:  move to a service
		this.bookingApiService.updateReservation(body).subscribe((data: SaveReservationDto) => {
			if (data.Reservation.TempBookingSeatID > 0 || data.Reservation.ID > 0) {
				this.isLoading = false;
				this.showUpsellModal = false;
				this.updateReservationSuccess(data);
				this.configureOptionalItems();
			} else {
				this.isLoading = false;
				this.showUpsellModal = false;
				this.updateReservationError(data.Message.Error);
			}
		}, error => {
			this.isLoading = false;
			this.showUpsellModal = false;
			this.updateReservationError('');
		}
		);
	}

	// Set initial quantities for each optional item based on what has been added to the reservation
	setSelectedOptionalItems() {
		if (this.reservationDetails.Reservation && this.reservationDetails.Reservation.OptionalPartyItems) {
			this.selectedOptionalItems = this.reservationDetails.Reservation.OptionalPartyItems;
		}

		// Set this.optionaItems item quantities based on selectedOptionalItems from model
		this.optionalItems.forEach(category => {
			category.forEach(item => {
				const selectedItem = this.selectedOptionalItems.find(x => x.OptionalPartyItemID == item.OptionalPartyItemID);
				if (selectedItem) {
					item.Quantity = item.InitialQuantity = selectedItem.Quantity;
				} else {
					item.Quantity = item.InitialQuantity = 0;
				}
			});
		});
	}

	updateOptionalItem(item) {
		this.isLoading = true;
		const localData = this.storageService.fetch();
		const reservationContext = localData.reservationContext;
		const endtime = localData.time.end;

		// add optional item or update quantity if already added
		const optionalItem = {
			ItemTitle: item.ItemTitle,
			ItemType: item.ItemTypeId,
			OptionalPartyItemID: item.OptionalPartyItemID,
			Price: item.Price,
			Quantity: item.Quantity
		};

		this.gtmService.pushEvent({
			event: 'updateQuantityUpsell',
			upsellItem: optionalItem
		});

		const foundIndex = this.selectedOptionalItems.findIndex(x => x.OptionalPartyItemID == item.OptionalPartyItemID);

		if (foundIndex === -1) {
			this.selectedOptionalItems.push(optionalItem);
		} else {
			this.selectedOptionalItems[foundIndex] = optionalItem;
		}

		reservationContext.Reservation.OptionalPartyItems = this.selectedOptionalItems;

		const minChildren = parseInt(localStorage.getItem('minChildren'));
		if (this.reservationDetails?.Reservation?.IsVIPPartyType && this.reservationDetails?.Reservation?.Children < minChildren) {
			this.isLoading = false;
			this.showUpsellModal = false;
			this.displayAlertWithMessage(
				'This time slot does not support your party size',
				'#cc0000',
				8000
			);
			return;
		}

		this.bookingApiService.updateReservation(reservationContext).subscribe(data => {
			if (data.Reservation.ID > 0) {
				// reset initial quantity
				item.InitialQuantity = item.Quantity;
				// set shared data & local storage
				const response = data;
				response.Reservation.TimeSlotEnd = endtime;
				this.saveReservationDetailsObject(response);
				this.saveReservationToLocalStorage();
				this.alertColor = '#4BB543';
				this.showAlert = true;
				this.applicationMessage = 'Your reservation has been updated';
				this.optionalQuantity = 0;
				this.optionalPrice = 0;
				this.itemCount = [];
				this.itemPrice = [];

				for (let i = 0; i < this.reservationDetails.Reservation.OptionalPartyItems.length; i++) {
					this.itemCount.push(this.reservationDetails.Reservation.OptionalPartyItems[i].Quantity);
					this.optionalQuantity += this.itemCount[i];
					this.itemPrice.push(this.reservationDetails.Reservation.OptionalPartyItems[i].Price);
					this.optionalPrice += this.itemPrice[i] * this.itemCount[i];
				}
				setTimeout(() => {
					this.isLoading = false;
					this.showAlert = false;
					this.alertColor = '#4BB543';
				}, 8000);
			} else {
				this.showAlert = true;
				this.applicationMessage = 'An error occurred saving your reservation';
				this.alertColor = '#cc0000';
				setTimeout(() => {
					this.isLoading = false;
					this.showAlert = false;
					this.alertColor = '#4BB543';
				}, 8000);
			}
		},
			error => this.logger.logError(error)
		);
	}

	applyChanges(event, controllerAction?: string) {
		// Default controller action (use normal UpdateRequest endpoint if we didn't specify another action)
		if (!controllerAction) {
			controllerAction = 'Reservation';
		}

		const body = this.reservationDetails;
		this.isLoading = true;

		// todo:  do we need the controller action
		this.reservationApiService.applyChanges(controllerAction, body).subscribe((data: any) => {
			if (data.Reservation.TempBookingSeatID > 0 || data.Reservation.ID > 0) {
				this.isLoading = false;
				this.showUpsellModal = false;
				this.updateReservationSuccess(data);
				this.gtmService.pushEvent({
					event: 'reservationDetails',
					location: this.reservationDetails.Reservation.StoreID,
					OrderID: this.reservationDetails.Reservation.ID,
					price: this.reservationDetails.Reservation.TotalPrice,
					order_code: this.reservationDetails.Reservation.ConfirmationID,
					sku: this.reservationDetails.Reservation.PartyTypeID,
					package: this.reservationDetails.PartyType.PartyTypeTitle,
					cecSession: this.reservationDetails.Reservation.InvoiceNumber,
					children: this.reservationDetails.Reservation.Children,
					adults: this.reservationDetails.Reservation.Adults
				});
			} else {
				this.isLoading = false;
				this.showUpsellModal = false;
				this.updateReservationError(data.Message.Error);
			}
		},
			error => {
				this.isLoading = false;
				this.showUpsellModal = false;
				this.logger.logError(error);
				this.updateReservationError('');
			}
		);
	}

	checkForUpsell() {
		// Respect global & per-page upsell maximums
		const upsellLimits =
			this.reservationService.sharedData &&
			this.reservationService.sharedData.reservationContext &&
			this.reservationService.sharedData.reservationContext.Store &&
			this.reservationService.sharedData.reservationContext.Store.parameters &&
			this.reservationService.sharedData.reservationContext.Store.parameters
				.PromotionUpsellParameters;

		const hasHitUpsellMax =
			upsellLimits &&
			(this.reservationService.sharedData.sessionUpsellCount >=
				upsellLimits.MaxPromotionUpsellDisplayedPerSession ||
				this.pageUpsellCount >=
				upsellLimits.MaxPromotionUpsellDisplayedPerPage);

		const newReservation = this.route.snapshot.queryParamMap.get('new');
		// If "newReservation" has any value (true or false) then we came from the payment page want to check if we should show the modal (NOT just if it's "true"/new)
		if (!!newReservation && !hasHitUpsellMax && this.reservationService.sharedData.reservationContext.Store.parameters.ShowPromotions) {
			// Check if the party has an "IsPlayPass" optional item
			const playPassOptionalItem =
				this.reservationDetails.PartyType.OptionalItemList &&
				this.reservationDetails.PartyType.OptionalItemList.find(function (item) {
					return item.IsPlayPass;
				});

			if (playPassOptionalItem) {
				// Make sure the play pass item hasn't already been added to the order somehow
				const existingSelectedPlayPassItem =
					this.reservationDetails.Reservation.OptionalPartyItems &&
					this.reservationDetails.Reservation.OptionalPartyItems.find(function (
						existingItem
					) {
						return (
							existingItem.OptionalPartyItemID ==
							playPassOptionalItem.OptionalPartyItemID &&
							existingItem.Quantity > 0
						);
					});

				if (!existingSelectedPlayPassItem) {
					// Check that the party package promotion category is type Star or Super Star
					const partyPromotionCategoryId =
						this.reservationDetails &&
						this.reservationDetails.PartyType &&
						this.reservationDetails.PartyType.PromotionCategoryID;

					if (
						partyPromotionCategoryId ===
						Constants.PartyPromotionCategoryIds.STAR
					) {
						this.modalUpsellContent = this.sfStarUpsellContent;
						if (this.modalUpsellContent && this.modalUpsellContent.includes('<img')) {
							this.modalUpsellContent = this.modalUpsellContent.replace('<img', '<img class="aycp-display"');
						}
						this.showUpsellModal = true;
						this.pageUpsellCount++;
						this.reservationService.addSessionUpsell();
						this.gtmService.pushEvent({
							event: 'upgradeOverlay'
						});
					}
					if (
						partyPromotionCategoryId ===
						Constants.PartyPromotionCategoryIds.SUPERSTAR
					) {
						this.showUpsellModal = true;
						this.modalUpsellContent = this.sfSuperStarUpsellContent;
						if (this.modalUpsellContent && this.modalUpsellContent.includes('<img')) {
							this.modalUpsellContent = this.modalUpsellContent.replace('<img', '<img class="aycp-display"');
						}
						this.pageUpsellCount++;
						this.reservationService.addSessionUpsell();
						this.gtmService.pushEvent({
							event: 'upgradeOverlay'
						});
					}
				}
			}
		}

		// Remove 'new' query parameter
		this.router.navigate([], {
			queryParams: {
				new: null
			},
			queryParamsHandling: 'merge'
		});

		if (!this.showUpsellModal) {
			this.gtmService.pushEvent({
				event: 'reservationDetails',
				location: this.reservationDetails.Reservation.StoreID,
				// 'type': 'birthday-new',
				OrderID: this.reservationDetails.Reservation.ID,
				// 'Value'  : '63.96',
				price: this.reservationDetails.Reservation.TotalPrice,
				order_code: this.reservationDetails.Reservation.ConfirmationID,
				sku: this.reservationDetails.Reservation.PartyTypeID,
				package: this.reservationDetails.PartyType.PartyTypeTitle,
				cecSession: this.reservationDetails.Reservation.InvoiceNumber,
				children: this.reservationDetails.Reservation.Children,
				adults: this.reservationDetails.Reservation.Adults
			});
		}
	}

	applyUpsell(event) {
		this.applyChanges(event, 'ApplyUpsell');
		this.gtmService.pushEvent({
			event: 'upgradePartyButtons',
			upgradeParty: 'Yes'
		});
	}

	updateReservationSuccess(data) {
		this.saveReservationDetailsObject(data);
		this.saveReservationToLocalStorage();
		this.makeOptionalItemRecommendations();
		this.setSelectedOptionalItems();
		this.optionalQuantity = 0;
		this.optionalPrice = 0;
		for (
			let i = 0;
			i < this.reservationDetails.Reservation.OptionalPartyItems.length;
			i++
		) {
			this.itemCount.push(
				this.reservationDetails.Reservation.OptionalPartyItems[i].Quantity
			);
			this.itemPrice.push(
				this.reservationDetails.Reservation.OptionalPartyItems[i].Price
			);
			this.optionalQuantity += this.itemCount[i];
			this.optionalPrice += this.itemPrice[i] * this.itemCount[i];
		}
		this.alertColor = '#4BB543';
		this.showAlert = true;
		this.applicationMessage = 'Your reservation has been updated';
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '#4BB543';
		}, 8000);
	}

	updateReservationError(errorMsg: any) {
		this.showAlert = true;
		if (errorMsg !== null && errorMsg !== '') {
			this.applicationMessage = 'This time slot does not support your party size';
		} else {
			this.applicationMessage = 'An error occurred saving your reservation';
		}

		this.alertColor = '#cc0000';
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '#4BB543';
		}, 8000);
	}

	changeCategoryFilter(categoryItem: any, selectedCategoryName: any) {
		if (categoryItem.PromotionCategoryGroupTitle) {
			const filterCategoryIndex = this.filterCategories.findIndex(
				x => x.categoryTitle == categoryItem.PromotionCategoryGroupTitle
			);
			if (filterCategoryIndex !== -1 && this.filterCategories[filterCategoryIndex]) {
				this.filterCategories[filterCategoryIndex].categoryFilter = selectedCategoryName;
				this.filterCategories[filterCategoryIndex].selectedCategory = selectedCategoryName;

				// Need to update in this.optionalItems
				for (let i = 0; i < this.optionalItems.length; i++) {
					if (this.optionalItems[i].length > 0) {
						for (let j = 0; j < this.optionalItems[i].length; j++) {
							if (this.optionalItems[i][j].PromotionCategoryGroupTitle === categoryItem.PromotionCategoryGroupTitle) {
								this.optionalItems[i][j].IsHidden = this.optionalItems[i][j].ItemGroupName !== selectedCategoryName;
							}
						}
					}
				}

				$('.carousel').slick('refresh');
				this.makeOptionalItemRecommendations();
			}
		}
	}

	cancelReservation(event) {
		this.localStorage = this.storageService.fetch();
		this.reservationDetails.Reservation.TransactionNotes = '';
		const body = this.reservationDetails;
		this.gtmService.pushEvent({
			event: 'cancelReservation'
		});

		event.preventDefault();
		this.isLoading = true;
		this.isShow = true;
		this.reservationApiService.cancelReservation(body).subscribe(reservationDetail => {
			this.isShow = false;
			this.isCancelled = true;

			this.showAlert = true;
			this.applicationMessage = 'Your reservation has been cancelled';
			this.reservationService.clearReservationSession();
			this.alertColor = '#4BB543';
			setTimeout(() => {
				this.showAlert = false;
			}, 8000);

			if (reservationDetail.Reservation.ID > 0) {
				this.reservationDetails = reservationDetail;
			}

			this.refundComplete = this.reservationDetails.Reservation?.DepositDetails[0]?.IsDepositRefunded;
		},
			error => {
				this.logger.logError(error);
				this.isLoading = false;
				this.isCancelled = false;
			}
		);
	}

	validateRefundDate() {
		const today = new Date();
		const partyDate = moment(this.reservationDetails.Reservation.PartyDate).format('YYYYMMDD');
		const validDateForRefund = moment(today).add(2, 'days').format('YYYYMMDD');
		if (validDateForRefund < partyDate) {
			this.partyMoreThanTwoDay = true;
		} else {
			this.partyMoreThanTwoDay = false;
		}

		if (this.reservationDetails.Reservation.DepositType === Constants.ReservationPaymentTypeConst.CREDIT) {
			this.depositTypeCredit = true;
		} else {
			this.depositTypeCredit = false;
		}
		const depositDetails = this.reservationDetails.Reservation.DepositDetails;

		if (depositDetails.length) {
			this.depositStatusPaid = true;
		} else {
			this.depositStatusPaid = false;
		}

		if (depositDetails.length) {
			const latestDepositDetail = depositDetails[depositDetails.length - 1];
			if (!latestDepositDetail.AuthorizationNumber && this.reservationDetails?.Reservation?.DepositStatus == 2 && this.reservationDetails?.Reservation?.IsOnlinePaid === false) {
				this.isInStorePayment = true;
			} else {
				this.isInStorePayment = false;
			}
		} else {
			this.isInStorePayment = false;
		}
	}

	enforceStoreDetails(isRetry?: boolean) {
		const restype = this.reservationDetails.Reservation.ReservationTypeID;

		// Check that we have enough store information (if not, go get it)
		if (!this.reservationService.sharedData.reservationContext || !this.reservationService.sharedData.reservationContext.Store) {
			this.reservationService.getStoreDetails(this.reservationService.sharedData.storeId, restype).subscribe(data => {
				// Retry now that we should have minimum attendees, but only once
				if (!isRetry) {
					this.enforceStoreDetails(true);
				}
			});
		} else {

			if (this.reservationService.sharedData.reservationContext.Store.PaymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT) {
				this.isDepositRequired = true;
			}

			// Check minimum in shared service store if we have it
			this.minimumChildren = (this.reservationService.sharedData.reservationContext.Store.parameters && this.reservationService.sharedData.reservationContext.Store.parameters.Children) || 1;
			this.minimumAdults = (this.reservationService.sharedData.reservationContext.Store.parameters && this.reservationService.sharedData.reservationContext.Store.parameters.Adults) || 1;

			if (this.reservationService.sharedData.numberOfKids < this.minimumChildren) {
				this.reservationService.sharedData.numberOfKids = this.minimumChildren;
			}

			if (this.reservationService.sharedData.numberOfAdults < this.minimumAdults) {
				this.reservationService.sharedData.numberOfAdults = this.minimumAdults;
			}
		}
	}

	// -------------- Attendee functions -----------------
	incrementChildAttendees() {
		this.reservationDetails.Reservation.Children++;
		this.reservationService.sharedData.numberOfKids = this.reservationDetails.Reservation.Children;
	}

	decrementChildAttendees() {
		if (this.reservationDetails.Reservation.Children > this.minimumChildren) {
			this.reservationDetails.Reservation.Children--;
		} else {
			alert(
				'Must have at least ' +
				this.minimumChildren +
				' child attendee' +
				(this.minimumChildren > 1 ? 's' : '')
			);
		}

		this.reservationService.sharedData.numberOfKids = this.reservationDetails.Reservation.Children;
	}

	incrementAdultAttendees() {
		this.reservationDetails.Reservation.Adults++;
		this.reservationService.sharedData.numberOfAdults = this.reservationDetails.Reservation.Adults;
	}

	decrementAdultAttendees() {
		if (this.reservationDetails.Reservation.Adults > this.minimumAdults) {
			this.reservationDetails.Reservation.Adults--;
		} else {
			alert(
				'Must have at least ' +
				this.minimumAdults +
				' adult attendee' +
				(this.minimumAdults > 1 ? 's' : '')
			);
		}

		this.reservationService.sharedData.numberOfAdults = this.reservationDetails.Reservation.Adults;
	}

	makeOptionalItemRecommendations() {
		this.makePizzaRecommendations();
		this.makeCakeRecommendations();
	}

	makePizzaRecommendations() {
		// the strategy is to figure out how many adults will attend
		// use that number to recommend # of pizzas in #1 spot
		const numAdults: number = this.reservationService.sharedData.reservationContext.Reservation.Adults;
		let pizzaItemsArrayIndex = -1;
		for (let i = 0; i < this.optionalItems.length; i++) {
			if (this.optionalItems[i][0].PromotionCategoryGroupID && this.optionalItems[i][0].PromotionCategoryGroupID == Constants.ItemPromotionCategoryGroupID.PIZZA) {
				pizzaItemsArrayIndex = i;
			}
		}

		if (pizzaItemsArrayIndex > -1) {
			if (this.optionalItems.length > pizzaItemsArrayIndex && this.optionalItems[pizzaItemsArrayIndex]) {
				const pizzaArray = this.optionalItems[pizzaItemsArrayIndex].sort(
					this.compareOptionalItemsByDisplayOrder
				);
				if (pizzaArray.length > 0) {
					const firstPizza = pizzaArray[0];
					if (firstPizza && firstPizza.ItemServeCount > 0) {
						const minimumNumPizza = Math.max(
							Math.ceil(numAdults / firstPizza.ItemServeCount),
							2
						);
						this.recommendationText['pizza'] = 'We recommend <strong>' + minimumNumPizza + '</strong> ' + firstPizza.ItemTitle;
					}
				}
			}
		}
	}

	makeCakeRecommendations() {
		const numAdults: number = this.reservationService.sharedData.reservationContext.Reservation.Adults;
		const numChildren: number = this.reservationService.sharedData.reservationContext.Reservation.Children;
		const compareFunc = function (selectedCakeName) {
			return function (array: OptionalItemI[]) {
				return (
					array.filter(item => item.ItemGroupName == selectedCakeName).length > 0
				);
			};
		};

		const cakeIndex = this.filterCategories.findIndex(x => x.categoryId == Constants.ItemPromotionCategoryGroupID.CAKE);

		if (this.optionalItems.length > 0 && cakeIndex !== -1 && this.filterCategories[cakeIndex] &&
			this.filterCategories[cakeIndex].categoryOptions && this.filterCategories[cakeIndex].categoryOptions.length > 0) {

			const selectedCake = this.filterCategories[cakeIndex].categoryOptions.find(
				option => option.name == this.filterCategories[cakeIndex].selectedCategory
			);

			if (selectedCake) {
				const selectedCakeGroupTitle = selectedCake.name;
				const filteredCakeArrayIndex = this.optionalItems.findIndex(compareFunc(selectedCakeGroupTitle));

				if (filteredCakeArrayIndex > -1 && this.optionalItems[filteredCakeArrayIndex].length > 0) {
					const sortedFilteredCakeArray = this.optionalItems[filteredCakeArrayIndex].sort(this.compareOptionalItemsByDisplayOrder);

					if (sortedFilteredCakeArray.length > 0 && sortedFilteredCakeArray[0].ItemServeCount > 0) {
						const firstCake = sortedFilteredCakeArray[0];
						const minimumCakes = Math.max(Math.ceil((numAdults + numChildren) / firstCake.ItemServeCount), 1);
						this.recommendationText['cakes'] = 'We recommend <strong>' + minimumCakes + '</strong> ' +
							(firstCake.ItemAttributes &&
								firstCake.ItemAttributes.includes('IsCake')
								? 'cake'
								: 'item') +
							(minimumCakes === 1 ? '' : 's');
					} else {
						this.recommendationText['cakes'] = '';
					}
				}
			}
		}
	}

	makeDrinkRecommendations() {
		const othersItemsArrayIndex = 6;
		const numAdults: number = this.reservationService.sharedData.reservationContext.Reservation.Adults;
		if (this.optionalItems.length > 0) {
			const othersArray = this.optionalItems[othersItemsArrayIndex];
			if (othersArray.length > 0) {
				const firstItem = othersArray[0];
				const firstItemMinimum = Math.max(numAdults, firstItem.Quantity, 2);
				firstItem.Quantity = firstItemMinimum;
				const firstOtherIndex = this.optionalItems[othersItemsArrayIndex].findIndex(otherItem => otherItem.ID == firstItem.ID);
				this.optionalItems[othersItemsArrayIndex][firstOtherIndex] = firstItem;
			}
		}
	}

	makeGoodieBagRecommendations() {
		const othersItemsArrayIndex = 6;
		const goodieBagTitle = 'Goody Bag';

		if (this.optionalItems.length > 0 && this.optionalItems.length > othersItemsArrayIndex) {
			const othersArray = this.optionalItems[othersItemsArrayIndex];
			if (othersArray && othersArray.length > 0) {
				const goodieBagItemIndex = othersArray.findIndex(
					otherItem => otherItem.ItemTitle == goodieBagTitle
				);
			}
		}
	}

	getRecommendationText(category: any) {
		if (category && category.PromotionCategoryGroupID == Constants.ItemPromotionCategoryGroupID.PIZZA) {
			return this.recommendationText['pizza'];
		} else if (category && category.PromotionCategoryGroupID == Constants.ItemPromotionCategoryGroupID.CAKE) {
			return this.recommendationText['cakes'];
		}
		return '';
	}

	printPage() {
		this.expandAddItems = true;
		setTimeout(() => {
			window.print();
		}, 200);
	}

	interceptPrintKeys(rdComponent: ReservationDashboardComponent) {
		return function (event) {
			if (event.ctrlKey && event.keyCode == 80) {
				event.preventDefault();
				rdComponent.expandAddItems = true;
				rdComponent.printPage();
			}
		};
	}

	updateIsReservationPaid() {
		if (typeof this.reservationDetails !== 'undefined' && this.reservationDetails != null) {
			this.isLoading = false;
			if (this.reservationDetails.Reservation.DepositDetails.length > 0) {
				this.isReservationAlreadyPaid =
					this.isReservationAlreadyPaid ||
					(this.reservationDetails.Reservation.DepositDetails[0].PaidOn.length >
						0 &&
						this.reservationDetails.Reservation.DepositDetails[0].PaidOn !==
						'0001-01-01T00:00:00');
			}
			this.depositAmountDue = this.reservationService.sharedData.reservationContext.Reservation.DepositAmount;
		}
	}

	checkPaymentSuccess() {
		// Validate that the payment page thought the payment was successful
		if (this.reservationService && this.reservationService.sharedData && this.reservationService.sharedData.showPaymentSuccess) {
			// Validate that the reservation considers the deposit as 'paid'
			if (this.isReservationAlreadyPaid) {
				this.displayAlertWithMessage('Deposit payment submitted successfully', '#4BB543', 8000);
				this.reservationService.sharedData.showPaymentSuccess = false;
				this.storageService.store({ showPaymentSuccess: false });
			}
		}
	}

	goToPaymentPage() {
		this.router.navigate(['payment']);
	}

	saveReservationDetailsObject(data) {
		this.reservationDetails = data;
		if (this.reservationService.sharedData === null) {
			this.reservationService.sharedData = {};
		}
		this.reservationService.sharedData.reservationContext = data;
		let jDate = data.Reservation.PartyDate.split('T')[0];
		jDate = jDate.split('-');
		const localData = this.storageService.fetch();
		const endtime = data.Reservation?.TimeSlotEnd !== null ? data.Reservation.TimeSlotEnd : localData?.time?.end;
		const json = {
			adults: data.Reservation.Adults,
			kids: data.Reservation.Children,
			time: {
				start: data.Reservation.TimeSlot,
				end: endtime
			},
			date: {
				year: jDate[0],
				month: jDate[1],
				day: jDate[2]
			},
			store: {
				address: data.Store.Address,
				city: data.Store.City,
				state: data.Store.State,
				zip: data.Store.ZipCode,
				phone: this.formatPhone(data.Store.PhoneNumber),
				hasPartyRooms: data.Store.parameters.HasPartyRooms || false
			},
			storeRewards: data.Store.parameters.RewardList || [],
			packageId: data.PartyType.ID,
			partyTypeTitle: data.PartyType.PartyTypeTitle,

			booking: {
				deposit: data.Store.parameters.maximumDeposit,
				maxDate: data.Store.parameters.Latest,
				minDate: data.Store.parameters.Earliest,
				minKids: data.Store.parameters.Children,
				minAdults: data.Store.parameters.Adults,
				store: data.Store.ID,
				type: data.Reservation.ReservationTypeID,
				promotions: data.Store.parameters.PromotionList,
				AdditionalDepositPartyRoom: data.Store.parameters.AdditionalDepositPartyRoom
			},
			existingReservationId: data.Reservation.ID
		};

		this.reservationService.sharedData.upsell = 0;
		this.reservationService.sharedData = Object.assign(this.reservationService.sharedData, { ...json });
		this.utilsService.changingPopUpMessagesforVisit(json.booking.type);
	}

	formatPhone(phone: number) {
		let myPhone = phone.toString();
		myPhone = '(' + myPhone.slice(0, 3) + ') ' + myPhone.slice(3, 6) + '-' + myPhone.slice(6, 10);
		return myPhone;
	}

	displayUnableToFindReservationAlert() {
		this.showAlert = true;
		this.applicationMessage = 'Unable to find reservation';
		this.alertColor = '#cc0000';
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '#4BB543';
		}, 8000);
	}

	incrementOptionalItemCount(optionalItem: OptionalItemI) {
		optionalItem.Quantity += 1;
		this.unsavedItem = true;
	}
	closedUpsell() {

		this.gtmService.pushEvent({
			event: 'upgradePartyButtons',
			upgradeParty: 'No'
		});

		this.gtmService.pushEvent({
			event: 'reservationDetails',
			location: this.reservationDetails.Reservation.StoreID,
			OrderID: this.reservationDetails.Reservation.ID,
			price: this.reservationDetails.Reservation.TotalPrice,
			order_code: this.reservationDetails.Reservation.ConfirmationID,
			sku: this.reservationDetails.Reservation.PartyTypeID,
			package: this.reservationDetails.PartyType.PartyTypeTitle,
			cecSession: this.reservationDetails.Reservation.InvoiceNumber,
			children: this.reservationDetails.Reservation.Children,
			adults: this.reservationDetails.Reservation.Adults
		});
	}

	showDesc(event) {
		const $this = $(event.currentTarget);
		$this.parent()
			.find('.item-desc__wrapper')
			.addClass('show');
	}

	hideDesc(event) {
		const $this = $(event.currentTarget);
		$this.parent().removeClass('show');
	}

	private setOnlineInvitationLink() {
		this.sideMenu.sendInviteLink = `${this.sfPunchbowlBaseUrl}?street=${this.reservationDetails.Store.Address}
    &city=${this.reservationDetails.Store.City}&state=${this.reservationDetails.Store.State}&zip=${this.reservationDetails.Store.ZipCode}
    &phone=${this.reservationDetails.Store.PhoneNumber}`;
	}

	cancelReservationEvent(event) {
		const localData = this.storageService.fetch();
		this.reservationDetails = localData?.reservationContext;
		this.validateRefundDate();
		if (event) {
			this.showCancelModal = true;
			this.noBtnSelected = false;
			this.hideYesNoBtn = false;
			this.refundComplete = false;
		}
	}

	configureCategoryOptions() {
		if (this.filterCategories.length > 0) {
			for (let i = 0; i < this.filterCategories.length; i++) {
				if (this.filterCategories[i] && this.filterCategories[i].categoryTitle) {
					const uniqueFilterCategoryOptions = this.filterUniqueArray(
						this.reservationDetails.PartyType.OptionalItemList.filter(item =>
							item.PromotionCategoryGroupTitle == this.filterCategories[i].categoryTitle && item.ItemShowOnPage === true)
							.map(item => item.ItemGroupName)
					);

					const categoryOptions = [];
					for (let i = 0; i < uniqueFilterCategoryOptions.length; i++) {
						categoryOptions.push({
							name: uniqueFilterCategoryOptions[i],
							value: uniqueFilterCategoryOptions[i]
						});
					}

					this.filterCategories[i].categoryOptions = categoryOptions;
				}
			}
		}
	}

	getSlideConfig(category) {
		const newSlideConfig = this.slideConfig;
		newSlideConfig.slidesToShow = category.length < 3 ? category.length : 3;
		return newSlideConfig;
	}

	saveReservationToLocalStorage() {
		this.storageService.store(this.reservationService.sharedData);
		this.menuFlag = true;
		if (this.sideMenu.page === 3 && this.sideMenu.localStorageExists === false) {
			this.sideMenu.localStorageExists = true;
			this.sideMenu.loadSummaryInfo(this.reservationService.sharedData);
			this.sideMenu.skipPromoValidation = false;
		} else {
			this.sideMenu.loadSummaryInfo(this.reservationService.sharedData);
		}
	}

	compareOptionalItemsByDisplayOrder(first, second) {
		if (first.DisplayOrder < second.DisplayOrder) {
			return -1;
		}
		if (first.DisplayOrder > second.DisplayOrder) {
			return 1;
		}
		return 0;
	}

	comparePromotionCategoryGroup(first, second) {
		if (first.displayOrder < second.displayOrder) {
			return -1;
		}
		if (first.displayOrder > second.displayOrder) {
			return 1;
		}
		return 0;
	}

	displayAlertWithMessage(message: string, color: string, timeout) {
		this.showAlert = true;
		this.applicationMessage = message;
		this.alertColor = color;
		setTimeout(() => {
			this.showAlert = false;
			this.alertColor = '';
		}, timeout);
	}

	filterUniqueArray = arrArg =>
		arrArg.filter((elem, pos, arr) => arr.indexOf(elem) == pos)

	filterUniqueArrayByProperty(arrArg, prop): any[] {
		return arrArg.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
		});
	}

	getFilterCategory(promotionCategoryGroupTitle: string) {
		return this.filterCategories.find(x => x.categoryTitle === promotionCategoryGroupTitle);
	}


	hasNoButton() {
		return !this.isCancelled && !this.hideYesNoBtn;
	}

	noButtonClickHandler() {
		this.noBtnSelected = true;
		this.hideYesNoBtn = true;
	}

	onPopupClose() {
		this.showCancelModal = false;
		setTimeout(() => {
			this.noBtnSelected = false;
			this.hideYesNoBtn = false;
		}, 1000);
	}
}
