import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreDetailsI } from '../models';
import { RESERVATION_API_URL } from '../tokens';

@Injectable({
	providedIn: 'root'
})
export class StoreApiService {

	constructor(@Inject(RESERVATION_API_URL) private reservationApiUrl: string, private http: HttpClient) { }

	getStoreDetails(storeId: string, resType: string): Observable<StoreDetailsI> {
		return this.http.get<StoreDetailsI>(this.reservationApiUrl + '/Api/Cec/Store/Details/' + storeId + '/' + resType);
	}

}
