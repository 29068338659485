import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FREEDOMPAY_API_URL, RESERVATION_API_URL } from 'src/app/tokens';

@Injectable({
	providedIn: 'root',
})
export class FreedompayService {
	validatePayment$: Subject<any>;
	errorHandler$: Subject<any>;
	paymentError$: Subject<boolean>;
	formValidityHandler$: Subject<any>;
	constructor(
		@Inject(RESERVATION_API_URL) private baseApiUrl: string,
		@Inject(FREEDOMPAY_API_URL) private freedompayApiUrl: string,
		private http: HttpClient
	) {
		this.validatePayment$ = new Subject();
		this.errorHandler$ = new Subject();
		this.paymentError$ = new Subject();
		this.formValidityHandler$ = new BehaviorSubject({});
	}

	getIframe(payload: any) {
		const postheaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		return this.http.post(
			`${this.freedompayApiUrl}/FreedomPayAuthorizeIframe`,
			payload,
			{ headers: postheaders }
		);
	}

	getAttributes({ appId, storeId }: any) {
		return this.http.get(
			`${this.freedompayApiUrl}/GatewayAttributes/${appId}/${storeId}`
		);
	}

	savePayment(payload: any) {
		const postheaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		return this.http.post(
			`${this.freedompayApiUrl}/FreedomPayPurchase`,
			payload,
			{ headers: postheaders }
		);
	}

	saveDepositDetails(payload: any, storeType: number) {
		const postheaders = new HttpHeaders({
			'Content-Type': 'application/json',
		});
		return this.http.post(
			`${this.baseApiUrl}/api/cec/booking/SaveDepositDetails?storeType=${storeType}`,
			payload,
			{ headers: postheaders }
		);
	}
}
