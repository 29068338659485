import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  PPPflag: boolean;

  constructor() {
    this.PPPflag = environment.context === 'PPP';
  }

}
