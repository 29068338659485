import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/constants/constants';
import { PartyTypeI } from 'src/app/models';
import { ApplicationData } from 'src/app/models/application-data';
import { GTMService } from 'src/app/services/gtm.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utilities } from 'src/app/services/utilities';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { WebString } from '../../constants/string';
import { Alert } from '../../models/alerts';
import { SaveReservationDto } from '../../models/save-reservation-dto';
import { BookingApiService } from '../../services/booking-api.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MessageComponent } from '../message/message.component';
import { PromoService } from 'src/app/services/promo.service';

@Component({
	selector: 'app-change-package',
	templateUrl: './change-package.component.html',
	styleUrls: ['./change-package.component.scss']
})
export class ChangePackageComponent implements OnInit {

	@Input() page: number;
	@Output() updatedLocal = new EventEmitter<boolean>();
	@ViewChild(MessageComponent)

	alertMessage = new Alert();
	assets = environment.assetRoot;
	className = '';
	currentAccordianActiveContent: number;
	currentTabActiveContent: number;
	loadingTempBooking = false;
	message: MessageComponent;
	modalStyle = '';
	reservationUpdate = 0;
	tabs: any[];
	showLoader = false;


	constructor(private storageService: StorageService, private gtmService: GTMService, private utilsService: UtilsService,
		private dialog: MatDialog, private reservationService: ReservationService, private bookingApiService: BookingApiService, private promoservice: PromoService) { }

	ngOnInit() {
		this.modalStyle = 'open';
		const data: ApplicationData = this.storageService.fetch();
		this.loadingTempBooking = data && !data.packageId && data.tempBookingInfo && Object.keys(data.tempBookingInfo).length > 0;
		this.loadInfo();
	}

	loadInfo() {
		this.tabs = [];
		const data: ApplicationData = this.storageService.fetch();
		const depositAmount = !data?.booking ? 0 : this.utilsService.calculateDepositAmount(parseInt(data.booking.store, 10), +data.booking.type, data.booking.deposit, data.booking.AdditionalDepositPartyRoom, data.kids, data.adults);

		if (data && data.hasOwnProperty('packageId') && data.hasOwnProperty('bookingInfo')) {
			const currentId = data.packageId;
			data.bookingInfo.PartyTypes.forEach((partytype, index) => {
				const partyTitles = partytype.DisplayTypeTitle;
				if (currentId === partytype.ID) {
					this.className = this.utilsService.updateColor(partyTitles);
					this.currentTabActiveContent = index;
					this.currentAccordianActiveContent = index;
					const json = {
						number: index,
						name: partytype.DisplayTypeTitle,
						style: 'active',
						partyItem: partytype.PartyItemList,
						price: partytype.Price,
						id: partytype.ID,
						className: this.className,
						bookString: 'Already Selected',
						buttonDisable: true,
						IsVipPartyType: partytype.IsVipPartyType

					};
					this.tabs.push(json);
				} else {
					const json = {
						number: index,
						name: partytype.DisplayTypeTitle,
						style: '',
						partyItem: partytype.PartyItemList,
						price: partytype.Price,
						id: partytype.ID,
						className: this.utilsService.updateColor(partyTitles),
						bookString: partytype.PriceAsPer === Constants.PriceAsPer.PartyType ? 'Book this package for $' + partytype.Price + ' includes ' + partytype.TotalGuest + ' guests' : partytype.Price !== 0 ? 'Book this package for $' + partytype.Price + ' per child' : 'Reserve tables only with $' + depositAmount + ' deposit',
						buttonDisable: false,
						IsVipPartyType: partytype.IsVipPartyType
					};
					this.tabs.push(json);
				}
			});
		} else if (data && !data.packageId && this.loadingTempBooking) {
			const tempBookingInfo = data.tempBookingInfo.bookingInfo;
			this.currentTabActiveContent = 0;
			this.currentAccordianActiveContent = 0;
			tempBookingInfo.PartyTypes.forEach((partytype, index) => {
				const partyTitles = partytype.DisplayTypeTitle;
				const json = {
					number: index,
					name: partytype.DisplayTypeTitle,
					style: index === 0 ? 'active' : '',
					partyItem: partytype.PartyItemList,
					price: partytype.Price,
					id: partytype.ID,
					className: this.utilsService.updateColor(partyTitles),
					bookString: partytype.PriceAsPer === Constants.PriceAsPer.PartyType
						? 'Book this package for $' + partytype.Price + ' includes ' + partytype.TotalGuest + ' guests'
						: partytype.Price !== 0 ? 'Book this package for $' + partytype.Price + ' per child' : 'Reserve tables only with $' + depositAmount + ' deposit',
					buttonDisable: false,
					IsVipPartyType: partytype.IsVipPartyType
				};

				if (index === 0) {
					this.className = this.utilsService.updateColor(partyTitles);
				}

				this.tabs.push(json);
			});
		}
	}

	doTabsAction(tab, index) {
		tab.style = 'active';
		this.currentTabActiveContent = index;
		this.className = tab.className;
		for (let t = 0; t < this.tabs.length; t++) {
			if (t !== index) {
				this.tabs[t]['style'] = '';
			}
		}
	}

	closeModal() {
		this.alertMessage.show = false;
		this.modalStyle = '';
		if (this.loadingTempBooking) {
			const data: ApplicationData = this.storageService.fetch();
			const tBookingInfo = data.tempBookingInfo;
			const revertData = {
				tempBookingInfo: undefined, packageId: tBookingInfo.oldPackageId, partyTypeTitle: tBookingInfo.oldPartyTypeTitle,
				mytimeslot: tBookingInfo.oldTimeSlot,
				myrewards: tBookingInfo.oldmyrewards, isVipParty: tBookingInfo.oldisVipParty
			};
			this.storageService.store(revertData);
		}
		this.loadingTempBooking = false;
		this.updatedLocal.emit(true);
	}

	private openConfirmDialog(message: string, yesButton: string, noButton: string) {
		return this.dialog.open(ConfirmationDialogComponent, {
			data: {
				message: message,
				buttonText: {
					ok: yesButton,
					cancel: noButton
				}
			}
		});
	}

	changePackage(id: number) {

		const data: ApplicationData = this.storageService.fetch();
		const partyType = data?.bookingInfo?.PartyTypes?.find(type => type.ID === id);
		if (partyType?.DisplayTypeTitle === 'Do It Myself' && data?.reservationContext?.Reservation?.ConfirmationID) {
			const message = 'Thanks for partying with us! It looks like you would like to change your celebration to a "Do It Yourself" party. Please note that any "add on" options you previously added to your booking will be removed from your reservation; however, these options are available for order when you arrive for your party.';
			const dialogRef = this.openConfirmDialog(message, 'Ok', 'Cancel');
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.selectPackage(id);
				} else {
					return false;
				}
			});
		} else {
			this.selectPackage(id);
		}
		this.promoservice.clearPromo$.next(true);
		this.reservationService.sharedData = this.storageService.fetch();
	}

	selectPackage(id: number) {
		let partyTypePricing = null;
		const data: ApplicationData = this.storageService.fetch();
		const partyType: PartyTypeI = data?.bookingInfo?.PartyTypes?.find(x => x.ID === id);
		const storeId = data.reservationContext?.Store.ID ?? 0;

		if (!!partyType) {
			const kids = data.tempBookingInfo && data.tempBookingInfo.kids ? data.tempBookingInfo.kids : data.kids;
			const adults = data.tempBookingInfo && data.tempBookingInfo.adults ? data.tempBookingInfo.adults : data.adults;
			if (partyType.PriceAsPer === <number>Constants.PriceAsPer.PartyType) {
				partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, parseInt(kids), parseInt(adults), partyType);
			} else {
				const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, parseInt(kids), parseInt(adults), partyType);
				if (packageValidation) {
					return this.showErrorPopup(WebString.ERROR, packageValidation);
				} else {
					this.selectPartyType(id);
				}
			}
		}

		if (partyTypePricing) {
			if (typeof partyTypePricing === 'string') {
				return this.showErrorPopup(WebString.ERROR, partyTypePricing);
			}
			if (partyTypePricing.flag) {
				this.selectPartyType(id);
			} else {
				partyTypePricing.dialogRef.afterClosed().subscribe(dialogResult => {
					if (dialogResult) {
						if (this.loadingTempBooking) {
							const localStorage: ApplicationData = this.storageService.fetch();
							localStorage.tempBookingInfo.kids = partyTypePricing.tempKids;
							localStorage.tempBookingInfo.adults = partyTypePricing.tempAdults;
							this.storageService.store(localStorage);
						} else {
							this.storageService.store({
								kids: partyTypePricing.tempKids,
								adults: partyTypePricing.tempAdults
							});
						}
						this.selectPartyType(id);
					}
				});
			}
		}

	}

	private showErrorPopup(errorType: string, errorMsg: string): void {
		this.alertMessage.show = true;
		this.alertMessage.className = errorType;
		this.alertMessage.message = errorMsg;
	}

	private requestReservation(data: any, id: number, existingResId: any, reservationId: any) {
		this.showLoader = true;
		const selectedDate = Utilities.formatSimpleDate(data.date);
		this.bookingApiService.bookPackage(data.adults, data.kids, selectedDate, data.time.start, data.booking.store, data.booking.type, reservationId, existingResId, id.toString()).subscribe((response: any) => {
			const res = response;
			if (res.Message.IsOk) {
				const localData: ApplicationData = this.storageService.fetch();
				if (localData.reservationContext) {
					const promoReward = localData.reservationContext.Reservation.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
					if (promoReward) {
						res.Reservation.ReservationRewards.push(promoReward);
					}
				}
				const storeData = {
					reservationContext: res,
					date: data.date,
					kids: data.kids,
					time: data.time,
					adults: data.adults,
					bookingInfo: data.bookingInfo
				};
				this.storageService.store(storeData);
				const storeData2 = {
					reservationId: res.Reservation.TempBookingSeatID,
					packageId: id,
					partyTypeTitle: res.PartyType.PartyTypeTitle
				};
				this.storageService.store(storeData2);

				this.reservationService.sharedData = this.storageService.fetch();

				if (this.page === 2) {
					this.gtmService.pushBirthdayPartySteps(3, undefined);
				}
				if (this.reservationUpdate === 1) {
					this.updateReservation(storeData, id);
				} else {
					this.alertMessage.show = false;
					this.updatedLocal.emit(true);
				}
			} else {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = res.Message.Error;
			}
		},
			error => {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = error.message;
			}, () => {
				this.showLoader = this.reservationUpdate === 1;
			});
	}

	private updateReservation(data: any, id: number) {
		this.bookingApiService.updateReservation(data.reservationContext).subscribe((response: SaveReservationDto) => {
			this.alertMessage.show = false;
			const res = response;
			if (res.Message.IsOk) {
				data.reservationContext = {};
				const storeData = {
					reservationContext: res,
					packageId: id,
					packageType: res.PartyType.DisplayTypeTitle,
					requestedPartyType: id,
					date: data.date,
					kids: data.kids,
					time: data.time,
					adults: data.adults,
					bookingInfo: data.bookingInfo
				};
				this.storageService.store(storeData);
				const storeData2 = {
					reservationId: res.Reservation.TempBookingSeatID,
					packageId: id,
					partyTypeTitle: res.PartyType.PartyTypeTitle
				};
				this.storageService.store(storeData2);
				this.updatedLocal.emit(true);
			} else {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = res.Message.Error;
				this.updatedLocal.emit(false);
			}
		}, error => {
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.ERROR;
			this.alertMessage.message = error.message;
			this.updatedLocal.emit(false);
		}, () => {
			this.showLoader = false;
		});
	}

	doAccordianAction(item) {
		this.className = this.utilsService.updateColor(item.name);
		this.currentAccordianActiveContent = item.number;
	}

	private selectPartyType(id: number) {
		const data: ApplicationData = this.storageService.fetch();
		this.alertMessage.show = true;
		this.alertMessage.className = WebString.INFO;
		this.alertMessage.message = WebString.SELECTPACKAGE;

		data.reservationContext.PartyType.ID = id;
		data.reservationContext.Reservation.PartyTypeID = id;

		const existingResId = data.existingReservationId || 0;
		const reservationId = data.reservationId || 0;
		const tempBookingInfo = data.tempBookingInfo;

		if (this.loadingTempBooking) {
			data.date = tempBookingInfo.date;
			data.kids = tempBookingInfo.kids;
			data.adults = tempBookingInfo.adults;
			data.time = tempBookingInfo.time;
			data.bookingInfo = tempBookingInfo.bookingInfo;
			this.storageService.store({ tempBookingInfo: undefined });
		}

		if (existingResId > 0) {
			this.reservationUpdate = 1;
		}
		this.requestReservation(data, id, existingResId, reservationId);
	}
}
