import { Component } from '@angular/core';
import { LoggingService } from './services/logging.service';

declare global {
	interface Window {
		firstdata: any;
	}
}

window['cookieAccepted'] = false;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'ppp-booking';

	constructor(private logger: LoggingService) { }


	private checkCookie() {
		if (window['cookieAccepted'] === true) {
			if (window.hasOwnProperty('onAcceptClick')) {
				window['onAcceptClick'].call();
			}
		}

		if (window['cookieAccepted'] === false) {
			window['cookieAccepted'] = true;
		}
	}

	onActivate(event) {
		this.checkCookie();
		window.scroll(0, 0);
	}
}
