import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { Constants } from 'src/app/constants/constants';
import { WebString } from 'src/app/constants/string';
import { AvailableTimeSlotI, ReservationTimeSlot } from 'src/app/models';
import { Alert } from 'src/app/models/alerts';
import { ApplicationData } from 'src/app/models/application-data';
import { PackageInfoService } from 'src/app/services/package.service';
import { ReservationApiService } from 'src/app/services/reservation-api.service';
import { StorageService } from 'src/app/services/storage.service';
import { Utilities } from 'src/app/services/utilities';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';
import { SaveReservationDto } from '../../models/save-reservation-dto';
import { BookingApiService } from '../../services/booking-api.service';
import { MessageComponent } from '../message/message.component';
import { PromoService } from 'src/app/services/promo.service';

@Component({
	selector: 'app-edit-info',
	templateUrl: './edit-info.component.html',
	styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent implements OnInit {

	@Input() page: number;
	@Output() updatedInfo = new EventEmitter<boolean>();
	@Output() closeModalHandler = new EventEmitter<boolean>();
	@ViewChild(MessageComponent) message: MessageComponent;

	adultsValue: number;
	alertMessage = new Alert();
	assets = environment.assetRoot;
	availableSlot: AvailableTimeSlotI[];
	dateofParty: string;
	eventDate: string;
	formattedDate: any;
	isDate: boolean;
	isDateSelected: boolean;
	isEvent = false;
	isLoading = false;
	isModelOpen = false;
	kidsValue: number;
	localData: ApplicationData;
	minAdults: number[] = [];
	minKids: number[] = [];
	mydate: any;
	myDatePickerOptions: IMyDpOptions;
	myrewardsList: any;
	selectedPartyTimeIndex: any;
	partyRoom: boolean;
	partytime: any = {};
	reservationType: number;
	reservationUpdate = 0;
	resType: number;
	storeArray: any[];
	tempAdultValue: number;
	tempAvailableSlot: any;
	tempAvailableSlots: any[];
	tempDate: any;
	tempIsVipParty = false;
	tempKidsValue: number;
	tempMyDate: any;
	tempReservationTimeSlots: ReservationTimeSlot[];
	tempResType: any;
	temprewardsList: any;
	temprewardsListonDate: any;
	timeofParty: string;
	timeSlot: any = {};
	timeSlotOptionValue: any = -1;
	timeToggle = false;
	hasDateChanged = false;

	constructor(private storageServie: StorageService, private packageInfo: PackageInfoService, private bookingApiService: BookingApiService,
		private entryService: ReservationApiService, private utilsService: UtilsService, private route: ActivatedRoute, private router: Router, private promoservice: PromoService) {

		this.localData = <ApplicationData>this.storageServie.fetch();

		if (this.localData && Object.keys(this.localData).length > 0) {
			this.kidsValue = this.localData.kids;
			this.adultsValue = this.localData.adults;
			this.tempResType = this.localData.booking.type;
			this.tempDate = this.localData.date;
			this.mydate = {
				date: this.tempDate,
			};
			this.availableSlot = this.localData.mytimeslot;
			if (this.availableSlot) {
				const index = this.availableSlot.findIndex(x => x.start === this.localData.time?.start);
				this.selectedPartyTimeIndex = index < 0 ? this.selectedPartyTimeIndex : index;
			}
			if (this.localData.hasOwnProperty('booking')) {
				this.resType = this.localData.booking.type;
				this.dateofParty = this.resType === Constants.ReservationTypes.VISITEVENT ? 'Date of Visit*' : 'Date of Party*';
				this.timeofParty = this.resType === Constants.ReservationTypes.VISITEVENT ? 'Time of Visit*' : 'Time of Party*';
				const earliest = moment().add(this.localData.booking.minDate - 1, 'days');
				const endDate = moment().add(this.localData.booking.maxDate, 'days');
				this.myDatePickerOptions = {
					openSelectorOnInputClick: true,
					editableDateField: false,
					showTodayBtn: false,
					dateFormat: 'mm-dd-yyyy',
					firstDayOfWeek: 'su',
					sunHighlight: true,
					satHighlight: true,
					disableUntil: { year: earliest.year(), month: earliest.month() + 1, day: earliest.date() },
					disableSince: { year: endDate.year(), month: endDate.month() + 1, day: endDate.date() + 1 }
				};
			}
		}
		this.filteredStoreArrayList();

		this.isDateSelected = true;
		this.isDate = false;
		this.formattedDate = {};
	}

	ngOnInit() {
		if (!this.localData || Object.keys(this.localData).length === 0) {
			return;
		}

		for (let i = this.localData.booking.minKids; i < 200; i++) {
			this.minKids.push(i);
		}

		for (let i = this.localData.booking.minAdults; i < 200; i++) {
			this.minAdults.push(i);
		}
		this.setPartyRoomType();

		this.formattedDate = this.localData.date;
		this.availableSlot = this.localData.mytimeslot;
		if (this.availableSlot) {
			const index = this.availableSlot?.findIndex(x => x.start === this.localData.time.start);
			this.selectedPartyTimeIndex = index < 0 ? this.selectedPartyTimeIndex : index;
		} else {
			const existingResId = this.localData.existingReservationId || 0;
			const reservationId = this.localData.reservationId || 0;
			this.availableSlot = [];
			if (this.eventDate === undefined) {
				this.eventDate = Utilities.formatSimpleDate(this.localData.date);
			}
			this.isLoading = true;
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.INFO;
			this.alertMessage.message = WebString.TIMESLOT;
			this.entryService.getTimeSlot(this.eventDate, this.kidsValue, this.adultsValue, this.localData.booking.store, this.localData.booking.type, reservationId, existingResId).subscribe((response: any) => {
				this.timeSlot = response;
				this.tempReservationTimeSlots = response.AvailabilityDates[0].ReservationTimeSlots;
				this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
				const index = this.availableSlot?.findIndex(x => x.start === this.localData.time.start);
				this.selectedPartyTimeIndex = index < 0 ? this.selectedPartyTimeIndex : index;
				this.isLoading = false;
				this.alertMessage.show = false;
				this.message.clear();
			},
				error => {
					this.isLoading = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
		}
		// }

		this.openModal();
	}

	setPartyRoomType() {
		// this code bracket referancing can't make to Object referancing because of type conflicts.
		this.partyRoom = this.localData.booking && this.localData.booking.type === Constants.ReservationTypes.PARTYROOM;
	}

	getAllTimeSlot(date: string, kids: number, adults: number) {
		$('.time-slots-list').removeClass('time-slots-list-colors');
		if (this.availableSlot.length > 0) {
			this.availableSlot = [];
		}

		this.isDate = true;
		const totalAttendees = kids + adults;
		this.availableSlot = this.utilsService.getEnreachedTimeslot(this.timeSlot.AvailabilityDates[0].ReservationTimeSlots, totalAttendees);
		const hasAvailableSlot = this.availableSlot.findIndex(x => x.available) >= 0;

		if (hasAvailableSlot) {
			this.timeToggle = true;
		}
		if (this.timeToggle === false) {
			$('.time-slots-list').addClass('time-slots-list-colors');
		}

		this.storageServie.store({ mytimeslot: this.availableSlot });
	}

	onDateChanged(event: IMyDateModel) {
		this.selectedPartyTimeIndex = this.timeofParty;
		$('.time-slots-list').addClass('time-slots-list-colors');
		this.isDateSelected = true;
		this.hasDateChanged = true;
		this.formattedDate = event.date;
		const localData = this.storageServie.fetch();
		this.isEvent = true;
		if (this.kidsValue > 0 && this.adultsValue > 0 && event.date.day && event.date.month && event.date.year) {
			this.isLoading = true;
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.INFO;
			this.alertMessage.message = WebString.TIMESLOT;
			this.availableSlot = [];
			const existingResId = localData.existingReservationId || 0;
			const reservationId = localData.reservationId || 0;
			const newDate = Utilities.formatSimpleDate(event.date);

			this.alertMessage = new Alert();
			this.entryService.getTimeSlot(newDate, this.kidsValue, this.adultsValue, localData.booking.store, this.reservationType, reservationId, existingResId).subscribe((response: any) => {
				this.timeSlot = response;
				this.tempReservationTimeSlots = response.AvailabilityDates[0].ReservationTimeSlots;
				this.eventDate = newDate;
				this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
				if (!this.availableSlot.some(x => x.available)) {
					this.selectedPartyTimeIndex = this.timeofParty;
					$('.time-slots-list').addClass('time-slots-list-colors');
					this.alertMessage.className = WebString.WARNING;
					this.alertMessage.message = WebString.NOTIMESLOT;
					this.alertMessage.show = true;
					this.isDateSelected = false;
					this.mydate = null;
					this.eventDate = null;
					this.partytime = null;
					this.availableSlot = [];
					this.tempAvailableSlot = [];
					this.message.clear();
				}
				const index = this.availableSlot?.findIndex(x => x.start === this.partytime?.eventTime?.start);
				if (index >= 0) {
					this.selectedPartyTimeIndex = index;
				}
				this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(newDate, this.kidsValue);
				this.isLoading = false;
			},
				error => {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
					this.isLoading = false;
				});

		} else {
			if (!event.date.day || !event.date.month || !event.date.year) {
				this.availableSlot = [];
			}
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.ERROR;
			this.alertMessage.message = WebString.SELECT_PARTY_DATE;
			this.message.clear();
		}
	}

	kids(value: string) {
		this.kidsValue = parseInt(value);
		this.isEvent = true;
		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
		} else {
			this.onKidAdultUpdate(false, 'K');
		}
	}

	adults(value: string) {
		this.adultsValue = parseInt(value);
		this.isEvent = true;
		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
		} else {
			this.onKidAdultUpdate(false, 'A');
		}
	}

	partyTime(time: number) {
		this.alertMessage.show = false;
		this.isEvent = true;
		this.timeSlotOptionValue = time;
		this.partytime = {
			eventTime: {
				start: this.availableSlot[time].start,
				end: this.availableSlot[time].end,
			}
		};

		const data = {
			isVipParty: this.availableSlot[time].IsVipParty,
		};
		this.myrewardsList = this.utilsService.getTimeslotRewards(time);
		this.storageServie.store(data);
	}

	getBookingInfo() {
		const selectedTimeSlot = this.partytime.eventTime;
		return this.tempReservationTimeSlots.find(function (reservationTimeSlot: any) {
			return reservationTimeSlot.EndTimeSlot === selectedTimeSlot.end && reservationTimeSlot.TimeSlot === selectedTimeSlot.start;
		});
	}

	storeLocal(emitFlag = false) {
		this.partytime.bookingInfo = this.getBookingInfo();
		const data: any = {
			kids: this.kidsValue,
			adults: this.adultsValue,
			time: this.partytime.eventTime,
			bookingInfo: this.partytime.bookingInfo,
			date: this.formattedDate
		};

		data.booking = Object.assign(this.localData.booking, { type: this.reservationType });
		this.storageServie.store(data);
		if (!emitFlag) {
			this.updatedInfo.emit(true);
		}
	}

	update() {

		// hack: this is done to fix those times when edit info is loaded before the parent page has finished saving to local storage.
		if (!this.localData.bookingInfo) {
			this.localData = this.storageServie.fetch();
		}

		if (this.mydate === undefined || this.mydate === null) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_DATE);
			return;
		} else if (this.selectedPartyTimeIndex === null || this.selectedPartyTimeIndex === undefined || this.selectedPartyTimeIndex === this.timeofParty) {
			this.showErrorPopup(WebString.ERROR, WebString.SELECT_PARTY_TIMESLOT);
			return;
		}

		if (!this.isEvent) {
			this.closeModal();
			this.isEvent = false;
			this.updatedInfo.emit(false);
			return;
		}

		if (this.eventDate === undefined) {
			this.eventDate = this.localData.date.year + '-' + this.localData.date.month + '-' + this.localData.date.day;
		}

		if (this.partytime.eventTime === undefined) {
			this.partytime = {
				eventTime: {
					start: this.localData.time.start,
					end: this.localData.time.end,
				}
			};
		}

		if (this.kidsValue && this.adultsValue && this.eventDate && this.partytime.eventTime) {
			const minConfig = this.timeSlotOptionValue === -1 ? this.localData.bookingInfo.MinConfig : this.availableSlot[this.timeSlotOptionValue].MinConfig;
			const totalGuests = this.kidsValue + this.adultsValue;
			const availableSeats = this.timeSlotOptionValue === -1 ? this.localData.bookingInfo.AvailableSeat : this.availableSlot[this.timeSlotOptionValue].seat;
			const maxGuest = this.timeSlotOptionValue === -1 ? this.localData.bookingInfo.MaxGuest : this.availableSlot[this.timeSlotOptionValue].MaxGuest;

			let partyTypeFlag = false;
			let partyTypePricing;

			if (maxGuest === 0 || totalGuests <= maxGuest) {
				if (this.kidsValue < minConfig) {
					this.showErrorPopup(WebString.ERROR, WebString.VIPERROR.replace('{0}', minConfig.toString()));
					return;
				}
			}

			// validation of min children happens if the package exist in the partytypes
			const isPackageExist = this.checkIfPackageExist();
			const storeId = this.localData.reservationContext?.Store?.ID ?? 0;

			if (isPackageExist && this.localData.bookingInfo.IsVipParty === this.availableSlot[this.timeSlotOptionValue].IsVipParty) {

				if (this.localData.reservationContext && this.localData.reservationContext.PartyType) {

					if (this.localData.reservationContext.PartyType.PriceAsPer === Constants.PriceAsPer.PartyType) {
						partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, this.kidsValue, this.adultsValue, this.localData.reservationContext.PartyType, availableSeats);
					} else {
						const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, this.kidsValue, this.adultsValue, this.localData.reservationContext.PartyType);
						if (packageValidation) {
							return this.showErrorPopup(WebString.ERROR, packageValidation);
						} else {
							partyTypeFlag = true;
						}
					}
				}

				if (maxGuest && totalGuests > maxGuest) {
					this.showErrorPopup(WebString.ERROR, WebString.TIMESLOT_MAX_GUEST);
					return;
				}

				if (partyTypeFlag) {
					this.selectPartyType();
				}

				if (partyTypePricing) {

					if (typeof partyTypePricing === 'string') {
						return this.showErrorPopup(WebString.ERROR, partyTypePricing);
					}

					if (partyTypePricing.flag) {
						this.selectPartyType();
					} else {
						partyTypePricing.dialogRef.afterClosed().subscribe(dialogResult => {
							if (dialogResult) {
								this.kidsValue = partyTypePricing.tempKids;
								this.adultsValue = partyTypePricing.tempAdults;
								const data = {
									kids: partyTypePricing.tempKids,
									adults: partyTypePricing.tempAdults
								};
								this.storageServie.store(data);
								this.selectPartyType();
							}
						});
					}
				}
			} else {
				this.selectPartyType();
			}
			this.promoservice.clearPromo$.next(true);
		}
	}

	updateReservation() {
		this.isLoading = true;
		const localStorage = this.storageServie.fetch();
		this.bookingApiService.updateReservation(localStorage.reservationContext).subscribe((response: SaveReservationDto) => {
			if (response.Message.IsOk) {
				const storeData = {
					reservationContext: response
				};
				this.storageServie.store(storeData);
				this.updatedInfo.emit(true);
				this.isLoading = false;
				this.alertMessage.show = false;
				this.packageInfo.setEditPartySubject(this.kidsValue);
				this.closeModal();
				if(this.page === 3 && this.hasDateChanged) {
					this.router.navigate(
						[], 
						{
						  relativeTo: this.route,
						  queryParams: { qDate: encodeURIComponent(btoa(Utilities.formatSimpleDate(localStorage.date, 'yyyy-mm-dd'))) },
						  queryParamsHandling: 'merge'
						});
				}
			} 
			if (response.Message.Error) {
				this.isLoading = false;
				this.closeModalHandler.emit(false);
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = response.Message.Error;
			}
		},
			error => {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = error.message;
			});
	}

	checkIfPackageExist() {
		const newBookingInfo = this.getBookingInfo();
		const localStorage = this.storageServie.fetch();
		const packageId = localStorage.packageId;
		for (let i = 0; i < newBookingInfo.PartyTypes.length; i++) {
			if (packageId === newBookingInfo.PartyTypes[i].ID) {
				this.storageServie.store({ packageId: newBookingInfo.PartyTypes[i].ID });
				return true;
			}
		}

		return false;
	}

	select_package() {
		if (!this.checkIfPackageExist()) {
			const currentData = this.storageServie.fetch();
			const tempData = {
				kids: this.kidsValue,
				adults: this.adultsValue,
				time: this.partytime.eventTime,
				bookingInfo: this.getBookingInfo(),
				date: this.formattedDate,
				showChangePackage: true,
				oldPartyTypeTitle: currentData.partyTypeTitle,
				oldPackageId: currentData.packageId,
				oldTimeSlot: this.tempAvailableSlots,
				oldreservationTimeSlots: this.tempReservationTimeSlots,
				oldmyrewards: this.temprewardsListonDate,
				oldisVipParty: this.tempIsVipParty,
			};
			this.storageServie.store({ tempBookingInfo: tempData, packageId: undefined });
			this.updatedInfo.emit(true);
			this.closeModal();
			return;
		}
		this.alertMessage.show = true;
		this.alertMessage.className = WebString.INFO;
		this.alertMessage.message = WebString.INFOUPDATE;
		this.isLoading = true;
		const date = this.eventDate;
		this.localData = this.storageServie.fetch();
		const existingResId = this.localData.existingReservationId || 0;
		const reservationId = this.localData.reservationId || 0;
		this.bookingApiService.bookPackage(this.adultsValue, this.kidsValue, date, this.partytime.eventTime.start,
			this.localData.booking.store, this.reservationType.toString(), reservationId, existingResId, this.localData.packageId.toString())
			.subscribe((response: any) => {
				const res = response;
				if (res.Message.IsOk) {
					// add promo reward if any and update res
					if (this.localData.reservationContext) {
						const promoReward = this.localData.reservationContext.Reservation.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
						if (promoReward) {
							res.Reservation.ReservationRewards.push(promoReward);
						}
					}
					delete this.localData.reservationContext;
					const storeData = {
						reservationContext: res
					};
					this.storageServie.store(storeData);
					this.storeLocal(this.reservationUpdate === 1);
					if (this.reservationUpdate === 1) {
						this.updateReservation();
					} else {
						this.isLoading = false;
						this.alertMessage.show = false;
						this.closeModal();
					}
				} else {
					this.isLoading = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = res.Message.Error;
				}
			},
				error => {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
	}

	closeModal() {
		this.isModelOpen = false;
	}

	closeModalOnX() {
		this.closeModal();
		this.isEvent = false;
		this.kidsValue = this.tempKidsValue;
		this.adultsValue = this.tempAdultValue;
		this.availableSlot = this.tempAvailableSlots;
		this.myrewardsList = this.temprewardsList;

		const index = this.availableSlot?.findIndex(x => x.start === this.tempAvailableSlot.start && x.end === this.tempAvailableSlot.end);
		this.selectedPartyTimeIndex = index < 0 ? this.selectedPartyTimeIndex : index;

		this.mydate = {
			date: moment(this.tempMyDate).format('MM-DD-YYYY'),
		};
		// available slots and reservation time slots needs to be reset in local storage
		const localData = this.storageServie.fetch();
		const data = {
			mytimeslot: this.availableSlot,
			myrewards: this.temprewardsListonDate,
			isVipParty: this.localData.bookingInfo.IsVipParty,
			booking: {
				...localData.booking,
				type: this.tempResType
			}
		};
		this.storageServie.store(data);
		this.updatedInfo.emit(false);
	}

	reInitializeObjects() {
		const data = this.storageServie.fetch();
		this.kidsValue = data.kids;
		this.adultsValue = data.adults;
		if (data?.time) {
			const index = this.tempAvailableSlots.findIndex(x => x.start === data.time.start && x.end === data.time.end);
			this.selectedPartyTimeIndex = index;
			this.timeSlotOptionValue = index;
		}
		this.availableSlot = data.mytimeslot;
		this.myrewardsList = data.bookingInfo.Rewards;
		if (data.reservationContext) {
			const dateValue = Utilities.formatSimpleDate(data.date);
			this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(dateValue, this.kidsValue);
			this.filteredStoreArrayList();
		}

		if (this.tempAvailableSlot?.length > 0) {
			this.tempIsVipParty = this.tempAvailableSlots[this.selectedPartyTimeIndex].IsVipParty;
		}

		// In change package scenario we need this
		if (this.timeSlot && this.timeSlot.AvailabilityDates && this.timeSlot.AvailabilityDates.length > 0) {
			this.timeSlot.AvailabilityDates[0].ReservationTimeSlots = data.reservationTimeSlots;
		}
		this.mydate = {
			date: Utilities.formatSimpleDate(data.date, 'mm-dd-yyyy')
		};
		const index = this.availableSlot?.findIndex(x => x.start === data.time.start && x.end === data.time.end);
		this.selectedPartyTimeIndex = index < 0 ? this.selectedPartyTimeIndex : index;
		this.eventDate = Utilities.formatSimpleDate(data.date);
		this.formattedDate = data.date;
		this.partytime.eventTime = data.time;
	}

	openModal() {
		this.isLoading = true;
		this.isModelOpen = true;
		this.localData = this.storageServie.fetch();
		this.setPartyRoomType();
		this.tempAdultValue = this.localData.adults;
		this.tempKidsValue = this.localData.kids;
		this.tempAvailableSlot = this.localData.time;
		this.tempAvailableSlots = this.localData.mytimeslot;
		this.tempReservationTimeSlots = this.localData.reservationTimeSlots;
		this.tempMyDate = Utilities.formatSimpleDate(this.localData.date);
		this.temprewardsList = this.localData.bookingInfo.Rewards;
		this.temprewardsListonDate = this.localData['myrewards'];
		this.partyRoom = this.localData.booking && this.localData.booking.type == Constants.ReservationTypes.PARTYROOM;
		this.reservationType = this.localData.booking.type || 1;
		this.reInitializeObjects();
		const tempBookingId = this.localData.reservationId || 0;
		const existingReservationId = this.localData.existingReservationId || 0;
		this.entryService.getTimeSlot(this.eventDate, this.localData.kids, this.localData.adults, this.localData?.booking?.store, this.localData?.booking?.type, tempBookingId, existingReservationId)
			.subscribe(
				res => {
					this.tempReservationTimeSlots = res.AvailabilityDates[0].ReservationTimeSlots;
					this.isLoading = false;
				},
				error => {
					this.isLoading = false;
				}
			);
	}

	changePartyRoomHandler(isPartyRoom: boolean) {
		const localData = this.storageServie.fetch();
		this.reservationType = isPartyRoom ? Constants.ReservationTypes.PARTYROOM : Constants.ReservationTypes.BIRTHDAYPARTY;
		if (localData.kids !== undefined && localData.adults !== undefined) {
			this.onKidAdultUpdate(false, null);
		}
		this.selectedPartyTimeIndex = 'Time of Party*';
	}

	showErrorPopup(errorType: string, errorMsg: string) {
		this.alertMessage.show = true;
		this.alertMessage.className = errorType;
		this.alertMessage.message = errorMsg;
		this.message.clear();
	}

	onKidAdultUpdate(isNavigation: boolean, fromEvent: string) {
		const localData = this.storageServie.fetch();
		if (this.eventDate === undefined) {
			this.eventDate = Utilities.formatSimpleDate(this.localData.date);
		}
		if (!this.partytime?.eventTime) {
			this.partytime = {
				eventTime: {
					start: this.localData.time.start,
					end: this.localData.time.end,
				}
			};
		}
		if (this.kidsValue > 0 && this.adultsValue > 0 && this.eventDate && this.partytime) {
			this.isLoading = true;
			if (fromEvent !== 'K' && fromEvent !== 'A') {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.INFO;
				this.alertMessage.message = WebString.INFOUPDATE;
			}
			this.availableSlot = [];
			const existingResId = localData.existingReservationId || 0;
			const reservationId = localData.reservationId || 0;

			this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(this.eventDate, this.kidsValue);
			if (this.selectedPartyTimeIndex && this.selectedPartyTimeIndex !== this.timeofParty) {
				this.myrewardsList = this.utilsService.getTimeslotRewards(this.selectedPartyTimeIndex);
			}
			this.entryService.getTimeSlot(this.eventDate, this.kidsValue, this.adultsValue,
				localData.booking.store, this.reservationType, reservationId, existingResId).subscribe((response: any) => {
					this.timeSlot = response;
					this.tempReservationTimeSlots = response.AvailabilityDates[0].ReservationTimeSlots;
					this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
					this.isLoading = false;
					this.alertMessage.show = false;
					const totalAttendees = this.kidsValue + this.adultsValue;
					if (!this.availableSlot.some(x => x.available)) {
						this.mydate = null;
						const errorMessage = this.reservationType === Constants.ReservationTypes.PARTYROOM ? WebString.PARTY_ROOM_NO_AVAILABLE_SEAT : WebString.SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT;
						this.showErrorPopup(WebString.WARNING, errorMessage);
						this.selectedPartyTimeIndex = this.timeofParty;
						this.availableSlot = [];
						this.eventDate = null;
						this.partytime = null;
						$('.time-slots-list').addClass('time-slots-list-colors');
						this.isDateSelected = false;
					} else {
						if (this.selectedPartyTimeIndex !== this.timeofParty && this.availableSlot !== null && this.availableSlot.length > 0
							&& !this.availableSlot[this.selectedPartyTimeIndex].available) {
							let errorMessage = '';
							if (this.availableSlot[this.selectedPartyTimeIndex]['seat'] < totalAttendees) {
								errorMessage = +this.reservationType === Constants.ReservationTypes.PARTYROOM ? WebString.PARTY_ROOM_NO_AVAILABLE_SEAT : WebString.SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT;
							} else {
								errorMessage = WebString.NO_TIME_SLOT;
							}
							this.showErrorPopup(WebString.WARNING, errorMessage);
							this.selectedPartyTimeIndex = this.timeofParty;
							this.isDateSelected = false;
						}
						if (isNavigation) {
							this.storeLocal();
							this.closeModal();
						}
					}
				},
					error => {
						this.isLoading = false;
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.ERROR;
						this.alertMessage.message = error.message;
					});

		}
	}

	filteredStoreArrayList() {
		let filteredStoreArray = [];
		if (this.localData && this.localData['myrewards'] !== null && this.localData['myrewards'] !== undefined) {
			for (const reward of this.localData['myrewards']) {
				for (const timeslot of reward['EligibleTimeSlots']) {
					if (this.selectedPartyTimeIndex !== undefined && this.selectedPartyTimeIndex !== null) {
						if (parseInt(this.selectedPartyTimeIndex.toString(), 10) + 1 == timeslot) {
							filteredStoreArray.push(reward);
						}
					}
				}
			}
		} else if (this.localData && this.localData.reservationContext) {
			filteredStoreArray = this.localData.reservationContext['Reservation']['ReservationRewards'];
			if (filteredStoreArray) {
				filteredStoreArray = filteredStoreArray.filter(({ RewardTypeID }) => RewardTypeID !== 3);
			}
		}
		this.myrewardsList = filteredStoreArray;
	}

	private selectPartyType() {
		if (this.isEvent) {
			if (this.kidsValue && this.adultsValue && this.eventDate && this.partytime.eventTime) {
				if (this.localData.existingReservationId && this.page !== 1) {
					this.reservationUpdate = 1;
					this.select_package();
				} else if (this.localData.reservationId && this.page !== 1) {
					this.select_package();
				} else {
					this.onKidAdultUpdate(true, 'U');
				}
			} else {
				this.showErrorPopup(WebString.ERROR, WebString.REQUIRED);
			}
		} else {
			this.closeModal();
		}
	}
}
