import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class StorageService {

	constructor(private logger: LoggingService) { }

	private readonly storageKey = 'reservation';

	store(data: any): void {
		try {
			const local = JSON.parse(localStorage.getItem(this.storageKey));
			if (local) {
				const finalObj = Object.assign({}, local, data);
				localStorage.setItem(this.storageKey, JSON.stringify(finalObj));
			} else {
				localStorage.setItem(this.storageKey, JSON.stringify(data));
			}

			if (!environment.production) {
				this.calcLocalStorageSize();
			}

		} catch (error) {
			const e = new Error('StorageService:Store: ' + error);
			this.logger.logError(e);
		}
	}

	fetch(key?: string): any {
		const local = JSON.parse(localStorage.getItem(this.storageKey));
		return !key
			? local
			: local[`${key}`];
	}

	clear(key?: string): void {

		if (!key) {
			localStorage.clear();
			return;
		}

		localStorage.removeItem(key);

	}

	private calcLocalStorageSize(): void {

		let storedTotal = 0;

		Object.keys(localStorage).forEach(k => {
			const x = localStorage.getItem(k);
			const total = x.length * 2 / 1024 / 1024;
			if (k === this.storageKey && total > 3) {
				this.logger.logInfo(`reservation local storage size: ${total.toFixed(4)}  MB`);
			}
			storedTotal += x.length;
		});

		const total = storedTotal * 2 / 1024 / 1024;
		if (total > 3) {
			const message = `total local storage size: ${total.toFixed(4)}  MB`;
			this.logger.logInfo(message);
		}
	}


}
