import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BookingRequestI } from '../models';
import { ReservationDetail } from '../models/reservation-detail';
import { SaveReservationDto } from '../models/save-reservation-dto';
import { RESERVATION_API_URL } from '../tokens';

@Injectable({ providedIn: 'root' })
export class BookingApiService {

	constructor(@Inject(RESERVATION_API_URL) private baseApiUrl: string, private http: HttpClient) { }

	updateReservation(reservation): Observable<SaveReservationDto> {
		const request: SaveReservationDto = Object.assign({}, reservation);
		request.storeId = reservation.Store.ID;
		request.partyTypeId = reservation.PartyType.ID;

		delete request.Message;
		delete request.PartyType;
		delete request.Store;
		delete request.Payments;

		return this.http.post<SaveReservationDto>(`${this.baseApiUrl}/api/cec/booking/Reservation/v2`, request).pipe(
			map((response) => {
				response.Store = reservation.Store;
				response.PartyType = reservation.PartyType;
				response.Payments = reservation.Payments;
				return response;
			})
		);
	}

	searchReservation(reservation): Observable<any> {
		return this.http.post(`${this.baseApiUrl}/api/cec/booking/ReservationSearch`, reservation);
	}

	bookPackage(adult: number, kids: number, date: string, Time: string, StroreId: string, ResType: string, TempId: number, ResId: number, partyId: string): Observable<BookingRequestI> {

		const body = {
			AdultCount: adult,
			ChildCount: kids,
			PartyDate: date,
			TimeSlot: Time,
			StoreId: StroreId,
			ReservationTypeId: ResType,
			TempBookingID: TempId,
			ReservationId: ResId,
			PartyTypeID: partyId
		};

		return this.http.post<BookingRequestI>(`${this.baseApiUrl}/api/cec/booking/Request`, body);
	}


	getReservationDetails(requestData: any): Observable<ReservationDetail> {
		return this.http.post<ReservationDetail>(`${this.baseApiUrl}/api/cec/booking/ReservationDetails`, requestData);
	}

	getReservationData(id: any): Observable<BookingRequestI> {
		return this.http.get<BookingRequestI>(this.baseApiUrl + '/api/cec/booking/ReservationData/' + id);
	}


}
