<div class="cec__module PPP-wrapper" id="reservationFinal">
  <div class="container">
    <div class="lookUp" [hidden]="showDetails">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="find__title">Find your Reservation</h3>
          <p>Enter Party Date and either your Confirmation # OR Email below to make changes to your existing
            reservation.
          </p>
        </div>
        <div class="col-sm-12" *ngIf="isFundraiserEvent === true">
          <div class="row">
            <!-- <div class="col-md-1"><img alt="alert" src="{{assetRoot}}/assets/alert_error_icon.gif" /></div> -->
            <div class="col-12">
              <p class="error-msg">
                <img alt="alert" src="{{assetRoot}}/assets/alert_error_icon.gif" /> We do not allow changes to a
                fundraising reservation online, please contact the Fundraising department at 888-232-4386.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="cec__form">
        <div class="loader-overlay" [ngClass]="{'show': isLoading === true}"></div>
        <div id="loader" class="loader" [ngClass]="{'show': isLoading === true}">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path fill="#702f8a"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
              <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                to="360 50 50" repeatCount="indefinite" />
            </path>
          </svg>
        </div>
        <form #f="ngForm" name="resLookup" id="resLookup" (ngSubmit)="resLookup(f)" validate>
          <div class="row">
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-5">
                  <label for="confNumber">Confirmation Number</label>
                  <input id="confNumber" name="confNumber" type="text" [(ngModel)]="model.confNumber">
                </div>
                <div class="col-sm-2">
                  <p class="text-center mt-3 mt-sm-0">OR</p>
                </div>
                <div class="col-sm-5">
                  <label for="contEmail">E-Mail</label>
                  <input id="contEmail" name="contEmail" type="text" [(ngModel)]="model.contEmail">
                </div>
              </div>
              <!-- PBI-5998,5999 -->
              <div *ngIf="(isConfirmNumOrEmail)" class="text-center alert-danger">
                Confirmation Id or Email is required
              </div>

              <div class="row PPP-resDate mt-4">
                <div class="col-sm-5">
                  <label for="resDate" class="mt-3">Reservation Date<sup>*</sup></label>
                  <my-date-picker id="resDate" name="resDate" [options]="myDatePickerOptions"
                    [(ngModel)]="model.resDate" placeholder="mm/dd/yyyy" (dateChanged)="toggle()" class="mydatepick d-block"
                    data-test-id="resDateInput">
                  </my-date-picker>
                  <div *ngIf="isReservationDateRequired" class="alert-danger">
                    Reservation date is required
                  </div>
                  <div *ngIf="isReservationDtCurrentDt" class="alert-danger">
                    {{samedayChangeMessage}}
                  </div>
                </div>
                <div class="col-sm-1">

                </div>
                <div class="col-sm-6">
                  <re-captcha class="PPP-captcha" (resolved)="resolved($event)"
                    [siteKey]="recapthcaSiteKey">
                  </re-captcha>
                  <div>
                    <p style="color: red" class="PPP-captcha-alert">{{captchaMessage}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 PPP-resbutton"
                  style="margin-top: 40px; text-align: center;">
                  <button class="btn--primary btn--purple" for="resLookup" type="submit">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.47443 182.64998">
                      <defs>
                        <style>
                          .cls-1 {
                            fill: none;
                            stroke-linecap: round;
                            stroke-miterlimit: 10;
                            stroke-width: 7px;
                          }
                        </style>
                      </defs>
                      <path class="cls-1 path"
                        d="M16.35228,67.598S185.18984-41.06585,451.85651,69.60082c0,0,111.97656,41.02035-26.34505,74.17684s-364.26172,50.42619-398.625-19.542S233.52317,27.93415,314.18984,37.26749" />
                    </svg>
                    Find Party
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="cec__module">
    <div [ngClass]="{'show': showAlert, '': !showAlert}" [ngStyle]="{'background-color': alertColor}"
      id="applicationMessage" class="application-message">
      <h4 *ngIf="this.applicationMessage">{{this.applicationMessage}}</h4>
    </div>
  </div>
  <div class="cec__module">
    <div [ngClass]="{'show': showModal, '': !showModal}" id="duplicateModal" class="duplicate-modal">
      <div class="duplicate__inner cec__form">
        <a class="duplicate-close" aria-label="Close Modal" href="javascript:void(0);" (click)="showModal = false"></a>
        <h3>Multiple Reservations</h3>
        <p>We have found multiple Reservation bookings associated with your email.</p>
        <ul *ngFor="let duplicate of multipleReservation">
          <li>Reservation:
            <a [routerLink]="['/reservation-dashboard']"
              [queryParams]="{id: duplicate.ConfirmationID, date: duplicate.PartyDate | date: 'MM-dd-yyyy'}">
              <strong>#{{duplicate.ConfirmationID}}</strong></a> - Party Date:
            <strong>{{duplicate.PartyDate | date: 'MM-dd-yyyy'}}</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
