import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Promotion } from 'src/app/2.0/models/promotion';
import { GTMService } from 'src/app/services/gtm.service';
import { environment } from 'src/environments/environment';
import { Popup } from '../../models/popup';

@Component({
	selector: 'app-promotions',
	templateUrl: './promotions.component.html',
	styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent {

	@Input() popup: Popup;
	@Input() data: Promotion;

	@Output() promotionUpdate = new EventEmitter<boolean>();

	response: boolean;
	assetRoot: string;

	constructor(private gtmService: GTMService) {
		this.response = false;
		this.assetRoot = environment.assetRoot;
	}

	upgrade(flag: number) {
		if (flag) {
			this.gtmService.pushEvent({
				event: 'upgradePackageButtons',
				upgradeParty: 'Yes'
			});
			this.response = true;
			this.onUpsellChange();
		} else {
			this.gtmService.pushEvent({
				event: 'upgradePackageButtons',
				upgradeParty: 'No'
			});
			this.response = false;
			this.onUpsellChange();
		}
	}

	onUpsellChange() {
		this.promotionUpdate.emit(this.response);
	}

	closePopup() {
		this.popup.showPromotionPopup = false;
	}
}
