import { Pipe, PipeTransform } from '@angular/core';
import { SimpleDateI } from 'src/app/models';
import { Utilities } from '../services/utilities';

@Pipe({
	name: 'displaySimpleDate'
})
export class DisplaySimpleDatePipe implements PipeTransform {

	transform(value: SimpleDateI, ...args: any[]): unknown {
		if (!value) {
			return null;
		}
		const format = args[0] || 'mm/dd/yyyy';

		return Utilities.formatSimpleDate(value, format);
	}

}

