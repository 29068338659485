import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MyDatePickerModule } from 'mydatepicker';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgxMaskModule } from 'ngx-mask';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePackageComponent } from './components/change-package/change-package.component';
import { CompareComponent } from './components/compare/compare.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DisplayCommentComponent } from './components/display-comment/display-comment.component';
import { EditInfoComponent } from './components/edit-info/edit-info.component';
import { EntryComponent } from './components/entry/entry.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MessageComponent } from './components/message/message.component';
import { PackageInfoComponent } from './components/package-info/package-info.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { ReservationDashboardComponent } from './components/reservation-dashboard/reservation-dashboard.component';
import { ReservationDetailsComponent } from './components/reservation-details/reservation-details.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { DisplaySimpleDatePipe } from './pipes/display-simple-date.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { HttpInterceptorServiceInterceptor } from './services/interceptors/http-interceptor-service.interceptor';
import { DisplayTimeSlotTimePipe } from './pipes/display-time-slot-time.pipe';
import { FreedompayComponent } from './components/freedompay/freedompay.component';
@NgModule({
	declarations: [
		AppComponent,
		ChangePackageComponent,
		CompareComponent,
		ConfirmationDialogComponent,
		DisplaySimpleDatePipe,
		EditInfoComponent,
		EntryComponent,
		EscapeHtmlPipe,
		LoaderComponent,
		MessageComponent,
		PackageInfoComponent,
		PaymentComponent,
		PromotionsComponent,
		ReservationDashboardComponent,
		ReservationDetailsComponent,
		SideMenuComponent,
		DisplayCommentComponent,
		DisplayTimeSlotTimePipe,
		FreedompayComponent
	],
	entryComponents: [ConfirmationDialogComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CommonModule,
		FormsModule,
		GoogleTagManagerModule.forRoot({ id: environment?.gtmConfig?.id || '' }),
		HttpClientModule,
		MatDialogModule,
		MyDatePickerModule,
		NgxMaskModule.forRoot(),
		ReactiveFormsModule,
		RecaptchaFormsModule,
		RecaptchaModule,
		SlickCarouselModule,
	],
	providers: [
		{ provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorServiceInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
