// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	assetRoot: '#{assetRoot}#',
	apiDomain: '#{apiDomain}#',
	packageContentAPI: '#{packageContentAPI}#',
	locationUri: '#{locationUri}#',
	automationHost: '#{automationHost}#',
	freedomPayApiDomain: '#{FreedomPayApiDomain}#',
    freedomPayUrl: '#{FreedomPayUrl}#',
	paymentjsEnv: '#{paymentjsEnv}#',
	paymentjsVersion: '2.0.0',
	siteVersion: '#{siteVersion}#',
	miniPackageStores: '#{miniPackageStores}#',
	context: '#{context}#',
	ignoreCountValidationStoreIds: [4594],
	preventPromotionsPopup: true,
	recapthcaSiteKey: '6LdwAY4UAAAAALvJYAvvhhzD5Ax1qBZvoCxPP4nO',
	appInsights: {
		instrumentationKey: '#{instrumentationKey}#'
	},
	gtmConfig: {
		id: 'GTM-T5SL8J'
	},
	alternateDepositRuleStoreIds: [4134, 4167, 4505, 4576],
	excludedStores: '#{ExcludedStores}'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * apiDomain: 'https://bf2-dev-brand.azurewebsites.net'
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
