import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { BookingStatus } from 'src/app/constants/booking-status.enum';
import { ReservationTimeSlot, StoreDetailsI } from 'src/app/models';
import { ApplicationData } from 'src/app/models/application-data';
import { StoreApiService } from 'src/app/services/store-api.service';
import { Utilities } from 'src/app/services/utilities';
import { environment } from '../../../environments/environment';
import { Constants } from '../../constants/constants';
import { WebString } from '../../constants/string';
import { Alert } from '../../models/alerts';
import { GTMService } from '../../services/gtm.service';
import { ReservationApiService } from '../../services/reservation-api.service';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';
import { MessageComponent } from '../message/message.component';
import { MINI_PACKAGES_STORES } from 'src/app/tokens';

@Component({
	selector: 'app-entry',
	templateUrl: './entry.component.html',
	styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit, AfterViewInit {

	@ViewChild(MessageComponent) private message: MessageComponent;

	adultsValue = -1;
	adultText: string;
	alertMessage = new Alert();
	assets = environment.assetRoot;
	availableSlot: any[] = [];
	bgImagePath: string;
	childText: string;
	dateofParty: string;
	depositAmount: number;
	eventDate: string;
	flag = 0;
	isDate = false;
	isDateSelected = false;
	isdepositrequired: boolean;
	isShow: boolean;
	kidsValue = -1;
	locationUri = environment.locationUri;
	minAdults: number[] = []
	minKids: number[] = [];
	minkidsCount = 0;
	mydate: any;
	myDate: any;
	myDatePickerOptions: IMyDpOptions;
	myrewards = '';
	myrewardsList: any[];
	mytimeslot: any;
	partyRoom: boolean;
	phoneNumber = '';
	ReqType: number;
	RequestedPartyType: string;
	reservationData: any;
	rewardList: any;
	Store: string;
	storeArray: any[];
	StoreData: StoreDetailsI;
	tempDate: any;
	timeGrayToggle = false;
	timeofParty: string;
	timeSlot: any = {};
	Title: string;
	Type: number;
	updateStore: string;

	constructor(private route: ActivatedRoute, private reservationApiService: ReservationApiService, private utilsService: UtilsService,
		private storeApiService: StoreApiService, private storageService: StorageService, private router: Router, private gtmService: GTMService, @Inject(MINI_PACKAGES_STORES) private miniPackageStores: number[]) {

		this.route.queryParams.subscribe(params => {
			this.Type = params.restype ? parseInt(params.restype) : null;
			this.ReqType = params.requestedPartyType || 1;
			this.RequestedPartyType = params.requestedPartyType || 1;
			this.Store = params.store;
			this.updateStore = params.updateStore;
			this.partyRoom = this.Type === Constants.ReservationTypes.PARTYROOM;
		});

		$('.time-slots-list').addClass('time-slots-list-color');
		this.bgImagePath = this.assets + '/assets/background.png';

		if (this.updateStore !== undefined) {
			this.Store = this.updateStore;
			this.setPreferredStoreOnCookie(this.Store);
			const localStorage: ApplicationData = this.storageService.fetch();

			if ((localStorage.kids) && localStorage.adults && localStorage.date) {
				this.isDate = true;
				this.kidsValue = localStorage.kids;
				this.adultsValue = localStorage.adults;
				this.tempDate = localStorage.date;
				this.mydate = {
					date: this.tempDate
				};
			}
		} else {
			this.storageService.clear();
		}
	}

	goback() {
		window.history.back();
		if (!this.updateStore) {
			this.storageService.clear();
		}
	}
	ngOnInit() {
		this.isShow = true;
		this.isdepositrequired = false;
		if (this.ReqType === undefined && this.Type === undefined) {
			const data: ApplicationData = this.storageService.fetch();
			this.ReqType = data?.booking?.resType;
			this.Type = data?.booking?.type;
			data.booking.store = this.Store;
			this.storageService.store(data);
		}

		this.utilsService.changingPopUpMessagesforVisit(this.Type);
		this.dateofParty = this.Type === Constants.ReservationTypes.VISITEVENT ? 'Date of Visit*' : 'Date of Party*';
		this.timeofParty = this.Type === Constants.ReservationTypes.VISITEVENT ? 'Time of Visit*' : 'Time of Party*';
		this.mytimeslot = this.timeofParty;
		if ((this.mydate !== undefined) || (this.timeSlot !== this.timeofParty && this.timeSlot !== undefined)) {
			$('.time-slots-list').removeClass('time-slots-list-color');
			this.isDateSelected = true;
		}

		this.storeApiService.getStoreDetails(this.Store, this.Type.toString()).subscribe((response: StoreDetailsI) => {
			this.StoreData = response;
			this.isShow = false;
			this.minkidsCount = this.StoreData.parameters.Children;

			const localData: ApplicationData = this.storageService.fetch();

			if (this.updateStore && localData.kids && localData.kids > 0) {
				this.kidsValue = localData.kids;
				if (this.kidsValue < this.minkidsCount) {
					this.availableSlot = [];
					this.mytimeslot = this.timeofParty;
					$('.time-slots-list').addClass('time-slots-list-color');
					this.kidsValue = -1;
					this.storageService.store({ kids: '', time: '' });
					this.isDateSelected = !!localData.adults && (localData.adults > 0) && !!localData.date;
					this.showError(WebString.UPDATE_STORE_MIN_CHILDREN.replace('{0}', this.minkidsCount.toString()));
				} else {
					if (localData.date) {
						const partyDate = Utilities.formatSimpleDate(localData.date);
						const earliestDate = moment(this.StoreData.parameters.EarliestDate).format('YYYY-MM-DD');
						if (earliestDate > partyDate) {
							this.showError(WebString.NOTIMESLOT);
							this.isDateSelected = false;
							this.mydate = null;
							this.mytimeslot = this.timeofParty;
						} else if (localData.adults && (localData.adults > 0)) {
							this.getTimeSlotForUpdateStore(localData);
						}
					}
				}
			}

			this.updateKidsCount(this, response.parameters.ReservationTypeId, this.minkidsCount);
			this.formatPhone(this.StoreData.PhoneNumber as number);

			if (this.StoreData.PaymentOptionID !== Constants.StoreDepositTypes.NODEPOSITPAYMENT) {
				this.isdepositrequired = true;
			}

			if (this.StoreData.parameters) {

				for (let i = this.StoreData.parameters.Adults; i < 200; i++) {
					this.minAdults.push(i);
				}

				for (let i = this.StoreData.parameters.Children; i < 200; i++) {
					this.minKids.push(i);
				}
				const earliest = moment().add(this.StoreData.parameters.Earliest - 1, 'days');
				const endDate = moment().add(this.StoreData.parameters.Latest, 'days');
				this.myDatePickerOptions = {
					openSelectorOnInputClick: true,
					editableDateField: false,
					showTodayBtn: false,
					dateFormat: 'mm-dd-yyyy',
					firstDayOfWeek: 'su',
					sunHighlight: true,
					satHighlight: true,
					disableUntil: { year: earliest.year(), month: earliest.month() + 1, day: earliest.date() },
					disableSince: { year: endDate.year(), month: endDate.month() + 1, day: endDate.date() + 1 }
				};
				const data = {
					booking: {
						type: this.Type,
						resType: this.ReqType,
						store: this.Store,
						promotions: this.StoreData.parameters.PromotionList,
						deposit: this.StoreData.parameters.maximumDeposit,
						AdditionalDepositPartyRoom: this.StoreData.parameters.AdditionalDepositPartyRoom,
						minKids: this.StoreData.parameters.Children,
						minAdults: this.StoreData.parameters.Adults,
						maxDate: this.StoreData.parameters.Latest,
						minDate: this.StoreData.parameters.Earliest,
						isDepositRequired: this.isdepositrequired
					},
					upsell: 0,
					store: {
						address: this.StoreData.Address,
						city: this.StoreData.City,
						state: this.StoreData.State,
						zip: this.StoreData.ZipCode,
						phone: this.formatPhone(this.StoreData.PhoneNumber as number),
						country: this.StoreData.CountryID,
						hasPartyRooms: this.StoreData.parameters.HasPartyRooms || false
					},
					storeRewards: this.StoreData.parameters.RewardList,
					requestedPartyType: null
				};

				if (this.StoreData.StoreType === 1) {
					data.requestedPartyType = '3';
				}

				if (this.RequestedPartyType) {
					data.requestedPartyType = this.RequestedPartyType;
				}

				this.storageService.store(data);
				this.reservationData = data;
				this.updateDepositAmount();
			}
			this.gtmService.pushCoreViewDataToGTM('');
		},
			(error: any) => {
				this.isShow = false;
				this.alertMessage.className = WebString.ERROR;

				if ((error as string).toLowerCase().startsWith('http failure response')) {
					this.alertMessage.message = WebString.STORE_API_FAILURE;
				} else {
					this.alertMessage.message = error.message;
				}
				this.alertMessage.show = true;
			}
		);

		switch (this.Type) {
			case Constants.ReservationTypes.PARTYROOM:
			case Constants.ReservationTypes.BIRTHDAYPARTY:
				this.Title = WebString.BIRTHDAY;
				break;
			case Constants.ReservationTypes.GROUPEVENT:
				this.Title = WebString.EVENT;
				break;
			case Constants.ReservationTypes.VISITEVENT:
				this.Title = WebString.VISIT;
				break;
			default:
				throw new Error('Event type not supported');
		}

		this.adultText = 'Adult supervision is required for all parties. Please indicate the number of adults attending to reserve seating. You’ll be able to select additional food for adult guests on the confirmation page (at participating locations).';

	} // end of ngOnInit ...

	ngAfterViewInit() {
		const dateinputfield = document.querySelector('.selectiongroup input');
		if (dateinputfield) {
			dateinputfield.setAttribute('id', 'date_inputfield_entryPage');
		}
	}

	updateKidsCount(store: EntryComponent, reservationTypeId: number, minKidsCount: number) {
		if (reservationTypeId === Constants.ReservationTypes.BIRTHDAYPARTY) {
			store.childText = `Party packages are charged per child attending. A minimum of <strong>${minKidsCount}</strong> children is required to book a party.`;
		} else if (reservationTypeId === Constants.ReservationTypes.GROUPEVENT) {
			store.childText = 'Minimum of ' + minKidsCount + ' paid guests required to reserve an event. You can subtract children up to 24 hours prior to the event, but we cannot guarantee that you can add on later.';
		} else if (reservationTypeId === Constants.ReservationTypes.VISITEVENT) {
			store.childText = 'Minimum children guests vary by package selected.';
		}
	}


	changePartyRoomHandler(isPartyRoom: boolean) {
		this.Type = isPartyRoom ? Constants.ReservationTypes.PARTYROOM : Constants.ReservationTypes.BIRTHDAYPARTY;
		const localData: ApplicationData = this.storageService.fetch();
		const data = {
			booking: { ...localData.booking, type: this.Type }
		};
		this.storageService.store(data);
		const isDateSelected = localData && localData.date && localData.date.day > 0 && localData.date.year > 0 && localData.date.month > 0 && this.isDateSelected;
		if (isDateSelected && localData.kids !== undefined && localData.adults !== undefined) {
			this.kidsAdults();
		}
		this.updateDepositAmount();
		this.mytimeslot = 'Time of Party*';
	}

	updateDepositAmount() {
		const localData: ApplicationData = this.storageService.fetch();
		const bookingType = localData.booking.type;
		const storeId = localData.booking.store;
		const kids = localData.kids;
		const adults = localData.adults;
		const standardDepositAmount = localData.booking.deposit;
		const partyRoomDeposit = localData.booking.AdditionalDepositPartyRoom;

		this.depositAmount = this.utilsService.calculateDepositAmount(parseInt(storeId), bookingType, standardDepositAmount, partyRoomDeposit, kids, adults);
	}

	private getTimeSlotForUpdateStore(localStorage: any) {
		const type = localStorage['booking']['type'];
		const dateOld = Utilities.formatSimpleDate(localStorage.date);
		const existingResId = localStorage.existingReservationId || 0;
		const reservationId = localStorage.reservationId || 0;
		this.isShow = true;
		this.reservationApiService.getTimeSlot(dateOld, this.kidsValue, this.adultsValue, this.Store, type, reservationId, existingResId)
			.subscribe((response: any) => {
				this.isShow = false;


				this.timeSlot = response;

				this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);

				let index = 0, timeSlotAvailable = 0;
				if (localStorage.time) {
					for (const slot of this.availableSlot) {
						if (slot.start === localStorage.time.start && slot.end === localStorage.time.end) {
							this.mytimeslot = index;
							timeSlotAvailable = 1;
							break;
						}
						index++;
					}
					if (!timeSlotAvailable && this.flag === 1) {
						this.mytimeslot = this.timeofParty;
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.WARNING;
						this.alertMessage.message = WebString.NO_TIME_SLOT;
						this.isDateSelected = true;
						this.isDate = true;
						this.message.clear();
					}
				}
				this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(dateOld, this.kidsValue);

				if (timeSlotAvailable) {
					const totalAttendees = this.kidsValue + this.adultsValue;
					const slot = this.timeSlot.AvailabilityDates[0].ReservationTimeSlots[this.mytimeslot as number];
					if (slot.BookingStatus === BookingStatus.Available && totalAttendees <= slot.AvailableSeat) {
						this.flag = 1;
					}
					const data = {
						isVipParty: this.availableSlot[this.mytimeslot as number].IsVipParty,
						bookingInfo: this.timeSlot.AvailabilityDates[0].ReservationTimeSlots[this.mytimeslot as number]
					};

					this.storageService.store(data);

					const rewardData: ApplicationData = this.storageService.fetch();

					if (rewardData.bookingInfo && rewardData.bookingInfo.Rewards) {
						this.myrewardsList = rewardData.bookingInfo.Rewards;
					}

					if (this.flag === 0) {
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.WARNING;
						this.alertMessage.message = WebString.NOTIMESLOT;
						this.isDateSelected = false;
						this.mydate = null;
						this.mytimeslot = this.timeofParty;
						$('.time-slots-list').addClass('time-slots-list-color');
						this.message.clear();
					} else {
						if (this.mytimeslot !== this.timeofParty && this.availableSlot !== null && this.availableSlot.length > 0
							&& !this.availableSlot[this.mytimeslot].available) {
							this.alertMessage.show = true;
							this.alertMessage.className = WebString.WARNING;
							this.alertMessage.message = WebString.NO_TIME_SLOT;
							this.isDateSelected = true;
							this.isDate = true;
							this.message.clear();
							this.mytimeslot = this.timeofParty;
						}
					}
				}
			}, error => {
				this.isShow = false;
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = error.message;
			});
	}

	formatPhone(phone: number) {
		const myPhone = phone.toString();
		this.phoneNumber = '(' + myPhone.slice(0, 3) + ') ' + myPhone.slice(3, 6) + '-' + myPhone.slice(6, 10);
		return this.phoneNumber;
	}

	getAllTimeSlot(date: string, kids: number, adults: number) {
		$('.time-slots-list').removeClass('time-slots-list-color');
		this.availableSlot = [];
		this.flag = 0;
		this.isDate = true;
		const totalAttendees = kids + adults;
		this.availableSlot = this.utilsService.getEnreachedTimeslot(this.timeSlot.AvailabilityDates[0].ReservationTimeSlots, totalAttendees);
		const hasAvailableSlot = this.availableSlot.findIndex(x => x.available) >= 0;

		if (hasAvailableSlot) {
			this.timeGrayToggle = true;
			this.flag = 1;
		}
		if (this.timeGrayToggle === false) {
			$('.time-slots-list').addClass('time-slots-list-color');
		}

		this.storageService.store({ mytimeslot: this.availableSlot, });
	}

	onDateChanged(event: IMyDateModel) {
		this.mytimeslot = this.timeofParty;
		$('.time-slots-list').addClass('time-slots-list-color');
		$('#select_package').addClass('submit-disable');
		this.isDateSelected = true;
		const localData: ApplicationData = this.storageService.fetch();
		this.storeArray = [];

		// Storing the rewards of the store in localstorage
		const storeRewardsData = {
			storeRewards: this.StoreData.parameters.RewardList
		};
		this.storageService.store(storeRewardsData);
		this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(Utilities.formatSimpleDate(event.date), this.kidsValue);

		if (this.kidsValue > 0 && this.adultsValue > 0 && event.date.day && event.date.month && event.date.year) {
			this.isShow = true;
			this.alertMessage.show = true;
			this.alertMessage.className = WebString.INFO;
			this.alertMessage.message = WebString.TIMESLOT;
			this.availableSlot = [];
			document.getElementById('head').scrollIntoView({
				behavior: 'smooth'
			});
			this.storageService.store({ date: event.date });
			const existingResId = localData.existingReservationId || 0;
			const reservationId = localData.reservationId || 0;
			const newDate = Utilities.formatSimpleDate(event.date);
			this.reservationApiService.getTimeSlot(newDate, this.kidsValue, this.adultsValue, this.Store, this.Type, reservationId, existingResId).subscribe((response: any) => {
				this.timeSlot = response;
				this.eventDate = newDate;
				this.getAllTimeSlot(this.eventDate, localData.kids, localData.adults);
				this.isShow = false;
				this.alertMessage.show = false;
				if (this.flag === 0) {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.WARNING;
					this.alertMessage.message = WebString.NOTIMESLOT;
					this.isDateSelected = false;
					$('.time-slots-list').addClass('time-slots-list-color');
					this.mydate = null;
					this.message.clear();
				}
			},
				error => {
					this.isShow = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});

		} else {
			this.isDate = false;
			const data = {
				date: event.date
			};
			this.availableSlot = null;
			this.storageService.store(data);
			this.mytimeslot = this.timeofParty;
			$('.time-slots-list').addClass('time-slots-list-color');
		}
	}

	partyTime(selectedTimeSlot: string) {
		let tempBookingInfo: ReservationTimeSlot = null;
		let timeIndex;
		const availableTimeSlot: any = this.availableSlot.find((x: any, i: number) => {
			const isFind = x.uuid === selectedTimeSlot;
			if (isFind) {
				timeIndex = i;
			}
			return isFind;
		});

		if (this.timeSlot !== null && this.timeSlot !== undefined) {
			tempBookingInfo = (this.timeSlot.AvailabilityDates[0].ReservationTimeSlots as any[]).find(x => x.uuid === selectedTimeSlot);
		} else if (availableTimeSlot?.bookingInfo) {
			tempBookingInfo = availableTimeSlot.bookingInfo;
		} else {
			const localStorage: ApplicationData = this.storageService.fetch();
			tempBookingInfo = localStorage.bookingInfo;
		}

		$('#select_package').removeClass('submit-disable');

		this.myrewardsList = this.utilsService.getTimeslotRewards(timeIndex);

		const data = {
			time: {
				start: availableTimeSlot.start,
				end: availableTimeSlot.end,
			},
			isVipParty: availableTimeSlot.IsVipParty,
			bookingInfo: tempBookingInfo
		};

		this.storageService.store(data);
	}

	handleChangeKidCount(value: string) {
		this.kidsValue = parseInt(value);
		this.storageService.store({ kids: this.kidsValue });
		const localData: ApplicationData = this.storageService.fetch();
		this.updateDepositAmount();
		if (this.isDateSelected && localData.kids !== undefined && localData.adults !== undefined) {
			this.kidsAdults();
		} else if (this.isDateSelected && localData.adults !== undefined) {
			this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
		}
	}

	handleChangeAdultCount(value: string) {
		this.adultsValue = parseInt(value);
		this.storageService.store({ adults: this.adultsValue });
		const localData: ApplicationData = this.storageService.fetch();
		this.updateDepositAmount();
		if (this.isDateSelected && this.kidsValue > 0 && this.adultsValue > 0) {
			$('.time-slots-list').addClass('time-slots-list-color');
			this.kidsAdults();
		} else if (this.isDateSelected && localData.kids !== undefined) {
			this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
		}
	}

	showError(message: string): void {
		this.alertMessage.show = true;
		this.alertMessage.className = WebString.ERROR;
		this.alertMessage.message = message;
		this.message.clear();
	}

	private setPreferredStoreOnCookie(storeId) {
		document.cookie = 'preferredStore = ' + storeId;
	}

	selectPackage(): void {
		const data: ApplicationData = this.storageService.fetch();

		if (this.kidsValue < 0 || this.adultsValue < 0) {
			this.showError(WebString.REQUIRED);
			return;
		} else if ((data.kids && data.adults) && (this.mydate === null || this.mydate === undefined)) {
			this.showError(WebString.SELECT_PARTY_DATE);
			return;
		} else if ((data.kids && data.adults && data.hasOwnProperty('date')) && (this.mytimeslot === null || this.mytimeslot === undefined || this.mytimeslot === this.timeofParty)) {
			this.showError(WebString.SELECT_PARTY_TIMESLOT);
			return;
		}

		if (this.timeSlot) {
			const totalGuests = data.kids + data.adults;
			const selectedReservationTimeslot: any = (this.timeSlot.AvailabilityDates[0].ReservationTimeSlots as any[]).find(t => t.uuid === this.mytimeslot);
			const maxGuest = selectedReservationTimeslot.MaxGuest;
			if (maxGuest && maxGuest < totalGuests) {
				this.showError(WebString.TIMESLOT_MAX_GUEST);
				return;
			} else if (parseInt(selectedReservationTimeslot.MinConfig, 10) > data.kids) {
				this.alertMessage.show = true;
				this.alertMessage.className = WebString.ERROR;
				this.alertMessage.message = 'This timeslot requires a minimum of ' + selectedReservationTimeslot.MinConfig + ' children';
				this.message.clear();
				return;
			}
		}

		if (data.kids && data.hasOwnProperty('adults') && data.time && data.hasOwnProperty('bookingInfo')) {
			if (this.myrewardsList) {
				const bookingInfo = data.bookingInfo;
				bookingInfo.Rewards = this.myrewardsList;
				const updatedBookingInfo = {
					bookingInfo: bookingInfo
				};
				this.storageService.store(updatedBookingInfo);
			}
			this.gtmService.pushBirthdayPartySteps(1, this.ReqType.toString());
			this.router.navigate(['packageInfo']);
		} else {
			this.showError(WebString.REQUIRED);
		}

	}

	kidsAdults() {
		const localData: ApplicationData = this.storageService.fetch();
		this.availableSlot = [];
		this.kidsValue = localData.kids;
		this.adultsValue = localData.adults;

		document.getElementById('head').scrollIntoView({
			behavior: 'smooth'
		});

		const existingResId = localData.existingReservationId || 0;
		const reservationId = localData.reservationId || 0;
		const newDate = Utilities.formatSimpleDate(localData.date);
		this.myrewardsList = this.utilsService.getAutomaticRewardsforPartyDate(newDate, this.kidsValue);

		if (this.mytimeslot && this.mytimeslot !== this.timeofParty) {
			this.myrewardsList = this.utilsService.getTimeslotRewards(this.mytimeslot);
		}

		if (!localData.date?.year || !localData.date?.month || !localData.date?.day) {
			return;
		}

		this.isShow = true;
		this.reservationApiService.getTimeSlot(newDate, localData.kids, localData.adults, this.Store, this.Type, reservationId, existingResId)
			.subscribe((response: any) => {
				this.timeSlot = response;
				this.eventDate = newDate;
				this.getAllTimeSlot(this.eventDate, this.kidsValue, this.adultsValue);
				this.isShow = false;
				this.alertMessage.show = false;
				if (this.flag === 0) {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.WARNING;
					this.alertMessage.message = +this.Type === Constants.ReservationTypes.PARTYROOM ? WebString.PARTY_ROOM_NO_AVAILABLE_SEAT : WebString.SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT;
					this.isDateSelected = false;
					this.mydate = null;
					this.message.clear();
					this.eventDate = null;
					this.mytimeslot = this.timeofParty;
					$('.time-slots-list').addClass('time-slots-list-color');
				} else {
					if (this.mytimeslot !== this.timeofParty && this.availableSlot !== null && this.availableSlot.length > 0
						&& !this.availableSlot[this.mytimeslot].available) {
						this.alertMessage.show = true;
						this.alertMessage.className = WebString.WARNING;
						this.alertMessage.message = WebString.NO_TIME_SLOT;
						this.isDateSelected = true;
						this.isDate = true;
						this.message.clear();
						this.mytimeslot = this.timeofParty;
					}
				}
			},
				error => {
					this.isShow = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
	}
}
