<app-message [alert]="alertMessage"></app-message>

<app-loader *ngIf="isLoading"></app-loader>

<app-promotions [popup]="popUp" [data]="promotion" (promotionUpdate)="upsellResponse($event)"></app-promotions>

<app-compare [popup]="popUp"></app-compare>

<div class="m-select-section mt-40 PPP-wrapper" id="head">
  <div class="m-select-section__head">
    <h2 class="m-select-section__title">{{ title }}</h2>
    <p *ngIf="isDepositRequired && depositAmount" class="m-select-section__subtitle">
      Reservations require a <span class="-bold">${{ depositAmount }}</span> deposit. In the case of a cancellation, the party must be cancelled 48 hours prior to your reservation time to receive a
      deposit refund.
    </p>
  </div>

  <div class="m-select-section__container">
    <div class="m-select-section__left">
      <div class="c-info">
        <div class="c-info__head">
          <h4 *ngIf="restype" class="c-info__title">{{ restype == 4 ? "Visit" : "Party" }} Date & Time</h4>
          <span class="c-info__lbl" *ngIf="localStorage">Date: {{ localStorage.date.month | number: "2.0" }}/{{ localStorage.date.day | number: "2.0" }}/{{ localStorage.date.year }} </span>
          <span class="c-info__lbl" *ngIf="localStorage">Time: {{ localStorage.time.start | displayTimeSlotTime }} - {{ localStorage.time.end | displayTimeSlotTime }} </span>
        </div>

        <div class="all-parties" *ngIf="includeBlock">
          <div class="package_info" [innerHtml]="includeBlock | keepHtml">--></div>
        </div>
      </div>
    </div>

    <div class="m-select-section__right">
      <app-side-menu [page]="1" (packageUpdated)="updateInfo($event)"></app-side-menu>
    </div>
  </div>
  <div class="m-select-section__container">
    <p class="col-md-12 col-sm-12 text-left mt-4 mb-0 step-label">
      <strong class="ml-4 ml-lg-0">Step 2: Select a package</strong>
    </p>
  </div>
  <div class="c-widget__group">
    <div class="c-widget" *ngFor="let Package of partyTypes; let i = index" [ngClass]="{ 'selected-highlight': Package.ID === selectedPartyTypeId }">
      <div class="c-widget__container" [ngClass]="{'mt-4': !Package.CallOutOption}">
        <span *ngIf="Package.CallOutOption !== ''" class="c-widget__lbl">{{ Package.CallOutOption }}</span>
        <h4 class="c-widget__title" [ngStyle]="{'color': Package.CallOutColorValue}">{{ Package.DisplayTypeTitle }}</h4>
        <h4 class="c-widget__title" [ngStyle]="{'color': Package.CallOutColorValue}">Package</h4>
        <a href="javascript:void(0)" (click)="scroll(Package.ID)" data-test-id="viewDetailsBtn" class="c-widget__link"
          >View Details
          <img src="{{ assetRoot }}/assets/down-arrow.png" />
        </a>
      </div>
    </div>
  </div>

  <div class="m-blog" *ngFor="let Package of partyTypes; let i = index">
    <div id="{{ Package.ID }}" *ngIf="i % 2 === 0" class="m-blog__container">
      <div class="m-blog__img" *ngIf="Package.ImagePath" [ngStyle]="{ backgroundImage: 'url(' + Package.ImagePath + ')' }"></div>
      <div class="m-blog__content">
        <div class="c-blog-card -right">
          <div class="c-blog-card__head" [ngStyle]="{ 'background-color': Package.CallOutColorValue }">
            <div class="c-blog-card__content">
              <div class="u-flex align-v-center">
                <div class="c-blog-card__content-center">
                  <h4 class="c-blog-card__title" [ngStyle]="{ color: Package.TextColor }">{{ Package.DisplayTypeTitle }}</h4>
                  <h4 class="c-blog-card__sub-title" [ngStyle]="{ color: Package.TextColor }">{{ Package.TypeDescription }}</h4>
                  <p class="c-blog-card__text" [ngStyle]="{ color: Package.TextColor }" *ngIf="Package?.partyTypesDescription" [innerHtml]="Package.partyTypesDescription | keepHtml"></p>
                </div>
              </div>
            </div>
            <div class="c-blog-card__widge">
              <div (click)="checkUpsell(Package.DisplayTypeTitle, Package.ID, i)" class="u-flex-all">
                <span *ngIf="Package.CallOutOption !== ''" class="c-blog-card__orange--text" [ngClass]="{ 'white-text': Package.ClassColor === '-yellow' }">{{ Package.CallOutOption }}</span>
                <span *ngIf="Package.Price !== 0" class="c-blog-card__number" [ngStyle]="{ color: Package.TextColor }">${{ Package.Price }}</span>
                <span *ngIf="Package.Price === 0" class="c-blog-card__number" [ngStyle]="{ color: Package.TextColor }">${{ depositAmount ? depositAmount : "0.00" }}</span>
                <span *ngIf="Package.Price !== 0" class="c-blog-card__type" [ngStyle]="{ color: Package.TextColor }">{{
                  Package.PriceAsPer == partyTypePricing ? "includes " + Package.TotalGuest + " guests" : "per child"
                }}</span>
              </div>
            </div>
          </div>
          <div class="c-blog-card__body">
            <span class="c-blog-card__lbl">Package Includes</span>
            <ul class="c-blog-card__list">
              <li class="c-blog-card__list--items" *ngFor="let Partyitems of Package.PartyItemList">
                {{ Partyitems.ItemTitle }}
              </li>
            </ul>
            <a href="javascript:void(0)" (click)="checkUpsell(Package.DisplayTypeTitle, Package.ID, i)" data-test-id="bookPackageLink" class="c-blog-card__link"
              >Book this package<img style="padding-bottom: 5px" src="{{ assetRoot }}/assets/arrow.PNG" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div id="{{ Package.ID }}" *ngIf="i % 2 !== 0" class="m-blog__container -flex-mobile">
      <div class="m-blog__content">
        <div class="c-blog-card -left">
          <div class="c-blog-card__head" [ngStyle]="{ 'background-color': Package.CallOutColorValue }">
            <div class="c-blog-card__content">
              <div class="u-flex align-v-center">
                <div class="c-blog-card__content-center">
                  <h4 class="c-blog-card__title" [ngStyle]="{ color: Package.TextColor }">{{ Package.DisplayTypeTitle }}</h4>
                  <h4 class="c-blog-card__sub-title" [ngStyle]="{ color: Package.TextColor }">{{ Package.TypeDescription }}</h4>
                  <p class="c-blog-card__text" [ngStyle]="{ color: Package.TextColor }" *ngIf="Package?.partyTypesDescription" [innerHtml]="Package.partyTypesDescription | keepHtml"></p>
                </div>
              </div>
            </div>
            <div class="c-blog-card__widge">
              <div (click)="checkUpsell(Package.DisplayTypeTitle, Package.ID, i)" class="u-flex-all">
                <span *ngIf="Package.CallOutOption !== ''" class="c-blog-card__orange--text">{{ Package.CallOutOption }}</span>
                <span *ngIf="Package.Price !== 0" class="c-blog-card__number" [ngStyle]="{ color: Package.TextColor }">${{ Package.Price }}</span>
                <span *ngIf="Package.Price === 0" class="c-blog-card__number" [ngStyle]="{ color: Package.TextColor }">${{ depositAmount ? depositAmount : "0.00" }}</span>
                <span *ngIf="Package.Price !== 0" class="c-blog-card__type" [ngStyle]="{ color: Package.TextColor }">
                  {{ Package.PriceAsPer == partyTypePricing ? "includes " + Package.TotalGuest + " guests" : "per child" }}
                </span>
              </div>
            </div>
          </div>
          <div class="c-blog-card__body">
            <span class="c-blog-card__lbl">Package Includes</span>
            <ul class="c-blog-card__list">
              <li class="c-blog-card__list--items" *ngFor="let Partyitems of Package.PartyItemList">
                {{ Partyitems.ItemTitle }}
              </li>
            </ul>
            <a href="javascript:void(0)" (click)="checkUpsell(Package.DisplayTypeTitle, Package.ID, i)" data-test-id="bookPackageLink" class="c-blog-card__link"
              >Book this package<img style="padding-bottom: 5px" src="{{ assetRoot }}/assets/arrow.PNG" />
            </a>
          </div>
        </div>
      </div>
      <div class="m-blog__img" *ngIf="Package.ImagePath" [ngStyle]="{ backgroundImage: 'url(' + Package.ImagePath + ')' }"></div>
    </div>
  </div>
</div>
