import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreDetailsI } from '../models';
import { ReservationApiService } from './reservation-api.service';
import { LoggingService } from './logging.service';
import { StorageService } from './storage.service';
import { StoreApiService } from './store-api.service';

@Injectable({
	providedIn: 'root'
})
export class ReservationService {
	sharedData: any = {};
	CCOUNT;
	t;
	count;
	showAlert;
	applicationMessage;
	alertColor;
	private timeToWait = 1200;

	constructor(private entryService: ReservationApiService, private router: Router, private storeApiService: StoreApiService, private logger: LoggingService, private storageService: StorageService) {
		this.CCOUNT = this.getTimeCreated();
		this.showAlert = false;
		this.applicationMessage = 'Your reservation is about to expire, click here to extend.';
		this.alertColor = '#f8d7da';
		this.timeToWait = 1200;
		this.sharedData.sessionUpsellCount = this.getSessionUpsellCount();
	}

	clearReservationSession(resetUpsellCounts: boolean = false) {
		if (this.sharedData) {
			if (
				this.sharedData.reservationContext &&
				this.sharedData.reservationContext.Store
			) {
				this.sharedData.reservationContext = {
					Store: this.sharedData.reservationContext.Store
				};
				this.sharedData.existingReservationId = 0;
				this.sharedData.reservationId = 0;
				if (resetUpsellCounts) {
					this.sharedData.sessionUpsellCount = 0;
				}
			}

			this.storageService.store(this.sharedData);
			this.cdreset();
		}
	}

	getStoreDetails(storeId: number, resType: number = 1): Observable<any> {
		return new Observable(observer => {
			this.storeApiService.getStoreDetails(storeId.toString(), resType.toString()).subscribe((storeDetails: StoreDetailsI) => {
				if (!this.sharedData.reservationContext) {
					this.sharedData.reservationContext = {};
				}
				if (!this.sharedData.reservationContext.Store) {
					this.sharedData.reservationContext.Store = storeDetails;
				}
				observer.next(this.sharedData);
				return observer.complete();
			},
				error => {
					this.logger.logError(error);
				}
			);
		});
	}

	getTimeCreated() {
		const time = localStorage.getItem('timeStart');
		const timeStamp = new Date(parseInt(time, 10)).getTime();
		const newTime = new Date().getTime();
		const diffTime = (newTime - timeStamp) / 1000;
		const timeLeft = this.timeToWait - diffTime;
		return timeLeft;
	}

	getSessionUpsellCount() {
		const sessionUpsells = localStorage.getItem('sessionUpsells') || 0;
		return sessionUpsells;
	}

	addSessionUpsell() {
		this.sharedData.sessionUpsellCount++;
		localStorage.setItem('sessionUpsells', this.sharedData.sessionUpsellCount);
	}

	countdown() {
		// starts countdown
		if (this.CCOUNT <= 0) {
			// time is up
			alert('Your session has expired');
			this.clearReservationSession(true);
			this.router.navigate(['/reservations']);
			this.showAlert = false;
		} else {
			this.CCOUNT--;
			this.t = setTimeout(() => {
				this.countdown();
			}, 1000);

			if (this.CCOUNT < this.timeToWait * 0.2) {
				this.showAlert = true;
			} else {
				this.showAlert = false;
			}
		}
	}

	cdpause() {
		clearTimeout(this.t);
	}

	cdreset() {
		localStorage.setItem('timeStart', new Date().getTime().toString());
		this.cdpause();
		this.CCOUNT = this.getTimeCreated();
	}
}
