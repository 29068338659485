<div class="upsell-modal" *ngIf="popup?.showComparePopup">
  <div class="upsell__inner">
    <app-promotions [popup]="ppopUp" [data]="promotion" (promotionUpdate)="upsellResponse($event)"></app-promotions>
    <app-message [alert]="alertMessage"></app-message>
    <app-loader *ngIf="isShow"></app-loader>
    <div>
      <span (click)="closePopup()" class="close_image"><img src="{{assetRoot}}/assets/close.png" /></span>
    </div>
    <div class="c-compare-page">
      <div class="c-compare-page__container">

        <div class="c-compare-page__list">
          <div class="c-compare-page__items" *ngFor="let package of packageInfo; let i = index">
            <div class="c-compare-page__item--head">
              <span class="c-compare-page__item--lbl" *ngIf="i===2"><img src="{{assetRoot}}/assets/users.png"
                  class="m-t2" />Staff pick</span>
              <h4 class="c-compare-page__item--title {{package.className}}">
                {{package.DisplayTypeTitle}} package
              </h4>
            </div>
            <div class="c-compare-page__item--img">
              <img src="{{assetRoot}}/assets/{{package.ImagePath}}" />
            </div>
          </div>
        </div>
        <div class="c-compare-page__rowstatic">
          <div class="c-compare-page__column">
            <div class="c-package__head -height90">
              <h4 class="c-package__head--title">Pick your party package</h4>
            </div>
          </div>
          <div class="c-compare-page__column">
            <div class="c-package__option--group">
              <div class="c-package__option {{package.className}}" *ngFor="let package of packageInfo"
                [ngStyle]="customWidth">
                <span class="-type">{{package.DisplayTypeTitle.split(" ")[0]}}</span>
                <span class="-cost">${{package.Price}}</span>
                <span class="-person">Per child</span>
              </div>
            </div>
          </div>
        </div>
        <div class="c-compare-page__row -no-bg">
          <div class="c-compare-page__column">
            <div class="c-package__head">
              <h4 class="c-package__head--title -bold">Package Features</h4>
            </div>
          </div>
          <div class="c-compare-page__column">
            <div class="c-package__option--group">
              <div class="c-package__option  bl-1" *ngFor="let package of packageInfo, let i = index" [ngStyle]="customWidth">
                <a href="javascript:void(0)" (click)="checkUpsell(package.DisplayTypeTitle, package.ID, i)"
                  class="c-package__option--link">BOOK NOW</a>
              </div>
            </div>
          </div>
        </div>
        <div class="c-compare-page__row" *ngFor="let info of compareInfo; let i = index">
          <div class="c-compare-page__column">
            <div class="c-package__head">
              <h4 class="c-package__head--title">{{info.compareParams}}</h4>
            </div>
          </div>
          <div class="c-compare-page__column">
            <div class="c-package__option--group">
              <div class="c-package__option bl-1" *ngFor="let package of info.package; let i = index"
                [ngStyle]="customWidth">
                <img *ngIf="package.str" src="{{assetRoot}}/assets/verification-mark.png" />
                <span *ngIf="package.int" class="c-package__option--lbl -bold">{{package.int}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="c-compare-page__row">
          <div class="c-compare-page__column">
            <div class="c-package__head">

            </div>
          </div>
          <div class="c-compare-page__column">
            <div class="c-package__option--group">
              <div class="c-package__option  bl-1" *ngFor="let package of packageInfo; let i = index" [ngStyle]="customWidth">
                <a href="javascript:void(0)" (click)="checkUpsell(package.DisplayTypeTitle, package.ID, i)"
                  class="c-package__option--link">BOOK NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
