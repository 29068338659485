import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MyArray } from 'src/app/constants/className';
import { ImagePath } from 'src/app/constants/imagePath';
import { WebString } from 'src/app/constants/string';
import { Alert } from 'src/app/models/alerts';
import { Popup } from 'src/app/models/popup';
import { GTMService } from 'src/app/services/gtm.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from '../../../environments/environment';
import { Promotion } from '../../2.0/models/promotion';
import { BookingApiService } from '../../services/booking-api.service';

@Component({
	selector: 'app-compare',
	templateUrl: './compare.component.html',
	styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {
	@Input() popup: Popup;
	customWidth: any;
	isShow: boolean;
	packageInfo: any[];
	compareInfo: Array<object>;
	params: string[];
	packageId: string[];
	assetRoot: string;
	alertMessage: Alert;
	currentId: string;
	showPromotion: boolean;
	promotion: Promotion;
	ppopUp: Popup;

	constructor(private storageService: StorageService, private bookingApiService: BookingApiService, private router: Router, private gtmService: GTMService) {
		this.assetRoot = environment.assetRoot;
		this.alertMessage = new Alert();
		this.isShow = false;
		this.showPromotion = false;
		this.promotion = new Promotion();
		this.ppopUp = new Popup();
	}

	ngOnInit() {
		this.popup.showPromotionPopup = false;
		this.packageId = [];
		this.compareInfo = [];
		this.params = [];
		const data = this.storageService.fetch();
		this.packageInfo = data.bookingInfo.PartyTypes;
		this.customWidth = {
			width: 'calc(100% /' + this.packageInfo.length + ')'
		};
		this.packageInfo.forEach((element, index) => {
			const id = element['ID'];
			this.packageId.push(id);
			element['className'] = MyArray.class[index];
			element['ImagePath'] = ImagePath.imagePath[index];
			element['PartyItemList'].forEach(element2 => {
				let flag = false;
				let isNum = false;
				let numValue = 0;
				let stringValue = false;
				let feature = element2.ItemTitle;
				MyArray.comparePackage.forEach(element3 => {
					if (element2.ItemTitle.includes(element3)) {
						feature = element3;
						flag = true;
					}
				});
				if (this.params.includes(feature)) {
					if (flag) {
						numValue = element2.ItemTitle.match(/\d+/)[0];
					} else {
						stringValue = true;
					}
					const Index = this.params.indexOf(feature);
					const available = {
						ID: id,
						int: numValue,
						str: stringValue
					};
					this.compareInfo[Index]['package'].push(available);
				} else {
					if (flag) {
						isNum = true,
							numValue = element2.ItemTitle.match(/\d+/)[0];
					} else {
						isNum = false;
						stringValue = true;
					}
					const arr = [];
					this.packageId.forEach((i) => {
						if (i === id) {
							arr.push({
								ID: id,
								int: numValue,
								str: stringValue
							});
						} else {
							arr.push({
								ID: i,
								int: false,
								str: false
							});
						}
					});
					const info = {
						compareParams: feature,
						integer: isNum,
						string: stringValue,
						package: arr
					};
					this.compareInfo.push(info);
					this.params.push(feature);
				}
			});
		});
	}

	checkUpsell(partyName: string, id: string, index: number) {
		let match = 0;
		this.currentId = id;
		const local = this.storageService.fetch();
		let upsellCount = local['upsell'];
		const promotionArr = local['booking']['promotions'];
		if (upsellCount < WebString.maxUpsell && promotionArr.length && ((index + 1) < local.bookingInfo.PartyTypes.length)) {
			upsellCount += 1;
			const data = {
				upsell: upsellCount
			};
			this.storageService.store(data);
			partyName = partyName.split(' ')[0].toLowerCase();
			promotionArr.forEach(element => {
				if (partyName === element['Name'].split(' ')[0].toLowerCase()) {
					this.promotion.Image = element.IconPath.startsWith('http') ? (element.IconPath) : (element.IconPath).replace(/[/]+/g, '/');
					this.promotion.Title = element.Title;
					this.promotion.Description = element.Description;
					match = 1;
				}
			});
			if (match === 1) {
				this.ppopUp.showPromotionPopup = true;
				this.showPromotion = true;
				this.gtmService.pushEvent({
					event: 'upgradePackageOverlay'
				});
			} else {
				this.select_package(id);
			}

		} else {
			this.select_package(id);
		}
	}

	upsellResponse(event) {
		this.ppopUp.showPromotionPopup = false;
		this.showPromotion = false;
		if (event) {
			const local = this.storageService.fetch();
			local.bookingInfo.PartyTypes.forEach((element, index) => {
				if (element.ID === this.currentId) {
					this.select_package(local.bookingInfo.PartyTypes[index + 1]['ID']);
					return 0;
				}
			});
		} else {
			this.select_package(this.currentId);
		}

	}

	select_package(id: string) {
		this.isShow = true;
		const data = this.storageService.fetch();
		const date = data.date.year + '-' + data.date.month + '-' + data.date.day;
		const existingResId = data.existingReservationId || 0;
		const reservationId = data.reservationId || 0;
		this.bookingApiService.bookPackage(data.adults, data.kids, date, data.time.start, data.booking.store,
			data.booking.type, reservationId, existingResId, id)
			.subscribe((response: any) => {
				const res = response;
				if (res.Message.IsOk) {
					const storeData = {
						reservationContext: res
					};
					this.storageService.store(storeData);
					const storeData2 = {
						reservationId: res.Reservation.TempBookingSeatID,
						packageId: id
					};
					this.storageService.store(storeData2);
					this.isShow = false;
					this.router.navigate(['/payment']);
				} else {
					this.isShow = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = res.Message.Error;
				}
			},
				error => {
					this.isShow = false;
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
	}

	closePopup() {
		this.popup.showComparePopup = false;
	}

}
