<app-message [alert]="alertMessage"></app-message>
<app-loader *ngIf="showLoader"></app-loader>

<div class="upsell-modal PPP-wrapper" *ngIf="tabs?.length > 0">


  <div class="m-modal {{modalStyle}}">
    <div class="m-modal__container">
      <div class="m-modal__header {{className}}">
        <h4 class="m-modal__title">Change package</h4>
        <span class="m-modal__close" (click)="closeModal()">
          <img src="{{assets}}/assets/close-white.png" />
        </span>
      </div>
      <div class="m-modal__body">
        <div class="c-tabs">
          <div class="c-tabs__container">
            <div class="c-tabs__items {{tab.style}}" *ngFor="let tab of tabs; let i=index"
              (click)=" doTabsAction(tab,i)">
              <span class="c-tabs__lbl" data-test-id="tabName">{{tab.name}}</span>
            </div>
          </div>
          <div class="c-tabs__content">
            <div class="c-tabs__items--content" *ngFor="let tab of tabs; let i=index">
              <div class="c-tabs-card {{tab.className}}" *ngIf="currentTabActiveContent == i">
                <div class="c-tabs-card__content">
                  <span class="c-tabs-card__list--title">Package Includes</span>
                  <ul class="c-tabs-card__list" *ngFor="let items of tab.partyItem">
                    <li class="c-tabs-card__list--item -blue">{{items.ItemTitle}}</li>
                  </ul>
                  <div class="c-tabs-card__button">
                    <button type="button" class="-book" [disabled]="tab.buttonDisable" data-test-id="bookBtn"
                      (click)="changePackage(tab.id)">{{tab.bookString}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile accordian start -->
        <div class="c-accordian" *ngFor="let item of tabs">
          <div class="c-accordian__header" (click)="doAccordianAction(item)">
            <div class="c-accordian__inline">
              <h4 class="c-accordian__title">{{item.name}}</h4>
              <span class="c-accordian__icon" *ngIf="currentAccordianActiveContent != item.number">
                <img src="{{assets}}/assets/add.png" />
              </span>
              <span class="c-accordian__icon" *ngIf="currentAccordianActiveContent == item.number">
                <img src="{{assets}}/assets/substract.png" />
              </span>
            </div>
          </div>
          <div class="c-accordian__body" *ngIf="currentAccordianActiveContent == item.number">
            <div class="c-tabs-card  {{item.className}}">
              <div class="c-tabs-card__content">
                <span class="c-tabs-card__list--title">Package Includes</span>
                <ul class="c-tabs-card__list">
                  <li class="c-tabs-card__list--item -blue" *ngFor="let list of item.partyItem">{{list.ItemTitle}}</li>
                </ul>
                <div class="c-tabs-card__button">
                  <button type="button" class="-book" [disabled]="item.buttonDisable"
                    (click)="changePackage(item.id)">{{item.bookString}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile accordian end -->
      </div>
    </div>
  </div>
</div>
