import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggingService } from '../logging.service';

@Injectable()
export class HttpInterceptorServiceInterceptor implements HttpInterceptor {

	constructor(private logger: LoggingService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(error => {
				this.logger.logError(error);
				return throwError(error.message);
			})
		);
	}
}
