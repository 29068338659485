import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RESERVATION_API_URL } from '../tokens';

@Injectable({
	providedIn: 'root'
})
export class AuthorizationApiService {

	constructor(@Inject(RESERVATION_API_URL) private reservationApiUrl: string, private http: HttpClient) { }

	authorizeClientToken(nonce: string, storeId: number) {
		return this.http.get(this.reservationApiUrl + '/Api/CEC/Booking/AuthorizeSession/' + nonce + '/' + storeId);
	}



}
