import { SimpleDateI } from '../models';

export class Utilities {

	static formatSimpleDate(simpleDate: SimpleDateI, format: string = 'yyyy-mm-dd') {
		if (!simpleDate || !format) {
			return null;
		}

		return format.toLocaleLowerCase().toLocaleLowerCase()
			.replace('yyyy', simpleDate.year.toString())
			.replace('mm', simpleDate.month.toString().padStart(2, '0'))
			.replace('dd', simpleDate.day.toString().padStart(2, '0'));
	}

	static encodeParameter(input): string {
		if (input) {
			return encodeURIComponent(btoa(input));
		} else {
			return input;
		}
	}

	static decodeParameter(input) {
		if (input) {
			return this.tryDecodeBase64(decodeURIComponent(input));
		} else {
			return input;
		}
	}

	static tryDecodeBase64(input): string {
		try {
			if (btoa(atob(input)) == input) {
				return atob(input);
			}
		} catch (err) {
			return err;
		}
		return null;
	}



}
