export namespace Constants {
	export class ReservationPaymentTypeConst {
		public static readonly INSTORE = 1;
		public static readonly CREDIT = 2;
	}

	export class StoreDepositTypes {
		public static readonly ONLINEANDINSTOREPAYMENT = 1;
		public static readonly ONLINEAUTHONLYANDINSTOREPAYMENT = 2;
		public static readonly INSTOREPAYMENT = 3;
		public static readonly NODEPOSITPAYMENT = 4;
		public static readonly CreditDepositRequired = 5;
	}

	export class GatewayPaymentTypes {
		public static readonly PAYEEZYPAYMENTTYPE = 2;
		public static readonly MONERISPAYMENTTYPE = 1;
	}

	export class ReservationTypes {
		public static readonly GROUPEVENT = 3;
		public static readonly BIRTHDAYPARTY = 1;
		public static readonly FUNDRAISEREVENT = 2;
		public static readonly VISITEVENT = 4;
	}

	export class PartyPromotionCategoryIds {
		public static readonly STAR = 1;
		public static readonly SUPERSTAR = 2;
		public static readonly MEGASUPERSTAR = 3;
		public static readonly VIPMEGASUPERSTAR = 24;
		public static readonly ULTIMATESUPERSTAR = 132;
		public static readonly PLAY60 = 90;
		public static readonly PLAY90 = 91;
		public static readonly PLAY120 = 92;
		public static readonly HALLOWEEN = 141;
	}

	export class ItemTypeId {
		public static readonly UNKNOWN = 0;
		public static readonly QUANTITY = 1;
		public static readonly CHECKBOX = 2;
		public static readonly CHILDCOUNT = 3;
	}

	export class RewardScope {
		public static readonly GLOBAL = 0;
		public static readonly WHOLEDAY = 1;
		public static readonly LIMITEDTIMESLOT = 2;
	}

	export class ItemPromotionCategoryGroupID {
		public static readonly CAKE = 2;
		public static readonly PIZZA = 3;
	}

	export class RewardType {
		public static readonly automaticRewardId = 0;
		public static readonly callCenterRewardId = 2;
	}

}
