
export class Reservation {
	reservationContext?: any;
	optionalPartyItems: any[];
	numberOfKids: number;
	numberOfAdults: number;
	restype: number;
	booking?: any;
	store: { address, city, state, zip };
}


