export class WebString {
	public static BIRTHDAY = 'BOOK YOUR PARTY';
	public static CECSAMEDAYCHANGE = 'We do not allow earlier/same day changes to your reservation, please contact your local Chuck E. Cheese.';
	public static CONNECTION_ERROR = 'There was an error making your payment, please try again.';
	public static ERROR = 'alert-danger alert custom';
	public static EVENT = 'BOOK YOUR EVENT';
	public static FORMVALIDATION = 'Please review the page for any missing or invalid information.';
	public static INFO = 'alert-info alert custom';
	public static INFOUPDATE = 'Updating Your Party Information';
	public static LESSPARTYTYPEPRICING = 'The package you\'ve selected includes food and fun for a certain number of guests: {0}. You have <b>less</b> Guests attending your party than this package accommodates – you will still be charged the set package price, so feel free to invite more friends and family to your celebration.';
	public static maxUpsell = 2;
	public static MOREPARTYTYPEPRICING = 'The package you\'ve selected includes food and fun for a set number of guests: {0}. You have <b>more</b> guests attending your party than this package accommodates, an additional package purchase is required to book.';
	public static NO_TIME_SLOT = 'Selected timeslot is not available. Please select another timeslot.';
	public static NOTAPPLICABLEPARTYTYPEPRICING = 'Let\'s party! Unfortunately, the number of guests selected exceeds our current capacity limits. Please reduce your guest count and try again. For more information, please call your location.';
	public static NOTIMESLOT = 'No available timeslot. Select a different date';
	public static PACKAGE = 'SELECT A PACKAGE';
	public static PACKAGE_MAX_GUESTS = 'Party booking limited to {0} guests';
	public static PACKAGE_MIN_CHILDREN = '{0} Package requires a minimum of {1} Children to Book';
	public static PARTY_ROOM_NO_AVAILABLE_SEAT = 'The party room cannot accommodate more than the capacity.';
	public static PAYMENTAUTHORIZEERROR = 'Error while authorization payment ';
	public static PPPSAMEDAYCHANGE = 'We do not allow earlier/same day changes to your reservation, please contact your local Peter Piper Pizza.';
	public static PROMOAPPLIED = '{0} is applied.';
	public static PROMOCODENOTELIGIBLE = '{0} not eligible.';
	public static PROMODESCRIPTION = 'Only 1 promo code is allowed per reservation. Clicking \'Apply\' replaces any existing promo code.';
	public static PROMOERROR = 'Please enter promo code';
	public static PROMOMINCHILDDESCRIPTION= 'Minimum of {0} child attendees needed to redeem the promo code. Please adjust child attendees.';
	public static PROMONOTFOUND = '{0} not found.';
	public static PROMONOTAPPLIED = '{0} is not applied.';
	public static REQUIRED = 'Please select All Required Data';
	public static SELECT_DIFFERENT_DATE_NO_AVAILABLE_SEAT = 'No times are available on the selected date for the selected number of attendees. Please select another date.';
	public static SELECT_PARTY_DATE = 'Please select Party date.';
	public static SELECT_PARTY_TIMESLOT = 'Please select timeslot.';
	public static SELECTDATE = 'A Date is Required to Update Timeslot';
	public static SELECTPACKAGE = 'Selecting Your Package';
	public static STORE_API_FAILURE = 'Store Data is Unavailable.  Please try again in a few minutes.';
	public static SUCCESS = 'alert-success alert custom';
	public static TIMESLOT = 'Gathering Available Times for Your Party';
	public static TIMESLOT_MAX_GUEST = 'You\'ve reached maximum party guests allowed for booking. Please adjust your quantity.';
	public static UPDATE_STORE_MIN_CHILDREN = 'This store requires a minimum of {0} children. Please update the child count to proceed.';
	public static VIPERROR = 'This time slot requires a minimum of {0} children to book';
	public static VISIT = 'BOOK YOUR VISIT';
	public static WARNING = 'alert-warning alert custom';
}
