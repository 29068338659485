import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { APP_INSIGHTS_INSTRUMENTATION_KEY } from '../tokens';

@Injectable({ providedIn: 'root' })
export class LoggingService {

	appInsights: ApplicationInsights;
	isProduction = environment.production;

	constructor(@Inject(APP_INSIGHTS_INSTRUMENTATION_KEY) private instrumentationKey: string | null | undefined) {
		this.appInsights = new ApplicationInsights({
			config: {

				instrumentationKey: this.instrumentationKey || null,
				enableAutoRouteTracking: true // option to log all route changes
			}
		});

		if (!!this.instrumentationKey) {
			this.appInsights.loadAppInsights();
		}

	}

	trackEvent(event: string) {
		this.appInsights.trackEvent({ name: event });
	}

	logInfo(message: string): void {

		this.trackEvent(message);

		if (!console || this.isProduction) {
			return;
		}
		// eslint-disable-next-line no-console
		console.log(message);
	}

	logError(error: Error): void {

		this.trackException(error, SeverityLevel.Error);

		if (!console || this.isProduction) {
			return;
		}
		// eslint-disable-next-line no-console
		console.error(error);
	}

	logTrace(message: string): void {

		this.trackException(new Error(message), SeverityLevel.Verbose);

		if (!console || this.isProduction) {
			return;
		}
		// eslint-disable-next-line no-console
		console.trace(message);
	}


	private trackException(exception?: Error, severityLevel?: SeverityLevel) {
		this.appInsights.trackException({
			exception: exception,
			severityLevel: severityLevel
		});
	}


}
