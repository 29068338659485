import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONTENT_API_URL } from '../tokens';

@Injectable({
	providedIn: 'root'
})
export class ContentApiService {

	constructor(@Inject(CONTENT_API_URL) private contentApitUrl: string, private http: HttpClient) { }

	get(body: any): Observable<any> {
		return this.http.post<any>(`${this.contentApitUrl}/api/sfcontent`, body);
	}
}
