import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Alert } from '../../models/alerts';

@Component({
	selector: 'app-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss']
})
export class MessageComponent {

	@Input() alert: Alert;

	PPPflag: boolean;

	constructor() {
		this.PPPflag = environment.context === 'PPP';
	}

	close() {
		this.alert.show = false;
	}

	clear(timeout: number = 10000) {
		setTimeout(() => {
			this.alert.show = false;
		}, timeout);
	}
}
