import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-display-comment',
	templateUrl: './display-comment.component.html',
	styleUrls: ['./display-comment.component.scss']
})
export class DisplayCommentComponent{

	@Input() comment: string | null;

}
