import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { BookingStatus } from '../constants/booking-status.enum';
import { MyArray } from '../constants/className';
import { Constants } from '../constants/constants';
import { PricePer } from '../constants/price-per.enum';
import { WebString } from '../constants/string';
import { PartyTypeI } from '../models';
import { AppConfig } from '../models/app-config';
import { APP_CONFIG } from '../tokens';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UtilsService {

	constructor(@Inject(APP_CONFIG) private appConfig: AppConfig, private storageService: StorageService, private dialog: MatDialog) { }

	getAutomaticRewardsforPartyDate(partyDate: string, kids: number): any[] {

		const dayOfWeek = moment(partyDate, 'YYYY-MM-DD').format('dddd');
		const automaticRewardsforPartyDate = this.getRewardsOnDayOfWeek(dayOfWeek, partyDate, kids);
		return automaticRewardsforPartyDate;
	}

	private getRewardsOnDayOfWeek(dayOfWeek, partyDate, kids): any[] {
		const automaticRewards = [];
		const localStorage = this.storageService.fetch();
		let storeRewards: any[] = [];
		let storeType;
		const kidCount = isNaN(kids) ? 0 : parseInt(kids);

		if (localStorage?.reservationContext?.Store?.ID == localStorage?.booking?.store) {
			storeRewards = localStorage.reservationContext.Store.parameters.RewardList || [];
			storeType = localStorage.reservationContext.Store.StoreType;
		} else {
			storeRewards = localStorage.storeRewards;
			storeType = 2;
		}

		for (const reward of storeRewards) {
			if (reward.RewardTypeId === Constants.RewardType.automaticRewardId && reward.EligibleReservationApplication != Constants.RewardType.callCenterRewardId && kidCount >= reward.EligibleMiniumChildren) {
				const today = moment().format('YYYY-MM-DD');
				const ActiveEnd = moment(reward.ActiveEndOn).format('YYYY-MM-DD');
				const ActiveStart = moment(reward.ActiveStartOn).format('YYYY-MM-DD');
				const Eligiblestart = moment(reward.EligibleStartOn).format('YYYY-MM-DD');
				const Eligibleend = moment(reward.EligibleEndOn).format('YYYY-MM-DD');
				if (ActiveEnd >= today && ActiveStart <= today) {
					if (Eligiblestart <= partyDate && Eligibleend >= partyDate) {
						for (const day of reward.EligibleWeekdays) {
							if (day === dayOfWeek && reward.StoreType === storeType) {
								automaticRewards.push(reward);
							}
						}
					}
				}
			}
		}
		const rewards = {
			myrewards: automaticRewards
		};
		this.storageService.store(rewards);
		return automaticRewards;
	}

	getTimeslotRewards(time): any[] {
		const rewards = this.storageService.fetch('myrewards');
		const automaticRewards = [];
		if (rewards) {
			for (const reward of rewards) {
				for (const timeslot of reward['EligibleTimeSlots']) {
					if (parseInt(time) + 1 == timeslot) {
						automaticRewards.push(reward);
					}
				}
			}
		}
		return automaticRewards;
	}

	updateColor(partyTypeTitle: string): string {

		partyTypeTitle = partyTypeTitle.replace(/\s/g, '').toLowerCase();
		if (partyTypeTitle === 'star' || partyTypeTitle === '60minutesofallyoucanplay') {
			return MyArray.class[0];
		} else if (partyTypeTitle === 'superstar' || partyTypeTitle === '90minutesofallyoucanplay' || partyTypeTitle === 'halloween') {
			return MyArray.class[1];
		} else if (partyTypeTitle === 'megasuperstar' || partyTypeTitle === '120minutesofallyoucanplay') {
			return MyArray.class[2];
		} else if (partyTypeTitle === 'ultimatesuperstar') {
			return MyArray.class[3];
		} else if (partyTypeTitle === 'vipmegasuperstar') {
			return MyArray.class[4];
		} else if (partyTypeTitle === 'classicparty') {
			return MyArray.class[5];
		} else if (partyTypeTitle === 'vipparty') {
			return MyArray.class[6];
		} else if (partyTypeTitle === 'primeparty') {
			return MyArray.class[7];
		} else if (partyTypeTitle === 'deluxeparty') {
			return MyArray.class[8];
		} else if (partyTypeTitle === 'ultimateparty') {
			return MyArray.class[9];
		} else if (partyTypeTitle === 'doitmyself') {
			return MyArray.class[10];
		}else if (partyTypeTitle === 'epic') {
			return MyArray.class[11];
		}else if (partyTypeTitle === 'blastparty') {
			return MyArray.class[12];
		}else if (partyTypeTitle === 'baseparty') {
			return MyArray.class[13];
		}else if (partyTypeTitle === 'diyparty') {
			return MyArray.class[14];
		}
		return MyArray.class[0];
		
	}

	changingPopUpMessagesforVisit(restype: number) {
		if (restype === Constants.ReservationTypes.VISITEVENT) {
			WebString.TIMESLOT = WebString.TIMESLOT.replace('Party', 'Visit');
			WebString.SELECT_PARTY_DATE = WebString.SELECT_PARTY_DATE.replace('Party', 'Visit');
			WebString.INFOUPDATE = WebString.INFOUPDATE.replace('Party', 'Visit');
			WebString.TIMESLOT_MAX_GUEST = WebString.TIMESLOT_MAX_GUEST.replace('party', 'visit');
			WebString.PACKAGE_MAX_GUESTS = WebString.PACKAGE_MAX_GUESTS.replace('Party', 'Visit');
		}
	}

	validateMinMaxOnPartyType(storeId: number, kids: number, adults: number, partytype: PartyTypeI): string {
		const totalGuests = kids + adults;

		// hack:  this should be configured in the back end at some point.  this was done for ppp go live critical issue 32264
		if (this.appConfig.ignoreCountValidationStoreIds.includes(storeId) && partytype.PriceAsPer === PricePer.Party) {
			return '';
		}

		if (partytype.MaximumGuest !== 0 && partytype.MaximumGuest < totalGuests) {
			let maxGuestAlert = WebString.PACKAGE_MAX_GUESTS;
			maxGuestAlert = maxGuestAlert.replace('{0}', partytype.MaximumGuest.toString());
			return maxGuestAlert;
		} else if (partytype.MinimumChildren > kids) {
			let minKidAlert = WebString.PACKAGE_MIN_CHILDREN;
			minKidAlert = minKidAlert.replace('{0}', partytype.PartyTypeTitle).replace('{1}', partytype.MinimumChildren.toString());
			return minKidAlert;
		} else {
			return '';
		}
	}

	validatePartyTypePricing(storeId: number, kids: number, adults: number, partyType: any, availableSeats?: number) {
		if (!storeId) {
			throw new Error('Store Id cannot be null or undefined');
		}
		const data = this.storageService.fetch();
		availableSeats = availableSeats
			? availableSeats
			: data.tempBookingInfo
				? data.tempBookingInfo.bookingInfo.AvailableSeat
				: data.bookingInfo.AvailableSeat;

		const totalAttendees = kids + adults;
		const totalGuests = partyType.TotalGuest;
		const ntimes = Math.ceil(totalAttendees / totalGuests);
		const response = {};
		let flag = false;
		let dialogRef;
		let message, yesButton, noButton;

		if (availableSeats >= totalGuests) {
			const remainingGuests = (ntimes * totalGuests) - totalAttendees;
			kids += Math.floor(remainingGuests / 2);
			adults += remainingGuests % 2 ? Math.floor(remainingGuests / 2) + 1 : Math.floor(remainingGuests / 2);
			const newTotalAttendees = kids + adults;
			if (availableSeats >= newTotalAttendees) {

				if (this.appConfig.ignoreCountValidationStoreIds.includes(storeId)) {
					flag = true;
				} else if (remainingGuests && totalAttendees > totalGuests) {
					message = WebString.MOREPARTYTYPEPRICING.replace('{0}', String(kids + adults));
					yesButton = 'Ok, upgrade';
					noButton = 'No, thanks';
					dialogRef = this.openDialog(message, yesButton, noButton);
				} else if (remainingGuests && totalAttendees < totalGuests) {
					message = WebString.LESSPARTYTYPEPRICING.replace('{0}', String(kids + adults));
					yesButton = 'Ok, confirm';
					noButton = 'No, thanks';
					dialogRef = this.openDialog(message, yesButton, noButton);
				} else {
					flag = true;
				}

				response['dialogRef'] = dialogRef;
				response['flag'] = flag;
				response['tempKids'] = kids;
				response['tempAdults'] = adults;
				return response;
			} else {
				return WebString.NOTAPPLICABLEPARTYTYPEPRICING;
			}
		} else {
			return WebString.NOTAPPLICABLEPARTYTYPEPRICING;
		}
	}

	private openDialog(message: string, yesButton: string, noButton: string) {
		return this.dialog.open(ConfirmationDialogComponent, {
			data: {
				message: message,
				buttonText: {
					ok: yesButton,
					cancel: noButton
				}
			}
		});
	}

	getEnreachedTimeslot(slots: Array<any>, totalAttendees: number): any[] {

		const availableSlots: any[] = [];
		slots.forEach((slot: any) => {
			const timeslot = {
				start: slot.TimeSlot,
				end: slot.EndTimeSlot,
				available: (slot.BookingStatus === BookingStatus.Available && totalAttendees <= slot.AvailableSeat),
				seat: slot.AvailableSeat,
				IsVipParty: slot.IsVipParty,
				bookingInfo: slot.bookingInfo,
				MinConfig: slot.MinConfig,
				MaxGuest: slot.MaxGuest,
				uuid: slot.uuid || null
			};

			// if not found then added it to available timeslots
			// if already there only replace if the new timeslot is available OR if the number of seats is less than the one currently in the aray.
			const idx = availableSlots.findIndex((x: any) => x.start === slot.TimeSlot);
			if (idx < 0) {
				availableSlots.push(timeslot);
			} else if (((availableSlots[idx] as any).available === false && timeslot.available) || (timeslot.available && (availableSlots[idx] as any).seat > timeslot.seat)) {
				availableSlots[idx] = timeslot;
			}
		});

		return availableSlots;
	}

	getDepositDueDate(partyDate: Date, noDays: number, today = new Date()): string {
		return moment.min([
			moment(today).add(noDays, 'days'),
			moment(partyDate).subtract(1, 'days')
		]).format('MM/DD');
	}

	calculateDepositAmount(storeId: number, bookingType: number, standardDeposit: number, partyRoomDeposit: number, kids: number, adults: number): number {

		// hack:  last minute critcal issue caused this...not me.
		// hack:  use + on values that should be number....  strict mode is not on so strings can be passed into method
		if (this.appConfig.alternateDepositRuleStoreIds.includes(storeId)) {
			const additionalCharge = +standardDeposit - (+kids + +adults);
			const deposit = additionalCharge < 0
				? standardDeposit + Math.abs(additionalCharge)
				: standardDeposit;

			return +bookingType === Constants.ReservationTypes.PARTYROOM
				? +partyRoomDeposit + deposit
				: deposit;
		}

		return bookingType === Constants.ReservationTypes.PARTYROOM
			? +partyRoomDeposit + +standardDeposit
			: standardDeposit;

	}

}
