import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RESERVATION_API_URL } from 'src/app/tokens';
import { v4 as uuidv4 } from 'uuid';
import { ReservationDetail } from '../models/reservation-detail';

@Injectable({ providedIn: 'root' })
export class ReservationApiService {

	constructor(@Inject(RESERVATION_API_URL) private reservationApiUrl: string, private http: HttpClient) { }

	getTimeSlot(partyDate: string, kids: number, adults: number, storeId: string, resType: string | number, tempId: number, existId: number) {

		const body = {
			PartyDate: partyDate,
			AdultCount: adults,
			ChildCount: kids,
			ReservationTypeId: resType,
			StoreId: storeId,
			TempBookingId: tempId,
			ReservationId: existId
		};

		return this.http.post(this.reservationApiUrl + '/Api/cec/Booking/Timeslots', body).pipe(
			map((response: any[]) => {
				const data = response.map(x => Object.assign(x, { uuid: uuidv4() }));
				const timeSlotsResponse = {
					AvailabilityDates: {
						[0]: {
							ReservationTimeSlots: data
						}
					}
				};
				return timeSlotsResponse;
			})
		);
	}

	cancelReservation(body: any): Observable<ReservationDetail> {
		return this.http.post<ReservationDetail>(this.reservationApiUrl + '/Api/cec/Booking/Cancel', body);
	}

	// todo: for now take controller name...but eventually create diff method.
	applyChanges(controllerName: string, body: any) {
		return this.http.post(this.reservationApiUrl + '/Api/CEC/Booking/' + controllerName, body);
	}

	duplicateCheck(reservation: any) {
		return this.http.post(this.reservationApiUrl + '/Api/cec/Booking/DuplicateCheck', reservation);
	}

	saveReservation(reservation: any) {
		return this.http.post(this.reservationApiUrl + '/Api/CEC/Booking/Request', reservation);
	}
}
