<app-message [alert]="alertMessage"></app-message>

<div class="upsell-modal PPP-wrapper">

  <app-loader *ngIf="isLoading"></app-loader>

  <div class="m-modal">
    <div class="m-modal__container">
      <div class="m-modal__header">
        <h4 *ngIf='localData.booking.type' class="m-modal__title">Edit {{localData.booking.type == 4? 'Visit':'Party'}}
          Information</h4>
        <span class="m-modal__close" (click)="closeModalOnX()">
          <img id="close_button_editPage" src="{{assets}}/assets/close-white.png" />
        </span>
      </div>
      <div class="m-modal__body">
        <div class="c-tabs">
          <div class="c-tabs__content">
            <div class="c-tabs__items--content">
              <div class="col-sm-12 party-info">
                <div class="row">
                  <div class="col-sm-6 children" id="children_textbox_editPage">
                    <select id="select_children_editPage" #child class="c-custom-form__textbox"
                      (change)="kids($event.target.value)" [(ngModel)]="kidsValue">
                      <option disabled selected>Children<span class="must">*</span></option>
                      <option id="children_{{kid}}_editPage" *ngFor="let kid of minKids" value={{kid}}>{{kid}}</option>
                    </select>
                    <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                          <g id="Group-4" transform="translate(19.000000, 180.000000)">
                            <path
                              d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                              id="Fill-1"
                              transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) ">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="col-sm-6 adults" id="adult_textbox_editPage">
                    <select id="select_adult_editPage" #adult class="c-custom-form__textbox"
                      (change)="adults($event.target.value)" [(ngModel)]="adultsValue">
                      <option disabled selected>Adults<span class="must">*</span></option>
                      <option id="adult_{{adult}}_editPage" *ngFor="let adult of minAdults" value={{adult}}>
                        {{adult}}
                      </option>
                    </select>
                    <svg class="downArrow" width="16px" height="9px" viewBox="0 0 16 9" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                          <g id="Group-4" transform="translate(19.000000, 180.000000)">
                            <path
                              d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                              id="Fill-1"
                              transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) ">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 date date_textbox_editPage" id="date_textbox_editPage">
                    <form id="select_date_editPage" #myForm="ngForm" novalidate class="c-custom-form"
                      style="height: 100px; position: relative; padding: 0;">
                      <my-date-picker id="date_picker_editPage" style="
                                        top: 50%;
                                        position: absolute;
                                        right: 24px;
                                        left: 20px;
                                        z-index: 995;
                                        transform: translateY(-50%); margin-top: -10px;" name="mydate" [options]="myDatePickerOptions"
                        [(ngModel)]="mydate" placeholder={{dateofParty}} (dateChanged)="onDateChanged($event)" required>
                      </my-date-picker>
                    </form>
                  </div>
                  <div class="col-sm-6 time" id="time_textbox_editPage">
                    <div class="party-room" *ngIf="localData?.store?.hasPartyRooms && [1,5].includes(+resType)">
                      <input name="partyRoom" id="partyRoom" type="checkbox" [value]="false" [(ngModel)]="partyRoom"
                        (ngModelChange)="changePartyRoomHandler($event)">
                      <label class="checkbox-label" for="partyRoom">&nbsp;Select a private party room<br>
                        Requires an additional ${{localData.booking.AdditionalDepositPartyRoom}} non-refundable
                        deposit<br><br></label>
                    </div>

                    <select id="select_time_editPage" (change)="partyTime($event.target.value)"
                      class="c-custom-form__textbox time-slots-list" [(ngModel)]="selectedPartyTimeIndex">
                      <option *ngIf='timeofParty' value={{timeofParty}} disabled selected>{{timeofParty}}</option>
                      <option id="timeslot_{{i}}_editPage" *ngFor="let slot of availableSlot; let i = index " value={{i}} [disabled]="!slot.available"  [ngClass]="{'grey':!slot.available, 'white': slot.available}">
                        {{ slot.start | displayTimeSlotTime }} - {{ slot.end | displayTimeSlotTime }} &nbsp;&nbsp;&nbsp;
                        <span *ngIf="slot.IsVipParty">VIP &nbsp;</span>
                        <span *ngIf="!slot.available">Full</span>
                      </option>
                    </select>
                    <svg class="downArrow party-room_dd-svg" width="16px" height="9px" viewBox="0 0 16 9" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="CEC-Mobile---FAQ-Expand" transform="translate(-269.000000, -196.000000)" fill="#363F54">
                          <g id="Group-4" transform="translate(19.000000, 180.000000)">
                            <path
                              d="M264.752955,16.4649608 C265.265958,16.6131244 265.106515,16.9597213 264.926045,17.2249955 C264.54923,17.7792164 264.155984,18.3296776 263.710519,18.828755 C262.386098,20.3124797 261.04051,21.7774055 259.687821,23.2353687 C259.153513,23.811313 258.614749,24.3953337 258.001764,24.8789542 C257.797064,25.0402075 257.230728,25.0417392 257.04065,24.8751944 C255.602878,23.6160823 254.224985,22.2888763 252.812557,21.0001036 C252.097083,20.3472926 251.365455,19.7092422 250.610294,19.1029412 C249.927684,18.555265 249.847475,18.2674322 250.221783,17.6220015 C250.504602,17.1342035 250.909964,17.0364489 251.333149,17.2794428 C252.087753,17.7126542 252.840268,18.1714879 253.515498,18.7165183 C254.75609,19.7180151 255.962147,20.7657434 257.134784,21.8461958 C257.519536,22.2004516 257.730502,21.9734716 257.941607,21.7611131 C258.867769,20.8302168 259.79407,19.8972318 260.673026,18.922332 C261.259274,18.272306 261.713513,17.4986247 262.327194,16.8796517 C262.712225,16.49114 263.282877,16.2869973 263.770257,16 C263.778752,16.0621062 263.787385,16.1243516 263.79588,16.1864578 C264.114905,16.2793385 264.433791,16.3727763 264.752955,16.4649608 Z"
                              id="Fill-1"
                              transform="translate(257.549789, 20.500000) rotate(-360.000000) translate(-257.549789, -20.500000) ">
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-sm-12 model_footer -custom-modal-footer">
                <button type="button" class="btn btn-primary btn-md" [disabled]="alertMessage.show"
                  (click)="update()" id="update_package"> Update
                </button>
              </div>
              <div *ngIf="myrewardsList">
                <div *ngFor="let reward of myrewardsList">
                  <tr>
                    <td>
                      <span> *<span>{{reward.RewardTitle}}</span></span>
                    </td>
                  </tr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
