import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { PartyTypeI } from 'src/app/models';
import { AppConfig } from 'src/app/models/app-config';
import { ContentApiService } from 'src/app/services/content-api.service';
import { LoggingService } from 'src/app/services/logging.service';
import { APP_CONFIG } from 'src/app/tokens';
import { environment } from '../../../environments/environment';
import { Promotion } from '../../2.0/models/promotion';
import { Constants } from '../../constants/constants';
import { WebString } from '../../constants/string';
import { Alert } from '../../models/alerts';
import { Popup } from '../../models/popup';
import { BookingApiService } from '../../services/booking-api.service';
import { GTMService } from '../../services/gtm.service';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';
import { MessageComponent } from '../message/message.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';

@Component({
	selector: 'app-package-info',
	templateUrl: './package-info.component.html',
	styleUrls: ['./package-info.component.scss']
})
export class PackageInfoComponent implements OnInit, AfterContentInit {
	@ViewChild(SideMenuComponent) sideMenu: SideMenuComponent;
	@ViewChild(MessageComponent) message: MessageComponent;

	alertMessage = new Alert();
	assetRoot = environment.assetRoot;
	closeResult: string;
	currentId: string;
	depositAmount: number;
	includeBlock: string;
	isDepositRequired: boolean;
	isLoading = false;
	localStorage: any = {};
	partyitems: string;
	partyTypes: PartyTypeI[] = [];
	popUp = new Popup();
	promotion = new Promotion();
	restype: number;
	selectedPartyTypeId = 0;
	sfContent: any;
	showCompare = false;
	showPromotion = false;
	tempAdults: number;
	tempKids: number;
	title = WebString.PACKAGE;

	readonly partyTypePricing = Constants.PriceAsPer.PartyType;

	constructor(@Inject(APP_CONFIG) private appConfig: AppConfig, private storageService: StorageService, private router: Router, private logger: LoggingService,
		private contentApiService: ContentApiService, private bookingApiService: BookingApiService, private gtmService: GTMService, private utilsService: UtilsService) { }

	ngOnInit() {
		this.isLoading = true;

		const tempLocalData = this.storageService.fetch();

		//clear if package ID is exist on localstorage.
		delete tempLocalData.packageId;
		delete tempLocalData.partyTypeTitle;
		this.localStorage = tempLocalData;
		this.storageService.clear();
		this.storageService.store(tempLocalData);
		
		
		this.partyTypes = this.encodePartyTypeImageUrls(this.localStorage.bookingInfo?.PartyTypes);
		this.isDepositRequired = this.localStorage.booking.isDepositRequired;
		this.restype = parseInt(this.localStorage.booking.type);
		this.updateDepositAmount();

		// Get All Packages Information
		let contentBlocks;
		if (this.restype === Constants.ReservationTypes.BIRTHDAYPARTY || this.restype === Constants.ReservationTypes.PARTYROOM) {
			this.partyitems = 'BirthdayPartyPackagesIncludeBlock';
			contentBlocks = ['BirthdayPartyPackagesIncludeBlock',
				'PrimePartyTypeDescription',
				'DeluxePartyTypeDescription',
				'UltimatePartyTypeDescription',
				'DoItMySelfPartyTypeDescription',
				'VIPPartyTypeDescription'
			];
		} else if (this.restype === Constants.ReservationTypes.GROUPEVENT) {
			this.partyitems = 'GroupEventEveryPartyIncludesBlock';
			contentBlocks = ['GroupEventEveryPartyIncludesBlock',
				'60MinPartyTypeDescription',
				'90MinPartyTypeDescription',
				'120MinPartyTypeDescription'
			];
		} else if (this.restype === Constants.ReservationTypes.VISITEVENT) {
			this.partyitems = 'VisitEveryPartyIncludesBlock';
			contentBlocks = ['VisitEveryPartyIncludesBlock',
				'HalloweenPartyTypeDescription'
			];
		}
		this.contentApiService.get(contentBlocks).subscribe((response: any) => {
			this.isLoading = false;
			this.sfContent = response;
			if (this.sfContent.Contents && this.sfContent.Contents[this.partyitems]) {
				this.includeBlock = this.sfContent.Contents[this.partyitems];
			}

			this.updateParty(this.sfContent);
			this.gtmService.pushCoreViewDataToGTM('');
		},
			(error: HttpErrorResponse) => {
				this.logger.logError(error);
			}
		);
	}

	ngAfterContentInit() {
		this.updateFocusPartyType();
	}

	updateParty(sfContent) {
		let packageTitles;
		if (this.partyTypes.length) {
			for (let i = 0; i < this.partyTypes.length; i++) {
				packageTitles = this.partyTypes[i].DisplayTypeTitle;
				this.partyTypes[i].ClassColor = this.utilsService.updateColor(packageTitles);
				this.partyTypes[i].TextColor = this.partyTypes[i].CallOutColor.toLowerCase() === 'white' ? 'slategray' : 'white';
				this.updatePartytypeDescription(sfContent);
			}
		}
	}

	private updateFocusPartyType() {

		const partyTypeCondition = (partyType) => partyType.CallOutOption === 'Best Value' || partyType.PromotionCategoryID === this.localStorage.requestedPartyType;
		const partyTypes = this.localStorage.bookingInfo.PartyTypes;
		const selectedParty = partyTypes.find(partyTypeCondition);
		if (selectedParty) {
			this.selectedPartyTypeId = selectedParty.ID;
		}
	}

	private updatePartytypeDescription(sfContent: any) {
		if ([Constants.ReservationTypes.BIRTHDAYPARTY, Constants.ReservationTypes.PARTYROOM].includes(this.restype)) {
			this.partyTypes.map(partytype => {

				if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.PRIME) {
					partytype.PartyTypesDescription = sfContent.Contents['PrimePartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.DELUXE) {
					partytype.PartyTypesDescription = sfContent.Contents['DeluxePartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.ULTIMATE) {
					partytype.PartyTypesDescription = sfContent.Contents['UltimatePartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.DOITMYSELF) {
					partytype.PartyTypesDescription = sfContent.Contents['DoItMySelfPartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.VIPMEGASUPERSTAR) {
					partytype.PartyTypesDescription = sfContent.Contents['VIPPartyTypeDescription'];
				} else {
					partytype.PartyTypesDescription = sfContent.Contents['PrimePartyTypeDescription'];
				}
				return partytype;

			});
		} else if (this.restype === Constants.ReservationTypes.GROUPEVENT) {
			this.partyTypes.map(partytype => {
				if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.PLAY60) {
					partytype.PartyTypesDescription = sfContent.Contents['60MinPartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.PLAY90) {
					partytype.PartyTypesDescription = sfContent.Contents['90MinPartyTypeDescription'];
				} else if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.PLAY120) {
					partytype.PartyTypesDescription = sfContent.Contents['120MinPartyTypeDescription'];
				} else {
					partytype.PartyTypesDescription = sfContent.Contents['60MinPartyTypeDescription'];
				}
				return partytype;
			});
		} else if (this.restype === Constants.ReservationTypes.VISITEVENT) {
			this.partyTypes.map(partytype => {
				if (partytype.PromotionCategoryID === Constants.PartyPromotionCategoryIds.HALLOWEEN) {
					partytype.PartyTypesDescription = sfContent.Contents['HalloweenPartyTypeDescription'];
				} else {
					partytype.PartyTypesDescription = sfContent.Contents['VisitEveryPartyIncludesBlock'];
				}
				return partytype;
			});
		}
	}

	escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	replaceAll(str, term, replacement) {
		return str.replace(new RegExp(this.escapeRegExp(term), 'g'), replacement);
	}

	checkUpsell(partyName: string, id: string, index: number) {
		const local = this.storageService.fetch();
		const storeId = parseInt(local.booking.store, 10);

		if (local.bookingInfo.PartyTypes[index].PriceAsPer === Constants.PriceAsPer.PartyType) {
			const partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, local.kids, local.adults, local.bookingInfo.PartyTypes[index]);
			if (partyTypePricing) {
				if (typeof partyTypePricing === 'string') {
					return this.showErrorPopup(WebString.ERROR, partyTypePricing);
				}
				if (partyTypePricing['flag']) {
					this.partyTypeSelection(partyName, id, index);
				} else {
					partyTypePricing['dialogRef'].afterClosed().subscribe(dialogResult => {
						if (dialogResult) {
							this.tempKids = partyTypePricing['tempKids'];
							this.tempAdults = partyTypePricing['tempAdults'];
							const data = {
								kids: partyTypePricing['tempKids'],
								adults: partyTypePricing['tempAdults']
							};
							this.storageService.store(data);
							this.partyTypeSelection(partyName, id, index);
						}
					});
				}
			}
		} else {
			const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, local.kids, local.adults, local.bookingInfo.PartyTypes[index]);
			if (packageValidation) {
				return this.showErrorPopup(WebString.ERROR, packageValidation);
			} else {
				this.partyTypeSelection(partyName, id, index);
			}
		}
	}

	upsellResponse(event) {
		this.popUp.showPromotionPopup = false;

		if (event) {
			const local = this.storageService.fetch();
			const storeId = parseInt(local.booking.store, 10);
			let partyTypeFlag = false;
			local.bookingInfo.PartyTypes.forEach((element, index) => {
				if (element.ID === this.currentId) {
					if (local.bookingInfo.PartyTypes[index + 1].PriceAsPer === Constants.PriceAsPer.PartyType) {
						const partyTypePricing = this.utilsService.validatePartyTypePricing(storeId, local.kids, local.adults, local.bookingInfo.PartyTypes[index + 1]);
						if (partyTypePricing) {
							if (typeof partyTypePricing === 'string') {
								return this.showErrorPopup(WebString.ERROR, partyTypePricing);
							}
							if (partyTypePricing['flag']) {
								partyTypeFlag = true;
							} else {
								partyTypePricing['dialogRef'].afterClosed().subscribe(dialogResult => {
									if (dialogResult) {
										const data = {
											kids: partyTypePricing['tempKids'],
											adults: partyTypePricing['tempAdults']
										};
										this.storageService.store(data);
										this.select_package(local.bookingInfo.PartyTypes[index + 1]['ID']);
										this.gtmService.pushUpgradePackageData(event);
										return;
									}
								});
							}
						}
					} else {
						const packageValidation = this.utilsService.validateMinMaxOnPartyType(storeId, local.kids, local.adults, local.bookingInfo.PartyTypes[index + 1]);
						if (packageValidation) {
							return this.showErrorPopup(WebString.ERROR, packageValidation);
						} else {
							partyTypeFlag = true;
						}
					}
					if (partyTypeFlag) {
						this.select_package(local.bookingInfo.PartyTypes[index + 1]['ID']);
						this.gtmService.pushUpgradePackageData(event);
						return 0;
					}
				}
			});
		} else {
			if (this.tempKids) {
				const data = {
					kids: this.tempKids,
					adults: this.tempAdults
				};
				this.storageService.store(data);
			}
			this.select_package(this.currentId);
			this.gtmService.pushUpgradePackageData(event);
		}

	}

	updateDepositAmount() {
		// hack here to calc deposit amount based on store id
		const storeId = parseInt(this.localStorage.booking.store, 10);
		const kids = parseInt(this.localStorage.kids, 10);
		const adults = parseInt(this.localStorage.adults, 10);
		const standardDepositAmount = parseInt(this.localStorage.booking.deposit, 10);
		const partyRoomDeposit = parseInt(this.localStorage.booking.AdditionalDepositPartyRoom, 10);

		return this.utilsService.calculateDepositAmount(storeId, this.restype, standardDepositAmount, partyRoomDeposit, kids, adults);
	}

	updateInfo(event) {
		if (!event) {
			return;
		}

		this.localStorage = this.storageService.fetch();
		this.restype = parseInt(this.localStorage.booking.type);
		if (this.localStorage.bookingInfo !== undefined) {
			this.partyTypes = this.encodePartyTypeImageUrls(this.localStorage.bookingInfo.PartyTypes);
		}

		this.updateParty(this.sfContent);
		this.updateFocusPartyType();
		this.updateDepositAmount();
	}

	encodePartyTypeImageUrls(partyTypes: PartyTypeI[]): PartyTypeI[] {

		return partyTypes.map((partyType, index) => {
			const imageSrcArr = partyType.ImagePath.split('/');
			const lastURI = imageSrcArr[imageSrcArr.length - 1];
			const spaceExistRegex = /\s/;
			if (spaceExistRegex.test(lastURI)) {
				imageSrcArr[imageSrcArr.length - 1] = encodeURIComponent(lastURI);
			}
			const updatedImageUri = imageSrcArr.join('/');
			return { ...partyType, ImagePath: updatedImageUri };
		});

	}

	select_package(id: string) {
		document.getElementById('head').scrollIntoView({
			behavior: 'smooth'
		});
		this.alertMessage.show = true;
		this.alertMessage.className = WebString.INFO;
		this.alertMessage.message = WebString.SELECTPACKAGE;
		this.isLoading = true;
		const data = this.storageService.fetch();
		const date = data.date.year + '-' + data.date.month + '-' + data.date.day;
		const existingResId = data.existingReservationId || 0;
		const reservationId = data.reservationId || 0;
		this.bookingApiService.bookPackage(data.adults, data.kids, date, data.time.start, data.booking.store,
			data.booking.type, reservationId, existingResId, id).subscribe(response => {
				this.isLoading = false;
				this.alertMessage.show = false;
				if (response.Message.IsOk) {
					// add promo reward if any from previous reservation context/change location scenarios
					if (data.reservationContext) {
						const promoReward = data.reservationContext.Reservation.ReservationRewards.find(({ RewardTypeID }) => RewardTypeID === 3);
						if (promoReward) {
							response.Reservation.ReservationRewards.push(promoReward);
							this.storageService.store({ reservationContext: response });
							response = this.sideMenu.reValidatePromoReward(response);
							const localData = this.storageService.fetch();
							response.Reservation.ReservationRewards = localData.reservationContext.Reservation.ReservationRewards;
						}
					}
					const storeData = {
						reservationContext: response
					};
					this.storageService.store(storeData);
					const storeData2 = {
						reservationId: response.Reservation.TempBookingSeatID,
						packageId: id,
						partyTypeTitle: response.PartyType.PartyTypeTitle
					};
					this.storageService.store(storeData2);
					this.gtmService.pushBirthdayPartySteps(2, undefined);
					this.router.navigate(['/payment']);
				} else {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = response.Message.Error;
				}
			},
				error => {
					this.alertMessage.show = true;
					this.alertMessage.className = WebString.ERROR;
					this.alertMessage.message = error.message;
				});
	}

	compare() {
		this.popUp.showComparePopup = true;
	}

	showErrorPopup(errorType: string, errorMsg: string) {
		this.alertMessage.show = true;
		this.alertMessage.className = errorType;
		this.alertMessage.message = errorMsg;
		this.message.clear();
	}

	scroll(id: number) {

		$('html, body').animate({
			scrollTop: $('#' + id.toString()).offset().top - 132
		}, 1000);

	}

	private partyTypeSelection(partyName: string, id: string, index: number) {
		let match = 0;
		this.currentId = id;
		const local = this.storageService.fetch();
		let upsellCount = local.upsell;
		const promotionArr = local.booking.promotions;
		if (upsellCount < WebString.maxUpsell && promotionArr.length && ((index + 1) < local.bookingInfo.PartyTypes.length)) {
			upsellCount += 1;
			const data = {
				upsell: upsellCount
			};
			this.storageService.store(data);
			partyName = partyName.split(' ')[0].toLowerCase();
			promotionArr.forEach(element => {
				if (partyName === element['Name'].split(' ')[0].toLowerCase()) {
					this.promotion.Image = element.IconPath.startsWith('http') ? (element.IconPath) : (element.IconPath).replace(/[/]+/g, '/');
					this.promotion.Title = element.Title;
					this.promotion.Description = element.Description;
					// hack:  ppp has no promotions at this point.. will need to change when they do.
					match = this.appConfig.preventPromotionsPopup ? 0 : 1;
				}
			});

			this.promotion.Description = this.replaceAll(this.promotion.Description, '- ', '<br>- ');

			if (match === 1) {
				this.popUp.showPromotionPopup = true;
				this.gtmService.pushEvent({
					event: 'upgradePackageOverlay'
				});
			} else {
				this.select_package(id);
			}

		} else {
			this.select_package(id);
		}
	}
}
