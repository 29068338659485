import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, APP_INSIGHTS_INSTRUMENTATION_KEY, CONTENT_API_URL, FREEDOMPAY_API_URL, FREEDOMPAY_URL, MINI_PACKAGES_STORES, RESERVATION_API_URL, EXCLUDEDSTORES } from './app/tokens';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic([
	{ provide: RESERVATION_API_URL, useValue: environment.apiDomain },
	{ provide: CONTENT_API_URL, useValue: environment.packageContentAPI },
	{ provide: FREEDOMPAY_URL, useValue: environment.freedomPayUrl },
	{ provide: FREEDOMPAY_API_URL, useValue: environment.freedomPayApiDomain },
	{ provide: MINI_PACKAGES_STORES, useValue: environment.miniPackageStores },
	{ provide: EXCLUDEDSTORES, useValue: environment.excludedStores },
	{ provide: APP_INSIGHTS_INSTRUMENTATION_KEY, useValue: environment.appInsights.instrumentationKey },
	{
		provide: APP_CONFIG, useValue: {
			ignoreCountValidationStoreIds: environment.ignoreCountValidationStoreIds,
			alternateDepositRuleStoreIds: environment.alternateDepositRuleStoreIds,
			preventPromotionsPopup: environment.preventPromotionsPopup
		}
	}
]).bootstrapModule(AppModule)
	// eslint-disable-next-line no-console
	.catch(err => console.error(err));

